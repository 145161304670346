import io from 'socket.io-client'

export let socket
export let socketNotifications
// const localIP = 'localhost'
// const networkIP = '172.20.0.1'
// const structuredPort = 5000
// const notificationsPort = 5003
// const port = 5005
// const networkConnection = false

function getdashboarApiUrl () {
  // var u = new URL(window.location.href)
  // var apiUrl = u.protocol + '//' + u.hostname + `:${port}`
  var apiUrl = 'https://dashboard-api.idg.gaiavf.tech/'
  console.log('getdashboarApiUrl: ' + apiUrl)
  return apiUrl
}

// export const dashboarApiUrl = `http://localhost:${port}`
export const dashboarApiUrl = getdashboarApiUrl()

export function initialiseSocket () {
  if (!socket) {
    // const url = networkConnection ? `http://${networkIP}:${port}/growing_dashboard` : dashboarApiUrl + '/growing_dashboard'
    // const url = 'https://www.idg.gaiavf.tech/dashboard-api/growing_dashboard'
    const url = 'https://dashboard-api.idg.gaiavf.tech/growing_dashboard'
    socket = io(url, { transports: ['websocket'], upgrade: true })
  }
}

function getStructuredApiUrl () {
  // var u = new URL(window.location.href)
  // var apiUrl = networkConnection ? `http://${networkIP}:${structuredPort}/structured-data-api/v1.0` : `${u.protocol}//${u.hostname}:${structuredPort}/structured-data-api/v1.0`
  var apiUrl = 'https://www.idg.gaiavf.tech/structured-data-api/v1.0'
  console.log('getStructuredApiUrl: ' + apiUrl)
  return apiUrl
}

export const structuredApiUrl = getStructuredApiUrl()

function getNotificationsApiUrl () {
  // var u = new URL(window.location.href)
  // var apiUrl = networkConnection ? `http://${networkIP}:${notificationsPort}/notifications-api/v1.0` : `${u.protocol}//${u.hostname}:${notificationsPort}/notifications-api/v1.0`
  var apiUrl = 'https://www.idg.gaiavf.tech/notifications-api/v1.0'
  console.log('getNotificationsApiUrl: ' + apiUrl)
  return apiUrl
}

export const notificationsApiUrl = getNotificationsApiUrl()

export function initialiseSocketNotifications () {
  if (!socketNotifications) {
    socketNotifications = io(notificationsApiUrl, { transports: ['websocket'], upgrade: false })
  }
}
