import mutations from './mutations.js'
import getters from './getters.js'
// import actions from './actions.js'
export default {
  state () {
    return {
      token: null,
      role: null,
      user: null,
      preferred_username: null,
      needs_sidebar: null,

    }
  },
  mutations,
  // actions,
  getters,
}
