<template>
  <div class="header">
    <div class="row">
      <div class="flex xs9 header__name">
        {{name}}
      </div>

      <template v-if="context === 'line'">
        <div class="flex xs3 searchField">
          <va-input
            :value="termSearch"
            :placeholder="$t('tables.search')"
            @input="search"
            removable
          >
            <va-icon name="at-icon l_search" slot="prepend" />
          </va-input>
        </div>
      </template>
      <template>
        <slot name="timelineHeaderCustom" />
      </template>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  components: {},
  name: 'AtIrrigationMapHeader',
  props: {
    name: {
      type: String,
    },
    context: {
      type: String,
    },
  },
  data () {
    return {
      is15min: false,
      isEvents: true,
      termSearch: null,
    }
  },
  mounted () {},
  methods: {
    toogleShowSummaries () {
      this.$emit('toogleShowSummaries')
    },
    setDataPoint (dataPoint) {
      if (dataPoint === '15min') {
        this.is15min = true
        this.isEvents = false
      } else if (dataPoint === 'events') {
        this.is15min = false
        this.isEvents = true
      } else {
        this.is15min = false
        this.isEvents = false
      }

      this.$emit('setDataPoint', {
        dataPoint: dataPoint,
      })
    },
    search: debounce(function (termSearch) {
      if (termSearch !== this.termSearch) {
        this.termSearch = termSearch
        this.$emit('searchTerm', termSearch)
      }
    }, 1000),
  },
  watch: {},
  computed: {},
}
</script>

<style lang="scss">
.header {
  @include shadow($offset-y: 0, $offset-x: 0, $radius: 15px, $alpha: 0.3);

  padding: 0 1rem;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 10000;
  height: 70px;

  &__name {
    display: flex;
    align-items: center;
    font-size: pxtorem(28);
    color: var(--primary-color);
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    letter-spacing: pxtorem(1.5);
  }

  &__edit {
    button {
      color: var(--primary-color);
    }
  }
}

.searchField {
  .va-input__container__input {
    padding-bottom: 0.5em !important;
  }
}
</style>
