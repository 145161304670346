<template>
  <div class="at-recipe-dropover-options" v-if="visible">
    <ul>
      <li v-if="details" @click="onOptionClick('details')">
        <va-icon :name="'at-icon l_list'" />
        <span>{{ $t(`buttons.details`) }}</span>
      </li>
      <li v-else @click="onOptionClick('edit')">
        <va-icon :name="'at-icon l_edit2'" />
        <span>{{ $t(`buttons.edit`) }}</span>
      </li>
      <li @click="onOptionClick('duplicate')">
        <va-icon :name="'at-icon l_duplicate'" />
        <span>{{ $t(`buttons.duplicate`) }}</span>
      </li>
      <li v-if="!details" @click="onOptionClick('delete')">
        <va-icon :name="'at-icon l_delete2'" />
        <span>{{ $t(`buttons.delete`) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'at-recipe-dropover-options',
  components: {
  },
  props: {
    show: {
      type: String,
      default: '',
    },
    details: {
      type: Boolean,
      default: false,
    },
    recipeSettingId: {
      type: Number,
    },
    variableId: {
      type: Number,
    },
    day: {
      type: Number,
    },
  },
  data () {
    return {
      visible: false,
    }
  },
  computed: {
    computedIconStyle () {
      return {
      }
    },
    computedPopoverStyle () {
      return {
      }
    },
  },
  watch: {
    show: function (newVal, oldVal) {
      this.visible = this.show === `${this.recipeSettingId}-${this.variableId}-${this.day}`
    },
  },
  methods: {
    onOptionClick (option) {
      this.$emit('onOptionClick', {
        option: option,
        recipeSettingId: this.recipeSettingId,
        variableId: this.variableId,
        day: this.day,
      })
    },
  },
}
</script>

<style lang="scss">
.at-recipe-dropover-options {
  @include shadow($radius: var(--shadow-radius));

  position: absolute;
  height: 50px;
  top: -58px;
  left: -10px;
  padding: 0;
  display: inline-block;
  background: #ffffff;
  border-radius: 5px;
  z-index: 10;

  &::after {
    content: '';
    position: absolute;
    left: 15px;
    margin: 0 auto;
    border-top: 5px solid #ffffff;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    bottom: -5px;
  }

  ul {
    white-space: nowrap;

    li {
      display: inline-block;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;

      &:not(:first-of-type) {
        margin: 0 5px 0 0;
      }

      .va-icon,
      span {
        display: block;
        text-align: center;
        font-size: pxtorem(10);
      }

      .va-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
      }

      // &:hover {
      // }
    }
  }
}
</style>
