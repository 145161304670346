<template>
  <aside
    class="app-sidebar"
    :class="computedClass"
    :style="computedStyle"
  >
    <ul class="app-sidebar__menu">
      <template v-for="(item, key) in items">
        <app-sidebar-link-group
          v-if="item.children"
          :key="key"
          :minimized="minimized"
          :icon="item.meta && item.meta.iconClass"
          :title="$t(item.displayName)"
          :children="item.children"
          :active-by-default="hasActiveByDefault(item)"
        >
          <app-sidebar-link
            v-for="(subMenuItem, key) in item.children"
            :key="key"
            :to="{ name: subMenuItem.name }"
            :title="$t(subMenuItem.displayName)"
            @click.native="minizeMobile()"
          />
        </app-sidebar-link-group>
        <app-sidebar-link
          v-else
          :key="key"
          :minimized="minimized"
          :active-by-default="item.name === $route.name"
          :icon="item.meta && item.meta.iconClass"
          :to="{ name: item.name }"
          :title="$t(item.displayName)"
          @click.native="minizeMobile()"
        />
      </template>
    </ul>
  </aside>
</template>

<script>
import { navigationRoutes } from './NavigationRoutes'
import AppSidebarLink from './components/AppSidebarLink'
import AppSidebarLinkGroup from './components/AppSidebarLinkGroup'
import { ColorThemeMixin } from '../../../services/vuestic-ui'

export default {
  name: 'app-sidebar',
  inject: ['contextConfig'],
  components: {
    AppSidebarLink,
    AppSidebarLinkGroup,
  },
  mixins: [ColorThemeMixin],
  props: {
    minimized: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      default: 'secondary',
    },
    mobileWidth: {
      type: Number,
      default: 767,
    },
  },
  data () {
    return {
      items: navigationRoutes.routes,
    }
  },
  created () {
    this.get_menu_items_by_role()
  },
  computed: {
    computedClass () {
      return {
        'app-sidebar--minimized': this.minimized,
        'app-sidebar--danger': this.$status.value === 'danger',
        'app-sidebar--warning': this.$status.value === 'warning',
      }
    },
    computedStyle () {
      const style = {
        /* Update styles via JS Helpers (currently they are being changed in a global watcher) */
        // '--primary-color-hue': hex2hsl(this.$themes.primary).h,
        // '--primary-color': this.$themes.primary,

        /* Alternative: Update styles via JS Helpers */
        // background: styleHelpers.navGradient(this.$themes.primary, this.$status.value),
        // boxShadow: styleHelpersgenShadow(this.$themes.primary, { radius: 6 }),
        // borderRadius: styleHelpersgenRadius({ bottomRight: '24px' }),
      }

      return style
    },
  },
  methods: {
    hasActiveByDefault (item) {
      return item.children.some(child => child.name === this.$route.name || child.name === this.$route.meta.parentName)
    },
    get_menu_items_by_role () {
      this.items = this.items.filter(el => {
        if (!el.meta.authorize) {
          return true
        }
        // at least one assigned role inside all roles required
        if (el.meta.authorize.length > 0 && el.meta.authorize.some(r => this.$store.getters.get_user_roles.includes(r))) {
          // console.log(el)
          // console.log(el.children)
          if (el.children) {
            el.children = el.children.filter(children => {
              if (!children.meta || !children.meta.authorize) {
                return true
              }
              if (children.meta.authorize.length > 0 && children.meta.authorize.some(r => this.$store.getters.get_user_roles.includes(r))) {
                return true
              }
              return false
            })
          }
          return true
        }
        return false
      })
    },
    minizeMobile () {
      if (!this.checkIsDesktop()) {
        this.$emit('update:minimized', true)
      }
    },
    checkIsDesktop () {
      return window.matchMedia(`(min-width: ${this.mobileWidth}px)`).matches
    },
  },
}

</script>

<style lang="scss">
.app-sidebar {
  @include nav-gradient();
  @include shadow($radius: var(--shadow-radius));
  @include sidebar-radius();

  overflow-x: hidden;
  display: flex;
  // max-height: 100%;
  height: calc(100vh - 80px);
  flex: 0 0 16rem;

  @include media-breakpoint-down(sm) {
    flex: 0 0 100%;
  }

  // &--minimized {
  //   flex: 0 0 3.25rem;
  // }

  &--minimized {
    flex: 0 0 0;
  }

  &--danger {
    @include nav-gradient-alert();
  }

  &--warning {
    @include nav-gradient-state();
  }

  &__menu {
    margin-bottom: 0;
    padding-top: 2.5625rem;
    padding-bottom: 2.5rem;
    list-style: none;
    padding-left: 0;
    width: 100%;
  }
}
</style>
