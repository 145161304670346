import Vue from 'vue'
import moment from 'moment-timezone'

export const changeToFactorytime = (timestampUTC) => {
  return moment.utc(timestampUTC).tz(Vue.farmTimezone ? Vue.farmTimezone : 'Europe/Rome')
}

export const getInFactoryTime = (timestamp) => {
  return moment.utc(timestamp).tz(Vue.farmTimezone ? Vue.farmTimezone : 'Europe/Rome')
}

export const getTimeDateFormat = (timestamp, style, isUTC) => {
  moment.locale(Vue.i18n.locale())
  if (isUTC) {
    timestamp = changeToFactorytime(timestamp)
  }
  let timeDate = ''
  switch (style) {
    case 'super_short':
      timeDate = timestamp.format('DD-MM, HH:mm')
      break
    case 'short':
      timeDate = timestamp.format('DD-MM-YYYY, HH:mm')
      break
    case 'medium':
      timeDate = timestamp.format('D MMM YYYY<br>HH:mm:ss')
      break
    case 'long':
      timeDate = timestamp.format('ddd, D MMM YYYY<br>HH:mm:ss z')
      break
  }
  return timeDate
}

export const getDateFormat = (timestamp, style, isUTC) => {
  moment.locale(Vue.i18n.locale())
  if (isUTC) {
    timestamp = changeToFactorytime(timestamp)
  }
  let date = ''
  switch (style) {
    case 'super_short':
      date = timestamp.format('DD-MM')
      break
    case 'short':
      date = timestamp.format('DD-MM-YYYY')
      break
    case 'medium':
      date = timestamp.format('D MMM YYYY')
      break
    case 'long':
      date = timestamp.format('ddd, D MMM YYYY')
      break
  }
  return date
}

/**
 * Formats a timestamp in a short, medium or long notation
 *
 * @param   moment     timestamp     Timestamp
 * @param   String     style         Type of format
 * @param   bool       isUTC         Timestamp in UTC time?
 * @return  String                   Timestamp in a given format
 */
export const getTimeFormat = (timestamp, style, isUTC) => {
  if (isUTC) {
    timestamp = changeToFactorytime(timestamp)
  }
  moment.locale(Vue.i18n.locale())
  let time = ''
  switch (style) {
    case 'short':
      time = timestamp.format('HH:mm')
      break
    case 'medium':
      time = timestamp.format('HH:mm:ss')
      break
    case 'long':
      time = timestamp.format('HH:mm:ss z') // test with no timezone object
      break
  }
  return time
}

/**
 * Converts a UTC timestamp in a factory day month string
 *
 * @param   moment     timestampUTC     Timestamp in UTC
 * @return  String                      Factory time D MMM string
 */
export const getTimeStampDay = (timestampUTC) => {
  moment.locale(Vue.i18n.locale())
  return (timestampUTC === null) ? '' : changeToFactorytime(timestampUTC).format('D MMM')
}

/**
 * Returns the current factory local time
 *
 * @return   Date                       Factory current date object
 */
export const getLocalNowTimeDate = () => {
  const nowLocal = moment().tz('Europe/Rome')
  const nowLocalYear = nowLocal.get('year')
  const nowLocalMonth = nowLocal.get('month')
  const nowLocalMonthDay = nowLocal.get('date')
  const nowLocalHours = nowLocal.get('hour')
  const nowLocalMinutes = nowLocal.get('minute')

  const newDate = new Date()
  newDate.setFullYear(nowLocalYear)
  newDate.setMonth(nowLocalMonth)
  newDate.setDate(nowLocalMonthDay)
  newDate.setHours(nowLocalHours)
  newDate.setMinutes(nowLocalMinutes)

  return newDate
}

/**
 * Converts a factory date in UTC date string
 *
 * @param   Date     date     Fatory date object
 * @return  String            UTC time date string
 */
export const getUTCtimeString = (date) => {
  const startDayYear = date.getFullYear()
  const startDayMonth = date.getMonth()
  const startDayMonthDay = date.getDate()

  const startDayHours = date.getHours()
  const startDayMinutes = date.getMinutes()
  const startDaySeconds = date.getSeconds()

  const m = moment().tz('Europe/Rome')
  const newDate = m.set({
    year: startDayYear, month: startDayMonth, date: startDayMonthDay, hour: startDayHours, minute: startDayMinutes, second: startDaySeconds,
  })

  return newDate.toISOString()
}
