export function getBadgeProductColor (product) {
  const color = ''

  return color
}

export function getBadgeProductStyle (product, opacity) {
  const style = {}

  return style
}

export function getBadgeIcon (badge) {
  const icon = ''

  return icon
}

export function getBadgeColor (badge) {
  const color = ''

  return color
}

export function getBadgeStyle (badge) {
  const style = {}

  return style
}
