<template>
  <app-page-layout
    class="app-layout"
    :style="computedStyle"
    :is-top-bar.sync="isTopBar"
    :minimized.sync="minimized"
    :mobile-width="mobileWidth"
  >
    <app-navbar
      v-if="is_topbar_needed()"
      class="app-layout__navbar"
      :is-top-bar.sync="isTopBar"
      :minimized.sync="minimized"
    />
    <app-topbar
      class="app-layout__topbar"
      v-if="isTopBar"
    />
    <div class="app-layout__container">
      <app-sidebar
        class="app-layout__sidebar"
        v-if="is_sidebar_needed() && !isTopBar"
        :minimized.sync="minimized"
        :mobile-width="mobileWidth"
      />
      <div
        class="app-layout__main"
        :class="{'app-layout__main--top': isTopBar}"
      >
        <main :key="$route.fullPath"
          class="app-layout__main-layout layout fluid gutter--xl"
          slot="content"
          role="main"
        >
          <app-breadcrumbs v-if="is_breadcrumb_needed()" />
          <router-view/>
        </main>
      </div>
    </div>
  </app-page-layout>
</template>

<script>
import AppPageLayout from './AppPageLayout'
import AppNavbar from './app-navbar/AppNavbar'
import AppTopbar from './app-topbar/AppTopbar'
import AppSidebar from './app-sidebar/AppSidebar'
import AppBreadcrumbs from './app-breadcrumbs/AppBreadcrumbs'
// import { originalTheme, corporateTheme } from 'vuestic-ui/src/services/themes'
import {
  ColorThemeActionsMixin,
  ColorThemeMixin,
} from '../../services/vuestic-ui'
import { styleHelpers } from '@/services/atmosphere-ui'
import { getSidebarVisible, getBreadcrumbVisible, getTopbarVisible } from '../../store/modules/auth/utility'

export default {
  name: 'app-layout',
  components: {
    AppPageLayout,
    AppNavbar,
    AppTopbar,
    AppSidebar,
    AppBreadcrumbs,
  },
  data () {
    return {
      isTopBar: false,
      minimized: false,
      mobileWidth: 767,
    }
  },
  inject: ['contextConfig'],
  mixins: [ColorThemeActionsMixin, ColorThemeMixin],
  created () {
  },
  beforeDestroy () {
  },
  methods: {
    has_multiple_roles () {
      // console.log('roles: ' + this.$store.getters.get_user_roles)
      if (!this.$store.getters.get_user_roles) {
        return false
      }
      if (this.$store.getters.get_user_roles === '') {
        return false
      }
      if (this.$store.getters.get_user_roles.split(',').length > 1) {
        return true
      }
      return false
    },
    is_sidebar_needed () {
      if (this.has_multiple_roles()) {
        return true
      }
      return getSidebarVisible(this.$store.getters.get_user_role)
    },
    is_breadcrumb_needed () {
      if (this.has_multiple_roles()) {
        return true
      }
      return getBreadcrumbVisible(this.$store.getters.get_user_role)
    },
    is_topbar_needed () {
      if (this.has_multiple_roles()) {
        return true
      }
      return getTopbarVisible(this.$store.getters.get_user_role)
    },
  },
  computed: {
    computedStyle () {
      const style = {
        background: this.$mode.value === 'day' ? styleHelpers.setSatLumHexColor(this.$themes.primary, { sat: 25, lum: 80 }) : styleHelpers.setSatLumHexColor(this.$themes.primary, { sat: 40, lum: 11 }),
      }

      return style
    },
  },
}
</script>

<style lang="scss">
.app-layout {
  // @include bg-gradient();

  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    // TODO Probably there is a better way to achieve this.
    height: calc(100% - 55px);

    @include media-breakpoint-down(sm) {
      height: calc(100% - 110px);
    }
  }

  &__main {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    max-height: 100%;
    min-height: 100%;

    &--top {
    }

    &-layout {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      overflow: auto;
      box-sizing: border-box;
      min-height: 100%;
      margin: 0;
    }
  }
}
</style>
