import AtChartsSlider from './at-charts-slider/AtChartsSlider'
import AtDataTable from './at-data-table/AtDataTable.vue'
import AtDatePicker from './at-date-picker/AtDatePicker'
import AtIrrigationEventForm from './at-irrigation-event-form/AtIrrigationEventForm'
import AtIrrigationEventsDropoverOptions from './at-irrigation-events-dropover-options/AtIrrigationEventsDropoverOptions'
import AtIrrigationMap from './at-irrigation-map/AtIrrigationMap'
import AtIrrigationMapCurrentAction from './at-irrigation-map/AtIrrigationMapCurrentAction'
import AtIrrigationMapHeader from './at-irrigation-map/AtIrrigationMapHeader'
import AtIrrigationMapSideMenu from './at-irrigation-map/AtIrrigationMapSideMenu'
import AtIrrigationMapTimeline from './at-irrigation-map/AtIrrigationMapTimeline'
import AtIrrigationMapLayersLineEvents from './at-irrigation-map/at-irrigation-map-line/AtIrrigationMapLayersLineEvents'
import AtIrrigationMapLayersLineQuarterHourly from './at-irrigation-map/at-irrigation-map-line/AtIrrigationMapLayersLineQuarterHourly'
import AtIrrigationMapLayersRoom from './at-irrigation-map/at-irrigation-map-room/AtIrrigationMapLayersRoom'

import AtRecipeTimeline from './recipes/AtRecipeTimeline.vue'

import AtRecipeDropoverOptions from './at-recipe-dropover-options/AtRecipeDropoverOptions'
import AtSelectionTree from './at-selection-tree/AtSelectionTree'
import AtSettingsForm from './at-settings-form/AtSettingsForm'
import Spinner from './at-spinner/AtSpinner'

import VaAccordion from 'vuestic-ui/src/components/vuestic-components/va-collapse/VaAccordion'
import VaAvatar from 'vuestic-ui/src/components/vuestic-components/va-avatar/VaAvatar'
import VaBadge from 'vuestic-ui/src/components/vuestic-components/va-chip/VaBadge.vue'
import VaButton from './va-button/VaButton.vue'
import VaButtonGroup from './va-button-group/VaButtonGroup.vue'
import VaButtonToggle from 'vuestic-ui/src/components/vuestic-components/va-button-toggle/VaButtonToggle.vue'
import VaCard from './va-card/VaCard'
import VaChart from './va-chart/VaChart.vue'
import VaCheckbox from 'vuestic-ui/src/components/vuestic-components/va-checkbox/VaCheckbox.vue'
import VaChip from 'vuestic-ui/src/components/vuestic-components/va-chip/VaChip.vue'
import VaCollapse from 'vuestic-ui/src/components/vuestic-components/va-collapse/VaCollapse'
import VaCountBadge from 'vuestic-ui/src/components/vuestic-components/va-count-badge/VaCountBadge'
import VaDataTable from './va-data-table/VaDataTable.vue'
import VaDatePicker from 'vuestic-ui/src/components/vuestic-components/va-date-picker/VaDatePicker'
import VaDropdown from 'vuestic-ui/src/components/vuestic-components/va-dropdown/VaDropdown'
import VaFeed from 'vuestic-ui/src/components/vuestic-components/va-feed/VaFeed.vue'
import VaFileUpload from 'vuestic-ui/src/components/vuestic-components/va-file-upload/VaFileUpload'
import VaIcon from 'vuestic-ui/src/components/vuestic-components/va-icon/VaIcon'
import VaInnerLoading from 'vuestic-ui/src/components/vuestic-components/va-inner-loading/VaInnerLoading'
import VaInput from './va-input/VaInput'
import VaItem from 'vuestic-ui/src/components/vuestic-components/va-list/VaItem'
import VaItemLabel from 'vuestic-ui/src/components/vuestic-components/va-list/VaItemLabel'
import VaItemSection from 'vuestic-ui/src/components/vuestic-components/va-list/VaItemSection'
import VaList from 'vuestic-ui/src/components/vuestic-components/va-list/VaList'
import VaListLabel from 'vuestic-ui/src/components/vuestic-components/va-list/VaListLabel'
import VaListSeparator from 'vuestic-ui/src/components/vuestic-components/va-list/VaListSeparator'
import VaMediumEditor from 'vuestic-ui/src/components/vuestic-components/va-medium-editor/VaMediumEditor.vue'
import VaModal from 'vuestic-ui/src/components/vuestic-components/va-modal/VaModal.vue'
import VaNavbar from 'vuestic-ui/src/components/vuestic-components/va-navbar/VaNavbar'
import VaNotification from './va-notification/VaNotification.vue'
import VaPagination from 'vuestic-ui/src/components/vuestic-components/va-pagination/VaPagination.vue'
import VaPopover from './va-popover/VaPopover.vue'
import VaPopup from 'vuestic-ui/src/components/vuestic-components/va-popup/popup/VaPopup.vue'
import VaProgressBar from 'vuestic-ui/src/components/vuestic-components/va-progress-bar/progress-types/VaProgressBar.vue'
import VaProgressCircle from './va-progress-bar/progress-types/VaProgressCircle.vue'
import VaRadioButton from 'vuestic-ui/src/components/vuestic-components/va-radio-button/VaRadioButton'
import VaRating from 'vuestic-ui/src/components/vuestic-components/va-rating/VaRating'
import VaScrollbar from 'vuestic-ui/src/components/vuestic-components/va-scrollbar/VaScrollbar.vue'
import VaSelect from 'vuestic-ui/src/components/vuestic-components/va-select/VaSelect.vue'
import AtSelect from './at-select/AtSelect.vue'
import VaSeparator from 'vuestic-ui/src/components/vuestic-components/va-card/VaSeparator'
import VaSidebar from 'vuestic-ui/src/components/vuestic-components/va-sidebar/VaSidebar'
import VaSidebarLink from 'vuestic-ui/src/components/vuestic-components/va-sidebar/VaSidebarLink'
import VaSidebarLinkGroup from 'vuestic-ui/src/components/vuestic-components/va-sidebar/VaSidebarLinkGroup'
import VaSlider from 'vuestic-ui/src/components/vuestic-components/va-slider/VaSlider.vue'
import VaSocialNews from 'vuestic-ui/src/components/vuestic-components/va-social-news/VaSocialNews.vue'
import VaTab from 'vuestic-ui/src/components/vuestic-components/va-tabs/VaTab.vue'
import VaTabs from 'vuestic-ui/src/components/vuestic-components/va-tabs/VaTabs.vue'
import VaTimeline from 'vuestic-ui/src/components/vuestic-components/va-timeline/VaTimeline'
import VaTimelineItem from 'vuestic-ui/src/components/vuestic-components/va-timeline/VaTimelineItem'
import VaToggle from 'vuestic-ui/src/components/vuestic-components/va-toggle/VaToggle.vue'
import VaTopbar from 'vuestic-ui/src/components/vuestic-components/va-topbar/VaTopbar'
import VaTopbarLink from 'vuestic-ui/src/components/vuestic-components/va-topbar/VaTopbarLink'
import VaTopbarLinkGroup from 'vuestic-ui/src/components/vuestic-components/va-topbar/VaTopbarLinkGroup'
import VaTreeCategory from 'vuestic-ui/src/components/vuestic-components/va-tree-view/VaTreeCategory'
import VaTreeNode from 'vuestic-ui/src/components/vuestic-components/va-tree-view/VaTreeNode'
import VaTreeRoot from 'vuestic-ui/src/components/vuestic-components/va-tree-view/VaTreeRoot'
import VaInputWrapper from 'vuestic-ui/src/components/vuestic-components/va-input/VaInputWrapper'
import StickyScroll from 'vuestic-ui/src/components/vuestic-directives/StickyScroll'
import VaButtonDropdown from 'vuestic-ui/src/components/vuestic-components/va-button-dropdown/VaButtonDropdown'
import { installPlatform } from 'vuestic-ui/src/components/vuestic-components/va-popup/install'
import { DropdownPopperPlugin } from 'vuestic-ui/src/components/vuestic-components/va-dropdown/dropdown-popover-subplugin'

import { BusPlugin } from 'vue-epic-bus'
import { registerVuesticObject } from 'vuestic-ui/src/components/resize-events'
import VaSimplePalettePicker from 'vuestic-ui/src/components/vuestic-components/va-color-picker/VaSimplePalettePicker'
import VaColorSquare from 'vuestic-ui/src/components/vuestic-components/va-color-picker/VaColorSquare'
import VaSliderColorPicker from 'vuestic-ui/src/components/vuestic-components/va-color-picker/VaSliderColorPicker'
import VaAdvancedColorPicker from 'vuestic-ui/src/components/vuestic-components/va-color-picker/VaAdvancedColorPicker'
import VaColorPickerInput from 'vuestic-ui/src/components/vuestic-components/va-color-picker/VaColorPickerInput'
import VaPaletteCustom from 'vuestic-ui/src/components/vuestic-components/va-color-picker/VaPaletteCustom'
import { ToastedMixin } from 'vuestic-ui/src/components/vuestic-mixins/VuesticToasted'

installPlatform()

const VuesticPlugin = {
  install (Vue, options) {
    [
      AtChartsSlider,
      AtIrrigationEventForm,
      AtIrrigationEventsDropoverOptions,
      AtIrrigationMap,
      AtIrrigationMapCurrentAction,
      AtIrrigationMapHeader,
      AtIrrigationMapSideMenu,
      AtIrrigationMapTimeline,
      AtIrrigationMapLayersLineEvents,
      AtIrrigationMapLayersLineQuarterHourly,
      AtIrrigationMapLayersRoom,
      AtRecipeTimeline,
      AtRecipeDropoverOptions,
      AtSelectionTree,
      AtSettingsForm,
      VaAccordion,
      VaAvatar,
      VaBadge,
      VaButton,
      VaButtonGroup,
      VaButtonToggle,
      VaCard,
      VaChart,
      VaCheckbox,
      VaChip,
      VaCollapse,
      VaCountBadge,
      AtDataTable,
      VaDataTable,
      VaDatePicker,
      AtDatePicker,
      VaDropdown,
      VaFeed,
      VaFileUpload,
      VaIcon,
      VaInnerLoading,
      VaInput,
      VaInputWrapper,
      VaItem,
      VaItemLabel,
      VaItemSection,
      VaList,
      VaListLabel,
      VaListSeparator,
      VaMediumEditor,
      VaModal,
      VaNavbar,
      VaNotification,
      VaPagination,
      VaPopover,
      VaPopup,
      VaProgressBar,
      VaProgressBar,
      VaProgressCircle,
      VaRadioButton,
      VaRating,
      VaScrollbar,
      VaSelect,
      AtSelect,
      VaSeparator,
      VaSidebar,
      VaSidebarLink,
      VaSidebarLinkGroup,
      VaSlider,
      VaSocialNews,
      VaTab,
      VaTabs,
      VaTimeline,
      VaTimelineItem,
      VaToggle,
      VaTopbar,
      VaTopbarLink,
      VaTopbarLinkGroup,
      VaTreeCategory,
      VaTreeNode,
      VaTreeRoot,
      VaInnerLoading,
      VaSimplePalettePicker,
      VaColorSquare,
      VaSliderColorPicker,
      VaAdvancedColorPicker,
      VaColorPickerInput,
      VaPaletteCustom,
      VaButtonDropdown,
      Spinner,
    ].forEach(component => {
      Vue.component(component.name, component)
    })

    registerVuesticObject(Vue)

    Vue.mixin(ToastedMixin)

    Vue.use(BusPlugin)

    Vue.use(DropdownPopperPlugin)

    Vue.directive('sticky-scroll', StickyScroll)
  },
}

export default VuesticPlugin
