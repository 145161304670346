<template>
  <div @click.stop="toggleVariableOptions()">
    <!-- Notifications -->
    <div class="flex xs12" v-if="!isInModal">
      <div class="mb-3">
        <va-notification v-model="nameNotificationVisible" color="danger" closeable>
          <va-badge color="danger">
            {{ $t('notificationsPage.alerts.error') }}
          </va-badge>
          {{ nameErrorsMessage }}
        </va-notification>
      </div>
      <div class="mb-3">
        <va-notification v-model="descriptionNotificationVisible" color="danger" input closeable>
          <va-badge color="danger">
            {{ $t('notificationsPage.alerts.error') }}
          </va-badge>
          {{ descriptionErrorsMessage }}
        </va-notification>
      </div>
      <div class="mb-3">
        <va-notification v-model="durationNotificationVisible" color="danger" input closeable>
          <va-badge color="danger">
            {{ $t('notificationsPage.alerts.error') }}
          </va-badge>
          {{ durationErrorsMessage }}
        </va-notification>
      </div>
    </div>
    <va-card
      :style="computedStyle"
      class="va-card-table recipes-card">
      <template>
        <div>
          <div class="flex xs12">
            <!-- Header -->
            <div class="header">
              <div class="row">
                <div class="flex xs4 header__name">
                  <input v-model="recipe.name" :disabled="!editable">
                </div>
                <div class="flex xs4 header__description">
                  <input v-model="recipe.description" :disabled="!editable">
                </div>
                <div class="flex xs2 header__cycle">
                  <b>{{ `${$t('growing.recipeEdition.recipeDuration')}: `}}</b>
                  <input v-model="durationDaysInput" :disabled="!editable">
                  {{ `${$t('timeData.days')}` }}
                </div>
                <div v-if="isInModal" class="flex xs1 header__edit">
                  <router-link v-if="isProductionRecipe" :to="{ name: 'area-recipe-growing-edit', params: { roomId: getControlGroupId(), areaId: getAreaId(), recipeId: getGrowingRecipeId(), displayName: $t('growing.editRecipe') }}">
                    <va-button outline icon="at-icon l_edit1">{{ $t('buttons.edit') }}</va-button>
                  </router-link>
                  <router-link v-else :to="{ name: 'recipe-growing-edit', params: { recipeId: getGrowingRecipeId(), displayName: $t('growing.editRecipe') }}">
                    <va-button outline icon="at-icon l_edit1">{{ $t('buttons.edit') }}</va-button>
                  </router-link>
                </div>
                <div v-else class="flex xs1 header__edit">
                  <va-button flat icon="at-icon b_eyeopen" style="font-size: 30px;" @click="toogleShowSummaries()"></va-button>
                </div>
              </div>
              <button v-if="isInModal" @click="cancelModal()" class="header__close"><i class="entypo entypo-cancel"></i></button>
            </div>
            <div class="recipes">
              <div :id="'groups_' + this._uid" class="recipes__groups disable-scrollbars" ref="recipesGroupsContainer">
                <div class="recipes__groups__placeholder-top"></div>
                <div
                  class="recipes__groups__group"
                  v-for="recipeSetting in recipe.content"
                  :key="recipeSetting.id"
                >
                  <div :class="{ close: !recipeSetting.open }">
                    <div
                      :ref="'recipe_' + recipeSetting.id"
                      class="recipes__groups__group__name"
                      @click="toggleGroup(recipeSetting.id)"
                    >
                      <div class="name-content">
                        <va-icon :name="getGroupIcon(recipeSetting.tag)"/>
                        <h5>{{ $t(`growing.recipeEdition.${recipeSetting.tag}`) }}</h5>
                      </div>

                      <va-icon name="at-icon l_arrow1-up"></va-icon>
                    </div>

                    <div v-if="recipeSetting.open">
                      <div
                        class="recipes__groups__group__variable"
                        v-for="variable in recipeSetting.variables"
                        :key="variable.id"
                      >
                        <h6>{{ $t(`${recipeSetting.tag}Data.${variable.tag}`) }}</h6>
                        <div class="actions">
                          <va-toggle
                            v-if="editable"
                            @click.stop="!variable.enabled"
                            v-model="variable.enabled"
                            small
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div :id="'variables_' + this._uid" class="recipes__variables">
                <div class="recipes__variables__timeline">
                  <div :class="isInModal ? 'timeline__position_line' : 'timeline__position'" :style="{ left: (getPixelPositionFromDay(currentDay + 1) + 4) + 'px' }"></div>
                  <canvas :id="'timeCanvas_' + this._uid" class="time-canvas"></canvas>
                </div>

                <!-- TODO: Test this in a custom component!! -->
                <template v-for="(recipeSetting, recipeIdx) in recipe.content">
                  <div :key="recipeSetting.id" class="recipes__variables__group overlay">
                    <div v-if="recipeIdx === 0">
                      <div class="overlay__sowing">
                        <va-icon name="at-icon l_layer_state_sowing"></va-icon>
                        <span>{{ $t('growing.recipeEdition.sowing') }}</span>
                      </div>
                      <div class="overlay__harvesting">
                        <va-icon name="at-icon l_cut"></va-icon>
                        <span>{{ $t('growing.recipeEdition.harvesting') }}</span>
                      </div>
                    </div>
                  </div>

                  <div
                    :key="recipeSetting.id + '_variable'"
                    v-if="recipeSetting.open"
                    :style="{ width: containerWidth + 30 + 'px' }"
                    class="overlay"
                  >
                    <div
                      class="recipes__variables__variable"
                      v-for="(variable, varIdx) in recipeSetting.variables"
                      :key="variable.id"
                      :style="
                        groupBottomBorderStyle(
                          recipeIdx,
                          recipe.content,
                          varIdx,
                          recipeSetting.variables
                        )
                      "
                      :class="[{ disabled: !variable.enabled }, recipeSetting.color]"
                    >
                      <template v-for="day in durationDays + 2">
                        <div
                          class="recipe-cycle-info"
                          :key="day"
                          v-if="checkIfCycleExistsInVariable(variable, day)"
                          :style="{ left: getPixelPositionFromDay(day) + 'px' }"
                          :class="{ show: showSummaries}">

                          <at-recipe-dropover-options
                            v-if="editable"
                            :recipeSettingId="recipeSetting.id"
                            :variableId="variable.id"
                            :day="day"
                            :details="day < currentDay"
                            :show="showOption"
                            @onOptionClick="onOptionClick">
                          </at-recipe-dropover-options>

                          <div class="recipe-cycle-info__summary">
                            <div class="recipe-cycle-info__summary__container">
                              <span v-for="(info, index) in getSummaryInfo(recipeSetting.id, variable.id, day)" :key="recipeSetting.id + variable.id + index">
                                <span class="pr-2" >
                                  <va-icon :class="info.class" :style="info.style" :name="info.icon"/>
                                  <span><b>{{info.value}}</b></span>
                                </span>
                              </span>
                            </div>
                          </div>

                          <button
                            :class="{ inactive: setIrrigationButtonColors(recipeSetting.id, variable.id, day, variable.tag)}"
                            @click.stop="toggleVariableOptions(recipeSetting.id, variable.id, day)"
                          >
                            <template v-if="getNumberSlots(recipeSetting.id, variable.id, day) > 1">
                              <va-count-badge
                                :class="recipeSetting.color"
                                :number="getNumberSlots(recipeSetting.id, variable.id, day)"
                              >
                                <va-icon class="irrigation-icon" v-if="variable.tag === 'irrigation'" name="at-icon l_water1drop"></va-icon>
                                <va-icon v-else :name="'material-icons'">more_horiz</va-icon>
                              </va-count-badge>
                            </template>
                            <template v-else>
                              <va-icon class="irrigation-icon" v-if="variable.tag === 'irrigation'" name="at-icon l_water1drop"></va-icon>
                              <va-icon v-else :name="'material-icons'">more_horiz</va-icon>
                            </template>
                          </button>
                        </div>

                        <button
                          v-else-if="day >= currentDay"
                          :disabled="!variable.enabled || !editable"
                          class="add"
                          :key="day"
                          @click="editVariable(recipeSetting.id, variable.id, day)"
                          :style="{ left: getPixelPositionFromDay(day) + 'px', visibility: (editable && day !== durationDays + 2) ? '' : 'hidden' }"
                        >
                          <va-icon :name="'material-icons'">add</va-icon>
                          <!-- <va-icon :name="'at-icon card_plus'"></va-icon> -->
                        </button>
                      </template>
                    </div>
                  </div>
                </template>
                <!-- Test this in a custom component!! -->
              </div>
            </div>
            <va-modal
              v-model="showAddNewModal"
              :noOutsideDismiss="true"
              :hideDefaultActions="true"
              ref="modal"
            >
              <template v-slot:header>
                <h6>{{ modalSubTitle }}</h6>
                <h4>{{ modalTitle }}</h4>
              </template>

              <template slot="actions">
                <div class="row">
                  <div class="flex xs4">
                  </div>
                  <div class="flex xs8 text-right">
                    <va-button @click="cancelUpdateCycle()" flat color="gray"
                    >{{ (!editable || detailsOption) ? $t('modal.close') : $t('modal.cancel') }}</va-button
                    >
                    <va-button @click="confirmUpdateCycle()" color="success" v-if="editable && !deleteOption && !duplicateOption && !detailsOption"
                    >{{ $t('modal.confirm') }}</va-button
                    >
                    <va-button
                      v-if="duplicateOption"
                      @click="duplicateCycle()"
                      color="primary"
                    >{{ $t('modal.duplicate') }}</va-button
                    >
                    <va-button
                      v-if="existingCycle && deleteOption"
                      @click="deleteCycle()"
                      color="danger"
                    >{{ $t('modal.delete') }}</va-button
                    >
                  </div>
                </div>
              </template>

              <div v-if="this.selectedVariable" class="form">

                <!-- Duplicate Day -->
                <div v-if="duplicateOption" class="row mb-3">
                  <h6 class="flex xs6">{{ duplicateTitle }}</h6>
                  <div class="flex xs2 ml-3">
                    <va-select
                      v-model="selectedDuplicateDate"
                      :options="getDaysList()"
                    />
                  </div>
                </div>
                <!-- /Duplicate Day -->

                <!-- IRRIGATION -->
                <div v-if="this.selectedGroupName === 'water'" class="row mb-3">
                  <div v-if="this.selectedVariable.tag === 'irrigation'" class="row mt-3">
                    <!-- toggle -->
                    <div class="flex xs3">
                      <va-toggle
                        v-model="selectedData.irrigate"
                        :disable="!editable || deleteOption || duplicateOption || detailsOption"
                        :label="$t('irrigation.irrigate') + ' ?'"
                        small
                      />
                    </div>
                    <!-- Irrigation cycle -->
                    <template v-if="selectedData.irrigate">

                      <template v-if="selectedData.slots">
                        <div class="row mb-2 mt-3">
                          <div class="flex xs12 add-slot-button">
                            <va-button
                              v-if="editable"
                              @click="addIrrigationSlot"
                              flat
                              small
                              icon="at-icon card_plus"
                            >Slot</va-button
                            >
                          </div>
                        </div>

                        <div
                          v-for="(slot, index) in this.selectedData.slots"
                          :key="index"
                          class="row slot mb-2"
                        >

                          <div class="flex xs12 offset--xs12 delete-slot-button">
                            <va-button
                              v-if="index > 0"
                              @click="deleteIrrigationSlot(index)"
                              flat
                              small
                              color="danger"
                            >
                              <va-icon :name="'at-icon l_close1'"></va-icon>
                            </va-button>
                          </div>
                          <!-- volume settings -->
                          <div class="flex xs12 mt-3">
                            <div class="title day">
                              <h6>{{ 'Volume' }}</h6>
                            </div>

                            <div class="row mt-3">
                              <div class="flex xs2">
                                <va-input
                                  :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                                  class="min_value"
                                  v-model.number="selectedData.slots[index].min"
                                  :label="`MIN ${getUnitFormated(selectedData.slots[index].units)}`"
                                  type="number"
                                  step="1"
                                  min="0"
                                  :error="!!minError.length"
                                  :error-messages="minError"
                                />
                              </div>
                              <div class="flex xs2">
                                <va-input
                                  :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                                  class="min_ref_value"
                                  v-model.number="selectedData.slots[index].minRef"
                                  :label="`REF MIN ${getUnitFormated(selectedData.slots[index].units)}`"
                                  type="number"
                                  step="1"
                                  :min="selectedData.slots[index].min"
                                  :error="!!minRefError.length"
                                  :error-messages="minRefError"
                                />
                              </div>
                              <div class="flex xs2">
                                <va-input
                                  :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                                  class="ref_value"
                                  v-model.number="selectedData.slots[index].ref"
                                  :label="`REF ${getUnitFormated(selectedData.slots[index].units)}`"
                                  type="number"
                                  step="1"
                                  :min="selectedData.slots[index].minRef"
                                  :error="!!refError.length"
                                  :error-messages="refError"
                                />
                              </div>
                              <div class="flex xs2">
                                <va-input
                                  :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                                  class="max_ref_value"
                                  v-model.number="selectedData.slots[index].maxRef"
                                  :label="`REF MAX ${getUnitFormated(selectedData.slots[index].units)}`"
                                  type="number"
                                  step="1"
                                  :min="selectedData.slots[index].ref"
                                  :error="!!maxRefError.length"
                                  :error-messages="maxRefError"
                                />
                              </div>
                              <div class="flex xs2">
                                <va-input
                                  :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                                  class="max_value"
                                  v-model.number="selectedData.slots[index].max"
                                  :label="`MAX ${getUnitFormated(selectedData.slots[index].units)}`"
                                  type="number"
                                  step="1"
                                  :min="selectedData.slots[index].maxRef"
                                  :error="!!maxError.length"
                                  :error-messages="maxError"
                                />
                              </div>
                            </div>
                          </div>
                          <!-- duration -->
                          <div class="flex xs12 mt-3">
                            <div class="title day">
                              <h6>{{ $t('timeData.duration') }}</h6>
                            </div>
                            <div class="row mt-3">
                              <div class="flex xs3">
                                <form>
                                  <at-date-picker
                                    :ref="'datepicker_' + index"
                                    :label="$t('timeData.duration').toUpperCase() + ' hh:mm:ss'"
                                    :disabled="!selectedData.irrigate || !editable || deleteOption || duplicateOption || detailsOption"
                                    :time="true"
                                    :config="{
                                      noCalendar: true,
                                      enableTime: true,
                                      enableSeconds: true,
                                      dateFormat: 'H:i:S',
                                      minuteIncrement: 1,
                                      defaultHour: 0,
                                      time_24hr: true,
                                      inline: false,
                                      locale: getCalendarLanguage(),
                                    }"
                                    v-model="selectedData.slots[index].irrigationTimeoutString"
                                    :error="!!irrigationDurationErrors.length"
                                    :error-messages="irrigationDurationErrors"
                                  />
                                </form>
                              </div>
                            </div>
                          </div>
                          <!-- start time settings -->
                          <div class="flex xs12 mt-3">
                            <div class="title day">
                              <h6>{{ $t('irrigation.startTime') }}</h6>
                            </div>
                            <div class="row mt-3">
                              <div class="flex xs4" v-if="!isProductionRecipe">
                                <form>
                                  <at-date-picker
                                    :ref="'datepicker_' + index"
                                    :label="$t('timeData.afterLightOn').toUpperCase() + ' hh:mm:ss'"
                                    :disabled="!selectedData.irrigate || !editable || deleteOption || duplicateOption || detailsOption"
                                    :time="true"
                                    :config="{
                                      noCalendar: true,
                                      enableTime: true,
                                      enableSeconds: true,
                                      dateFormat: 'H:i:S',
                                      time_24hr: true,
                                      minuteIncrement: 1,
                                      defaultHour: 0,
                                      inline: false,
                                      locale: getCalendarLanguage(),
                                    }"
                                    v-model="selectedData.slots[index].timeAfterLightOn"
                                  />
                                </form>
                              </div>

                              <template v-else>
                                <div class="flex xs4">
                                  <at-date-picker
                                    ref="datepicker"
                                    :label="$t('timeData.irrigationLocalStartTime').toUpperCase() + ' hh:mm:ss'"
                                    :disabled="!selectedData.irrigate || !editable || deleteOption || duplicateOption || detailsOption || !selectedData.slots[index].changeTimeAfterLightOn"
                                    :time="true"
                                    :config="{
                                      noCalendar: true,
                                      enableTime: true,
                                      enableSeconds: true,
                                      dateFormat: 'H:i:S',
                                      time_24hr: true,
                                      minuteIncrement: 1,
                                      defaultHour: 0,
                                      inline: false,
                                      locale: getCalendarLanguage(),
                                    }"
                                    v-model="selectedData.slots[index].irrigationLocalStartTime"
                                  />
                                </div>
                                <div class="flex xs4">
                                  <va-toggle
                                    v-model="selectedData.slots[index].changeTimeAfterLightOn"
                                    :disable="!editable || deleteOption || duplicateOption || detailsOption"
                                    :label="$t('timeData.changeTimeAfterLightOn')"
                                    small
                                  />
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>

                      <!-- to keep both versions -> remove in the future -->
                      <template v-else>
                        <!-- volume settings -->
                        <div class="flex xs12 mt-3">
                          <div class="title day">
                            <h6>{{ 'Volume' }}</h6>
                          </div>

                          <div class="row mt-3">
                            <div class="flex xs2">
                              <va-input
                                :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                                class="min_value"
                                v-model.number="selectedData.min"
                                :label="`MIN ${getUnitFormated(selectedData.units)}`"
                                type="number"
                                step="1"
                                min="0"
                                :error="!!minError.length"
                                :error-messages="minError"
                              />
                            </div>
                            <div class="flex xs2">
                              <va-input
                                :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                                class="min_ref_value"
                                v-model.number="selectedData.minRef"
                                :label="`REF MIN ${getUnitFormated(selectedData.units)}`"
                                type="number"
                                step="1"
                                :min="selectedData.min"
                                :error="!!minRefError.length"
                                :error-messages="minRefError"
                              />
                            </div>
                            <div class="flex xs2">
                              <va-input
                                :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                                class="ref_value"
                                v-model.number="selectedData.ref"
                                :label="`REF ${getUnitFormated(selectedData.units)}`"
                                type="number"
                                step="1"
                                :min="selectedData.minRef"
                                :error="!!refError.length"
                                :error-messages="refError"
                              />
                            </div>
                            <div class="flex xs2">
                              <va-input
                                :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                                class="max_ref_value"
                                v-model.number="selectedData.maxRef"
                                :label="`REF MAX ${getUnitFormated(selectedData.units)}`"
                                type="number"
                                step="1"
                                :min="selectedData.ref"
                                :error="!!maxRefError.length"
                                :error-messages="maxRefError"
                              />
                            </div>
                            <div class="flex xs2">
                              <va-input
                                :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                                class="max_value"
                                v-model.number="selectedData.max"
                                :label="`MAX ${getUnitFormated(selectedData.units)}`"
                                type="number"
                                step="1"
                                :min="selectedData.maxRef"
                                :error="!!maxError.length"
                                :error-messages="maxError"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- duration -->
                        <div class="flex xs12 mt-3">
                          <div class="title day">
                            <h6>{{ $t('timeData.duration') }}</h6>
                          </div>
                          <div class="row mt-3">
                            <div class="flex xs3">
                              <form>
                                <at-date-picker
                                  ref="datepicker"
                                  :label="$t('timeData.duration').toUpperCase() + ' hh:mm:ss'"
                                  :disabled="!selectedData.irrigate || !editable || deleteOption || duplicateOption || detailsOption"
                                  :time="true"
                                  :config="{
                                    noCalendar: true,
                                    enableTime: true,
                                    enableSeconds: true,
                                    dateFormat: 'H:i:S',
                                    minuteIncrement: 1,
                                    defaultHour: 0,
                                    time_24hr: true,
                                    inline: false,
                                    locale: getCalendarLanguage(),
                                  }"
                                  v-model="irrigationTimeoutString"
                                  :error="!!irrigationDurationErrors.length"
                                  :error-messages="irrigationDurationErrors"
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                        <!-- start time settings -->
                        <div class="flex xs12 mt-3">
                          <div class="title day">
                            <h6>{{ $t('irrigation.startTime') }}</h6>
                          </div>
                          <div class="row mt-3">
                            <div class="flex xs4" v-if="!isProductionRecipe">
                              <form>
                                <at-date-picker
                                  ref="datepicker"
                                  :label="$t('timeData.afterLightOn').toUpperCase() + ' hh:mm:ss'"
                                  :disabled="!selectedData.irrigate || !editable || deleteOption || duplicateOption || detailsOption"
                                  :time="true"
                                  :config="{
                                    noCalendar: true,
                                    enableTime: true,
                                    enableSeconds: true,
                                    dateFormat: 'H:i:S',
                                    time_24hr: true,
                                    minuteIncrement: 1,
                                    defaultHour: 0,
                                    inline: false,
                                    locale: getCalendarLanguage(),
                                  }"
                                  v-model="selectedData.timeAfterLightOn"
                                />
                              </form>
                            </div>

                            <template v-else>
                              <div class="flex xs4">
                                <at-date-picker
                                  ref="datepicker"
                                  :label="$t('timeData.irrigationLocalStartTime').toUpperCase() + ' hh:mm:ss'"
                                  :disabled="!selectedData.irrigate || !editable || deleteOption || duplicateOption || detailsOption || !changeTimeAfterLightOn"
                                  :time="true"
                                  :config="{
                                    noCalendar: true,
                                    enableTime: true,
                                    enableSeconds: true,
                                    dateFormat: 'H:i:S',
                                    time_24hr: true,
                                    minuteIncrement: 1,
                                    defaultHour: 0,
                                    inline: false,
                                    locale: getCalendarLanguage(),
                                  }"
                                  v-model="irrigationLocalStartTime"
                                />
                              </div>
                              <div class="flex xs4">
                                <va-toggle
                                  v-model="changeTimeAfterLightOn"
                                  :disable="!editable || deleteOption || duplicateOption || detailsOption"
                                  :label="$t('timeData.changeTimeAfterLightOn')"
                                  small
                                />
                              </div>
                            </template>
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>

                  <!-- pH, EC, ORP, Temp -->
                  <div v-else class="row mt-3">
                    <div class="flex xs2">
                      <va-input
                        :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                        class="min_value"
                        v-model.number="selectedData.min"
                        :label="`MIN ${getUnitFormated(selectedData.units)}`"
                        type="number"
                        step="0.1"
                        min="0"
                        :error="!!minError.length"
                        :error-messages="minError"
                      />
                    </div>
                    <div class="flex xs2">
                      <va-input
                        :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                        class="min_ref_value"
                        v-model.number="selectedData.minRef"
                        :label="`REF MIN ${getUnitFormated(selectedData.units)}`"
                        type="number"
                        step="0.1"
                        :min="selectedData.min"
                        :error="!!minRefError.length"
                        :error-messages="minRefError"
                      />
                    </div>
                    <div class="flex xs2">
                      <va-input
                        :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                        class="ref_value"
                        v-model.number="selectedData.ref"
                        :label="`REF ${getUnitFormated(selectedData.units)}`"
                        type="number"
                        step="0.1"
                        :min="selectedData.minRef"
                        :error="!!refError.length"
                        :error-messages="refError"
                      />
                    </div>
                    <div class="flex xs2">
                      <va-input
                        :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                        class="max_ref_value"
                        v-model.number="selectedData.maxRef"
                        :label="`REF MAX ${getUnitFormated(selectedData.units)}`"
                        type="number"
                        step="0.1"
                        :min="selectedData.ref"
                        :error="!!maxRefError.length"
                        :error-messages="maxRefError"
                      />
                    </div>
                    <div class="flex xs2">
                      <va-input
                        :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                        class="max_value"
                        v-model.number="selectedData.max"
                        :label="`MAX ${getUnitFormated(selectedData.units)}`"
                        type="number"
                        step="0.1"
                        :min="selectedData.maxRef"
                        :error="!!maxError.length"
                        :error-messages="maxError"
                      />
                    </div>
                  </div>
                </div>
                <!-- /IRRIGATION -->

                <!-- AIR -->
                <div v-if="this.selectedGroupName === 'air'" class="row mb-3">

                  <div class="flex xs10">
                    <div class="row mb-2" v-if="selectedVariable.tag === 'volume_flow'">
                      <div class="flex xs5">
                        <form>
                          <at-date-picker
                            ref="datepicker"
                            :label="$t('data.oscillation_period').toUpperCase() + ' hh:mm:ss'"
                            :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                            :time="true"
                            :config="{
                              noCalendar: true,
                              enableTime: true,
                              enableSeconds: true,
                              dateFormat: 'H:i:S',
                              minuteIncrement: 1,
                              defaultHour: 0,
                              time_24hr: true,
                              inline: false,
                              locale: getCalendarLanguage(),
                            }"
                            v-model="selectedData.oscillationPeriodString"
                          />
                        </form>
                      </div>
                    </div>
                  </div>

                  <div class="flex xs12 mt-3">
                    <div class="title day">
                      <h6><va-icon name="at-icon b_weather_sunny" style="padding-right: 2px;"/>{{ $t('timeData.day') }}</h6>
                    </div>

                    <div class="row mt-3">
                      <div class="flex xs2">
                        <va-input
                          :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                          class="min_value"
                          v-model="selectedData.dayMin"
                          :label="`MIN ${getUnitFormated(selectedData.units)}`"
                          type="number"
                          step="0.1"
                          placeholder="0"
                          min="0"
                          :error="!!minError.length"
                          :error-messages="minError"
                        />
                      </div>
                      <div class="flex xs2">
                        <va-input
                          :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                          class="min_ref_value"
                          v-model="selectedData.dayMinRef"
                          :label="`REF MIN ${getUnitFormated(selectedData.units)}`"
                          type="number"
                          step="0.1"
                          :min="selectedData.dayMin"
                          :error="!!minRefError.length"
                          :error-messages="minRefError"
                        />
                      </div>
                      <div class="flex xs2">
                        <va-input
                          :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                          class="ref_value"
                          v-model="selectedData.dayRef"
                          :label="`REF ${getUnitFormated(selectedData.units)}`"
                          type="number"
                          step="0.1"
                          :min="selectedData.dayMinRef"
                          :error="!!refError.length"
                          :error-messages="refError"
                        />
                      </div>
                      <div class="flex xs2">
                        <va-input
                          :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                          class="max_ref_value"
                          v-model="selectedData.dayMaxRef"
                          :label="`REF MAX ${getUnitFormated(selectedData.units)}`"
                          type="number"
                          step="0.1"
                          :min="selectedData.dayRef"
                          :error="!!maxRefError.length"
                          :error-messages="maxRefError"
                        />
                      </div>
                      <div class="flex xs2">
                        <va-input
                          :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                          class="max_value"
                          v-model="selectedData.dayMax"
                          :label="`MAX ${getUnitFormated(selectedData.units)}`"
                          type="number"
                          step="0.1"
                          :min="selectedData.dayMaxRef"
                          :error="!!maxError.length"
                          :error-messages="maxError"
                        />
                      </div>
                      <div class="flex xs5">
                        <div class="row">
                          <div class="flex xs2 pt-3">
                            <va-icon name="at-icon l_weather_sunny"/>
                          </div>
                          <div class="flex xs8">
                            <form>
                              <at-date-picker
                                class="transition_value"
                                ref="datepicker"
                                :label="$t('timeData.transition').toUpperCase() + ' hh:mm:ss'"
                                :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                                :time="true"
                                :config="{
                                  noCalendar: true,
                                  enableTime: true,
                                  enableSeconds: true,
                                  dateFormat: 'H:i:S',
                                  minuteIncrement: 1,
                                  defaultHour: 0,
                                  time_24hr: true,
                                  inline: false,
                                  locale: getCalendarLanguage(),
                                }"
                                v-model="selectedData.transitionDayNightString"
                              />
                            </form>
                          </div>
                          <div class="flex xs2 pt-3">
                            <va-icon name="at-icon l_weather_moon"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex xs12 mt-4">
                    <div class="title night">
                      <h6><va-icon name="at-icon b_weather_moon" style="padding-right: 2px;"/>{{ $t('timeData.night') }}</h6>
                    </div>

                    <div class="row mt-3">
                      <div class="flex xs2">
                        <va-input
                          :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                          class="min_value"
                          v-model="selectedData.nightMin"
                          :label="`MIN ${getUnitFormated(selectedData.units)}`"
                          type="number"
                          step="0.1"
                          min="0"
                          :error="!!nightMinError.length"
                          :error-messages="nightMinError"
                        />
                      </div>
                      <div class="flex xs2">
                        <va-input
                          :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                          class="min_ref_value"
                          v-model="selectedData.nightMinRef"
                          :label="`REF MIN ${getUnitFormated(selectedData.units)}`"
                          type="number"
                          step="0.1"
                          :min="selectedData.nightMin"
                          :error="!!nightMinRefError.length"
                          :error-messages="nightMinRefError"
                        />
                      </div>
                      <div class="flex xs2">
                        <va-input
                          :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                          class="ref_value"
                          v-model="selectedData.nightRef"
                          :label="`REF ${getUnitFormated(selectedData.units)}`"
                          type="number"
                          step="0.1"
                          :min="selectedData.nightMinRef"
                          :error="!!nightRefError.length"
                          :error-messages="nightRefError"
                        />
                      </div>
                      <div class="flex xs2">
                        <va-input
                          :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                          class="max_ref_value"
                          v-model="selectedData.nightMaxRef"
                          :label="`REF MAX ${getUnitFormated(selectedData.units)}`"
                          type="number"
                          step="0.1"
                          :min="selectedData.nightRef"
                          :error="!!nightMaxRefError.length"
                          :error-messages="nightMaxRefError"
                        />
                      </div>
                      <div class="flex xs2">
                        <va-input
                          :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                          class="max_value"
                          v-model="selectedData.nightMax"
                          :label="`MAX ${getUnitFormated(selectedData.units)}`"
                          type="number"
                          step="0.1"
                          :min="selectedData.nightMaxRef"
                          :error="!!nightMaxError.length"
                          :error-messages="nightMaxError"
                        />
                      </div>
                      <div class="flex xs5">
                        <div class="row">
                          <div class="flex xs2 pt-3">
                            <va-icon name="at-icon l_weather_moon"/>
                          </div>
                          <div class="flex xs8">
                            <form>
                              <at-date-picker
                                ref="datepicker"
                                class="transition_value"
                                :label="$t('timeData.transition').toUpperCase() + ' hh:mm:ss'"
                                :disabled="!editable || deleteOption || duplicateOption || detailsOption"
                                :time="true"
                                :config="{
                                  noCalendar: true,
                                  enableTime: true,
                                  enableSeconds: true,
                                  dateFormat: 'H:i:S',
                                  minuteIncrement: 1,
                                  defaultHour: 0,
                                  time_24hr: true,
                                  inline: false,
                                  locale: getCalendarLanguage(),
                                }"
                                v-model="selectedData.nightTransitionString"
                              />
                            </form>
                          </div>
                          <div class="flex xs2 pt-3">
                            <va-icon name="at-icon l_weather_sunny"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /AIR -->

                <!-- LIGHT -->
                <div v-if="this.selectedGroupName === 'light'" class="row mb-3">
                  <div class="row mb-2 mt-3">
                    <div class="flex xs6 slot-total-info">
                      <span
                      >{{ $t('timeData.day') }} Total: <b>{{ this.countTotalLightHours() }} {{ $t('timeData.hours') }}</b></span
                      >
                      <span
                      >{{ $t('timeData.night') }} Total:
                        <b>{{ (24 * this.selectedData.slots.length) - this.countTotalLightHours() }} {{ $t('timeData.hours') }}</b></span
                      >
                    </div>
                    <div class="flex xs6 add-slot-button">
                      <va-button
                        v-if="this.countTotalLightHours() < 24 && editable"
                        @click="addLightSlot"
                        flat
                        small
                        icon="at-icon card_plus"
                      >Slot</va-button
                      >
                    </div>
                  </div>

                  <div
                    v-for="(slot, index) in this.selectedData.slots"
                    :key="index"
                    class="row slot mb-2"
                  >
                    <div class="row">
                      <!-- duration -->
                      <div class="flex xs3">
                        <form>
                          <at-date-picker
                            ref="datepicker"
                            :label="$t('timeData.duration').toUpperCase() + ' hh:mm:ss'"
                            :disabled="!editable || deleteOption || duplicateOption"
                            :time="true"
                            :config="{
                              noCalendar: true,
                              enableTime: true,
                              enableSeconds: true,
                              dateFormat: 'H:i:S',
                              minuteIncrement: 1,
                              defaultHour: 0,
                              time_24hr: true,
                              inline: false,
                              locale: getCalendarLanguage(),
                            }"
                            v-model="slot.durationString"
                          />
                        </form>
                      </div>

                      <div class="flex xs3">
                        <!-- transition -->
                        <form>
                          <at-date-picker
                            ref="datepicker"
                            :label="$t('timeData.transition').toUpperCase() + ' hh:mm:ss'"
                            :disabled="!editable || deleteOption || duplicateOption"
                            :time="true"
                            :config="{
                              noCalendar: true,
                              enableTime: true,
                              enableSeconds: true,
                              dateFormat: 'H:i:S',
                              minuteIncrement: 1,
                              defaultHour: 0,
                              time_24hr: true,
                              inline: false,
                              locale: getCalendarLanguage(),
                            }"
                            v-model="slot.transitionString"
                          />
                        </form>
                      </div>
                      <div class="flex xs3 offset--xs3 delete-slot-button">
                        <va-button
                          v-if="index > 0"
                          @click="deleteLightSlot(index)"
                          flat
                          small
                          color="danger"
                        >
                          <va-icon :name="'at-icon l_close1'"></va-icon>
                        </va-button>
                      </div>
                    </div>

                    <div class="row">
                      <div class="flex xs3">
                        <va-input
                          :disabled="!editable || deleteOption || duplicateOption"
                          class="light_blue_value"
                          v-model="slot.blue"
                          :label="`${$t('colors.blue').toUpperCase()} (${getUnitFormated(slot.units, 'light')})`"
                          type="number"
                          step="0.1"
                          min="0"
                        />
                      </div>
                      <div class="flex xs3">
                        <va-input
                          :disabled="!editable || deleteOption || duplicateOption"
                          class="light_green_value"
                          v-model="slot.green"
                          :label="`${$t('colors.green').toUpperCase()} (${getUnitFormated(slot.units, 'light')})`"
                          type="number"
                          step="0.1"
                          min="0"
                        />
                      </div>
                      <div class="flex xs3">
                        <va-input
                          :disabled="!editable || deleteOption || duplicateOption"
                          class="light_red_value"
                          v-model="slot.red"
                          :label="`${$t('colors.red').toUpperCase()} (${getUnitFormated(slot.units, 'light')})`"
                          type="number"
                          step="0.1"
                          min="0"
                        />
                      </div>
                      <div class="flex xs3">
                        <va-input
                          :disabled="!editable || deleteOption || duplicateOption"
                          class="light_farred_value"
                          v-model="slot.farRed"
                          :label="`${$t('colors.farRed').toUpperCase()} (${getUnitFormated(slot.units, 'light')})`"
                          type="number"
                          step="0.1"
                          min="0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /LIGHT -->
              </div>
            </va-modal>
          </div>
        </div>
      </template>
    </va-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { Italian } from 'flatpickr/dist/l10n/it.js'
import { english } from 'flatpickr/dist/l10n/default.js'
import moment from 'moment-timezone'
import axios from 'axios'
import { styleHelpers } from '@/services/atmosphere-ui'
import { dummyRecipe, dummyRecipeEmpty } from '../../../../data/dummy_recipe'
import { structuredApiUrl } from '@/utilities/socketio'
import AtRecipeDropoverOptions from '../at-recipe-dropover-options/AtRecipeDropoverOptions.vue'
import { isEqual } from 'lodash'

var crypto = require('crypto')

export default {
  components: { AtRecipeDropoverOptions },
  name: 'AtRecipeTimeline',
  props: {
    growingRecipeEncrypted: {
      type: Object,
    },
    currentDay: {
      type: Number,
      default: -1,
    },
    isProductionRecipe: {
      type: Boolean,
      default: false,
    },
    isInModal: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      recipe: {},
      originalRecipe: {},
      durationDaysInput: 0,
      durationDays: 0,
      scale: 12,
      canvas: null,
      padding: 20,
      containerWidth: 0,
      showAddNewModal: false,
      modalTitle: '',
      modalSubTitle: '',
      selectedGroupName: '',
      selectedGroupId: 0,
      selectedVariableId: 0,
      selectedVariable: '',
      selectedData: {},
      existingCycle: false,
      nameErrorsMessage: '',
      nameNotificationVisible: false,
      descriptionErrorsMessage: '',
      descriptionNotificationVisible: false,
      durationErrorsMessage: '',
      durationNotificationVisible: false,
      showSummaries: this.isInModal,
      showOption: '',
      changeTimeAfterLightOn: true,
      deleteOption: false,
      duplicateOption: false,
      detailsOption: false,
      selectedDuplicateDate: '',
      invalidForm: false,
      minError: [],
      minRefError: [],
      refError: [],
      maxRefError: [],
      maxError: [],
      nightMinError: [],
      nightMinRefError: [],
      nightRefError: [],
      nightMaxRefError: [],
      nightMaxError: [],
      irrigationDurationErrors: [],
      irrigationTimeoutString: '',
      irrigationLocalStartTime: '',
      controlGroupLightStartTime: '',
      controlGroupIrrigationDefaultStartTime: '',
    }
  },
  mounted () {
    this.recipe = {}
    this.scrollbarSync()
    if (this.getGrowingRecipeId() === 0) {
      this.recipe = JSON.parse(JSON.stringify(dummyRecipeEmpty))
      this.durationDays = dummyRecipeEmpty.durationDays - 1
      this.durationDaysInput = dummyRecipeEmpty.durationDays - 1
      this.recipe.key = this.createRecipeKey()
    } else {
      this.decryptRecipe()

      if (this.isProductionRecipe) {
        this.getIrrigationLightOnTime()
      }
    }
    this.createTimeline()

    this.$nextTick(function () {
      this.getRecipeContainerSizes()
      window.addEventListener('resize', this.getRecipeContainerSizes)
    })
  },
  methods: {
    scrollbarSync () {
      /* TODO: Update to a more Vue kinda of way... */
      let isSyncingLeftScroll = false
      let isSyncingRightScroll = false
      const leftDiv = document.getElementById('groups_' + this._uid)
      const rightDiv = document.getElementById('variables_' + this._uid)
      // calculate max scroll top position of right div
      let maxScrollPosition = rightDiv.scrollHeight - rightDiv.clientHeight

      leftDiv.onscroll = function () {
        // fix issue with different height between the left and right divs
        if (this.scrollTop > maxScrollPosition) {
          leftDiv.scrollTo({ top: maxScrollPosition })
        }

        if (!isSyncingLeftScroll) {
          isSyncingRightScroll = true
          rightDiv.scrollTop = this.scrollTop
        }
        isSyncingLeftScroll = false
      }

      rightDiv.onscroll = function () {
        maxScrollPosition = rightDiv.scrollHeight - rightDiv.clientHeight
        if (!isSyncingRightScroll) {
          isSyncingLeftScroll = true
          leftDiv.scrollTop = this.scrollTop
        }
        isSyncingRightScroll = false
      }
    },
    getControlGroupId () {
      return parseInt(this.$route.params.roomId)
    },
    getAreaId () {
      return parseInt(this.$route.params.areaId)
    },
    getGrowingRecipeId () {
      if (this.isInModal) {
        return parseInt(this.growingRecipeEncrypted.id)
      } else {
        return parseInt(this.$route.params.recipeId)
      }
    },
    getRecipeFromFile () {
      this.recipe = dummyRecipe
      this.durationDays = dummyRecipe.durationDays - 1
    },
    createRecipeKey (name) {
      const now = new Date().toISOString()
      let hash = crypto.createHash('md5').update(now + name, 'utf8').digest('base64')
      hash = encodeURI(hash).slice(0, 16)
      return hash
    },
    getRecipeId () {
      return this.recipe.id
    },
    getRecipeKey () {
      return this.recipe.key
    },
    getRecipeName () {
      return this.recipe.name
    },
    getRecipeDescription () {
      return this.recipe.description
    },
    getRecipeDurationDays () {
      return this.recipe.durationDays
    },
    encryptRecipe () {
      var algorithm = 'aes-256-ctr'
      var password = process.env.VUE_APP_RECIPE_DECENC
      var cipher = crypto.createCipheriv(algorithm, password, this.recipe.key)
      var crypted = cipher.update(JSON.stringify(this.recipe), 'utf8', 'hex')
      crypted += cipher.final('hex')
      return crypted
    },
    decryptRecipe () {
      var algorithm = 'aes-256-ctr'
      var password = process.env.VUE_APP_RECIPE_DECENC
      var decipher = crypto.createDecipheriv(algorithm, password, this.growingRecipeEncrypted.key)
      var dec = decipher.update(this.growingRecipeEncrypted.recipe, 'hex', 'utf8')
      dec += decipher.final('utf8')
      this.recipe = JSON.parse(dec)
      console.log(this.recipe)
      this.originalRecipe = JSON.parse(dec)
      this.durationDays = this.recipe.durationDays - 1
      this.durationDaysInput = this.recipe.durationDays - 1

      if (this.$route.meta.duplicate) {
        this.recipe.id = 0
        this.recipe.name = `${this.growingRecipeEncrypted.name} - Duplicated`
        this.recipe.key = this.createRecipeKey()
      }

      for (const group in this.recipe.content) {
        if (this.recipe.content[group].tag === 'water') {
          for (const variable in this.recipe.content[group].variables) {
            if (this.recipe.content[group].variables[variable].tag === 'irrigation') {
              for (const cycle in this.recipe.content[group].variables[variable].cycles) {
                if ('slots' in this.recipe.content[group].variables[variable].cycles[cycle]) {
                  for (const slot in this.recipe.content[group].variables[variable].cycles[cycle].slots) {
                    if (this.recipe.content[group].variables[variable].cycles[cycle].timeAfterLightOn) {
                      const time = this.recipe.content[group].variables[variable].cycles[cycle].timeAfterLightOn
                      this.$set(this.recipe.content[group].variables[variable].cycles[cycle].slots[slot], 'timeAfterLightOn', time)
                    }
                    if (this.isProductionRecipe) {
                      this.$set(this.recipe.content[group].variables[variable].cycles[cycle].slots[slot], 'changeTimeAfterLightOn', true)
                    }
                  }
                }
              }
            }
          }
        }
      }

      for (const group in this.recipe.content) {
        if (this.recipe.content[group].tag === 'water') {
          for (const variable in this.recipe.content[group].variables) {
            if (this.recipe.content[group].variables[variable].tag === 'irrigation') {
              for (const cycle in this.recipe.content[group].variables[variable].cycles) {
                if (!('slots' in this.recipe.content[group].variables[variable].cycles[cycle])) {
                  this.recipe.content[group].variables[variable].cycles[cycle].slots = [
                    {
                      startTimestamp: '',
                      max: this.recipe.content[group].variables[variable].cycles[cycle].max,
                      maxRef: this.recipe.content[group].variables[variable].cycles[cycle].maxRef,
                      min: this.recipe.content[group].variables[variable].cycles[cycle].min,
                      minRef: this.recipe.content[group].variables[variable].cycles[cycle].minRef,
                      ref: this.recipe.content[group].variables[variable].cycles[cycle].ref,
                      units: this.recipe.content[group].variables[variable].cycles[cycle].units,
                      timeoutSeconds: this.recipe.content[group].variables[variable].cycles[cycle].timeoutSeconds,
                    },
                  ]
                  delete this.recipe.content[group].variables[variable].cycles[cycle].max
                  delete this.recipe.content[group].variables[variable].cycles[cycle].maxRef
                  delete this.recipe.content[group].variables[variable].cycles[cycle].min
                  delete this.recipe.content[group].variables[variable].cycles[cycle].minRef
                  delete this.recipe.content[group].variables[variable].cycles[cycle].ref
                  delete this.recipe.content[group].variables[variable].cycles[cycle].units
                  delete this.recipe.content[group].variables[variable].cycles[cycle].timeoutSeconds
                }
              }
            }
          }
        }
      }
    },
    getIrrigationLightOnTime () {
      axios.get(`${structuredApiUrl}/farms/1/control-groups/${this.$route.params.roomId}`)
        .then(response => {
          this.controlGroupLightStartTime = response.data.data.settings.light.light_start_time.value
          this.controlGroupIrrigationDefaultStartTime = response.data.data.settings.irrigation.irrigation_start_time.value
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          this.$router.push({ path: '/404/page-not-found' })
        })
    },
    createTimeline () {
      this.duration = (this.durationDays + 1) * 24 * this.scale
      this.containerWidth = this.duration + this.padding * 2
      const timelineTextColor = styleHelpers.setSatLumHexColor(
        this.$themes.primary,
        { sat: 28, lum: 68 },
      )

      const c = document.getElementById('timeCanvas_' + this._uid)
      c.width = this.containerWidth
      c.height = 60

      this.canvas = c.getContext('2d')
      this.canvas.fillStyle = '#fff'
      this.canvas.fillRect(0, 0, 900, 50)

      for (let i = 0; i <= this.duration; i += this.scale) {
        if (i % (24 * this.scale) === 0) {
          this.canvas.beginPath()
          this.canvas.strokeStyle = timelineTextColor
          this.canvas.moveTo(i + this.padding, 5)
          this.canvas.lineTo(i + this.padding, 30)
          this.canvas.stroke()
          // circle
          this.canvas.beginPath()
          this.canvas.arc(i + this.padding, 30, 2, 0, 2 * Math.PI, false)
          this.canvas.fillStyle = this.$themes.primary
          this.canvas.fill()
          this.canvas.closePath()
        } else if (i % (12 * this.scale) === 0) {
          this.canvas.beginPath()
          this.canvas.strokeStyle = timelineTextColor
          this.canvas.moveTo(i + this.padding, 5)
          this.canvas.lineTo(i + this.padding, 22)
          this.canvas.stroke()
        } else {
          this.canvas.beginPath()
          this.canvas.strokeStyle = timelineTextColor
          this.canvas.moveTo(i + this.padding, 5)
          this.canvas.lineTo(i + this.padding, 15)
          this.canvas.stroke()
        }
      }

      this.canvas.textAlign = 'center'

      for (let i = 0, nDay = 1; i <= this.duration; i += this.scale) {
        let text = ''
        let pos = 45

        if (i % (24 * this.scale) === 0) {
          this.canvas.fillStyle = timelineTextColor
          this.canvas.font = 'normal 10px Montserrat, Arial'
          text = `DAY ${nDay - 1}`
          nDay++
        } else if (i % (12 * this.scale) === 0) {
          this.canvas.fillStyle = timelineTextColor
          text = '12:00'
          pos = 35
        }

        this.canvas.fillText(text, i + this.padding, pos)
      }
    },
    toggleGroup (id) {
      const groupId = 'recipe_' + id
      const element = this.$refs[groupId][0]
      element.classList.toggle('close')

      this.recipe.content = this.recipe.content.map(r => {
        if (r.id === id) {
          r.open = !r.open
        }
        return r
      })

      setTimeout(() => this.getRecipeContainerSizes(), 10) // toggle has no callback, so added a timeout to trigger 10ms after
    },
    editVariable (id, variableId = 0, startDay = 1) {
      this.selectedGroupId = id
      this.selectedVariableId = variableId
      const recipeSetting = this.recipe.content.find(r => r.id === id)

      if (recipeSetting && recipeSetting.variables) {
        this.selectedGroupName = recipeSetting.tag
        const variable = recipeSetting.variables.find(
          i => i.id === this.selectedVariableId,
        )
        const cycle = variable.cycles.find(i => i.startDay === startDay)

        if (cycle) {
          if (recipeSetting.tag === 'light') {
            this.selectedData = {
              startDay: cycle.startDay,
              slots: [],
            }
            for (const slot in cycle.slots) {
              cycle.slots[slot].durationString = moment.utc(cycle.slots[slot].duration * 60 * 1000).format('HH:mm:ss')
              cycle.slots[slot].transitionString = moment.utc(cycle.slots[slot].transition * 60 * 1000).format('HH:mm:ss')

              this.selectedData.slots = [
                ...this.selectedData.slots,
                {
                  ...cycle.slots[slot],
                },
              ]
            }
          } else if (recipeSetting.tag === 'air') {
            if (cycle.oscillation_period_seconds) {
              cycle.oscillationPeriodString = moment.utc(cycle.oscillation_period_seconds * 1000).format('HH:mm:ss')
            }

            cycle.transitionDayNightString = moment.utc(cycle.transitionDayNightSeconds * 1000).format('HH:mm:ss')
            cycle.nightTransitionString = moment.utc(cycle.nightTransition * 1000).format('HH:mm:ss')

            this.selectedData = {
              ...cycle,
            }
          } else if (recipeSetting.tag === 'water') {
            if (variable.tag === 'irrigation') {
              if (('slots' in cycle)) {
                for (const slot in cycle.slots) {
                  this.$set(cycle.slots[slot], 'irrigationTimeoutString', moment.utc(cycle.slots[slot].timeoutSeconds * 1000).format('HH:mm:ss'))
                }
              } else {
                this.irrigationTimeoutString = moment.utc(cycle.timeoutSeconds * 1000).format('HH:mm:ss')
              }

              if (!('slots' in cycle)) {
                cycle.slots = [
                  {
                    startTimestamp: '',
                    max: cycle.max,
                    maxRef: cycle.maxRef,
                    min: cycle.min,
                    minRef: cycle.minRef,
                    ref: cycle.ref,
                    units: cycle.units,
                    timeoutSeconds: cycle.timeoutSeconds,
                  },
                ]
                delete cycle.max
                delete cycle.maxRef
                delete cycle.min
                delete cycle.minRef
                delete cycle.ref
                delete cycle.units
                delete cycle.timeoutSeconds
              }
            }

            if (this.isProductionRecipe) {
              let timeAfterLightOnSeconds = ''
              if (('slots' in cycle)) {
                for (const slot in cycle.slots) {
                  if (variable.tag === 'irrigation' && cycle.slots[slot].timeAfterLightOn) {
                    timeAfterLightOnSeconds = moment.duration(cycle.slots[slot].timeAfterLightOn).asSeconds()
                    const irrigationStartTime = moment.tz(this.controlGroupLightStartTime, 'HH:mm', this.farmTimezone).add(timeAfterLightOnSeconds, 'seconds')
                    this.$set(cycle.slots[slot], 'irrigationLocalStartTime', irrigationStartTime.format('HH:mm:ss'))
                  } else {
                    this.$set(cycle.slots[slot], 'irrigationLocalStartTime', moment.tz(this.controlGroupIrrigationDefaultStartTime, 'HH:mm', this.farmTimezone).format('HH:mm:ss'))
                  }
                }
              } else {
                if (variable.tag === 'irrigation' && cycle.timeAfterLightOn) {
                  timeAfterLightOnSeconds = moment.duration(cycle.timeAfterLightOn).asSeconds()
                  const irrigationStartTime = moment.tz(this.controlGroupLightStartTime, 'HH:mm', this.farmTimezone).add(timeAfterLightOnSeconds, 'seconds')

                  this.irrigationLocalStartTime = irrigationStartTime.format('HH:mm:ss')
                } else {
                  this.irrigationLocalStartTime = moment.tz(this.controlGroupIrrigationDefaultStartTime, 'HH:mm', this.farmTimezone).format('HH:mm:ss')
                }
              }
            }
            this.selectedData = {
              ...cycle,
            }
          }
          this.existingCycle = true
        } else {
          const recipeDummySetting = dummyRecipeEmpty.content.find(r => r.id === id)
          const variableDummy = recipeDummySetting.variables.find(
            i => i.id === this.selectedVariableId,
          )
          delete variableDummy.cycles[0].startDay

          this.selectedData = {
            startDay,
            ...JSON.parse(JSON.stringify(variableDummy.cycles[0])),
          }

          this.existingCycle = false
        }

        this.selectedVariable = variable
        this.modalTitle = this.$t(`${recipeSetting.tag}Data.${this.selectedVariable.tag}`)
        this.modalSubTitle = this.$t(`growing.recipeEdition.${recipeSetting.tag}`)
        this.showAddNewModal = true
        this.duplicateTitle = this.$t('growing.recipeEdition.duplicateSetting')
      }
    },
    confirmUpdateCycle () {
      if (this.selectedGroupId) {
        const recipeSetting = this.recipe.content.find(r => r.id === this.selectedGroupId)

        if (recipeSetting && recipeSetting.variables) {
          const variable = recipeSetting.variables.find(
            i => i.id === this.selectedVariableId,
          )
          const cycleIndex = variable.cycles.findIndex(
            i => i.startDay === this.selectedData.startDay,
          )

          if (variable.tag === 'irrigation' && !this.selectedData.irrigate) {
            this.invalidForm = false
          } else {
            this.validateCycle(recipeSetting.tag, variable.tag)
          }
          if (!this.invalidForm) {
            if (cycleIndex !== -1) {
              variable.cycles.splice(cycleIndex, 1, this.selectedData)
            } else {
              variable.cycles.push(this.selectedData)
            }

            recipeSetting.open = true
            this.selectedGroupName = ''
            this.selectedGroupId = 0
            this.selectedVariable = ''
            this.selectedData = null
            this.irrigationTimeoutString = ''
            this.irrigationLocalStartTime = ''
            this.deleteOption = false
            this.invalidForm = false
            this.minError = []
            this.nightMinError = []
            this.minRefError = []
            this.nightMinRefError = []
            this.refError = []
            this.nightRefError = []
            this.maxRefError = []
            this.nightMaxRefError = []
            this.maxError = []
            this.nightMaxError = []
            this.irrigationDurationErrors = []

            this.$refs.modal.ok()
          }
        }
      }
    },
    validateCycle (tag, variableTag) {
      this.minError = []
      this.nightMinError = []
      this.minRefError = []
      this.nightMinRefError = []
      this.refError = []
      this.nightRefError = []
      this.maxRefError = []
      this.nightMaxRefError = []
      this.maxError = []
      this.nightMaxError = []
      this.irrigationDurationErrors = []

      switch (tag) {
        case 'water':
          if (variableTag === 'irrigation') {
            if ('slots' in this.selectedData) {
              for (const slot in this.selectedData.slots) {
                this.selectedData.slots[slot].timeoutSeconds = moment.duration(this.selectedData.slots[slot].irrigationTimeoutString).asSeconds()
                if (this.selectedData.slots[slot].timeoutSeconds === 0) {
                  this.irrigationDurationErrors = ['Define irrigation duration']
                } else {
                  delete this.selectedData.slots[slot].irrigationTimeoutString
                  delete this.selectedData.timeoutSeconds
                }
              }
            } else {
              this.selectedData.timeoutSeconds = moment.duration(this.irrigationTimeoutString).asSeconds()
              if (this.selectedData.timeoutSeconds === 0) {
                this.irrigationDurationErrors = ['Define irrigation duration']
              } else {
                delete this.selectedData.irrigationTimeoutString
              }
            }
          }

          if (this.isProductionRecipe) {
            if ('slots' in this.selectedData) {
              for (const slot in this.selectedData.slots) {
                if (variableTag === 'irrigation' && this.selectedData.slots[slot].changeTimeAfterLightOn) {
                  const irrigationStart = moment.tz(this.selectedData.slots[slot].irrigationLocalStartTime, 'HH:mm:ss', this.farmTimezone)
                  const lightOn = moment.tz(this.controlGroupLightStartTime + ':00', 'HH:mm:ss', this.farmTimezone)
                  this.$set(this.selectedData.slots[slot], 'timeAfterLightOn', moment.utc(irrigationStart.diff(lightOn)).format('HH:mm:ss'))
                }
              }
            } else {
              if (variableTag === 'irrigation' && this.changeTimeAfterLightOn) {
                const irrigationStart = moment.tz(this.irrigationLocalStartTime, 'HH:mm:ss', this.farmTimezone)
                const lightOn = moment.tz(this.controlGroupLightStartTime + ':00', 'HH:mm:ss', this.farmTimezone)

                this.selectedData.timeAfterLightOn = moment.utc(irrigationStart.diff(lightOn)).format('HH:mm:ss')
              }
            }
          }

          this.validateValues(null, this.selectedData.min, this.selectedData.minRef, this.selectedData.ref, this.selectedData.maxRef, this.selectedData.max)
          break

        case 'air': // night and day
          if (variableTag === 'volume_flow') {
            this.selectedData.oscillation_period_seconds = moment.duration(this.selectedData.oscillationPeriodString).asSeconds().toString()
          } else {
            delete this.selectedData.oscillation_period_seconds
          }
          this.selectedData.transitionDayNightSeconds = moment.duration(this.selectedData.transitionDayNightString).asSeconds().toString()
          this.selectedData.nightTransition = moment.duration(this.selectedData.nightTransitionString).asSeconds().toString()
          delete this.selectedData.oscillationPeriodString
          delete this.selectedData.transitionDayNightString
          delete this.selectedData.nightTransitionString

          this.validateValues(null, this.selectedData.dayMin, this.selectedData.dayMinRef, this.selectedData.dayRef, this.selectedData.dayMaxRef, this.selectedData.dayMax)
          this.validateValues('night', this.selectedData.nightMin, this.selectedData.nightMinRef, this.selectedData.nightRef, this.selectedData.nightMaxRef, this.selectedData.nightMax)
          break

        case 'light':
          for (const slot in this.selectedData.slots) {
            this.selectedData.slots[slot].duration = moment.duration(this.selectedData.slots[slot].durationString).asMinutes().toString()
            this.selectedData.slots[slot].transition = moment.duration(this.selectedData.slots[slot].transitionString).asMinutes().toString()
            delete this.selectedData.slots[slot].durationString
            delete this.selectedData.slots[slot].transitionString
          }
          break

        default:
          break
      }
    },
    validateValues (tag, min, minRef, ref, maxRef, max) {
      min = parseFloat(min)
      minRef = parseFloat(minRef)
      ref = parseFloat(ref)
      maxRef = parseFloat(maxRef)
      max = parseFloat(max)

      const maxValue = Math.max(min, minRef, ref, maxRef)
      const minValue = Math.min(minRef, ref, maxRef, max)

      if (min >= minValue) {
        this.minError = tag ? [] : ['Should be the lowest']
        this.nightMinError = tag ? ['Should be the lowest'] : []
      }
      if (minRef <= min) {
        this.minRefError = tag ? [] : ['Should be higher than MIN']
        this.nightMinRefError = tag ? ['Should be higher than MIN'] : []
      }
      if (minRef >= ref) {
        this.minRefError = tag ? [] : ['Should be lowest then REF']
        this.nightMinRefError = tag ? ['Should be lowest then REF'] : []
      }
      if (ref <= minRef) {
        this.refError = tag ? [] : ['Should be higher than REF MIN']
        this.nightRefError = tag ? ['Should be higher than REF MIN'] : []
      }
      if (ref >= maxRef) {
        this.refError = tag ? [] : ['Should be lowest then REF MAX']
        this.nightRefError = tag ? ['Should be lowest then REF MAX'] : []
      }
      if (maxRef <= ref) {
        this.maxRefError = tag ? [] : ['Should be higher than REF']
        this.nightMaxRefError = tag ? ['Should be higher than REF'] : []
      }
      if (maxRef >= max) {
        this.maxRefError = tag ? [] : ['Should be lowest then MAX']
        this.nightMaxRefError = tag ? ['Should be lowest then MAX'] : []
      }
      if (max <= maxValue) {
        this.maxError = tag ? [] : ['Should be the highest']
        this.nightMaxError = tag ? ['Should be the highest'] : []
      }

      this.invalidForm = this.minError.length || this.nightMinError.length ||
      this.minRefError.length || this.nightMinRefError.length ||
      this.refError.length || this.nightRefError.length ||
      this.maxRefError.length || this.nightMaxRefError.length ||
      this.maxError.length || this.nightMaxError.length || this.irrigationDurationErrors.length
    },
    cancelUpdateCycle () {
      this.selectedGroupName = ''
      this.selectedGroupId = 0
      this.selectedVariable = ''
      this.selectedData = null
      this.irrigationTimeoutString = ''
      this.irrigationLocalStartTime = ''
      this.detailsOption = false
      this.deleteOption = false
      this.duplicateOption = false
      this.invalidForm = false
      this.minError = []
      this.nightMinError = []
      this.minRefError = []
      this.nightMinRefError = []
      this.refError = []
      this.nightRefError = []
      this.maxRefError = []
      this.nightMaxRefError = []
      this.maxError = []
      this.nightMaxError = []
      this.irrigationDurationErrors = []
      this.$refs.modal.cancel()
    },
    deleteCycle () {
      const recipeSetting = this.recipe.content.find(r => r.id === this.selectedGroupId)
      if (recipeSetting && recipeSetting.variables) {
        const variable = recipeSetting.variables.find(
          i => i.id === this.selectedVariableId,
        )
        if (variable && variable.cycles) {
          variable.cycles = variable.cycles.filter(
            i => i.startDay !== this.selectedData.startDay,
          )
          this.$refs.modal.cancel()

          if (variable.cycles.length === 0) {
            variable.enabled = false
          }
        }
      }
      this.deleteOption = false
    },
    groupBottomBorderStyle (recipeIdx, recipeSettings, varIdx, variables) {
      const style =
        'border-bottom: 2px solid hsl(var(--primary-color-hue), 20%, 83%);'
      return recipeIdx === recipeSettings.length - 1 && varIdx === variables.length - 1
        ? style
        : ''
    },
    getPixelPositionFromDay (day) {
      return day === 1 ? 25 : (day - 1) * 24 * this.scale + 25 // The value 25 is the CSS left margin for the __variable layer
    },
    checkIfCycleExistsInVariable (variable, startDay) {
      return variable.cycles.find(i => i.startDay === startDay) ?? false
    },
    addLightSlot () {
      this.selectedData.slots.push({
        duration: 0,
        transition: 0,
        blue: 0,
        green: 0,
        red: 0,
        farRed: 0,
        units: 'umol/m2/s',
      })
    },
    addIrrigationSlot () {
      let newSlot = {}
      const numberSlots = this.selectedData.slots.length
      if (numberSlots > 0) {
        newSlot = {
          ...this.selectedData.slots[numberSlots - 1],
        }
      } else {
        newSlot = {
          startTimestamp: '',
          min: 0,
          minRef: 0,
          ref: 0,
          maxRef: 0,
          max: 0,
          units: 'm3',
          timeoutSeconds: 0,
          changeTimeAfterLightOn: true,
        }
      }
      this.selectedData.slots.push(newSlot)
    },
    deleteLightSlot (index) {
      this.selectedData.slots.splice(index, 1)
    },
    deleteIrrigationSlot (index) {
      this.selectedData.slots.splice(index, 1)
    },
    countTotalLightHours () {
      return Math.round(
        this.selectedData.slots.reduce(
          (a, b) => +a + (+moment.duration(b.durationString).asMinutes() / 60 || 0),
          0,
        ),
      )
    },
    getNumberSlots (id, variableId = 0, startDay = 1) {
      const recipeSetting = this.recipe.content.find(r => r.id === id)
      const variable = recipeSetting.variables.find(
        i => i.id === variableId,
      )

      if (recipeSetting && recipeSetting.variables) {
        const cycle = variable.cycles.find(i => i.startDay === startDay)
        if (!('slots' in cycle)) {
          return 0
        } else {
          return cycle.slots.length
        }
      }
    },
    getSummaryInfo (recipeId, variableId, day) {
      const recipeSetting = this.recipe.content.find(r => r.id === recipeId)
      const summary = []

      if (recipeSetting && recipeSetting.variables) {
        const variable = recipeSetting.variables.find(i => i.id === variableId)

        if (variable && variable.cycles) {
          const cycle = variable.cycles.find(i => i.startDay === day)
          if (cycle) {
            switch (recipeSetting.tag) {
              case 'water':
                if (('slots' in cycle)) {
                  for (const slot in cycle.slots) {
                    if (variable.tag === 'irrigation' && !cycle.irrigate) {
                      summary.push({ value: 'No' })
                    } else {
                      summary.push({ label: 'Ref: ', value: `${cycle.slots[slot].ref} ${this.getUnitFormated(cycle.slots[slot].units)}`, icon: 'at-icon l_target' })
                    }
                  }
                } else {
                  summary.push({ label: 'Ref: ', value: `${cycle.ref} ${this.getUnitFormated(cycle.units)}`, icon: 'at-icon l_target' })
                }
                break

              case 'light':
                if (cycle.slots[0].blue !== null && cycle.slots[0].green !== null && cycle.slots[0].red !== null && cycle.slots[0].farRed !== null) {
                  summary.push({ label: 'blue', value: cycle.slots[0].blue, icon: 'circle', class: 'fa fa-circle', style: 'color: #728ac0;' })
                  summary.push({ label: 'green', value: cycle.slots[0].green, icon: 'circle', class: 'fa fa-circle', style: 'color: #7abf82;' })
                  summary.push({ label: 'red', value: cycle.slots[0].red, icon: 'circle', class: 'fa fa-circle', style: 'color: #da615b;' })
                  summary.push({ label: 'farRed', value: cycle.slots[0].farRed, icon: 'circle', class: 'fa fa-circle', style: 'color: #722622;' })
                  summary.push({ label: '', value: `${this.getUnitFormated(cycle.slots[0].units)}` })
                } else {
                  summary.push({ label: '', value: 'OFF' })
                }
                break

              default:
                summary.push({ label: 'DayRef', value: `${cycle.dayRef} ${this.getUnitFormated(cycle.units)}`, icon: 'at-icon b_weather_sunny' })
                summary.push({ label: 'NightRef', value: `${cycle.nightRef} ${this.getUnitFormated(cycle.units)}`, icon: 'at-icon b_weather_moon' })
                break
            }
          }
        }
      }

      return summary
    },
    getGroupIcon (group) {
      let icon = 'at-icon l_'

      switch (group) {
        case 'water':
          icon += 'water1drop'
          break

        case 'air':
          icon += 'airflow'
          break

        case 'light':
          icon += 'weather_sunny'
          break
      }

      return icon
    },
    getUnitFormated (units) {
      return styleHelpers.getOnlyUnitFormat(units)
    },
    validateRecipe () {
      let valid = true
      // Check inputs
      if (this.recipe.name === 'Empty Recipe' || this.recipe.name === '') {
        this.nameErrorsMessage = 'Add recipe name'
        this.nameNotificationVisible = true
        valid = false
      }
      if (this.recipe.description === 'This is an empty recipe' || this.recipe.description === '') {
        this.descriptionErrorsMessage = 'Add description'
        this.descriptionNotificationVisible = true
        valid = false
      }
      if (this.recipe.durationDays < 2) {
        this.durationErrorsMessage = 'The recipe must have at least one day'
        this.durationNotificationVisible = true
        valid = false
      }
      // Check cycle startDay after this.recipe.durationDays
      this.recipe.content.map(group => {
        group.variables.map(variable => {
          variable.cycles.sort((a, b) => (a.startDay > b.startDay ? 1 : -1))
          variable.cycles.some((cycle, index) => {
            if (cycle.startDay === null || cycle.startDay > this.recipe.durationDays || cycle.startDay < 0) {
              variable.cycles.splice(index) // remove days after
            }
          })
        })
      })
      return valid
    },
    checkManualChanges () {
      const waterChanges = !isEqual(this.originalRecipe.content[0].variables, this.recipe.content[0].variables)
      const climateChanges = !isEqual(this.originalRecipe.content[1].variables, this.recipe.content[1].variables)
      const lightChanges = !isEqual(this.originalRecipe.content[2].variables, this.recipe.content[2].variables)

      if (waterChanges && !this.growingRecipeEncrypted.tags.includes('recipe_manual_water')) {
        this.growingRecipeEncrypted.tags.push('recipe_manual_water')
      }

      if (climateChanges && !this.growingRecipeEncrypted.tags.includes('recipe_manual_air')) {
        this.growingRecipeEncrypted.tags.push('recipe_manual_air')
      }

      if (lightChanges && !this.growingRecipeEncrypted.tags.includes('recipe_manual_light')) {
        this.growingRecipeEncrypted.tags.push('recipe_manual_light')
      }
    },
    addCreatedTimeStamp () {
      this.recipe.createdAt = new Date().toISOString()
    },
    addLastUpdateTimeStamp () {
      this.recipe.lastUpdate = new Date().toISOString()
    },
    cancelModal () {
      // this.$refs.modal.close()
      this.$emit('cancel')
    },
    toogleShowSummaries () {
      this.showSummaries = !this.showSummaries
    },
    toggleVariableOptions (recipeSettingId, variableId, day) {
      if (!this.editable) {
        this.detailsOption = true
        this.deleteOption = false
        this.duplicateOption = false

        this.editVariable(recipeSettingId, variableId, day)
      }

      if (recipeSettingId) {
        this.showOption = `${recipeSettingId}-${variableId}-${day}`
      } else {
        this.showOption = ''
      }
    },
    onOptionClick (value) {
      const option = value.option
      const recipeSettingId = value.recipeSettingId
      const variableId = value.variableId
      const day = value.day

      switch (option) {
        case 'duplicate':
          this.detailsOption = false
          this.deleteOption = false
          this.duplicateOption = true
          break

        case 'delete':
          this.detailsOption = false
          this.deleteOption = true
          this.duplicateOption = false
          break

        case 'details':
          this.detailsOption = true
          this.deleteOption = false
          this.duplicateOption = false
          break

        default: // edit
          this.detailsOption = false
          this.deleteOption = false
          this.duplicateOption = false
          break
      }

      this.editVariable(recipeSettingId, variableId, day)
    },
    getDaysList () {
      // Remove days before current day and days with cycles
      return [...Array(this.durationDays + 1).keys()].reduce((previousValue, currentValue) => {
        if (parseInt((this.selectedData.startDay) - 1) !== currentValue &&
          !this.checkIfCycleExistsInVariable(this.selectedVariable, currentValue + 1) &&
          (this.currentDay - 1 <= currentValue && this.currentDay !== currentValue)) {
          previousValue.push(`${currentValue}`)
        }
        return previousValue
      }, [])
    },
    duplicateCycle () {
      this.selectedData.startDay = parseInt(this.selectedDuplicateDate) + 1
      this.confirmUpdateCycle()

      this.$refs.modal.cancel()
      this.selectedDuplicateDate = '' // reset to empty after duplication
      this.duplicateOption = false
    },
    getRecipeContainerSizes () {
      if (this.$refs.recipesGroupsContainer) {
        const recipeContainerHeight = this.$refs.recipesGroupsContainer.clientHeight - 5 + 'px'
        const recipeHarvestingHighlightPos = this.containerWidth - 4
        const root = document.querySelector('.recipes')
        root.style.setProperty('--recipe-container-height', recipeContainerHeight)
        root.style.setProperty('--recipe-harvesting-highlight-pos', recipeHarvestingHighlightPos + 'px')
      }
    },
    setIrrigationButtonColors (recipeSettingId, variableId, day, tag) {
      const summary = this.getSummaryInfo(recipeSettingId, variableId, day)
      let inactive = false

      if (summary[0] && summary[0].value && summary[0].value === 'No' && tag === 'irrigation') {
        inactive = true
      }

      return inactive
    },
    getCalendarLanguage () {
      if (Vue.i18n.locale() === 'it') {
        return Italian
      } else {
        return english
      }
    },
  },
  // try different approach less reactive
  watch: {
    'durationDaysInput' () {
      if (parseInt(this.durationDaysInput) > 0) {
        this.durationDays = parseInt(this.durationDaysInput)
        this.recipe.durationDays = parseInt(this.durationDaysInput) + 1
        this.createTimeline()

        this.$nextTick(function () {
          this.getRecipeContainerSizes()
          window.addEventListener('resize', this.getRecipeContainerSizes)
        })
      }
    },
    'changeTimeAfterLightOn' () {
      if (!this.changeTimeAfterLightOn) {
        this.irrigationLocalStartTime = moment.tz(this.controlGroupIrrigationDefaultStartTime, 'HH:mm', this.farmTimezone).format('HH:mm:ss')
      }
    },
  },
  computed: {
    computedStyle () {
      const style = {
        borderRadius: styleHelpers.genRadius({ topLeft: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 }),
      }
      return style
    },
    farmTimezone () {
      return Vue.farmTimezone ? Vue.farmTimezone : 'Europe/Rome'
    },
  },
}
</script>

<style lang="scss">
$timelineHeight: 60px;
$timeline-levels-width: 220px;
$groupHeight: 50px;
$containerMaxHeight: calc(100vh - 185px);
$variableGreen: #2abea5;
$variableBlue: #4eacf1;
$variableOrange: #f6a250;
$ledGreen: #7abf82;
$ledBlue: #728ac0;
$ledRed: #da615b;
$ledFarRed: #722622;

.header {
  padding: 0.8rem;
  margin: 0;
  position: relative;

  &__name,
  &__description,
  &__cycle {
    padding: 0 5px;

    input {
      width: 100%;
      background: transparent;
      border: 1px solid hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 10%);
      padding: 0 0.5rem;
      box-shadow: none;
      outline: none;
      color: var(--primary-color);
      font-weight: 500;
      letter-spacing: pxtorem(1.5);
      height: 50px;

      &:hover:not(:disabled) {
        border: 1px solid hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 40%);
      }

      &:disabled {
        border: none;
        padding: 0;
      }
    }
  }

  &__name {
    input {
      font-size: pxtorem(28);
      color: var(--primary-color);
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      letter-spacing: pxtorem(1.5);
    }
  }

  &__description {
    input {
      font-size: pxtorem(18);
      color: var(--primary-color);
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
    }
  }

  &__cycle {
    font-size: pxtorem(18);
    color: var(--primary-color);
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;

    input {
      width: 45px;
      font-size: pxtorem(18);
      color: var(--primary-color);
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
    }
  }

  &__close {
    position: absolute;
    right: 5px;
    top: 0;
    background: none;
    border: none;
    color: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 50%);
    cursor: pointer;

    i {
      font-size: pxtorem(28);
    }

    &:hover {
      color: var(--primary-color);
    }
  }

  &__bottom {
    font-size: pxtorem(16);
    display: flex;
    margin-top: 10px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: pxtorem(0.25);
  }
}

.recipes {
  --recipe-container-height: 100vh;
  --recipe-harvesting-highlight-pos: 100vw;

  position: relative;
  height: 100%;
  background: hsl(var(--primary-color-hue), 44%, 95%);

  .va-count-badge {
    padding-top: 6px;

    &.green {
      .va-count-badge__round {
        background-color: $variableGreen !important;
      }
    }

    &.blue {
      .va-count-badge__round {
        background-color: $variableBlue !important;
      }
    }

    &.orange {
      .va-count-badge__round {
        background-color: $variableOrange !important;
      }
    }

    .va-count-badge__round {
      width: 1.2rem !important;
      height: 1.2rem !important;
      top: -15px !important;
      right: -18px !important;
      border-color: white;
      outline-style: solid;
      border-width: 8px;
      font-size: 0.6rem;
    }
  }

  &__groups,
  &__variables {
    display: inline-block;
    vertical-align: top;
    max-height: $containerMaxHeight;
  }

  &__groups {
    @include shadow($offset-y: 0, $offset-x: 0, $radius: 15px, $alpha: 0.3);

    position: relative;
    width: $timeline-levels-width;
    background: hsl(var(--primary-color-hue), 44%, 95%);
    border-right: 1px solid hsla(var(--primary-color-hue), 20%, 83%, 0.5);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 3;
    padding-bottom: 5px;

    &__group {
      position: relative;

      /* hack to add a bottom border without screwing with the child elements */
      &:last-of-type::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: 2px solid hsl(var(--primary-color-hue), 20%, 83%);
      }

      &__name {
        padding: 15px 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;
        height: $groupHeight;
        background: hsl(var(--primary-color-hue), 39%, 92%);
        border-top: 2px solid hsl(var(--primary-color-hue), 20%, 83%);

        .name-content {
          i {
            margin-left: -15px;
            font-size: 1.3rem;
          }

          h5 {
            display: inline-block;
            font-size: pxtorem(16);
            text-transform: uppercase;
            font-weight: 700;
          }
        }

        i {
          margin-left: auto;
          color: var(--primary-color);
        }

        &.close {
          i {
            transform: rotate(180deg);
          }
        }
      }

      &__variable {
        height: $groupHeight;
        padding: 15px 0 15px 5px;
        width: calc(#{$timeline-levels-width} - 21px);
        margin-left: 20px;
        background: hsl(var(--primary-color-hue), 44%, 95%);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-of-type) {
          border-bottom: 2px solid hsl(var(--primary-color-hue), 20%, 83%);
        }

        h6 {
          font-size: pxtorem(14);
        }

        .actions {
          margin-top: 1rem;

          .va-toggle--small {
            .va-toggle__inner {
              transform: scale(0.5);
            }
          }
        }
      }
    }

    &__placeholder-top {
      z-index: 5;
      height: $timelineHeight;
      width: $timeline-levels-width;
      background: hsl(var(--primary-color-hue), 44%, 95%);
      position: sticky;
      top: 0;

      /* hack to add a bottom border without screwing with the child elements */
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: -2px;
        left: 0;
        right: 0;
        border-bottom: 2px solid hsl(var(--primary-color-hue), 20%, 83%);
      }
    }

    &__placeholder-bottom {
      z-index: 5;
      height: 5px;
      width: $timeline-levels-width;
      background: hsl(var(--primary-color-hue), 44%, 95%);
      position: relative;
      bottom: 0;

      /* hack to add a bottom border without screwing with the child elements */
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: -2px;
        left: 0;
        right: 0;
        border-top: 2px solid hsl(var(--primary-color-hue), 20%, 83%);
      }
    }
  }

  &__variables {
    width: calc(100% - #{$timeline-levels-width});
    overflow: auto;

    &__timeline {
      position: sticky;
      top: 0;
      z-index: 20;
      background: #ffffff;
      height: $timelineHeight;

      .timeline__position {
        background: url(../../../../assets/imgs/timeline-arrow.png);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 100%;
        image-rendering: pixelated;
        position: absolute;
        top: 0;
        left: 0;
        width: 13px;
        height: 4rem;
      }

      .timeline__position_line {
        background: url(../../../../assets/imgs/timeline-arrow.png);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 100%;
        image-rendering: pixelated;
        position: absolute;
        top: 0;
        left: 0;
        width: 13px;
        height: var(--recipe-container-height);

        &::after {
          content: '';
          position: absolute;
          z-index: 10;
          top: 0;
          bottom: 0;
          left: 49%;
          border-left: 2px solid #ff0000;
          transform: translate(-50%);
        }
      }

      .time-canvas {
        background: #ffffff;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 2px solid hsl(var(--primary-color-hue), 20%, 83%);
      }
    }

    &__group {
      height: $groupHeight;
    }

    /* Add border for all groups but not the first occurance */
    & > .recipes__variables__group ~ .recipes__variables__group {
      border-top: 2px solid hsl(var(--primary-color-hue), 20%, 83%);
    }

    &__variable {
      position: relative;
      height: $groupHeight;
      background: #ffffff;
      padding-left: 25px;
      padding-right: 20px;
      display: flex;
      align-items: center;

      &:not(:last-of-type) {
        border-bottom: 2px solid hsla(var(--primary-color-hue), 20%, 83%, 0.4);
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 35px;
        right: 20px;
        border-bottom: 4px solid hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.4);
        transform: translateY(-2px);
        z-index: 2;
      }

      .recipe-cycle-info {
        width: 20px;
        height: 20px;
        position: absolute;
        z-index: 10;

        &__summary {
          @include radius(20px);

          display: flex;
          align-items: center;
          width: max-content;
          z-index: -1;
          background: rgba(0, 0, 0, 0.75);
          position: absolute;
          bottom: -3px;
          left: 28px;
          opacity: 0;
          transition: opacity 0.5s;

          &::before {
            content: '';
            position: absolute;
            top: 6px;
            left: -10px;
            width: 0;
            height: 0;
            border-width: 6px;
            border-color: transparent rgba(0, 0, 0, 0.75) transparent transparent;
            border-style: solid;
          }

          &__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 25px;
            padding: 0 8px 0 8px;
            overflow: hidden;

            span {
              white-space: pre;
              color: #ffffff;
              font-size: pxtorem(12);
            }
          }
        }

        &:hover,
        &.show {
          .recipe-cycle-info__summary {
            opacity: 1;
            z-index: 1;
          }
        }
      }

      button {
        z-index: 10;
        position: absolute;
        background: var(--primary-color);
        border: none;
        outline: none;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          background: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.8);
        }

        i {
          display: flex;
          justify-content: center;
          color: #ffffff;
          font-size: pxtorem(20);

          &.irrigation-icon {
            font-size: pxtorem(13);
            top: 2px;
            left: 1px;
          }
        }

        &.add {
          background: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.8);
          opacity: 0;

          i {
            color: #ffffff;
            transition: all 0.4s;
          }

          &:hover {
            opacity: 1;
          }
        }
      }

      &.disabled.green,
      &.disabled.blue,
      &.disabled.orange {
        &::after {
          border-bottom: 4px solid rgba(0, 0, 0, 0.15);
        }

        button {
          cursor: default;
          background: #cccccc;

          &.add {
            display: none;
          }
        }

        .recipe-cycle-info {
          &__summary {
            display: none;
          }
        }
      }

      &.green {
        &::after {
          border-bottom: 4px solid rgba($variableGreen, 0.4);
        }

        button {
          background: $variableGreen;

          &:hover {
            background: rgba($variableGreen, 0.8);
          }

          &.add {
            background: rgba($variableGreen, 0.8);
          }
        }
      }

      &.blue {
        &::after {
          border-bottom: 4px solid rgba($variableBlue, 0.4);
        }

        button {
          background: $variableBlue;

          &:hover {
            background: rgba($variableBlue, 0.8);
          }

          &.add {
            background: rgba($variableBlue, 0.8);
          }

          &.inactive {
            background: #c33131;
          }
        }
      }

      &.orange {
        &::after {
          border-bottom: 4px solid rgba($variableOrange, 0.4);
        }

        button {
          background: $variableOrange;

          &:hover {
            background: rgba($variableOrange, 0.8);
          }

          &.add {
            background: rgba($variableOrange, 0.8);
          }
        }
      }
    }
  }
}

.va-modal {
  .va-modal__header {
    margin-bottom: 30px;

    h6 {
      text-transform: uppercase;
      font-weight: 600;
      font-size: pxtorem(16);
    }
  }

  .va-modal__message {
    width: 700px;

    .va-input__container__label,
    .va-select__label {
      font-family: var(--font-family-body);
      text-transform: none;
      top: 0.25rem;
      margin-bottom: 0;
      font-size: 0.55rem;
    }

    .va-input__container__input {
      font-family: var(--font-family-body);
    }

    .min_ref_value,
    .max_ref_value {
      .va-input__container {
        border-color: #f5a455 !important;
      }
    }

    .ref_value {
      .va-input__container {
        border-color: var(--success-color) !important;
      }
    }

    .min_value,
    .max_value {
      .va-input__container {
        border-color: #ff3333 !important;
      }
    }

    .transition_value {
      .va-input-wrapper.va-input {
        margin-left: -1rem;
      }

      .va-input__container__input {
        text-align: center;
      }

      .va-input__container::before {
        content: '\e84d';
        font-family: "atmosphere-icons";
        position: absolute;
        left: 7.7em;
        top: 1.01em;
        font-size: 1.5em;
        color: var(--primary-color);
      }
    }

    .light_blue_value {
      .va-input__container {
        border-color: $ledBlue !important;
      }
    }

    .light_green_value {
      .va-input__container {
        border-color: $ledGreen !important;
      }
    }

    .light_red_value {
      .va-input__container {
        border-color: $ledRed !important;
      }
    }

    .light_farred_value {
      .va-input__container {
        border-color: $ledFarRed !important;
      }
    }

    .va-select__displayed-text {
      font-size: 0.95rem;
    }

    .title {
      border-bottom: 2px solid;
      margin-bottom: 20px;

      h6 {
        @include radius(0 15px 0 0);

        width: 120px;
        // width: 100px;
        display: inline-block;
        padding: 3px 20px 1px 10px;
        // padding: 3px 20px 1px 20px;
        color: #ffffff;
        font-size: pxtorem(14);
        text-transform: uppercase;
      }

      &.day {
        border-color: #94bdd1;

        h6 {
          background: #94bdd1;
        }
      }

      &.night {
        border-color: #4d80b2;

        h6 {
          background: #4d80b2;
        }
      }
    }

    .form {
      .slot {
        padding: 0.75rem 0.375rem 0 0.375rem;
        background: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.1);
      }

      .slot-total-info {
        display: flex;
        align-items: center;

        span {
          font-size: 0.7rem;
          text-transform: uppercase;

          b {
            font-size: 0.9rem;
            text-transform: none;
            color: var(--primary-color);
          }
        }
      }

      .add-slot-button {
        text-align: right;
      }

      .delete-slot-button {
        position: relative;

        .va-button {
          position: absolute;
          top: -10px;
          right: 0;

          &.va-button--small {
            padding: 0.125rem 0.125rem;
          }

          i {
            font-size: 1.2rem;
          }
        }
      }

      .flex {
        .va-input {
          // padding-right: 0.75rem;
          padding-left: 0.375rem;
          padding-right: 0.375rem;

          &.va-input-wrapper {
            margin-bottom: 0.75rem;
          }
        }
      }
    }
  }
}

.va-select__option-list li {
  font-size: pxtorem(14);
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Chrome/Safari/Webkit */
  }
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 15px !important;
}

*::-webkit-scrollbar-track {
  // background: hsl(var(--primary-color-hue), 44%, 95%);
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 0;
}

.text-right {
  text-align: right;
}

.overlay {
  position: relative;

  &.no-overflow {
    overflow: hidden;
  }

  &::before {
    content: '';
    position: absolute;
    left: 35px;
    top: 0;
    width: 290px;
    height: 100%;
    background: hsl(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig));
    z-index: 1;
    opacity: 0.1;
  }

  &::after {
    content: '';
    position: absolute;
    left: calc(var(--recipe-harvesting-highlight-pos) - 290px);
    top: 0;
    width: 290px;
    height: 100%;
    background: hsl(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig));
    z-index: 1;
    opacity: 0.1;
  }

  &__sowing,
  &__harvesting {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 290px;
    height: $groupHeight;

    span {
      font-size: 1rem;
      margin-left: 10px;
    }

    i {
      font-size: 2.3rem;
    }
  }

  &__sowing {
    left: 35px;
  }

  &__harvesting {
    left: calc(var(--recipe-harvesting-highlight-pos) - 290px);
  }
}

</style>
