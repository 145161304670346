<template>
  <div class="slider">
    <va-button
      @click="show = !show"
      :class="show ? 'active' : ''"
    >
      <va-icon
        :name="'at-icon l_time'"
        style="font-size: 1.8rem; display: flex;"
        :color="contextConfig.invertedColor ? $themes.gray : 'white'"
      />
    </va-button>

    <at-charts-slider class="slider__component" v-if="show"></at-charts-slider>
  </div>
</template>

<script>

import AtChartsSlider from '@/services/atmosphere-ui/components/at-charts-slider/AtChartsSlider'

export default {
  name: 'chart-slider-dropdown',
  components: {
    AtChartsSlider,
  },
  inject: ['contextConfig'],
  data () {
    return {
      show: false,
      selectedInvertal: { start: null, end: null, dataPoint: null },
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">

.slider {
  margin-top: -3px !important;

  .va-button {
    @include radius(10px 0 10px 0);

    padding: 5px 0;
    background: none;
    background-image: none !important;

    &.active {
      background: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 1);
    }

    .va-icon {
      cursor: pointer;
    }
  }

  &__component {
    position: absolute;
    top: 70px;
    right: 20px;
    left: 20px;
  }
}

</style>
