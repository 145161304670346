<template>
  <component
    :is="computedTag"
    class="va-button"
    :class="computedClass"
    :style="computedStyle"
    :disabled="disabled"
    :type="type"
    :href="href"
    :target="target"
    :to="to"
    :replace="replace"
    :append="append"
    :active-class="activeClass"
    :exact="exact"
    :exact-active-class="exactActiveClass"
    v-on="inputListeners"
    @mouseenter="updateHoverState(true)"
    @mouseleave="updateHoverState(false)"
    @focus="updateFocusState(true)"
    @blur="updateFocusState(false)"
    tabindex="0"
  >
    <div class="va-button__content">
      <va-icon
        v-if="icon"
        fixed-width
        class="va-button__content__icon va-button__content__icon-left"
        :name="icon"
      />
      <div v-if="hasTitleData" class="va-button__content__title">
        <slot />
      </div>
      <va-icon
        v-if="iconRight"
        fixed-width
        class="va-button__content__icon va-button__content__icon-right"
        :name="iconRight"
      />
    </div>
  </component>
</template>

<script>
import {
  getFocusColor,
  getHoverColor,
  styleHelpers,
} from '@/services/atmosphere-ui'
import { ColorThemeActionsMixin, ColorThemeMixin } from '@/services/vuestic-ui'

export default {
  name: 'va-button',
  mixins: [ColorThemeMixin, ColorThemeActionsMixin],
  inject: {
    va: {
      default: () => ({}),
    },
    contextConfig: {},
  },
  props: {
    group: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'button',
    },
    color: {
      type: String,
      default: 'primary',
    },
    outline: {
      type: Boolean,
    },
    flat: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    iconRight: {
      type: String,
    },
    type: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    /* Link props */
    href: {
      type: String,
    },
    target: {
      type: String,
    },
    /* Router link props */
    to: {
      type: [String, Object],
    },
    replace: {
      type: Boolean,
    },
    append: {
      type: Boolean,
    },
    activeClass: {
      type: String,
    },
    exact: {
      type: Boolean,
    },
    exactActiveClass: {
      type: String,
    },
  },
  data () {
    return {
      hoverState: false,
      focusState: false,
      btnColor: undefined,
      btnOutline: false,
      btnFlat: false,
      btnSmall: false,
      btnLarge: false,
    }
  },
  mounted () {
    this.btnColor = this.$themes[this.va.color] ?? this.colorComputed
    this.btnOutline = this.va.outline ?? this.outline
    this.btnFlat = this.va.flat ?? this.flat
    this.btnSmall = this.va.small ?? this.small
    this.btnLarge = this.va.large ?? this.large
  },
  computed: {
    computedClass () {
      return {
        'va-button--default': !this.btnFlat && !this.btnOutline,
        'va-button--flat': this.btnFlat,
        'va-button--outline': this.btnOutline,
        'va-button--disabled': this.disabled,
        'va-button--hover': this.hoverState,
        'va-button--focus': this.focusState,
        'va-button--without-title': !this.hasTitleData,
        'va-button--with-left-icon': this.icon,
        'va-button--with-right-icon': this.iconRight,
        'va-button--large': this.btnLarge,
        'va-button--small': this.btnSmall,
        'va-button--normal': !this.btnLarge && !this.btnSmall,
      }
    },
    gradientStyle () {
      if (this.btnFlat || this.btnOutline) {
        return
      }
      if (this.va.color) {
        return
      }
      return styleHelpers.buttonGradient(this.colorComputed)
    },
    shadowStyle () {
      if (this.va.color) {
        return
      }

      if (this.btnFlat) {
        return
      }

      return styleHelpers.genShadow(this.btnColor, null, { offsetY: 0, offsetX: 0, radius: 6 })
    },
    computedStyle () {
      const computedStyle = {
        color: '',
        borderColor: '',
        background: '',
        backgroundImage: '',
        boxShadow: '',
      }

      if (this.focusState) {
        if (this.btnOutline || this.btnFlat) {
          computedStyle.color = !this.va.group ? this.btnColor : '#ffffff'
          computedStyle.borderColor = this.btnOutline ? this.btnColor : ''
          computedStyle.background = !this.va.group ? getFocusColor(this.btnColor) : this.btnColor
        } else {
          if (this.va.group) {
            computedStyle.background = 'rgba(255, 255, 255, 0.3)'
          } else {
            computedStyle.backgroundImage = this.gradientStyle
          }
          computedStyle.color = '#ffffff'
          computedStyle.filter = 'brightness(100%)'
        }
      } else if (this.hoverState) {
        if (this.btnOutline || this.btnFlat) {
          computedStyle.color = !this.va.group ? this.colorComputed : '#ffffff'
          computedStyle.borderColor = this.btnOutline ? this.colorComputed : ''
          computedStyle.background = !this.va.group ? getHoverColor(this.colorComputed) : this.colorComputed
        } else {
          if (this.va.group) {
            computedStyle.background = 'rgba(255, 255, 255, 0.3)'
          } else {
            computedStyle.backgroundImage = this.gradientStyle
          }
        }
      } else {
        computedStyle.color = this.btnFlat || this.btnOutline ? this.colorComputed : '#ffffff'
        computedStyle.borderColor = this.btnOutline ? this.colorComputed : ''
        computedStyle.backgroundImage = this.gradientStyle
        computedStyle.boxShadow = this.shadowStyle
      }

      if (
        !this.btnOutline &&
        !this.btnFlat &&
        (this.va.color || !this.contextConfig.gradient) &&
        !this.hoverState &&
        !this.focusState) {
        computedStyle.background = 'transparent'
      }

      return computedStyle
    },
    hasTitleData () {
      return this.$slots.default
    },
    computedTag () {
      if (this.tag === 'a' || this.href || this.target) {
        return 'a'
      }
      if (
        this.tag === 'router-link' ||
        this.to ||
        this.append ||
        this.replace ||
        this.activeClass ||
        this.exact ||
        this.exactActiveClass
      ) {
        return 'router-link'
      }
      return 'button'
    },
    inputListeners () {
      const vm = this
      return Object.assign({}, this.$listeners, {
        click (event) {
          vm.$emit('click', event)
        },
      })
    },
  },
  methods: {
    updateHoverState (isHover) {
      this.hoverState = isHover
    },
    updateFocusState (isHover) {
      this.focusState = isHover
    },
  },
}
</script>

<style lang='scss'>

$btn-font-size-sm: pxtorem(14) !important;
$btn-font-size-lg: pxtorem(18) !important;

.va-button {
  display: inline-block;
  margin: $btn-margin;
  background-image: none;
  box-shadow: none;
  outline: none !important;
  border: $btn-border;
  font-family: var(--font-family-body) !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  cursor: pointer;
  transition: $btn-transition;
  background-color: $white;

  &__content {
    display: flex;

    &__title,
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }

  &--default {
    color: $white;

    &:hover {
      filter: brightness(115%);
    }

    &:focus,
    &:active {
      filter: brightness(85%);
    }

    i {
      color: $white;
    }
  }

  &--outline {
    background-color: transparent;
    border: solid $btn-border-outline;
    text-decoration: none;

    &.va-button--disabled {
      background: transparent;

      @include va-disabled;

      &.va-button--active {
        .va-button__content,
        i {
          color: $white !important;
        }
      }
    }
  }

  &--flat {
    background: transparent;
    border: $btn-border solid transparent;
    text-decoration: none;
  }

  &.va-button--disabled {
    @include va-disabled;
  }

  &--large {
    @include va-button(
      $btn-padding-y-lg,
      $btn-padding-x-lg,
      $btn-font-size-lg,
      $btn-line-height-lg,
      $btn-border-radius-lg
    );

    letter-spacing: $btn-letter-spacing-lg;

    .va-button__content__icon {
      width: $btn-icon-width-lg;
    }

    &.va-button--with-left-icon {
      padding-left: $btn-with-icon-wrapper-padding-lg;

      &.va-button--without-title {
        padding-right: $btn-with-icon-wrapper-padding-lg;
      }

      .va-button__content__title {
        padding-left: $btn-with-icon-content-padding-lg;
      }
    }

    &.va-button--with-right-icon {
      padding-right: $btn-with-icon-wrapper-padding-lg;

      .va-button__content__title {
        padding-right: $btn-with-icon-content-padding-lg;
      }
    }

    &.va-button--outline {
      line-height: $btn-line-height-lg - 2 * $btn-border-outline;
    }
  }

  &--small {
    @include va-button(
      $btn-padding-y-sm,
      $btn-padding-x-sm,
      $btn-font-size-sm,
      $btn-line-height-sm,
      $btn-border-radius-sm
    );

    letter-spacing: $btn-letter-spacing-sm;
    // padding: pxtorem(5) pxtorem(12) !important;

    .va-button__content__icon {
      width: $btn-icon-width-sm;
    }

    &.va-button--with-left-icon {
      padding-left: $btn-with-icon-wrapper-padding-sm;

      &.va-button--without-title {
        padding-right: $btn-with-icon-wrapper-padding-sm;
      }

      .va-button__content__title {
        padding-left: $btn-with-icon-content-padding-sm;
      }
    }

    &.va-button--with-right-icon {
      padding-right: $btn-with-icon-wrapper-padding-sm;

      .va-button__content__title {
        padding-right: $btn-with-icon-content-padding-sm;
      }
    }

    &.va-button--outline {
      line-height: $btn-line-height-sm - 2 * $btn-border-outline;
    }
  }

  &--normal {
    @include va-button(
      $btn-padding-y-nrm,
      $btn-padding-x-nrm,
      $btn-font-size-nrm,
      $btn-line-height-nrm,
      $btn-border-radius-nrm
    );

    letter-spacing: $btn-letter-spacing-nrm;

    .va-button__content__icon {
      width: $btn-icon-width-nrm;
    }

    &.va-button--with-left-icon {
      padding-left: $btn-with-icon-wrapper-padding-nrm;

      &.va-button--without-title {
        padding-right: $btn-with-icon-wrapper-padding-nrm;
      }

      .va-button__content__title {
        padding-left: $btn-with-icon-content-padding-nrm;
      }
    }

    &.va-button--with-right-icon {
      padding-right: $btn-with-icon-wrapper-padding-nrm;

      .va-button__content__title {
        padding-right: $btn-with-icon-content-padding-nrm;
      }
    }

    &.va-button--outline {
      line-height: $btn-line-height-nrm - 2 * $btn-border-outline;
    }
  }
}
</style>
