<template>
  <div class="at-irrigation-events-dropover-options" v-if="visible">
    <ul>
      <li @click="onOptionClick('edit')">
        <va-icon :name="'at-icon l_edit2'" />
        <span>{{ $t(`buttons.edit`) }}</span>
      </li>
      <li @click="onOptionClick('delete')">
        <va-icon :name="'at-icon l_delete2'" />
        <span>{{ $t(`buttons.delete`) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'at-irrigation-events-dropover-options',
  components: {
  },
  props: {
    show: {
      type: String,
      default: '',
    },
    irrigationEventIndex: {
      type: [Number, String],
    },
    irrigationEvent: {
      type: Object,
    },
    day: {
      type: String,
    },
  },
  data () {
    return {
      visible: false,
    }
  },
  computed: {
  },
  watch: {
    show: function (newVal, oldVal) {
      this.visible = this.show === `${this.irrigationEventIndex}-${this.day}`
    },
  },
  methods: {
    onOptionClick (option) {
      this.$emit('onOptionClick', {
        option: option,
        irrigationEvent: this.irrigationEvent,
      })
    },
  },
}
</script>

<style lang="scss">
.at-irrigation-events-dropover-options {
  @include shadow($radius: var(--shadow-radius));

  position: absolute;
  height: 50px;
  top: -45px;
  // left: -10px;
  padding: 0;
  // display: inline-block;
  background: #ffffff;
  border-radius: 5px;
  z-index: 20;

  &::after {
    content: '';
    position: absolute;
    left: 38px;
    margin: 0 auto;
    border-top: 5px solid #ffffff;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    bottom: -5px;
  }

  ul {
    white-space: nowrap;

    li {
      display: inline-block;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;

      &:not(:first-of-type) {
        margin: 0 5px 0 0;
      }

      .va-icon,
      span {
        display: block;
        text-align: center;
        font-size: pxtorem(10);
      }

      .va-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
      }
    }
  }
}
</style>
