<script>
import VaTreeCategory from 'vuestic-ui/src/components/vuestic-components/va-tree-view/VaTreeCategory'
import { hex2rgb } from '@/services/vuestic-ui'

export default {
  name: 'at-selection-tree',
  extends: VaTreeCategory,
  mounted () {
    this.setCheckStyle()
  },
  methods: {
    toggle () {
      const primaryColor = `rgb(${hex2rgb(this.$themes.primary).r}, ${hex2rgb(this.$themes.primary).g}, ${hex2rgb(this.$themes.primary).b})`
      const allSelected = this.$children[0].$el.firstChild.className === 'va-icon ion ion-md-checkmark'
      this.$children[0].$el.firstChild.className = this.$children[0].$el.firstChild.className === 'va-icon ion ion-md-checkmark' ? '' : 'va-icon ion ion-md-checkmark'
      this.$children[0].$el.style.backgroundColor = this.$children[0].$el.style.backgroundColor === primaryColor ? 'rgb(255, 255, 255)' : primaryColor

      this.$children[0].$el.style.border = this.$children[0].$el.style.border === 'solid 0.125rem #acb5be' ? '' : 'solid 0.125rem #acb5be'
      this.$children[0].$el.style.borderRadius = this.$children[0].$el.style.borderRadius === '0.25rem' ? '' : '0.25rem'

      this.$emit('toggleSelection', this.$options.propsData.label, !allSelected)
    },
    getAllChecked () {
      let allChecked = true

      for (let i = 1; i < this.$children.length; i++) {
        allChecked = allChecked && this.$children[i].$children[0].$el.className === 'va-checkbox va-checkbox--selected'
      }

      return allChecked
    },
    setCheckStyle () {
      const allSelected = this.getAllChecked()
      const primaryColor = `rgb(${hex2rgb(this.$themes.primary).r}, ${hex2rgb(this.$themes.primary).g}, ${hex2rgb(this.$themes.primary).b})`

      this.$children[0].$el.firstChild.className = allSelected ? 'va-icon ion ion-md-checkmark' : ''
      this.$children[0].$el.style.backgroundColor = allSelected ? primaryColor : 'rgb(255, 255, 255)'
      this.$children[0].$el.style.border = allSelected ? '' : 'solid 0.125rem #acb5be'
      this.$children[0].$el.style.borderRadius = allSelected ? '0.25rem' : ''
    },
  },
}
</script>
