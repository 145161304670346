export const dummyRecipe = {
  version: 'v1.0',
  name: 'Dummy Recipe',
  description: 'This is just a dummy recipe.',
  key: '481-75-8356',
  createdAt: '2021-04-24T20:36:41.482769',
  lastUpdate: '2021-04-24T20:36:41.482769',
  durationDays: 21,
  content: [
    {
      id: 1,
      tag: 'water',
      open: true,
      color: 'blue',
      variables: [
        {
          id: 1,
          tag: 'irrigation',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              irrigate: true,
              slots: [
                {
                  min: 3.6,
                  minRef: 4.6,
                  ref: 5.6,
                  maxRef: 6.6,
                  max: 7.6,
                  units: 'm3',
                  timeoutSeconds: 600,
                  changeTimeAfterLightOn: true,
                  timeAfterLightOn: 0,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          tag: 'ph',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              min: 3.6,
              minRef: 4.6,
              ref: 5.6,
              maxRef: 6.6,
              max: 7.6,
              units: '',
            },
          ],
        },
        {
          id: 3,
          tag: 'electrical_conductivity',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              min: 0.8,
              minRef: 1,
              ref: 1.2,
              maxRef: 1.4,
              max: 1.6,
              units: 'mS/cm',
            },
          ],
        },
        {
          id: 4,
          tag: 'orp',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              min: 50,
              minRef: 100,
              ref: 150,
              maxRef: 200,
              max: 250,
              units: 'mV',
            },
          ],
        },
        {
          id: 5,
          tag: 'temperature',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              min: 19,
              minRef: 22,
              ref: 25,
              maxRef: 28,
              max: 31,
              units: 'ºC',
            },
          ],
        },
      ],
    },
    {
      id: 2,
      tag: 'air',
      open: true,
      color: 'green',
      variables: [
        {
          id: 1,
          tag: 'temperature',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              dayMin: 19,
              dayMinRef: 22,
              dayRef: 25,
              dayMaxRef: 28,
              dayMax: 31,
              transitionDayNightSeconds: 3600,
              nightMin: 17,
              nightMinRef: 19,
              nightRef: 21,
              nightMaxRef: 23,
              nightMax: 25,
              transitionNightDaySeconds: 3600,
              units: 'ºC',
            },
          ],
        },
        {
          id: 2,
          tag: 'relative_humidity',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              dayMin: 40,
              dayMinRef: 50,
              dayRef: 60,
              dayMaxRef: 70,
              dayMax: 80,
              transitionDayNightSeconds: 3600,
              nightMin: 60,
              nightMinRef: 70,
              nightRef: 80,
              nightMaxRef: 90,
              nightMax: 95,
              transitionNightDaySeconds: 3600,
              units: '%',
            },
          ],
        },
        {
          id: 3,
          tag: 'co2',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              dayMin: 1000,
              dayMinRef: 1100,
              dayRef: 1200,
              dayMaxRef: 1300,
              dayMax: 1400,
              transitionDayNightSeconds: null,
              nightMin: 300,
              nightMinRef: 350,
              nightRef: 400,
              nightMaxRef: 450,
              nightMax: 500,
              transitionNightDaySeconds: null,
              units: 'ppm',
            },
          ],
        },
        {
          id: 4,
          tag: 'volume_flow',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              oscillation_period_seconds: 30,
              dayMin: 2800,
              dayMinRef: 2900,
              dayRef: 3000,
              dayMaxRef: 3100,
              dayMax: 3200,
              transitionDayNightSeconds: null,
              nightMin: 800,
              nightMinRef: 900,
              nightRef: 1000,
              nightMaxRef: 1100,
              nightMax: 1200,
              transitionNightDaySeconds: null,
              units: 'm3/h',
            },
          ],
        },
      ],
    },
    {
      id: 3,
      tag: 'light',
      open: true,
      color: 'orange',
      variables: [
        {
          id: 1,
          tag: 'light_cycle',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              slots: [
                {
                  durationSeconds: 43200,
                  transitionSeconds: 0,
                  blue: 10,
                  green: 20,
                  red: 50,
                  farRed: 10,
                  units: 'umol/m2/s',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  updateLog: [
    {
      timeStamp: '2021-04-24T20:36:41.482769',
      log: 'Created by Gonçalo Cabrita.',
    },
  ],
}

export const dummyRecipeEmpty = {
  version: 'v1.0',
  name: 'Empty Recipe',
  description: 'This is an empty recipe',
  key: '',
  createdAt: '',
  lastUpdate: '',
  durationDays: 21,
  content: [
    {
      id: 1,
      tag: 'water',
      open: true,
      color: 'blue',
      variables: [
        {
          id: 1,
          tag: 'irrigation',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              irrigate: true,
              slots: [
                {
                  min: 0,
                  minRef: 0,
                  ref: 0,
                  maxRef: 0,
                  max: 0,
                  units: 'm3',
                  timeoutSeconds: 0,
                  changeTimeAfterLightOn: true,
                  timeAfterLightOn: 0,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          tag: 'ph',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              min: 0,
              minRef: 0,
              ref: 0,
              maxRef: 0,
              max: 0,
              units: '',
            },
          ],
        },
        {
          id: 3,
          tag: 'electrical_conductivity',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              min: 0,
              minRef: 0,
              ref: 0,
              maxRef: 0,
              max: 0,
              units: 'mS/cm',
            },
          ],
        },
        {
          id: 4,
          tag: 'orp',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              min: 0,
              minRef: 0,
              ref: 0,
              maxRef: 0,
              max: 0,
              units: 'mV',
            },
          ],
        },
        {
          id: 5,
          tag: 'temperature',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              min: 0,
              minRef: 0,
              ref: 0,
              maxRef: 0,
              max: 0,
              units: 'ºC',
            },
          ],
        },
      ],
    },
    {
      id: 2,
      tag: 'air',
      open: true,
      color: 'green',
      variables: [
        {
          id: 1,
          tag: 'temperature',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              dayMin: 0,
              dayMinRef: 0,
              dayRef: 0,
              dayMaxRef: 0,
              dayMax: 0,
              transitionDayNightSeconds: 0,
              nightMin: 0,
              nightMinRef: 0,
              nightRef: 0,
              nightMaxRef: 0,
              nightMax: 0,
              transitionNightDaySeconds: 0,
              nightTransition: 0,
              units: 'ºC',
            },
          ],
        },
        {
          id: 2,
          tag: 'relative_humidity',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              dayMin: 0,
              dayMinRef: 0,
              dayRef: 0,
              dayMaxRef: 0,
              dayMax: 0,
              transitionDayNightSeconds: 0,
              nightMin: 0,
              nightMinRef: 0,
              nightRef: 0,
              nightMaxRef: 0,
              nightMax: 0,
              transitionNightDaySeconds: 0,
              nightTransition: 0,
              units: '%',
            },
          ],
        },
        {
          id: 3,
          tag: 'co2',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              dayMin: 0,
              dayMinRef: 0,
              dayRef: 0,
              dayMaxRef: 0,
              dayMax: 0,
              transitionDayNightSeconds: 0,
              nightMin: 0,
              nightMinRef: 0,
              nightRef: 0,
              nightMaxRef: 0,
              nightMax: 0,
              transitionNightDaySeconds: 0,
              nightTransition: 0,
              units: 'ppm',
            },
          ],
        },
        {
          id: 4,
          tag: 'volume_flow',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              oscillation_period_seconds: 0,
              dayMin: 0,
              dayMinRef: 0,
              dayRef: 0,
              dayMaxRef: 0,
              dayMax: 0,
              transitionDayNightSeconds: 0,
              nightMin: 0,
              nightMinRef: 0,
              nightRef: 0,
              nightMaxRef: 0,
              nightMax: 0,
              transitionNightDaySeconds: 0,
              nightTransition: 0,
              units: 'm3/h',
            },
          ],
        },
      ],
    },
    {
      id: 3,
      tag: 'light',
      open: true,
      color: 'orange',
      variables: [
        {
          id: 1,
          tag: 'light_cycle',
          enabled: true,
          cycles: [
            {
              startDay: 1,
              slots: [
                {
                  durationSeconds: 0,
                  transitionSeconds: 0,
                  duration: 0,
                  transition: 0,
                  blue: 0,
                  green: 0,
                  red: 0,
                  farRed: 0,
                  units: 'umol/m2/s',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  updateLog: [
  ],
}

export const dummyRecipeEmptyForPropagation = {
  version: 'v1.0',
  name: 'Empty Recipe',
  description: 'This is an empty recipe',
  key: '',
  createdAt: '',
  lastUpdate: '',
  durationDays: 21,
  content:
    [
      {
        id: 1,
        tag: 'air',
        open: true,
        color: 'green',
        variables: [
          {
            id: 1,
            tag: 'temperature',
            enabled: true,
            cycles: [
              {
                startDay: 1,
                dayMin: 0,
                dayMinRef: 0,
                dayRef: 0,
                dayMaxRef: 0,
                dayMax: 0,
                transitionDayNightSeconds: 0,
                nightMin: 0,
                nightMinRef: 0,
                nightRef: 0,
                nightMaxRef: 0,
                nightMax: 0,
                transitionNightDaySeconds: 0,
                nightTransition: 0,
                units: 'ºC',
              },
            ],
          },
          {
            id: 2,
            tag: 'relative_humidity',
            enabled: true,
            cycles: [
              {
                startDay: 1,
                dayMin: 0,
                dayMinRef: 0,
                dayRef: 0,
                dayMaxRef: 0,
                dayMax: 0,
                transitionDayNightSeconds: 0,
                nightMin: 0,
                nightMinRef: 0,
                nightRef: 0,
                nightMaxRef: 0,
                nightMax: 0,
                transitionNightDaySeconds: 0,
                nightTransition: 0,
                units: '%',
              },
            ],
          },
          {
            id: 3,
            tag: 'co2',
            enabled: true,
            cycles: [
              {
                startDay: 1,
                dayMin: 0,
                dayMinRef: 0,
                dayRef: 0,
                dayMaxRef: 0,
                dayMax: 0,
                transitionDayNightSeconds: 0,
                nightMin: 0,
                nightMinRef: 0,
                nightRef: 0,
                nightMaxRef: 0,
                nightMax: 0,
                transitionNightDaySeconds: 0,
                nightTransition: 0,
                units: 'ppm',
              },
            ],
          },
          {
            id: 4,
            tag: 'volume_flow',
            enabled: true,
            cycles: [
              {
                startDay: 1,
                oscillation_period_seconds: 0,
                dayMin: 0,
                dayMinRef: 0,
                dayRef: 0,
                dayMaxRef: 0,
                dayMax: 0,
                transitionDayNightSeconds: 0,
                nightMin: 0,
                nightMinRef: 0,
                nightRef: 0,
                nightMaxRef: 0,
                nightMax: 0,
                transitionNightDaySeconds: 0,
                nightTransition: 0,
                units: 'm3/h',
              },
            ],
          },
        ],
      },
    ],
  updateLog: [
  ],
}
