const config = state => state.app.config
const palette = state => state.app.config.palette
const isLoading = state => state.app.isLoading
const getTimelineMinDate = state => state.timelineMinDate
const getSaveRecipe = state => state.saveRecipe
const getSaveInDatabase = state => state.saveInDatabase
const getAppLanguage = state => state.appLanguage

export {
  config,
  palette,
  isLoading,
  getTimelineMinDate,
  getSaveRecipe,
  getSaveInDatabase,
  getAppLanguage,
}
