import { isEmpty } from 'lodash'
import { Role } from '../../../router/roles'

function getDefaultRoleHomePage (role) {
  if (role === 'AGRONOMIST') {
    return { name: 'growing' }
  }

  return { name: 'growing' }
}

function firstValidRole (roles) {
  let ret = ''
  roles.forEach(r => {
    if (Object.values(Role).includes(r)) {
      ret = r
      return false
    }
  })
  return ret
}

function validRoles (roles) {
  const ret = []
  roles.forEach(r => {
    if (Object.values(Role).includes(r)) {
      ret.push(r)
    }
  })
  return ret
}

function getSidebarVisible (role) {
  if (isEmpty(role)) {
    return false
  }
  return true
}

function getTopbarVisible (role) {
  if (isEmpty(role)) {
    return false
  }
  return true
}

function getBreadcrumbVisible (role) {
  if (isEmpty(role)) {
    return false
  }
  return true
}

export { getDefaultRoleHomePage, getSidebarVisible, getBreadcrumbVisible, firstValidRole, validRoles, getTopbarVisible }
