<template>
  <v-popover
    :trigger="trigger"
    :open="open"
    :disabled="disabled"
    :placement="placement"
    :auto-hide="autoHide"
    :delay="delay"
    popover-class="va-popover"
    popover-inner-class="va-popover__inner"
    popover-wrapper-class="va-popover__wrap"
  >
    <slot />
    <div
      slot="popover"
      class="va-popover__content"
      :style="computedPopoverStyle"
    >
      <div v-if="icon" class="va-popover__icon">
        <i
          :class="icon"
          :style="computedIconStyle"
        />
      </div>
      <div v-if="title || message">
        <div v-if="title" class="va-popover__title">
          {{ title }}
        </div>
        <div class="va-popover__text">
          {{ message }}
        </div>
      </div>
    </div>
  </v-popover>
</template>

<script>
import { VPopover } from 'v-tooltip'

export default {
  name: 'va-popover',
  components: {
    VPopover,
  },
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    trigger: {
      type: String,
      default: 'hover',
    },
    open: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    autoHide: {
      type: Boolean,
      default: true,
    },
    delay: {
      type: Object,
      default: () => { return { show: 500, hide: 100 } },
    },
  },
  computed: {
    computedIconStyle () {
      return {
        fontSize: '1.5rem',
        color: this.$themes[this.color],
      }
    },
    computedPopoverStyle () {
      return {}
    },
  },
}
</script>

<style lang="scss">
.v-popover {
  display: inline;
}

.va-popover {
  @include radius(10px);

  opacity: 1;
  border: none;
  background: rgba(0, 0, 0, 0.75);
  transition: opacity 0.5s;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    font-size: pxtorem(12);
    transition: opacity 0.5s;
    width: max-content;
    letter-spacing: 0.01562rem;
    background-color: transparent;
  }

  &__icon + div {
    padding-left: 0.75rem;
    width: 100%;
    overflow: hidden;
    color: #ffffff;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 0.125rem;
  }

  &__text {
    line-height: 1.5;
    color: #ffffff;
    justify-content: center;
    align-items: center;
  }
}
</style>
