<template>
  <li
    class="app-sidebar-link"
    :class="computedLinkClass"
  >
    <router-link
      class="app-sidebar-link__item"
      @mouseenter.native="updateHoverState(true)"
      @mouseleave.native="updateHoverState(false)"
      :class="computedItemClass"
      :to="to"
      :target="target"
    >
      <va-icon
        v-if="icon"
        class="app-sidebar-link__item-icon"
        :name="icon"
      />
      <div class="app-sidebar-link__item-title" v-if="title">
        <slot>
          {{title}}
        </slot>
      </div>

      <va-icon
        v-if="iconRight"
        class="app-sidebar-link__item-icon-right"
        :name="iconRight"
      >{{iconRightContent}}
      </va-icon>
    </router-link>
  </li>
</template>

<script>
import { ColorThemeMixin } from '../../../../services/vuestic-ui'
// import { setOpacityForHexColor } from '@/services/atmosphere-ui'

export default {
  name: 'app-sidebar-link',
  inject: ['contextConfig'],
  mixins: [ColorThemeMixin],
  props: {
    to: {
      type: [Object, String],
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    icon: {
      type: [String, Array],
    },
    iconRight: {
      type: [String, Array],
    },
    iconRightContent: {
      type: String,
    },
    title: {
      type: String,
    },
    activeByDefault: {
      type: Boolean,
    },
    minimized: {
      type: Boolean,
    },
  },
  data () {
    return {
      isHovered: false,
      isActive: this.activeByDefault,
    }
  },
  watch: {
    $route () {
      this.updateActiveState()
    },
  },
  computed: {
    computedLinkClass () {
      return {
        'app-sidebar-link--minimized': this.minimized,
      }
    },
    // computedLinkStyles () {
    //   const style = {}

    //   if (this.isHovered || this.isActive) {
    //     style.backgroundColor = setOpacityForHexColor(this.$themes.primary, 0.20)
    //   }

    //   return style
    // },
    computedItemClass () {
      return {
        'app-sidebar-link__item--active': this.isHovered || this.isActive,
      }
    },
  },
  methods: {
    updateHoverState (isHovered) {
      this.isHovered = isHovered
    },
    updateActiveState () {
      this.isActive = (this.$route.name === this.to.name || this.activeByDefault)
      if (this.$route.meta.parentName) {
        this.isActive = this.isActive || this.$route.meta.parentName === this.to.name
      }
    },
  },
  mounted () {
    this.updateActiveState()
  },
}
</script>

<style lang="scss">
.app-sidebar-link {
  display: flex;
  font-family: var(--font-family-title);
  letter-spacing: 1.5px;
  font-weight: 600;

  &__item {
    // position: relative;
    display: flex;
    min-height: 3rem;
    cursor: pointer;
    padding: 1rem 0.75rem 1rem 0.75rem; // 0.75 - border size as 0.25
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    box-sizing: border-box;
    width: 20rem;
    border-left: 4px solid transparent;
    transition: ease 0.5s;

    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
    }

    .app-sidebar-link--minimized & {
      justify-content: center;
      padding-left: 0.5rem;
      width: 3.5rem;
    }

    &--active {
      border-left: 4px solid rgba(#ffffff, 0.5);
      background-color: rgba(#ffffff, 0.2);

      .app-sidebar-link__item-icon {
        color: #ffffff;
      }
    }

    &-icon {
      margin-top: -5px;
      font-size: $sidebar-menu-item-icon-size;
      color: rgba(#ffffff, 0.4);
      transition: ease 0.5s;
    }

    &-icon-right {
      display: block;
      font-weight: bold;
      position: absolute;
      right: 0.75rem;
      bottom: auto;

      .app-sidebar-link--minimized & {
        bottom: 1.25rem;
        right: auto;
        height: 0;
        display: block;
        text-align: center;
        font-weight: normal;
      }
    }

    &-title {
      line-height: 1.5em;
      // position: relative;
      top: -2px;

      .app-sidebar-link__item-icon + & {
        margin-left: 0.5rem;
      }

      .app-sidebar-link--minimized & {
        display: none;
      }
    }
  }
}
</style>
