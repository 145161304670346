<template>
  <div
    class="va-button-group"
    :class="computedClass"
    :style="computedStyle"
  >
    <slot/>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  styleHelpers,
} from '@/services/atmosphere-ui'
import { ColorThemeActionsMixin, ColorThemeMixin } from '@/services/vuestic-ui'

export default {
  name: 'va-button-group',
  mixins: [ColorThemeMixin, ColorThemeActionsMixin],
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    outline: {
      type: Boolean,
    },
    flat: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
    group: {
      type: Boolean,
      default: true,
    },
  },
  provide () {
    const parent = this
    return {
      va: new Vue({
        computed: {
          color () {
            return this.colorComputed
          },
          outline () {
            return parent.outline
          },
          flat () {
            return parent.flat
          },
          small () {
            return parent.small
          },
          large () {
            return parent.large
          },
          group () {
            return parent.group
          },
        },
      }),
    }
  },
  computed: {
    computedClass () {
      return {
        'va-button-group--large': this.large,
        'va-button-group--small': this.small,
        'va-button-group--normal': !this.large && !this.small,
      }
    },
    computedStyle () {
      const computedStyle = {
        background: (!this.outline && !this.flat) ? styleHelpers.buttonGradient(this.colorComputed) : 'transparent',
        boxShadow: !this.flat ? styleHelpers.genShadow(this.colorComputed, null, { offsetY: 0, offsetX: 0, radius: 6 }) : '',
      }

      return computedStyle
    },
  },
}
</script>

<style lang='scss'>
.va-button-group {
  display: flex;
  justify-content: stretch;
  margin: 0.375rem 0.5rem;
  width: fit-content;

  &--small {
    border-radius: $btn-border-radius-sm;
  }

  &--large {
    border-radius: $btn-border-radius-lg;
  }

  &--normal {
    border-radius: $btn-border-radius-nrm;
  }

  .va-button {
    margin: 0;
  }

  & > .va-button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  & > .va-button + .va-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
}
</style>
