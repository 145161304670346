import Vue from 'vue'
import Router from 'vue-router'
import AppLayout from '../components/admin/AppLayout'
import { Role } from './roles'

Vue.use(Router)

const EmptyParentComponent = {
  template: '<router-view></router-view>',
}

const demoRoutes = []
const isAuthRequired = true

export default new Router({
  mode: 'hash',
  routes: [
    ...demoRoutes,
    {
      path: '*',
      redirect: { name: 'growing' },
      meta: { requiresAuth: isAuthRequired },
    },
    {
      path: '/404',
      component: EmptyParentComponent,
      children: [
        {
          name: 'page-not-found',
          path: 'page-not-found',
          component: () => import('../components/pages/404-pages/AtPageNotFound.vue'),
        },
      ],
    },
    {
      name: 'Admin',
      path: '/admin',
      meta: { requiresAuth: isAuthRequired },
      component: AppLayout,
      children: [
        {
          name: 'growing',
          path: 'growing',
          redirect: { name: 'farm-overview' },
          meta: { requiresAuth: isAuthRequired },
          component: EmptyParentComponent,
          children: [
            {
              path: 'growing-rooms',
              component: EmptyParentComponent,
              meta: {
                requiresAuth: isAuthRequired,
                authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER],
              },
              children: [
                {
                  name: 'farm-overview',
                  path: '',
                  component: () => import('../components/growing/growing-rooms/GrowingFarm.vue'),
                  props: true,
                  meta: {
                    parentName: 'farm-overview',
                    requiresAuth: isAuthRequired,
                    authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER, Role.PRODUCTION_MANAGER],
                  },
                },
                {
                  name: 'room-details',
                  path: 'room/:roomId',
                  component: () => import('../components/growing/growing-rooms/GrowingControlGroup.vue'),
                  props: true,
                  meta: {
                    parentName: 'farm-overview',
                    requiresAuth: isAuthRequired,
                    authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER, Role.PRODUCTION_MANAGER],
                  },
                },
                {
                  name: 'area-details',
                  path: 'room/:roomId/area/:areaId',
                  props: true,
                  component: () => import('../components/growing/growing-rooms/GrowingControlArea.vue'),
                  meta: {
                    parentName: 'farm-overview',
                    requiresAuth: isAuthRequired,
                    authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER, Role.PRODUCTION_MANAGER],
                  },
                },
                {
                  name: 'area-recipe-growing-edit',
                  path: 'room/:roomId/area/:areaId/productionRecipe/:recipeId',
                  props: true,
                  component: () => import('../components/growing/growing-recipes/RecipeGrowingEdit.vue'),
                  meta: {
                    parentName: 'farm-overview',
                    productionRecipe: true,
                    requiresAuth: isAuthRequired,
                    authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER, Role.PRODUCTION_MANAGER],
                  },
                },
              ],
            },
            {
              path: 'irrigation',
              component: EmptyParentComponent,
              children: [
                {
                  name: 'irrigation-overview',
                  path: '',
                  component: () => import('../components/growing/growing-irrigation/IrrigationOverview.vue'),
                  props: true,
                  meta: {
                    parentName: 'irrigation-overview',
                    requiresAuth: isAuthRequired,
                    authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER, Role.PRODUCTION_MANAGER],
                  },
                },
                {
                  name: 'irrigation-details',
                  path: 'irrigationLine/:irrigationLineId',
                  component: () => import('../components/growing/growing-irrigation/IrrigationLine.vue'),
                  props: true,
                  meta: {
                    parentName: 'irrigation-overview',
                    requiresAuth: isAuthRequired,
                    authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER, Role.PRODUCTION_MANAGER],
                  },
                },
                {
                  name: 'recovery-tank-details',
                  path: 'irrigationLine/:irrigationLineId/recoveryTank/:tankId',
                  component: () => import('../components/growing/growing-irrigation/IrrigationTank.vue'),
                  props: true,
                  meta: {
                    parentName: 'irrigation-overview',
                    tankType: 'recovery-tank',
                    requiresAuth: isAuthRequired,
                    authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER, Role.PRODUCTION_MANAGER],
                  },
                },
                {
                  name: 'fresh-water-tank-details',
                  path: 'irrigationLine/:irrigationLineId/waterTank/:tankId',
                  component: () => import('../components/growing/growing-irrigation/IrrigationTank.vue'),
                  props: true,
                  meta: {
                    parentName: 'irrigation-overview',
                    tankType: 'fresh-water-tank',
                    requiresAuth: isAuthRequired,
                    authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER, Role.PRODUCTION_MANAGER],
                  },
                },
                {
                  name: 'drainage-tank-details',
                  path: 'irrigationLine/:irrigationLineId/drainageTank/:tankId',
                  component: () => import('../components/growing/growing-irrigation/IrrigationTank.vue'),
                  props: true,
                  meta: {
                    parentName: 'irrigation-overview',
                    tankType: 'drainage-tank',
                    requiresAuth: isAuthRequired,
                    authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER, Role.PRODUCTION_MANAGER],
                  },
                },
                {
                  name: 'mixing-tank-details',
                  path: 'irrigationLine/:irrigationLineId/mixingTank/:tankId',
                  component: () => import('../components/growing/growing-irrigation/IrrigationTank.vue'),
                  props: true,
                  meta: {
                    parentName: 'irrigation-overview',
                    tankType: 'mixing-tank',
                    requiresAuth: isAuthRequired,
                    authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER, Role.PRODUCTION_MANAGER],
                  },
                },
              ],
            },
            {
              path: 'growing-recipes',
              component: EmptyParentComponent,
              meta: {
                requiresAuth: isAuthRequired,
              },
              children: [
                {
                  name: 'recipes-growing',
                  path: 'recipes-growing',
                  component: () => import('../components/growing/growing-recipes/RecipesGrowing.vue'),
                  props: true,
                  meta: {
                    requiresAuth: isAuthRequired,
                    authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER, Role.PRODUCTION_MANAGER],
                  },
                },
                {
                  name: 'recipe-growing-new',
                  path: 'newRecipe/:recipeId',
                  props: true,
                  component: () => import('../components/growing/growing-recipes/RecipeGrowingNew.vue'),
                  meta: {
                    parentName: 'recipes-growing',
                    duplicate: false,
                    requiresAuth: isAuthRequired,
                    authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER, Role.PRODUCTION_MANAGER],
                  },
                },
                {
                  name: 'recipe-growing-duplicate',
                  path: 'duplicateRecipe/:recipeId',
                  props: true,
                  component: () => import('../components/growing/growing-recipes/RecipeGrowingNew.vue'),
                  meta: {
                    parentName: 'recipes-growing',
                    duplicate: true,
                    requiresAuth: isAuthRequired,
                    authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER, Role.PRODUCTION_MANAGER],
                  },
                },
                {
                  name: 'recipe-growing-edit',
                  path: 'recipe/:recipeId',
                  props: true,
                  component: () => import('../components/growing/growing-recipes/RecipeGrowingEdit.vue'),
                  meta: {
                    parentName: 'recipes-growing',
                    productionRecipe: false,
                    requiresAuth: isAuthRequired,
                    authorize: [Role.ADMIN, Role.AGRONOMIST, Role.PRODUCTION_MANAGER, Role.FACTORY_MANAGER, Role.PRODUCTION_MANAGER],
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'notifications',
          path: 'notifications',
          meta: {
            requiresAuth: isAuthRequired,
          },
          component: () => import('../components/notifications/Notifications.vue'),
          children: [],
        },
      ],
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})
