<template>
  <div class="row">
    <div class="flex md12 xs12">
      <va-breadcrumbs
        :breadcrumbs="breadcrumbs"
        :currentRouteName="currentRouteName"
      />
    </div>
  </div>
</template>

<script>
import { breadcrumbs } from './Breadcrumbs'
import VaBreadcrumbs from '../../../services/atmosphere-ui/components/va-breadcrumbs/VaBreadcrumbs'

export default {
  name: 'app-breadcrumbs',
  components: {
    VaBreadcrumbs,
  },
  data () {
    return {
      breadcrumbs: breadcrumbs,
    }
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    },
  },
}
</script>
