export default {
  token (state) {
    return state.token
  },
  is_authenticated (state) {
    return !!state.token || localStorage.getItem('token')
  },
  get_user_role (state) {
    return state.role || localStorage.getItem('role')
  },
  get_user_roles (state) {
    return state.roles || localStorage.getItem('roles')
  },
  get_user (state) {
    return state.user || localStorage.getItem('user')
  },
  get_preferred_username (state) {
    return state.preferred_username || localStorage.getItem('preferred_username')
  },
  // role_needs_sidebar (state) {
  //   console.log('riga 15 state.needs_sidebar: '+ state.needs_sidebar )
  //   console.log('riga 16 localstorage.needs_sidebar: ' + localStorage.getItem('needs_sidebar'))
  //   return state.needs_sidebar || localStorage.getItem('needs_sidebar')
  // },
}
