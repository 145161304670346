<template>
  <nav
    :style="computedStyle"
    class="app-navbar"
    v-bind:class="computedClass">
    <div class="app-navbar__content row">
      <div class="app-navbar__menu-container">
        <div v-if="is_menu_needed()">
          <va-icon-menu
            class="app-navbar__menu"
            v-if="!minimized && !isTopBar"
            @click.native="$emit('update:minimized', !minimized)"
          />
          <va-icon-menu-collapsed
            class="app-navbar__menu"
            v-if="minimized && !isTopBar"
            @click.native="$emit('update:minimized', !minimized)"
          />
        </div>

        <!-- TESTEBED ? -->
        <div v-if="testBed" class="app-navbar__test-banner">
          <test-site-banner/>
        </div>
        <!-- TESTEBED ? -->
        <div v-if="testBed" class="app-navbar__branch-info">
          {{ getBranchInfo() }}
        </div>

        <div class="app-navbar__logo" @click="getMyHome()">
          <router-link to=""><img :src="LogoMenuIdentitaGolose"/>
          </router-link>
        </div>
      </div>
      <app-navbar-actions
        class="app-navbar__actions md5 lg4"
        :user-name="userName"
        :is-top-bar.sync="isTopBarProxy"
      />
    </div>
    <div class="app-navbar__clock" v-if="checkIsMobile()">
      <div class="app-navbar__clock_date">{{ nowLocal.date }}</div>
      <div class="app-navbar__clock_time"><va-icon name="at-icon b_local_time"/>{{ nowLocal.time }}</div>
    </div>
  </nav>
</template>

<script>
import VaIconMenu from '../../../iconset/VaIconMenu'
import VaIconMenuCollapsed from '../../../iconset/VaIconMenuCollapsed'
import AppNavbarActions from './components/AppNavbarActions'
import { ColorThemeMixin } from '../../../services/vuestic-ui'
import { getInFactoryTime, getTimeDateFormat } from '@/services/atmosphere-ui/helpers/TimeHelpers'
import LogoMenuIdentitaGolose from '../../../assets/imgs/Logo_MenuIdentitaGolose.png'
import TestSiteBanner from '@/services/atmosphere-ui/components/icons/TestSiteBanner'
import { getSidebarVisible, getDefaultRoleHomePage } from '../../../store/modules/auth/utility'

export default {
  name: 'app-navbar',
  mixins: [ColorThemeMixin],
  inject: ['contextConfig'],
  components: {
    TestSiteBanner,
    VaIconMenu,
    VaIconMenuCollapsed,
    AppNavbarActions,
  },
  props: {
    isTopBar: {
      type: Boolean,
      required: true,
    },
    minimized: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      userName: this.$store.getters.get_user,
      userRole: this.$store.getters.get_user_role,
      nowLocal: {},
      mobileWidth: 767,
      testBed: process.env.VUE_APP_TESTBED || false,
      LogoMenuIdentitaGolose: LogoMenuIdentitaGolose,
    }
  },
  mounted () {
    this.updateDateTime()
    const timer = window.setTimeout(this.updateDateTime, 1000)
    this.$on('hook:destroyed', () => window.clearTimeout(timer))
  },
  computed: {
    isTopBarProxy: {
      get () {
        return this.isTopBar
      },
      set (isTopBar) {
        this.$emit('update:isTopBar', isTopBar)
      },
    },
    minimizedProxy: {
      get () {
        return this.minimized
      },
      set (minimized) {
        this.$emit('update:minimized', minimized)
      },
    },
    computedClass: function () {
      return {
        'app-navbar--danger': this.$status.value === 'danger',
        'app-navbar--warning': this.$status.value === 'warning',
      }
    },
    computedStyle () {
      const style = {
        /* Update styles via JS Helpers (currently they are being changed in a global watcher) */
        // '--primary-color-hue': styleHelpers.hex2hsl(this.$themes.primary).h,
        // '--primary-color': this.$themes.primary,

        /* Alternative: Update styles via JS Helpers */
        // background: styleHelpers.navGradient(this.$themes.primary, this.$status.value),
        // boxShadow: styleHelpers.genShadow(this.$themes.primary, null, { radius: 6 })),
      }

      return style
    },
  },
  methods: {
    is_menu_needed () {
      return getSidebarVisible(this.$store.getters.get_user_role)
    },
    getMyHome () {
      // console.log (getDefaultRoleHomePage(this.$store.getters.get_user_role))
      this.$router.push({ name: getDefaultRoleHomePage(this.$store.getters.get_user_role).name })
    },
    updateDateTime () {
      const time = getTimeDateFormat(getInFactoryTime(new Date()), 'short', false).split(', ')
      this.nowLocal = {
        date: time[0],
        time: time[1],
      }
      this.$options.timer = window.setTimeout(this.updateDateTime, 1000)
    },
    checkIsMobile () {
      return window.matchMedia(`(min-width: ${this.mobileWidth}px)`).matches
    },
    getBranchInfo () { // use this method to create the string with the brancn info
      // const branchInfo = process.env.VUE_APP_BUILD_SIGNATURE || `commit: ${COMMIT}`
      const branchInfo = process.env.VUE_APP_BUILD_SIGNATURE || 'unknown build'
      return branchInfo
    },
  },
}
</script>

<style lang="scss">
$nav-border-side-width: 3.1875rem;
$nav-height: 3.375rem;

.app-navbar {
  @include nav-gradient();
  @include shadow($radius: var(--shadow-radius));

  transition: background-color 0.3s ease; /* sidebar's bg color transitions as well -> consistency */
  display: flex;
  padding: 0.25rem 1rem;
  z-index: 1;
  height: $nav-height;

  &--danger {
    @include nav-gradient-alert();
  }

  &--warning {
    @include nav-gradient-state();
  }

  &__content {
    z-index: 1;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    flex: 1 1 auto;
  }

  &__clock {
    align-items: center;
    margin-left: 3.25rem;
    color: #ffffff;

    &_time {
      i {
        margin-left: -0.4rem;
      }
    }
  }

  &__menu {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin-right: 1.5rem;
    margin-top: $nav-height / 8;
  }

  &__menu-container {
    display: flex;
    flex-wrap: nowrap;
  }

  &__logo {
    width: 19rem;
    max-height: 2.25rem;
    align-items: center;

    & * {
      height: 100%;
      display: block;
    }
  }

  &__test-banner {
    position: absolute;
    height: 3.4rem;
    top: 0;
    margin-left: 4rem;
    z-index: -1;
  }

  &__branch-info {
    position: absolute;
    height: 3.4rem;
    top: 0;
    margin-left: 12rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    z-index: -1;
  }

  @include media-breakpoint-down(lg) {
    &__button {
      display: none !important;
    }
  }

  @include media-breakpoint-down(md) {
  }

  @include media-breakpoint-down(sm) {
    &__content {
      align-items: flex-end;
    }
  }
}
</style>
