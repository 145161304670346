<template>
  <div class="va-breadcrumbs">
    <div class="va-breadcrumbs__nav-section">
      <router-link
        class="va-breadcrumbs__nav-section-item"
        :to="{ name: getMyHome() }"
      >
        {{ $t(breadcrumbs.root.displayName) }}
      </router-link>
      <router-link
        v-for="(item, index) in displayedCrumbs" :key="index"
        :to="{ name:item.name }"
        class="va-breadcrumbs__nav-section-item"
        :class="{ disabled: item.disabled }"
      >
        {{ $t(item.displayName) }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { getDefaultRoleHomePage } from '../../../../store/modules/auth/utility'
export default {
  name: 'va-breadcrumbs',
  props: {
    breadcrumbs: {
      type: Object,
      default: () => ({}),
    },
    currentRouteName: {
      type: String,
      default: '',
    },
  },
  computed: {
    displayedCrumbs () {
      // Breadcrumbs object has root and routes. Root is required for us to display home page.
      const routeBreadcrumbList = this.breadcrumbs.routes

      const foundBreadcrumbs = this.findInNestedByName(routeBreadcrumbList, this.currentRouteName)

      if (!foundBreadcrumbs.length) {
        // eslint-disable-next-line no-console
        console.warn(`No breadcrumbs registered for route with name "${this.currentRouteName}"`)
      }

      return foundBreadcrumbs
    },
    currentRoute () {
      return this.$route.meta.wikiLink
    },
  },
  methods: {
    findInNestedByName (routeBreadcrumbList, name) {
      for (const routeBreadcrumb of routeBreadcrumbList) {
        // We found breadcrumbs for route
        if (routeBreadcrumb.name === name) {
          if (this.$route.params.displayName) {
            routeBreadcrumb.displayName = this.$route.params.displayName
          }
          return [routeBreadcrumb]
        }
        // We didn't find any breadcrumbs for route - means we have to go deeper!
        // Which works only if route breadcrumb has children declared.
        if (!routeBreadcrumb.children) {
          continue
        }
        const result = this.findInNestedByName(routeBreadcrumb.children, name)
        if (result.length) {
          return [routeBreadcrumb, ...result]
        }
      }
      return []
    },
    getMyHome () {
      // console.log(getDefaultRoleHomePage(this.$store.getters.get_user_role).name)
      return getDefaultRoleHomePage(this.$store.getters.get_user_role).name
    },
  },
}
</script>

<style lang='scss'>
.va-breadcrumbs {
  @include radius(0 var(--shape-radius) 0 var(--shape-radius));
  @include shadow($radius: 24px, $alpha: 0.2);
  @include breadcrumbs-gradient();

  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  padding: 0 1.5rem;
  margin-top: -5px;
  margin-bottom: 10px;

  .va-breadcrumbs__nav-section-item {
    color: hsl(0, 0, 30%);

    &:hover {
      color: hsl(var(--primary-color-hue), 63%, 46%);
    }

    text-transform: capitalize;

    &.disabled {
      pointer-events: none;
    }

    &:last-child::after {
      display: none;
    }

    &::after {
      padding: 0 5px;
      display: inline-block;
      font-family: "atmosphere-icons";
      font-weight: 600;
      content: '\e868';
      vertical-align: middle;
      font-size: 1rem;
      color: hsl(var(--primary-color-hue), 63%, 46%);
    }
  }

  .va-breadcrumbs__help-section {
    .va-icon {
      font-size: 20px;
    }
  }
}
</style>
