<template>
  <va-dropdown
    ref="notificationDropdown"
    class="notification-dropdown"
    offset="0, 16px"
  >
    <va-icon
      :name="getNotificationIcon()"
      style="font-size: 1.8rem; display: flex;"
      class="users-dropdown__icon"
      slot="anchor"
      :color="contextConfig.invertedColor ? $themes.gray : 'white'"
    />
    <div v-if="loaded" class="notification-dropdown__content pl-3 pr-3 pt-2 pb-2">
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="notification-dropdown__item row pt-1 pb-1 mt-2 mb-2"
        :class="{'notification-dropdown__item--unread': notification.unread}"
        @click="notification.unread = false"
      >
        <span class="ellipsis" style="max-width: 85%;">
          <va-icon :color="setColor(notification.level)" :name="setIcon(notification.level)"/>
          <span class="text--bold" v-if="notification.updated_at">{{ printTimeStamp(notification.updated_at) }}</span>
          {{ notification.message }}
        </span>
      </div>
      <div class="row justify--space-between">
        <router-link :to="{ name: 'notifications', params: { displayName: $t('notifications.all') }}">
          <va-button class="ma-0 mb-2 mt-1" small @click="closeDropdown()" >{{ $t('notifications.all') }}</va-button>
        </router-link>
        <!-- <va-button class="ma-0 mb-2 mt-1" small outline @click="markAllAsRead" :disabled="allRead">{{ $t('notifications.mark_as_read') }}</va-button> -->
      </div>
    </div>
  </va-dropdown>
</template>

<script>
import { ColorThemeMixin } from '../../../../../services/vuestic-ui'
// import axios from 'axios'
// import * as socketio from '../../../../../utilities/socketio'
import { getTimeDateFormat } from '../../../../../services/atmosphere-ui/helpers/TimeHelpers'

export default {
  name: 'notification-dropdown',
  inject: ['contextConfig'],
  mixins: [ColorThemeMixin],
  data () {
    return {
      notifications: [],
      loaded: false,
    }
  },
  mounted () {
    this.getNotifications()
  },
  computed: {
    allRead () {
      return !this.notifications.filter(item => item.unread).length
    },
  },
  methods: {
    getNotificationIcon () {
      return this.notifications.some(notification => notification.unread === true) ? 'at-icon b_notification_to_read' : 'at-icon b_notificationbell'
    },
    getUserId () {
      return 1 // hardcoded for testing
    },
    getContextId () {
      return 'f1' // hardcoded for testing
    },
    printTimeStamp (timestampUTC) {
      return getTimeDateFormat(timestampUTC + 'Z', 'short', true)
    },
    setColor (level) {
      let color = 'info'

      switch (level) {
        case 0:
          color = 'info'
          break

        case 1:
          color = 'warning'
          break

        case 2:
          color = 'danger' // error
          break

        case 3:
          color = 'danger' // old
          break
      }

      return color
    },
    setIcon (level) {
      let icon = ''

      switch (level) {
        case 0:
          icon = 'at-icon l_notification_text'
          break

        case 1:
          icon = 'at-icon l_alert'
          break

        case 2:
          icon = 'at-icon l_alert'
          break

        case 3:
          icon = 'at-icon l_alert'
          break
      }
      return icon
    },
    getNotifications () {
      // PF skip temporarily to deploy identita golose
      // axios.get(`${socketio.notificationsApiUrl}/users/${this.getUserId()}/notifications/context/${this.getContextId()}`, { params: { per_page: 5, sort: 'updated_at|desc' } })
      //   .then(response => {
      //     this.notifications = response.data.data
      //     this.loaded = true
      //   })
      //   .catch(function (error) {
      //     console.log(error)
      //   })
    },
    markAllAsRead () {
      this.notifications = this.notifications.map(item => ({
        ...item,
        unread: false,
      }))
    },
    closeDropdown () {
      this.$refs.notificationDropdown.hide()
    },
  },
}
</script>

<style lang="scss">

.notification-dropdown {
  cursor: pointer;

  .notification-dropdown__icon {
    position: relative;
    display: flex;
    align-items: center;

    &--unread::before {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      top: -0.5rem;
      background-color: $brand-danger;
      height: 0.375rem;
      width: 0.375rem;
      margin: 0 auto;
      border-radius: 0.187rem;
    }
  }

  &__content {
    background-color: $dropdown-background;
    box-shadow: $gray-box-shadow;
    border-radius: 0.5rem;
    max-width: 19rem;
  }

  &__item {
    cursor: pointer;
    margin-bottom: 0.75rem;
    color: $brand-secondary;
    flex-wrap: nowrap;
    position: relative;

    &--unread {
      color: $vue-darkest-blue;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        height: 0.375rem;
        width: 0.375rem;
        background-color: $brand-danger;
        margin: auto;
        border-radius: 0.187rem;
      }
    }

    &:hover {
      color: var(--primary-color);
    }

    &__avatar {
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
    }
  }

  .va-dropdown__anchor {
    display: inline-block;
  }
}
</style>
