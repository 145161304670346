<template>
  <div :id="'subgroups_' + uid" class="irrigation__subgroups">

    <template v-if="irrigationMapLoading">
      <div class="map-loading__placeholder-top"></div>
      <div class="map-loading__container" :style="{height: groupsHeight}">
        <div class="flex-center spinner-box">
          <spinner/>
        </div>
      </div>
    </template>

    <template v-else>
      <at-irrigation-map-timeline
        :uid="uid"
        :scale="scale"
        :padding="padding"
        :containerWidth="containerWidth"
        :timeline-start-date="timelineStartDate"
        :timeline-end-date="timelineEndDate"
        :duration-days="durationDays"
      />

      <template v-for="room in mapStructure">
        <div :key="room.id" class="irrigation__subgroups__group" :style="{ width: containerWidth + 'px' }">
          <div
            class="irrigation__subgroups__subgroup first">
            <template v-for="timeSlot in (durationDays + 1)">
              <div
                class="cycle-info"
                :key="'room_' + timeSlot"
                :style="{ width: scale + 'px', left: getPixelPositionFromTimeSlot(timeSlot) + 'px', 'border-left': separationCellStyle(timeSlot), 'background': currentCellBackground(timeSlot), 'box-shadow': currentCellShadow(timeSlot)}"
                :class="{ show: showSummaries}">
              </div>
            </template>
          </div>
        </div>

        <div
          :key="room.id + '_subgroup'"
          v-if="room.open"
          :style="{ width: containerWidth + 'px' }"
        >
          <!-- Control Group Structure -->
          <!-- Layer -->
          <div
            v-for="(layer) in room.structure.fields"
            :key="'subgroup_1_' + layer.name"
          >
            <!-- side -->
            <template v-if="layer.subFields.length > 0">
              <div v-for="side in layer.subFields" :key="side.id" class="irrigation__subgroups__subgroup">
                <template v-for="timeSlot in (durationDays + 1)">
                  <div
                    class="cycle-info"
                    :key="'subgroup_1_' + layer.name + '_side_' + side.id + '_' + timeSlot"
                    :style="{ width: scale + 'px', left: getPixelPositionFromTimeSlot(timeSlot) + 'px', 'border-left': separationCellStyle(timeSlot), 'background': currentCellBackground(timeSlot), 'box-shadow': currentCellShadow(timeSlot) }"
                    :class="{ show: showSummaries }"
                  >
                    <template v-for="(event, index) in getEventInfo(room.id, room.name, layer.name, side.name, timeSlot)"
                    >
                      <!-- ----------------------  CASE 1. MULTIPLE EVENTS ---------------------- -->
                      <template v-if="event.numberEvents > 1">

                        <!-- seeding_irrigation -->
                        <template v-if="event.action.toLowerCase() === 'seeding_irrigation'">
                          <div
                            :key="'subgroup_1_' + layer.name + '_side_' + side.id + '_event_' + index"
                            :class="event.action.toLowerCase()"
                          >
                            <va-icon :name="getEventIcon(event.action, event.production_growing_recipe.name)" :style="{transform: 'scaleX(-1)'}"/>
                            <div :class="'number-events'">{{ event.numberEvents }}</div>
                            <!-- <div class="recipeName">{{ formatRecipeName(event.production_growing_recipe.name) }}</div> -->

                          </div>
                        </template>

                        <!-- harvesting_irrigation -->
                        <template v-if="event.action.toLowerCase() === 'harvesting_irrigation'">
                          <div
                            :key="'subgroup_1_' + layer.name + '_side_' + side.id + '_event_' + index"
                            :class="event.action.toLowerCase()"
                          >
                            <va-icon :name="getEventIcon(event.action, event.production_growing_recipe.name)"/>
                            <div :class="'number-events'">{{ event.numberEvents }}</div>
                            <!-- <div class="recipeName">{{ formatRecipeName(event.production_growing_recipe.name) }}</div> -->
                          </div>
                        </template>

                        <!-- irrigation -->
                        <template v-if="event.action.toLowerCase() === 'irrigation' && !event.isOnSeedHarvDay">
                          <template v-if="index === 0">
                            <button
                              :key="'subgroup_1_' + layer.name + '_side_' + side.id + '_event_' + index"
                              :class="event.globalStatus"
                            >
                              <va-count-badge
                                :color="getColor(event.globalStatus)"
                                :number="event.numberEvents"
                              >
                                <va-icon name="at-icon b_water1drop"/>
                              </va-count-badge>
                            </button>
                          </template>
                        </template>

                        <!-- recipeName -->
                        <div :class="`recipeName__${event.action.toLowerCase()}`" v-if="event.action.toLowerCase() === 'seeding_irrigation' || event.action.toLowerCase() === 'harvesting_irrigation'"
                          :key="'subgroup_1_' + layer.name + '_side_' + side.id + '_event_' + index +'_recipe-name'"
                          :style="getRecipeNameContainerStyle(event.production_growing_recipe.name)"
                        >
                          {{ formatRecipeName(event.production_growing_recipe.name) }}
                        </div>

                        <!-- summary -->
                        <template v-if="(event.action.toLowerCase() === 'irrigation' && index === 0) || (event.action.toLowerCase() === 'seeding_irrigation' || event.action.toLowerCase() === 'harvesting_irrigation')">
                          <div class="cycle-info__summary multiple-events__summary"
                            :style="getSummaryBottomStyle(event.action, event.numberEvents, event.status, layer.name, side.name)"
                            :key="'subgroup_1_' + layer.name + '_side_' + side.id + '_event_' + index +'_info'">
                            <div class="cycle-info__summary__container multiple-events__container">
                              <template v-for="(value, name) in event.eventSummary">

                                <div class="multiple-events" :key="'info_' + name">
                                  <template v-if="name.includes('irrigation_')">
                                    <template v-for="(details, detailsIndex) in value">

                                      <span class="multiple-events__icon" v-if="detailsIndex === 0" :key="'irrigation__info_' + detailsIndex">
                                        <va-icon name="at-icon b_water1drop"/><b>{{ details.value }}</b>
                                      </span>
                                      <div v-else-if="details.details" class="multiple-events__icon__status-details"
                                        :class="details.level"
                                        :key="'irrigation__info_' + detailsIndex"
                                      >
                                        <va-icon :name="details.icon"/><b>{{ details.label }}</b> {{ Math.round(details.value * 100) / 100}} {{ details.units }}
                                      </div>
                                      <span v-else :key="'irrigation__info_' + detailsIndex"><b>{{ details.label }}</b> {{ details.value }}</span>
                                    </template>
                                  </template>
                                  <template v-else>
                                    <span class="multiple-events__icon">
                                      <va-icon name="at-icon b_recipes3"/>
                                      <b>{{ value[0].value }}</b>
                                    </span>
                                  </template>
                                </div>
                              </template>
                            </div>
                          </div>
                        </template>
                      </template>

                      <!-- ----------------------  CASE 1. ONE EVENT ---------------------- -->
                      <template v-else>

                        <!-- seeding -->
                        <template v-if="event.action.toLowerCase() === 'seeding'">
                          <div
                            :key="'subgroup_1_' + layer.name + '_side_' + side.id + '_event_' + index"
                            :class="event.action"
                          >
                            <va-icon :name="getEventIcon(event.action, event.production_growing_recipe.name)" :style="{transform: 'scaleX(-1)'}"/>
                          </div>
                        </template>

                        <!-- harvesting -->
                        <template v-else-if="event.action.toLowerCase() === 'harvesting'">
                          <div
                            :key="'subgroup_1_' + layer.name + '_side_' + side.id + '_event_' + index"
                            :class="event.action"
                          >
                            <div><va-icon :name="getEventIcon(event.action, event.production_growing_recipe.name)"/></div>
                          </div>
                        </template>

                        <!-- first day irrigation missing -->
                        <template v-else-if="event.action.toLowerCase() === 'missing_irrigation'">
                          <div
                            :key="'subgroup_1_' + layer.name + '_side_' + side.id + '_event_' + index"
                            :class="event.action"
                          >
                            <div><va-icon :name="getEventIcon(event.action)"/></div>
                          </div>
                        </template>

                        <!-- irrigation -->
                        <template v-else>
                          <button
                            :key="'subgroup_1_' + layer.name + '_side_' + side.id + '_event_' + index"
                            :class="event.status"
                          >
                            <va-icon name="at-icon b_water1drop"/>
                          </button>
                        </template>

                        <!-- recipeName -->
                        <div :class="`recipeName__${event.action.toLowerCase()}`" v-if="event.action.toLowerCase() === 'seeding' || event.action.toLowerCase() === 'harvesting'"
                          :key="'subgroup_1_' + layer.name + '_side_' + side.id + '_event_' + index +'_recipe-name'"
                          :style="getRecipeNameContainerStyle(event.production_growing_recipe.name)"
                        >
                          {{ formatRecipeName(event.production_growing_recipe.name) }}
                        </div>

                        <!-- summary -->
                        <div v-if="event.action.toLowerCase() !== 'seeding' && event.action.toLowerCase() !== 'harvesting'"
                          class="cycle-info__summary" :style="getSummaryBottomStyle(event.action, event.numberEvents, event.status, layer.name, side.name)"
                          :key="'subgroup_1_' + layer.name + '_side_' + side.id + '_event_' + index +'_info'">
                          <div class="cycle-info__summary__container">
                            <div class="single-event">
                              <template v-for="(info, index) in getSummaryInfo(event.action.toLowerCase(), event, room.name, layer.name, side.name)">
                                <template v-if="event.action.toLowerCase() === 'seeding' || event.action.toLowerCase() === 'harvesting'">
                                  <span class="multiple-events__icon" :key="'info_' + index">
                                    <va-icon name="at-icon b_recipes3"/><b>{{ info.value }}</b>
                                  </span>
                                </template>
                                <template v-else>
                                  <span v-if="index === 0" class="single-event__icon" :key="'info_' + index">
                                    <va-icon :name="info.icon"/><b>{{ info.value }}</b>
                                  </span>
                                  <div v-else-if="info.level"
                                    class="multiple-events__icon__status-details"
                                    :class="info.level"
                                    :key="'info_' + index"
                                  >
                                    <va-icon :name="info.icon"/><b>{{ info.label }}</b> {{ Math.round(info.value * 100) / 100}} {{ info.units }}
                                  </div>
                                  <span v-else :key="'info_' + index">
                                    <b>{{ info.label }}</b>{{info.value}}
                                  </span>
                                </template>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                </template>
              </div>
            </template>
            <!-- /side -->

            <template v-else>
              <div class="irrigation__subgroups__subgroup">
                <template v-for="timeSlot in (durationDays + 1)">
                  <div
                    class="cycle-info"
                    :key="'subgroup_1_' + layer.name + '_' + timeSlot"
                    :style="{ width: scale + 'px', left: getPixelPositionFromTimeSlot(timeSlot) + 'px', 'border-left': separationCellStyle(timeSlot), 'background': currentCellBackground(timeSlot), 'box-shadow': currentCellShadow(timeSlot) }"
                    :class="{ show: showSummaries }"
                  >
                    <template v-for="(event, index) in getEventInfo(room.id, room.name, layer.name, '', timeSlot)"
                    >
                      <!-- ----------------------  CASE 1. MULTIPLE EVENTS ---------------------- -->
                      <template v-if="event.numberEvents > 1">

                        <!-- seeding_irrigation -->
                        <template v-if="event.action.toLowerCase() === 'seeding_irrigation'">
                          <div
                            :key="'subgroup_1_' + layer.name + '_event_' + index"
                            :class="event.action.toLowerCase()"
                          >
                            <va-icon :name="getEventIcon(event.action, event.production_growing_recipe.name)" :style="{transform: 'scaleX(-1)'}"/>
                            <div :class="'number-events'">{{ event.numberEvents }}</div>
                            <!-- <div class="recipeName">{{ formatRecipeName(event.production_growing_recipe.name) }}</div> -->

                          </div>
                        </template>

                        <!-- harvesting_irrigation -->
                        <template v-if="event.action.toLowerCase() === 'harvesting_irrigation'">
                          <div
                            :key="'subgroup_1_' + layer.name + '_event_' + index"
                            :class="event.action.toLowerCase()"
                          >
                            <va-icon :name="getEventIcon(event.action, event.production_growing_recipe.name)"/>
                            <div :class="'number-events'">{{ event.numberEvents }}</div>
                            <!-- <div class="recipeName">{{ formatRecipeName(event.production_growing_recipe.name) }}</div> -->
                          </div>
                        </template>

                        <!-- irrigation -->
                        <template v-if="event.action.toLowerCase() === 'irrigation' && !event.isOnSeedHarvDay">
                          <template v-if="index === 0">
                            <button
                              :key="'subgroup_1_' + layer.name + '_event_' + index"
                              :class="event.globalStatus"
                            >
                              <va-count-badge
                                :color="getColor(event.globalStatus)"
                                :number="event.numberEvents"
                              >
                                <va-icon name="at-icon b_water1drop"/>
                              </va-count-badge>
                            </button>
                          </template>
                        </template>

                        <!-- recipeName -->
                        <div :class="`recipeName__${event.action.toLowerCase()}`" v-if="event.action.toLowerCase() === 'seeding_irrigation' || event.action.toLowerCase() === 'harvesting_irrigation'"
                          :key="'subgroup_1_' + layer.name + '_event_' + index +'_recipe-name'"
                          :style="getRecipeNameContainerStyle(event.production_growing_recipe.name)"
                        >
                          {{ formatRecipeName(event.production_growing_recipe.name) }}
                        </div>

                        <!-- summary -->
                        <template v-if="(event.action.toLowerCase() === 'irrigation' && index === 0) || (event.action.toLowerCase() === 'seeding_irrigation' || event.action.toLowerCase() === 'harvesting_irrigation')">
                          <div class="cycle-info__summary multiple-events__summary"
                            :style="getSummaryBottomStyle(event.action, event.numberEvents, event.status, layer.name, '')"
                            :key="'subgroup_1_' + layer.name + '_event_' + index +'_info'">
                            <div class="cycle-info__summary__container multiple-events__container">
                              <template v-for="(value, name) in event.eventSummary">

                                <div class="multiple-events" :key="'info_' + name">
                                  <template v-if="name.includes('irrigation_')">
                                    <template v-for="(details, detailsIndex) in value">

                                      <span class="multiple-events__icon" v-if="detailsIndex === 0" :key="'irrigation__info_' + detailsIndex">
                                        <va-icon name="at-icon b_water1drop"/><b>{{ details.value }}</b>
                                      </span>
                                      <div v-else-if="details.details" class="multiple-events__icon__status-details"
                                        :class="details.level"
                                        :key="'irrigation__info_' + detailsIndex"
                                      >
                                        <va-icon :name="details.icon"/><b>{{ details.label }}</b> {{ Math.round(details.value * 100) / 100}} {{ details.units }}
                                      </div>
                                      <span v-else :key="'irrigation__info_' + detailsIndex"><b>{{ details.label }}</b> {{ details.value }}</span>
                                    </template>
                                  </template>
                                  <template v-else>
                                    <span class="multiple-events__icon">
                                      <va-icon name="at-icon b_recipes3"/>
                                      <b>{{ value[0].value }}</b>
                                    </span>
                                  </template>
                                </div>
                              </template>
                            </div>
                          </div>
                        </template>
                      </template>

                      <!-- ----------------------  CASE 1. ONE EVENT ---------------------- -->
                      <template v-else>

                        <!-- seeding -->
                        <template v-if="event.action.toLowerCase() === 'seeding'">
                          <div
                            :key="'subgroup_1_' + layer.name + '_event_' + index"
                            :class="event.action"
                          >
                            <va-icon :name="getEventIcon(event.action, event.production_growing_recipe.name)" :style="{transform: 'scaleX(-1)'}"/>
                          </div>
                        </template>

                        <!-- harvesting -->
                        <template v-else-if="event.action.toLowerCase() === 'harvesting'">
                          <div
                            :key="'subgroup_1_' + layer.name + '_event_' + index"
                            :class="event.action"
                          >
                            <div><va-icon :name="getEventIcon(event.action, event.production_growing_recipe.name)"/></div>
                          </div>
                        </template>

                        <!-- first day irrigation missing -->
                        <template v-else-if="event.action.toLowerCase() === 'missing_irrigation'">
                          <div
                            :key="'subgroup_1_' + layer.name + '_event_' + index"
                            :class="event.action"
                          >
                            <div><va-icon :name="getEventIcon(event.action)"/></div>
                          </div>
                        </template>

                        <!-- irrigation -->
                        <template v-else>
                          <button
                            :key="'subgroup_1_' + layer.name + '_event_' + index"
                            :class="event.status"
                          >
                            <va-icon name="at-icon b_water1drop"/>
                          </button>
                        </template>

                        <!-- recipeName -->
                        <div :class="`recipeName__${event.action.toLowerCase()}`" v-if="event.action.toLowerCase() === 'seeding' || event.action.toLowerCase() === 'harvesting'"
                          :key="'subgroup_1_' + layer.name + '_event_' + index +'_recipe-name'"
                          :style="getRecipeNameContainerStyle(event.production_growing_recipe.name)"
                        >
                          {{ formatRecipeName(event.production_growing_recipe.name) }}
                        </div>

                        <!-- summary -->
                        <div v-if="event.action.toLowerCase() !== 'seeding' && event.action.toLowerCase() !== 'harvesting'"
                          class="cycle-info__summary" :style="getSummaryBottomStyle(event.action, event.numberEvents, event.status, layer.name, '')"
                          :key="'subgroup_1_' + layer.name + '_event_' + index +'_info'">
                          <div class="cycle-info__summary__container">
                            <div class="single-event">
                              <template v-for="(info, index) in getSummaryInfo(event.action.toLowerCase(), event, room.name, layer.name, '')">
                                <template v-if="event.action.toLowerCase() === 'seeding' || event.action.toLowerCase() === 'harvesting'">
                                  <span class="multiple-events__icon" :key="'info_' + index">
                                    <va-icon name="at-icon b_recipes3"/><b>{{ info.value }}</b>
                                  </span>
                                </template>
                                <template v-else>
                                  <span v-if="index === 0" class="single-event__icon" :key="'info_' + index">
                                    <va-icon :name="info.icon"/><b>{{ info.value }}</b>
                                  </span>
                                  <div v-else-if="info.level"
                                    class="multiple-events__icon__status-details"
                                    :class="info.level"
                                    :key="'info_' + index"
                                  >
                                    <va-icon :name="info.icon"/><b>{{ info.label }}</b> {{ Math.round(info.value * 100) / 100}} {{ info.units }}
                                  </div>
                                  <span v-else :key="'info_' + index">
                                    <b>{{ info.label }}</b>{{info.value}}
                                  </span>
                                </template>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                </template>
              </div>
            </template>
          </div>
          <!-- /Layer -->
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import { getTimeFormat, getInFactoryTime } from '../../../helpers/TimeHelpers'
import { getStatusbyLevel, getIconbyLevel, getEventIcon, getColor, getUnits, getCurrentCellBackground, getCurrentCellShadow, getWeekendCellStyle, getSeparationCellStyle } from './../styleProperties'
import AtIrrigationMapTimeline from '../AtIrrigationMapTimeline.vue'

export default {
  name: 'AtIrrigationMapLayers',
  props: {
    uid: {
      type: Number,
    },
    irrigationMapLoading: {
      type: Boolean,
    },
    scale: {
      type: Number,
    },
    context: {
      type: String,
    },
    timelineStartDate: {
      type: String,
    },
    timelineEndDate: {
      type: String,
    },
    irrigationMap: {
      type: Object,
    },
    workOrderInfo: {
      type: Object,
    },
    showSummaries: {
      type: Boolean,
      default: false,
    },
    mapStructure: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      padding: 150,
    }
  },
  mounted () {
    // const duration = (this.durationDays + 1) * this.scale
    // this.containerWidth = duration + this.padding * 2
  },
  computed: {
    durationDays () {
      return moment(this.timelineEndDate).diff(this.timelineStartDate, 'days')
    },
    groupsHeight () {
      let numberOfGroups = 0

      if (this.mapStructure.length > 0) {
        if (this.mapStructure[0]) {
          for (const field in this.mapStructure[0].structure.fields) {
            numberOfGroups += this.mapStructure[0].structure.fields[field].subFields.length
          }
        }
      }

      return (numberOfGroups ? (50 * numberOfGroups) : (50 * 12)) + 'px'
    },
    containerWidth () {
      const duration = (this.durationDays + 1) * this.scale
      return (duration + this.padding * 2)
    },
  },
  methods: {
    getStatusbyLevel,
    getIconbyLevel,
    getEventIcon,
    getColor,
    getUnits,
    getCurrentCellBackground,
    getCurrentCellShadow,
    getWeekendCellStyle,
    getSeparationCellStyle,
    getPixelPositionFromTimeSlot (timeSlot) {
      return timeSlot === 1 ? this.padding : (timeSlot - 1) * this.scale + this.padding // The value padding is the CSS left margin for the __subgroup subgroup
    },
    getEventInfo (roomId, roomName, layer, side, day) {
      let events = []
      let irrigationEvents = []
      const currentDay = moment(this.timelineStartDate).add(day - 1, 'days').format('YYYYMMDD')
      const dayEvents = this.irrigationMap[roomId][currentDay]

      if (dayEvents) {
        const controlAreaName = side !== '' ? (layer + ' ' + side) : layer
        const dayAreaEvents = dayEvents[controlAreaName]

        if (dayAreaEvents) {
          irrigationEvents = dayAreaEvents
        }
      }

      if (this.workOrderInfo[roomId]) {
        const controlArea = side !== '' ? (layer + ' ' + side) : layer
        const areaWorkOrderInfo = this.workOrderInfo[roomId].find(area => area.name === controlArea)

        if (areaWorkOrderInfo.work_order_name !== 'Empty' || areaWorkOrderInfo.work_order_name !== 'Semina/taglio finto') {
          const currentDay = moment(this.timelineStartDate).add(day - 1, 'days').format('YYYYMMDD')
          if (currentDay === moment(areaWorkOrderInfo.work_order_start_date).format('YYYYMMDD')) {
            events.push({
              day: day,
              action: irrigationEvents.length === 0 ? 'seeding' : 'seeding_irrigation',
              status: irrigationEvents.length === 0 ? '' : 'danger',
              numberEvents: irrigationEvents.length + 1,
              production_growing_recipe: areaWorkOrderInfo.production_growing_recipe,
              start_time_stamp: areaWorkOrderInfo.work_order_start_date,
              end_time_stamp: areaWorkOrderInfo.work_order_start_date,
            })
          } else if (currentDay === moment(areaWorkOrderInfo.work_order_end_date).format('YYYYMMDD')) {
            events.push({
              day: day,
              action: irrigationEvents.length === 0 ? 'harvesting' : 'harvesting_irrigation',
              status: irrigationEvents.length === 0 ? '' : 'danger',
              numberEvents: irrigationEvents.length + 1,
              production_growing_recipe: areaWorkOrderInfo.production_growing_recipe,
              start_time_stamp: areaWorkOrderInfo.work_order_end_date,
              end_time_stamp: areaWorkOrderInfo.work_order_end_date,
            })
          }
        }
      }

      if (irrigationEvents.length > 0) {
        irrigationEvents.forEach(event => {
          event.numberEvents = irrigationEvents.length + events.length
          if (events.length > 0) {
            event.isOnSeedHarvDay = true
          }
        })
        events = events.concat(irrigationEvents)
      }

      if (events.length > 0 && events[0].numberEvents > 1) {
        this.getMultiEventsSummaryInfo(events, roomId, roomName, layer, side)
      }
      return events
    },
    getWorstStatus (events) {
      let worst = events[0].status
      for (let i = 1; i < events.length; i++) {
        if (worst === 'danger' || events[i].status === 'danger') {
          worst = 'danger'
        } else if (worst === 'warning' || events[i].status === 'warning') {
          worst = 'warning'
        } else if (worst === 'done' && events[i].status === 'done') {
          worst = 'done'
        }
      }
      return worst
    },
    getSummaryInfo (eventAction, event, roomName, layer, side) {
      const summary = []
      let controlAreasNames = ''

      if (eventAction.toLowerCase() === 'seeding' || eventAction.toLowerCase() === 'harvesting') {
        summary.push({ value: event.production_growing_recipe.name })
      } else if (eventAction.toLowerCase() === 'missing_irrigation') {
        summary.push({ value: this.$t('irrigation.missingIrrigation') })
      } else if (eventAction.toLowerCase() === 'irrigation') {
        const controlAreas = event.destination_short_name.split(', ')

        for (let j = 0; j < controlAreas.length; j++) {
          controlAreasNames += controlAreas[j] + (j < (controlAreas.length - 1) ? ', ' : '')
        }

        summary.push({ icon: 'at-icon b_water1drop', value: controlAreasNames })
        summary.push({ label: 'Origin: ', value: event.origin })
        summary.push({ label: 'Start: ', value: this.printTimeStamp(event.start_time_stamp) })
        if (typeof event.duration === 'object') {
          summary.push({ label: 'Duration: ', value: this.durationToString(event.duration.ref) })
        } else {
          summary.push({ label: 'Duration: ', value: this.durationToString(event.duration) })
        }

        for (const key in event.status_details) {
          let value = ''
          if (event.status === 'scheduled') {
            value = event.status_details[key].ref
          } else {
            value = event.status_details[key].avg ? event.status_details[key].avg : event.status_details[key].val
          }

          summary.push(
            {
              icon: getIconbyLevel(event.status_details[key].level),
              level: event.status === 'scheduled' ? getStatusbyLevel(-1) : getStatusbyLevel(event.status_details[key].level),
              label: this.$t(`waterData.${key}`) + ': ',
              value: value,
              units: getUnits(key),
            },
          )
        }
      }
      return summary
    },
    getMultiEventsSummaryInfo (events, roomId, roomName, layer, side) { // check
      const summary = {}
      for (let i = 0; i < events.length; i++) {
        if (events[i].action.toLowerCase() === 'seeding_irrigation' || events[i].action.toLowerCase() === 'harvesting_irrigation') {
          summary[events[i].action] = [
            { value: events[i].production_growing_recipe.name },
          ]
        } else if (events[i].action.toLowerCase() === 'irrigation') {
          let controlAreasNames = ''
          const controlAreas = events[i].destination_short_name.split(', ')

          for (let j = 0; j < controlAreas.length; j++) {
            controlAreasNames += controlAreas[j] + (j < (controlAreas.length - 1) ? ', ' : '')
          }

          events[i].globalStatus = this.getWorstStatus(events)

          summary[`irrigation_${i}`] = []
          summary[`irrigation_${i}`].push({ icon: 'at-icon b_water1drop ', value: controlAreasNames })

          if (events[i].status === 'scheduled') {
            const origin = events[i].origin.split(' Irrigation ')
            summary[`irrigation_${i}`].push({ label: 'Origin: ', value: origin[1] })
          }

          summary[`irrigation_${i}`].push({ label: 'Start: ', value: this.printTimeStamp(events[i].start_time_stamp) })
          if (typeof events[i].duration === 'object') {
            summary[`irrigation_${i}`].push({ label: 'Duration: ', value: this.durationToString(events[i].duration.ref) })
          } else {
            summary[`irrigation_${i}`].push({ label: 'Duration: ', value: this.durationToString(events[i].duration) })
          }

          for (const key in events[i].status_details) {
            let value = ''
            if (events[i].status_details[key].avg) {
              value = events[i].status_details[key].avg
            } else if (events[i].status_details[key].val) {
              value = events[i].status_details[key].val
            } else if (events[i].status_details[key].ref) {
              value = events[i].status_details[key].ref
            }

            summary[`irrigation_${i}`].push(
              {
                icon: getIconbyLevel(events[i].status_details[key].level),
                details: true,
                level: getStatusbyLevel(events[i].status_details[key].level),
                label: this.$t(`waterData.${key}`) + ': ',
                value: value,
                units: getUnits(key),
              },
            )
          }
        }

        events[i].eventSummary = summary
      }
    },
    getSummaryBottomStyle (actionName, numberEvents, eventStatus, layer, side) {
      let style = {}

      if (actionName.toLowerCase() === 'irrigation') {
        const layerNumber = layer.split(' ')[1]
        if (this.context === 'area') {
          const bottom = eventStatus === 'scheduled' ? '0' : '-20px'
          const before = '133px'
          style = { zIndex: '20', bottom: bottom, left: '45px', '--before-top': before }
        } else {
          if (layerNumber > 1) {
            const bottom = '-95px'
            const before = numberEvents > 1 ? '50px' : (eventStatus === 'scheduled' ? '40px' : '59px')
            style = { bottom: bottom, left: '45px', '--before-top': before } // { bottom: bottom, left: '55px', '--before-top': before }
          } else {
            const sideNumber = side.split(' ')[1]
            if (sideNumber === '1') {
              const bottom = eventStatus === 'scheduled' ? '-50px' : '-46px'
              const before = numberEvents > 1 ? '99px' : (eventStatus === 'scheduled' ? '84px' : '109px')
              style = { bottom: bottom, left: '45px', '--before-top': before }
            } else if (sideNumber === '2') {
              const before = numberEvents > 1 ? '137px' : (eventStatus === 'scheduled' ? '134px' : '156px')
              style = { bottom: '2px', left: '45px', '--before-top': before }
            }
          }
        }
      } else if (actionName.toLowerCase() === 'seeding_irrigation') {
        const layerNumber = layer.split(' ')[1]
        if (this.context === 'area') {
          const bottom = '-5px'
          const before = '133px'
          style = { zIndex: '20', bottom: bottom, left: '56px', '--before-top': before }
        } else {
          if (layerNumber > 1) {
            style = { bottom: '-80px', left: '56px', '--before-top': '60px' }
          } else {
            const sideNumber = side.split(' ')[1]
            if (sideNumber === '1') {
              style = { bottom: '-50px', left: '56px', '--before-top': '92px' }
            } else if (sideNumber === '2') {
              style = { bottom: '2px', left: '56px', '--before-top': '140px' }
            }
          }
        }
      } else {
        const layerNumber = layer.split(' ')[1]
        if (this.context === 'area') {
          const bottom = eventStatus === 'scheduled' ? '0' : '-20px'
          const before = '133px'
          style = { zIndex: '20', bottom: bottom, left: '45px', '--before-top': before }
        } else {
          if (layerNumber > 1) {
            style = { bottom: '-5px', left: '56px', '--before-top': '25px' }
          } else {
            const sideNumber = side.split(' ')[1]
            if (sideNumber === '1') {
              style = { bottom: '-5px', left: '56px', '--before-top': '25px' }
            } else if (sideNumber === '2') {
              style = { bottom: '2px', left: '56px', '--before-top': '30px' }
            }
          }
        }
      }

      return style
    },
    printTimeStamp (timestampUTC) {
      return getTimeFormat(getInFactoryTime(timestampUTC + 'Z'), 'medium', true)
    },
    durationToString (timeSeconds) {
      return getTimeFormat(moment.utc(timeSeconds * 1000), 'medium', false)
    },
    separationCellStyle (timeSlot) {
      const currentTimeSlotDate = moment(this.timelineStartDate).add(timeSlot - 1, 'days')

      return currentTimeSlotDate.format('d') === '1' ? getSeparationCellStyle() : ''
    },
    currentCellBackground (timeSlot) {
      const currentDate = moment()
      const currentTimeSlotDate = moment(this.timelineStartDate).add(timeSlot - 1, 'days')
      let background = ''

      if (currentDate.format('YYYYMMDD') === currentTimeSlotDate.format('YYYYMMDD')) { // current
        background = getCurrentCellBackground()
      } else if (currentTimeSlotDate.day() === 0 || currentTimeSlotDate.day() === 6) { // weekend
        background = getWeekendCellStyle()
      }

      return background
    },
    currentCellShadow (timeSlot) {
      const currentDate = moment()
      const currentTimeSlotDate = moment(this.timelineStartDate).add(timeSlot - 1, 'days')
      let shadow = ''

      if (currentDate.format('YYYYMMDD') === currentTimeSlotDate.format('YYYYMMDD')) { // current
        shadow = getCurrentCellShadow()
      }

      return shadow
    },
    formatRecipeName (recipeName) {
      if (recipeName.includes('Rucola')) {
        return 'Rucola'
      } else if (recipeName.includes('Lattughino')) {
        return 'Lattughino'
      } else if (recipeName.includes('Basilicooh')) {
        return 'Basilicooh'
      } else if (recipeName.includes('oriental')) {
        return 'MixOriental'
      } else if (recipeName.includes('piccante')) {
        return 'MixPiccante'
      } else if (recipeName.includes('delicato')) {
        return 'MixDelicato'
      } else if (recipeName.includes('vivace')) {
        return 'MixVivace'
      } else if (recipeName.includes('esotico')) {
        return 'MixEsotico'
      } else if (recipeName.includes('Crop') && recipeName.length < 16) {
        return recipeName
      }
      return recipeName.substring(0, 15) + '...'
    },
    getRecipeNameContainerStyle (recipeName) {
      let style = { '--recipeName-left': '-143px', '--recipeName-after-left': '131px', '--recipeName-before-left': '132px' }
      if (recipeName.toLowerCase().includes('delicato')) {
        style = { '--recipeName-left': '-110px', '--recipeName-after-left': '101px', '--recipeName-before-left': '102px' }
      } else if (recipeName.toLowerCase().includes('lattughino')) {
        style = { '--recipeName-left': '-105px', '--recipeName-after-left': '96px', '--recipeName-before-left': '97px' }
      } else if (recipeName.toLowerCase().includes('basilico')) {
        style = { '--recipeName-left': '-99px', '--recipeName-after-left': '90px', '--recipeName-before-left': '91px' }
      } else if (recipeName.toLowerCase().includes('esotico')) {
        style = { '--recipeName-left': '-102px', '--recipeName-after-left': '93px', '--recipeName-before-left': '94px' }
      } else if (recipeName.toLowerCase().includes('vivace')) {
        style = { '--recipeName-left': '-96px', '--recipeName-after-left': '87px', '--recipeName-before-left': '88px' }
      } else if (recipeName.toLowerCase().includes('rucola')) {
        style = { '--recipeName-left': '-74px', '--recipeName-after-left': '65px', '--recipeName-before-left': '66px' }
      } else if (recipeName.toLowerCase().includes('crop')) {
        style = { '--recipeName-left': '-143px', '--recipeName-after-left': '131px', '--recipeName-before-left': '132px' }
      }

      return style
    },
  },
  components: {
    AtIrrigationMapTimeline,
  },
}
</script>

<style lang="scss">
.recipeName {
  --recipeName-left: '0';
  --recipeName-after-left: '0';
  --recipeName-before-left: '0';

  &__seeding,
  &__seeding_irrigation,
  &__harvesting,
  &__harvesting_irrigation {
    @include radius(5px);

    display: flex;
    align-items: center;
    width: max-content;
    background: hsl(180, 50%, 99%);
    border: 1px solid  hsl(var(--primary-color-hue), 40%, 88%);
    position: absolute;
    padding: 8px;
    z-index: 1;

    &::before,
    &::after {
      content: '';
      position: absolute;
      // top: 12px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px;
    }
  }

  &__seeding,
  &__seeding_irrigation {
    color: hsl(170, 63%, 30%);
    left: var(--recipeName-left);

    &::before,
    &::after {
      content: '';
      top: 12px;
    }

    &::before {
      left: var(--recipeName-before-left);
      border-color: transparent transparent transparent hsl(var(--primary-color-hue), 40%, 88%);
    }

    &::after {
      left: var(--recipeName-after-left);
      border-color: transparent transparent transparent white;
    }
  }

  &__harvesting,
  &__harvesting_irrigation {
    color: hsl(200, 80%, 30%);
    left: 56px;

    &::before,
    &::after {
      content: '';
      top: 12px;
    }

    &::before {
      left: -13px;
      border-color: transparent hsl(var(--primary-color-hue), 40%, 88%) transparent transparent;
    }

    &::after {
      left: -12px;
      border-color: transparent white transparent transparent;
    }
  }

  &__missing_irrigation {
    color: red;
    left: 56px;

    &::before,
    &::after {
      content: '';
      top: 12px;
    }

    &::before {
      left: -13px;
      border-color: transparent hsl(var(--primary-color-hue), 40%, 88%) transparent transparent;
    }

    &::after {
      left: -12px;
      border-color: transparent white transparent transparent;
    }
  }
}
</style>
