<template>
  <div :id="'subgroups_' + uid" class="irrigation__subgroups" @click.stop="toggleVariableOptions()">

    <template v-if="irrigationMapLoading">
      <div class="map-loading__placeholder-top"></div>
      <div class="map-loading__container" :style="{height: groupsHeight}">
        <div class="flex-center spinner-box">
          <spinner/>
        </div>
      </div>
    </template>

    <template v-else>
      <at-irrigation-map-timeline
        :uid="uid"
        :scale="scale"
        :padding="padding"
        :containerWidth="containerWidth"
        :timeline-start-date="timelineStartDate"
        :timeline-end-date="timelineEndDate"
        :data-point="'events'"
        :zoom-level="zoomLevel"
        :duration-days="durationDays"
        :n-events="nEvents"
        :irrigation-map="irrigationMap"
      />
      <template>
        <!-- Start spacing -->
        <div class="irrigation__timeslot__group">
          <div class="irrigation__timeslot__group__subgroup">
            <template>
              <div class="event-info first icon" :style="{'background': currentCellBackground(0), width: `${padding + 'px'}` }"></div>
            </template>
          </div>

          <div v-for="fields in mapStructure"
            class="irrigation__timeslot__group__subgroup"
            :key="'field_' + fields.name"
          >
            <template v-for="subField in fields.structure.fields">
              <div class="event-info first"
                :key="'field_' + fields.name + 'subfield_' + subField.id"
                :style="{ width: `${padding + 'px'}` }"
              >
              </div>
            </template>
          </div>
        </div>

        <!-- MATRIX - draw always the matrix accordingly to the timeslot -->
        <template v-for="(timeSlot, day) in irrigationMap">
          <!-- ------------ EMPTY DAY -------------- -->
          <div v-if="timeSlot.length === 0"
            class="irrigation__timeslot__group"
            :key="'timeSlot_' + day + '_event_' + 'empty'"
          >
            <!-- First of the group event icon-->
            <div
              class="irrigation__timeslot__group__subgroup"
              :key="'timeslot_' + day + '_event_' + 'empty' + '_group'"
            >
              <template>
                <div
                  class="event-info icon"
                  :key="'timeslot_' + day + '_event_' + 'empty' + '_group_cell'"
                  :style="getEmptyCellsStyle(scale, day)"
                >
                  <!--  -->
                  <button
                    :key="'timeslot_' + day + '_event_' + 'empty' + '_group_cell_icon'"
                  >
                  </button>
                </div>
              </template>
            </div>
            <!-- Event details -->
            <div v-for="fields in mapStructure"
              class="irrigation__timeslot__group__subgroup"
              :key="'field_' + fields.name"
            >
              <template v-for="subField in fields.structure.fields">
                <div
                  class="event-info"
                  :key="'field_' + fields.name + 'subfield_' + subField.id"
                  :style="getEmptyCellsStyle(scale, day)"
                >
                </div>
              </template>
            </div>
          </div>

          <!-- ------------ IRRIGATION EVENTS -->
          <div v-else v-for="(event, index) in timeSlot"
            class="irrigation__timeslot__group"
            :key="'timeSlot_' + day + '_event_' + index"
          >
            <!-- First of the group event icon-->
            <div
              class="irrigation__timeslot__group__subgroup"
              :key="'timeslot_' + day + '_event_' + index + '_group'"
            >
              <template>
                <!-- Event icon -->
                <div
                  class="event-info icon"
                  :class="event.status === 'current' ? 'current' : event.status === 'critical' ? 'critical' : ''"
                  :key="'timeslot_' + day + '_event_' + index + '_group_cell'"
                  :style="getEventsCellsStyle(scale, index, day, event.status)"
                >
                  <at-irrigation-events-dropover-options
                    v-if="isFutureEvent(event) && isToday(day)"
                    :show="showOption"
                    :irrigation-event-index="index"
                    :irrigation-event="event"
                    :day="day"
                    v-on="$listeners"
                  />

                  <button
                    :key="'timeslot_' + day + '_event_' + index + '_group_cell_icon'"
                    :disabled="!isFutureEvent(event) && isToday(day)"
                    :class="event.status"
                    @click.stop="toggleVariableOptions(index, day)"
                    :style="{ cursor: isFutureEvent(event) && isToday(day) ? '' : ''}"
                  >
                    <va-icon v-if="event.action.toLowerCase() === 'rinse'">R</va-icon>
                    <va-icon v-else-if="event.action.toLowerCase() === 'disinfect'">D</va-icon>
                    <va-icon v-else :name="getEventIcon(event.action.toLowerCase())"
                      :ref="currentFirstTimeSlot(day, index)"
                    />
                  </button>
                </div>
              </template>
            </div>
            <!-- Event details -->
            <div v-for="fields in mapStructure"
              class="irrigation__timeslot__group__subgroup"
              :key="'field_' + fields.name"
            >
              <template v-for="subField in fields.structure.fields">
                <div
                  class="event-info"
                  :class="event.status === 'current' + (event.status === 'critical' ? ' critical' : '') ? event.status : event.status === 'critical' ? 'critical' : ''"
                  :key="'field_' + fields.name + 'subfield_' + subField.id"
                  :style="getEventsCellsStyle(scale, index, day, event.status)"
                >
                  <template v-for="(info, index) in getInfo(event, subField.name)">
                    <span class="event-info__details"
                      :style="{fontWeight: subField.name == 'flow' ? 'bold' : ''}"
                      :class="info.status"
                      :key="'info__' + index"
                      v-html="info.value"
                    />
                    <template v-if="info.setpoint !== undefined">
                      <div class="event-info__summary"
                        :key="'info-summary__' + index"
                        :style="{bottom: '37px', left: '70px', position: 'relative'}"
                      >
                        <div class="event-info__summary__container">
                          <span>
                            <va-icon name="at-icon l_target"/>
                            {{info.setpoint}}
                          </span>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </template>

        <!-- End spacing -->
        <div class="irrigation__timeslot__group">
          <div class="irrigation__timeslot__group__subgroup">
            <template>
              <div class="event-info last icon" :style="{'background': currentCellBackground(0), width: `${padding + 'px'}` }"></div>
            </template>
          </div>

          <div v-for="fields in mapStructure"
            class="irrigation__timeslot__group__subgroup"
            :key="'field_' + fields.name"
          >
            <template v-for="subField in fields.structure.fields">
              <div class="event-info last"
                :key="'field_' + fields.name + '_subfield_' + subField.id"
                :style="{ width: `${padding + 'px'}` }"
              ></div>
            </template>
          </div>
        </div>
      </template>

      <slot name="currentAction" />
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import { getTimeFormat, getInFactoryTime, getTimeDateFormat } from '../../../helpers/TimeHelpers'
import { getIconbyLevel, getEventIcon, getColor, getUnits, getCurrentCellBackground, getSeparationCellStyle, getGridCellStyle, getStatusbyLevel } from './../styleProperties'
import AtIrrigationMapTimeline from '../AtIrrigationMapTimeline.vue'
import AtIrrigationEventsDropoverOptions from '../../at-irrigation-events-dropover-options/AtIrrigationEventsDropoverOptions.vue'

export default {
  name: 'AtIrrigationMapLayersLineEvents',
  props: {
    uid: {
      type: Number,
    },
    irrigationMapLoading: {
      type: Boolean,
    },
    timelineStartDate: {
      type: String,
    },
    timelineEndDate: {
      type: String,
    },
    id: {
      type: Number,
    },
    irrigationMap: {
      type: Object,
    },
    dataPoint: {
      type: String,
      default: 'events',
    },
    showSummaries: {
      type: Boolean,
      default: false,
    },
    mapStructure: {
      type: Array,
    },
    nEvents: {
      type: Number,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      scale: 90,
      padding: 50,
      zoomLevel: 1,
      showOption: '',
    }
  },
  computed: {
    containerWidth () {
      let duration = 0
      if (this.nEvents < this.durationDays) {
        duration = (this.durationDays) * this.zoomLevel * this.scale
      } else {
        duration = this.nEvents * this.zoomLevel * this.scale
      }
      return (duration + this.padding * 2)
    },
    durationDays () {
      const hours = moment(this.timelineEndDate).diff(this.timelineStartDate, 'hours')
      return Math.ceil(hours / 24)
    },
    groupsHeight () {
      let numberOfGroups = 0

      if (this.mapStructure.length > 0) {
        if (this.mapStructure[0]) {
          for (const field in this.mapStructure[0].structure.fields) {
            numberOfGroups += this.mapStructure[0].structure.fields[field].subFields.length
          }
        }
      }

      return (numberOfGroups ? (50 * numberOfGroups) : (50 * 12)) + 'px'
    },
  },
  methods: {
    getIconbyLevel,
    getEventIcon,
    getColor,
    getUnits,
    getCurrentCellBackground,
    getSeparationCellStyle,
    getGridCellStyle,
    getStatusbyLevel,

    getEmptyCellsStyle (scale, day) {
      return {
        width: scale + 'px',
        borderLeft: getSeparationCellStyle(),
        borderRight: getSeparationCellStyle(),
        background: this.currentCellBackground(day),
      }
    },
    getEventsCellsStyle (scale, index, day, eventStatus) {
      let background = ''

      if (eventStatus === 'current') {
        background = 'hsla(var(--primary-color-hue), 60%, 30%, 9%)'
      } else {
        if (moment.utc().format('YYYYMMDD') === day) { // current
          background = getCurrentCellBackground()
        }
      }

      return {
        width: scale + 'px',
        borderLeft: this.separationCellStyle(index, day, 'left'),
        borderRight: this.separationCellStyle(index, day, 'right'),
        background: background,
      }
    },
    separationCellStyle (index, day, position) {
      const lastEventOfDay = this.irrigationMap[day].length === (index + 1)

      if (day) { // if today dont add separation cell
        if (moment().format('YYYYMMDD') !== day) {
          if (position === 'left') {
            if ((moment().add(1, 'days').format('YYYYMMDD') === day) && index === 0) {
              return getGridCellStyle()
            } else {
              return index === 0 ? getSeparationCellStyle() : ''
            }
          } else if (position === 'right') {
            if ((moment().subtract(1, 'days').format('YYYYMMDD') === day) && lastEventOfDay) {
              return getGridCellStyle()
            } else {
              return lastEventOfDay ? getSeparationCellStyle() : ''
            }
          }
        }
      }
    },
    currentCellBackground (day) {
      let background = ''

      if (moment.utc().format('YYYYMMDD') === day) { // current
        background = getCurrentCellBackground()
      }
      return background
    },
    currentFirstTimeSlot (day, index) {
      return moment().format('YYYYMMDD') === day && index === 0 ? 'currentFirstTimeSlot' : null
    },
    getGroupsHeight () {
      const numberOfGroups = this.mapStructure[0] ? this.mapStructure[0].structure.fields.length + 1 : 0
      return (50 * numberOfGroups) + 'px'
    },
    isFutureEvent (event) {
      if ('type' in event) {
        return event.type === 'future-event'
      } else {
        return event.key === 'undefined' || event.key === 'future-event'
      }
    },
    isToday (day) {
      return getInFactoryTime(moment()).format('YYYYMMDD') === day
    },
    getInfo (event, subfield) {
      if (subfield.toLowerCase() === 'destination') {
        if (event.action.toLowerCase() === 'irrigation' || event.action.toLowerCase() === 'disinfect' || event.action.toLowerCase() === 'rinse') {
          return [
            { value: event.destination_short_name, status: '' },
          ]
        } else if (event.action.toLowerCase() === 'recalibration' || event.action.toLowerCase() === 'fill' || event.action.toLowerCase() === 'circulation') {
          return [
            { value: event.destination.split(' Irrigation ')[1] ? event.destination.split(' Irrigation ')[1] : event.destination, status: '' },
          ]
        }
      } else if (subfield.toLowerCase() === 'origin') {
        return [
          { value: event.origin.split(' Irrigation ')[1] ? event.origin.split(' Irrigation ')[1] : event.origin, status: '' },
        ]
      } else if (subfield.toLowerCase() === 'start') {
        return [
          { value: this.printTimeStamp(event.start_time_stamp), status: '' },
        ]
      } else if (subfield.toLowerCase() === 'duration') {
        if (event.status === 'current') {
          return ''
        }
        if (typeof event.duration === 'object') {
          return [
            { value: this.durationToString(event.duration.ref), status: '' },
          ]
        }
        return [
          { value: this.durationToString(event.duration), status: '' },
        ]
      } else if (subfield.toLowerCase() === 'volume') {
        if (event.status === 'current') {
          return ''
        }
        return [
          {
            value: event.status === 'scheduled' || event.status === 'current' ? Math.round(event.volume.ref * 100) / 100 : Math.round(event.volume.val * 100) / 100,
            setpoint: Math.round(event.volume.ref * 100) / 100,
            status: (event.volume.level === 1 || event.volume.level === 2 || event.volume.level === 3) ? getStatusbyLevel(event.volume.level) : '',
          },
        ]
      } else if (subfield.toLowerCase() === 'ph') {
        if (event.status === 'current') {
          return ''
        }

        if (event.action.toLowerCase() === 'irrigation') {
          return [
            {
              value: event.status === 'scheduled' || event.status === 'current' ? Math.round(event.ph.ref * 100) / 100 : Math.round(event.ph.avg * 100) / 100,
              setpoint: Math.round(event.ph.ref * 100) / 100,
              status: (event.ph.level === 1 || event.ph.level === 2 || event.ph.level === 3) ? getStatusbyLevel(event.ph.level) : '',
            },
          ]
        } else {
          return [{ value: '-' }, { status: '' }]
        }
      } else if (subfield.toLowerCase() === 'ec') {
        if (event.status === 'current') {
          return ''
        }
        if (event.action.toLowerCase() === 'irrigation') {
          return [
            {
              value: event.status === 'scheduled' || event.status === 'current' ? Math.round(event.electrical_conductivity.ref * 100) / 100 : Math.round(event.electrical_conductivity.avg * 100) / 100,
              setpoint: Math.round(event.electrical_conductivity.ref * 100) / 100,
              status: (event.electrical_conductivity.level === 1 || event.electrical_conductivity.level === 2 || event.electrical_conductivity.level === 3) ? getStatusbyLevel(event.electrical_conductivity.level) : '',
            },
          ]
        } else {
          return [{ value: '-' }, { status: '' }]
        }
      } else if (subfield.toLowerCase() === 'orp') {
        if (event.status === 'current') {
          return ''
        }
        if (event.action.toLowerCase() === 'irrigation') {
          return [
            {
              value: event.status === 'scheduled' || event.status === 'current' ? Math.round(event.orp.ref * 100) / 100 : Math.round(event.orp.avg * 100) / 100,
              setpoint: Math.round(event.orp.ref * 100) / 100,
              status: (event.orp.level === 1 || event.orp.level === 2 || event.orp.level === 3) ? getStatusbyLevel(event.orp.level) : '',
            },
          ]
        } else {
          return [{ value: '-', status: '' }]
        }
      } else if (subfield.toLowerCase() === 'flow') {
        if (event.status === 'current') {
          return ''
        }
        return [
          {
            value: event.status === 'scheduled' || event.status === 'current' ? '-' : Math.round(event.volume_flow.avg * 100) / 100,
            setpoint: Math.round(event.volume_flow.ref * 100) / 100,
            status: (event.volume_flow.level === 1 || event.volume_flow.level === 2 || event.volume_flow.level === 3) ? getStatusbyLevel(event.volume_flow.level) : '',
          },
        ]
      }
      return [{ value: '', status: '' }]
    },
    printTimeStamp (timestampUTC) {
      return getTimeDateFormat(getInFactoryTime(timestampUTC + 'Z'), 'medium', true).replace(', ', '<br>')
    },
    durationToString (timeSeconds) {
      return getTimeFormat(moment.utc(timeSeconds * 1000), 'medium', false)
    },
    toggleVariableOptions (selectedIrrigationEventIndex, day) {
      if (selectedIrrigationEventIndex !== undefined) {
        this.showOption = `${selectedIrrigationEventIndex}-${day}`
      } else {
        this.showOption = ''
      }
    },
  },
  watch: {
  },
  components: {
    AtIrrigationMapTimeline,
    AtIrrigationEventsDropoverOptions,
  },
}
</script>

<style lang="scss">
$timelineHeight: 70px;
$timeline-levels-width: 265px;
$groupHeight: 50px;
$doneColor: hsl(170, 65%, 45%);
$warningColor: hsl(30, 90%, 65%);
$dangerColor: hsl(0, 100%, 50%);
$scheduledColor: hsl(200, 85%, 60%);
$currentColor: hsla(var(--primary-color-hue), 100%, 30%, 100%);

.irrigation {
  &__subgroups {
    white-space: nowrap;
  }

  &__timeslot {
    @include card-gradient();

    color: hsl(var(--primary-color-hue), 55%, 35%);
    width: calc(100% - #{$timeline-levels-width});
    overflow: auto;

    &__group {
      display: inline-flex;
      flex-direction: column;
      vertical-align: top;
      justify-content: center;
      align-items: center;

      &__subgroup {
        background: #ffffff;

        .event-info {
          text-align: center;
          height: $groupHeight;
          border: none;
          border-left: 1px solid hsl(var(--primary-color-hue), 40%, 88%);
          border-bottom: 1px solid hsl(var(--primary-color-hue), 40%, 88%);
          position: relative;

          &__summary {
            @include radius(10px);

            display: flex;
            align-items: center;
            width: max-content;
            z-index: -1;
            background: rgba(0, 0, 0, 0.75);
            position: absolute;
            bottom: 18px;
            left: 50px;
            opacity: 0;
            transition: opacity 0.5s;

            &::before {
              content: '';
              position: absolute;
              top: 6px;
              left: -10px;
              width: 0;
              height: 0;
              border-width: 6px;
              border-color: transparent rgba(0, 0, 0, 0.75) transparent transparent;
              border-style: solid;
            }

            &__container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 25px;
              padding: 0 8px 0 8px;
              overflow: hidden;

              span {
                white-space: pre;
                color: #ffffff;
                font-size: pxtorem(12);
                justify-content: left;
              }
            }
          }

          &__details {
            &.warning {
              color: hsl(30, 90%, 65%);
              font-weight: 600;
            }

            &.danger {
              color: hsl(0, 100%, 56%);
              font-weight: 600;
            }
          }

          &:hover {
            .event-info__summary {
              opacity: 1;
              z-index: 1;
            }
          }

          button,
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            white-space: pre-wrap;
            font-size: pxtorem(13);
          }

          button {
            opacity: 0;
            font-size: pxtorem(24);
            color: #d4e4ec;
            border: none;
            background: none;
            outline: none;
            border-radius: 0;
            transition: opacity 0.3s;

            i {
              font-size: pxtorem(28);
            }

            &:hover {
              opacity: 1;
            }

            &.done,
            &.warning,
            &.danger,
            &.critical,
            &.scheduled,
            &.current {
              opacity: 0.9;
              color: #ffffff;
              transition: opacity 0.3s;

              i {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                font-size: pxtorem(14);
              }

              &:hover {
                opacity: 1;
              }
            }

            &.done {
              i {
                background: $doneColor;
              }
            }

            &.warning {
              i {
                background: $warningColor;
              }
            }

            &.danger,
            &.critical {
              i {
                background: $dangerColor;
              }
            }

            &.current {
              i {
                background: $currentColor;
                color: white;
              }
            }

            &.scheduled {
              i {
                background: $scheduledColor;
              }
            }
          }

          &.icon {
            background: hsla(var(--primary-color-hue), 25%, 70%, 10%);
          }

          &.critical {
            border-left: 1px solid hsl(0, 100%, 50%);
            border-right: 1px solid hsl(0, 100%, 50%);
            background: rgba(255, 0, 0, 0.2) !important;
          }

          &.current {
            border-left: 1px solid hsl(var(--primary-color-hue), 40%, 30%);
            border-right: 1px solid hsl(var(--primary-color-hue), 40%, 30%);
            background: rgb(225, 238, 244) !important;
            border-bottom: none;
          }

          &:last-of-type {
            border-bottom: 1px solid rgb(0, 102, 153);
          }

          &.current:last-of-type {
            border-bottom: none;
          }
        }

        .last {
          border-left: 1px solid hsl(var(--primary-color-hue), 20%, 83%);
        }
      }
    }
  }
}

.map-loading {
  z-index: 5;
  height: $timelineHeight;
  position: sticky;
  top: 0;

  &__placeholder-top {
    z-index: 5;
    height: $timelineHeight;
    position: sticky;
    top: 0;
  }
}
</style>
