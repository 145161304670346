<template>
  <va-dropdown
    class="profile-dropdown"
    v-model="isShown"
    offset="0, 16px"
  >
    <span
      class="profile-dropdown__anchor" slot="anchor"
      :style="{color: this.$themes.primary}"
    >
      <va-icon
        class="pa-1"
        name="at-icon l_menuusers"
        style="font-size: 1.8rem; display: flex; color: #ffffff;"
      />
      <slot/>
    </span>
    <div class="profile-dropdown__content pl-4 pr-4 pt-2 pb-2">
      <div
        class="profile-dropdown__item pt-1 pb-1 mt-2 mb-2"
        @click.prevent="editProfile">
        {{ $t(`user.editProfile`) }}
      </div>
      <div
        class="profile-dropdown__item pt-1 pb-1 mt-2 mb-2"
        @click.prevent="logout">
        Logout
      </div>

    </div>
  </va-dropdown>
</template>

<script>
// import store from '../../../../../store/index.js'
// import router from '../../../../../router/index.js'
export default {
  name: 'profile-section',
  data () {
    return {
      isShown: false,
    }
  },
  props: {
    options: {
      type: Array,
    },
  },
  methods: {
    editProfile () {
      const url = this.$keycloak.authServerUrl + '/realms/' + this.$keycloak.realm + '/account/?referrer=' + this.$keycloak.clientId
      window.location.href = url
    },
    logout () {
      localStorage.removeItem('token')
      localStorage.removeItem('role')
      localStorage.removeItem('roles')
      localStorage.removeItem('user')
      localStorage.removeItem('needs_sidebar')
      this.$keycloak.logout()
    },
  },
}
</script>

<style lang="scss">

.profile-dropdown {
  cursor: pointer;

  &__anchor {
    color: var(--primary-color);
    display: flex;
    align-items: center;
  }

  .va-dropdown-popper__anchor {
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    background-color: $dropdown-background;
    box-shadow: $gray-box-shadow;
    border-radius: 0.5rem;
    width: 8rem;
  }

  &__item {
    display: block;
    color: $vue-darkest-blue;

    &:hover,
    &:active {
      color: var(--primary-color);
      font-weight: 500;
    }
  }

  .va-dropdown__anchor {
    display: inline-block;
  }

  span {
    text-transform: uppercase;
    color: #ffffff;
    font-size: 0.85rem;
  }
}
</style>
