<template>
  <div>
    <div class="row align--center">
      <!-- SEARCH -->
      <div v-show="filterInputShown" class="flex xs12 md6">
        <!-- Search by content -->
        <va-input
          @keyup="doFilter()"
          v-model="filterText"
          :placeholder="$t(`tables.${filterInputLabel}`)"
          required
        >
          <va-icon name="at-icon l_search" slot="prepend" />
        </va-input>
      </div>
      <!-- PER PAGE SELECTOR -->
      <div v-show="perPageSelectorShown" class="flex xs12 md3" :class="filterInputShown ? 'offset--md3' : 'offset--md9'" style="font-size: 1rem;">
        <va-select
          :value="perPage"
          :label="$t('tables.perPage')"
          :options="itemsPerPage"
          @input="onItemsPerPage"
          noClear
        />
      </div>
    </div>

    <div class="va-data-table">
      <va-inner-loading :loading="loading">
        <vuetable
          ref="vuetable"
          :apiUrl="apiUrl"
          :apiMode="apiMode"
          :httpFetch="httpFetch"
          :httpOptions="httpOptions"
          :fields="fields"
          :dataManager="dataManager"
          :css="styles"
          :data-path="dataPath"
          :pagination-path="paginationPath"
          :appendParams="moreParams"
          :per-page="parseInt(perPage)"
          :sort-order="sortOrder"
          :queryParams="queryParams"
          :no-data-template="noDataTemplate"
          @vuetable:row-clicked="rowClicked"
          @vuetable:pagination-data="onPaginationData"
          @vuetable:loading="onLoading"
          @vuetable:loaded="onLoaded"
        >
          <!-- https://stackoverflow.com/questions/50891858/vue-how-to-pass-down-slots-inside-wrapper-component   -->
          <template
            v-for="slot in Object.keys($scopedSlots)"
            :slot="slot"
            slot-scope="scope"
          >
            <slot
              :name="slot"
              v-bind="scope"
            />
          </template>
        </vuetable>

        <div class="va-data-table__pagination">
          <vuetable-pagination
            ref="pagination"
            :css="cssPagination"
            :onEachSide="onEachSide"
            @vuetable-pagination:change-page="onChangePage"
          />
        </div>
      </va-inner-loading>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VaInnerLoading from 'vuestic-ui/src/components/vuestic-components/va-inner-loading/VaInnerLoading'

export default {
  name: 'at-data-table',
  components: {
    VaInnerLoading,
    Vuetable,
    VuetablePagination,
  },
  props: {
    apiUrl: {
      type: String,
    },
    httpFetch: {
      type: Function,
      default: null,
    },
    httpOptions: {
      type: Object,
      default: () => {
      },
    },
    filterQuery: {
      type: String,
      default: 'filter',
    },
    filterText: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      required: true,
    },
    perPageSelectorShown: {
      type: Boolean,
      default: true,
    },
    filterInputShown: {
      type: Boolean,
      default: true,
    },
    filterInputLabel: {
      type: String,
      default: 'searchByName',
    },
    defaultPerPage: {
      type: String,
      default: '5',
    },
    onEachSide: {
      type: Number,
      default: 2,
    },
    apiMode: {
      type: Boolean,
      default: true,
    },
    tableData: {
      type: Object,
      default () {
        return {
          data: [],
        }
      },
    },
    dataModeFilterableFields: {
      type: Array,
      default: () => [],
    },
    sortFunctions: {
      type: Object,
    },
    sortOrder: {
      type: Array,
    },
    dataPath: {
      type: String,
      default: 'data',
    },
    paginationPath: {
      type: String,
      default: '',
    },
    queryParams: {
      type: Object,
      default: () => {
        return {
          sort: 'sort',
          page: 'page',
          perPage: 'per_page',
        }
      },
    },
    appendParams: {
      type: Object,
      default () {
        return {}
      },
    },
  },
  data () {
    return {
      perPage: '0',
      term: null,
      noDataTemplate: '',

      typingTimeout: null,
      typingDelay: 250,
      itemsPerPage: ['5', '10', '20'],
      loading: false,
      cssPagination: {
        wrapperClass: 'va-button-group va-pagination va-button-group--normal',
        activeClass: 'active',
        disabledClass: 'va-button va-button--outline va-button--disabled va-button--without-title va-button--with-left-icon va-button--normal disabled',
        pageClass: 'va-button va-button--outline va-button--normal va-button',
        linkClass: 'va-button va-button--outline va-button--without-title va-button--with-left-icon va-button--normal',
        icons: {
          first: 'fa fa-angle-double-left',
          prev: 'at-icon l_arrow1-left',
          next: 'at-icon l_arrow1-right',
          last: 'fa fa-angle-double-right',
        },
      },
    }
  },
  created () {
    this.perPage = this.defaultPerPageComputed
  },
  mounted () {
    this.$emit('initialized', this.$refs.vuetable)
  },
  computed: {
    styles () {
      return {
        tableClass: this.buildTableClass(),
        ascendingIcon: 'at-icon l_arrow1-up',
        descendingIcon: 'at-icon l_arrow1-down',
        renderIcon: classes => {
          return '<span class="' + classes.join(' ') + '"></span>'
        },
      }
    },
    moreParams () {
      // HACK
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.appendParams[this.filterQuery] = this.filterText
      return this.appendParams
    },
    dataModeFilterableFieldsComputed () {
      const dataItem = this.tableData[this.dataPath][0] || {}
      const filterableFields = this.dataModeFilterableFields

      if (!filterableFields.length) {
        const itemFields = Object.keys(dataItem)
        itemFields.forEach(field => {
          if (typeof dataItem[field] !== 'object') {
            filterableFields.push(field)
          }
        })
      }
      return filterableFields
    },
    filteredTableData () {
      const txt = new RegExp(this.filterText, 'i')

      let filteredData = this.tableData[this.dataPath].slice()

      filteredData = this.tableData[this.dataPath].filter((item) => {
        return this.dataModeFilterableFieldsComputed.some(field => {
          const val = item[field] + ''
          return val.search(txt) >= 0
        })
      })

      return {

        [this.dataPath]: filteredData,
      }
    },
    defaultPerPageComputed () {
      let defaultPerPage = this.itemsPerPage[0]

      if (this.$options.propsData.defaultPerPage) {
        defaultPerPage = this.$options.propsData.defaultPerPage
      }
      return defaultPerPage
    },
    paginationPathComputed () {
      return this.apiMode ? this.paginationPath : 'pagination'
    },
  },
  methods: {
    buildTableClass () {
      let name = 'va-data-table__vuetable va-table va-table--striped'

      if (this.clickable) {
        name += ' va-table--clickable'
      }

      if (this.hoverable) {
        name += ' va-table--hoverable'
      }

      return name
    },
    dataManager (sortOrder, pagination) {
      const data = this.filteredTableData[this.dataPath]
      const sortFunctions = this.sortFunctions

      if (sortOrder.length > 0) {
        data.sort(function (item1, item2) {
          const sortField = sortOrder[0].sortField
          const fn = sortFunctions[sortField]
          if (fn) {
            return fn(item1[sortField], item2[sortField])
          }
        })

        if (sortOrder[0].direction === 'desc') {
          data.reverse()
        }
      }
      pagination = this.$refs.vuetable.makePagination(data.length)
      return {
        pagination: pagination,
        [this.dataPath]: data.slice(pagination.from - 1, pagination.to),
      }
    },
    doFilter () {
      this.typingTimeout && clearTimeout(this.typingTimeout)
      this.typingTimeout = setTimeout(() => this.onFilterSet(this.filterText))
    },
    onFilterSet (filterText) {
      this.filterText = filterText
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onItemsPerPage (itemsPerPageValue) {
      this.perPage = itemsPerPageValue
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.noDataTemplate = ''
      this.loading = true
      this.$emit('vuestic:loading')
    },
    onLoaded () {
      this.noDataTemplate = this.$t('tables.noDataAvailable')
      if (this.apiMode) {
        this.setPerPageOptions(this.$refs.vuetable._data.tablePagination.total)
      } else {
        this.setPerPageOptions(this.tableData[this.dataPath].length)
      }
      this.loading = false
      this.$emit('vuestic:loaded')
    },
    setPerPageOptions (n) {
      let i = Math.min(parseInt(this.defaultPerPage), parseInt(this.perPage))
      this.itemsPerPage = []
      while (i < n) {
        Vue.set(this.itemsPerPage, this.itemsPerPage.length, i.toString())
        i *= 2
      }
      Vue.set(this.itemsPerPage, this.itemsPerPage.length, n.toString())
    },
    rowClicked (row) {
      this.$emit('row-clicked', row)
    },
  },
}
</script>

<style lang="scss">

.va-data-table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: auto;

  &__vuetable {
    width: 100%;

    th {
      &.sortable {
        &:hover {
          color: inherit !important;
        }
      }

      .sort-icon {
        font-size: pxtorem(24);
        margin-top: -(pxtorem(5));
        height: pxtorem(16);
        float: left;
        margin-right: 5px;
      }
    }

    .vuetable-empty-result {
      padding: 4.5rem 1rem;
      font-size: 1rem;
      color: $gray;
    }
  }

  &__pagination {
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    .va-button-group--normal {
      .btn-nav.va-button {
        color: hsl(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig));
        opacity: 1;
        background-color: transparent;
      }

      .va-button.va-button--outline.va-button--normal.va-button.active {
        color: rgb(255, 255, 255);
        border-color: hsl(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig));
        opacity: 1;
        background-color: hsl(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig));
      }
    }
  }
}
</style>
