<template>
  <div class="flex xs12">
    <va-card
      :style="computedStyle"
      class="va-card-table irrigation-card">
      <template>
        <div>
          <!-- Header -->
          <at-irrigation-map-header ref="map_header"
            :name="mapName"
            :scale="scale"
            :context="context"
            :data-point="dataPoint"
            @toogleShowSummaries="toogleShowSummaries"
            @searchTerm="onSearchTerm"
          />
          <!-- /Header -->

          <div class="irrigation">
            <!-- Room Group -->
            <at-irrigation-map-side-menu ref="side_menu"
              :uid="this._uid"
              :context="context"
              :data-point="dataPoint"
              :map-structure="mapSctructure"
              @toggleGroup="onToggleGroup"
              v-on="$listeners"
            />
            <!-- /Room Group -->
            <!-- Room Layers -->
            <template v-if="context === 'room' || context === 'area'">
              <at-irrigation-map-layers-room ref="layers_room"
                :uid="this._uid"
                :scale="scale"
                :irrigation-map-loading="irrigationMapLoading"
                :timeline-start-date="timelineStartDate"
                :timeline-end-date="timelineEndDate"
                :irrigation-map="irrigationMapConverted"
                :work-order-info="workOrderInfo"
                :show-summaries="showSummaries"
                :map-structure="mapSctructure"
                :editable="false"
                :context="context"
              />
              <template>
                <slot name="tabs" />
              </template>
            </template>
            <!-- /Room Layers -->
            <template v-if="context === 'line'">
              <at-irrigation-map-layers-line-events ref="layers_room" v-if="context === 'line' && dataPoint === 'day'"
                :uid="this._uid"
                :scale="eventsScale"
                :irrigation-map-loading="irrigationMapLoading"
                :timeline-start-date="timelineStartDate"
                :timeline-end-date="timelineEndDate"
                :irrigation-map="irrigationMapFiltered"
                :n-events="nEvents"
                :data-point="'events'"
                :show-summaries="showSummaries"
                :map-structure="mapSctructure"
                :editable="false"
                v-on="$listeners"
              >
                <template slot="currentAction">
                  <slot name="currentAction" />
                </template>
              </at-irrigation-map-layers-line-events>

              <at-irrigation-map-layers-line-quarter-hourly ref="layers_room" v-if="context === 'line' && dataPoint === '15min'"
                :uid="this._uid"
                :irrigation-map-loading="irrigationMapLoading"
                :timeline-start-date="timelineStartDate"
                :timeline-end-date="timelineEndDate"
                :irrigation-map="irrigationMapFiltered"
                :data-point="dataPoint"
                :show-summaries="showSummaries"
                :map-structure="mapSctructure"
                :editable="false"
              />
            </template>
          </div>
        </div>
      </template>
    </va-card>
  </div>
</template>

<script>
import moment from 'moment'
import { styleHelpers } from '@/services/atmosphere-ui'
import { changeToFactorytime } from '@/services/atmosphere-ui/helpers/TimeHelpers'
import { controlGroupStructure, eventViewMapStructure, quarterHourlyViewMapStructure } from '@/data/irrigationMapSettings'
import { getStatusbyLevel } from './styleProperties'
import AtIrrigationMapHeader from './AtIrrigationMapHeader.vue'
import AtIrrigationMapSideMenu from './AtIrrigationMapSideMenu.vue'
import AtIrrigationMapLayersRoom from './at-irrigation-map-room/AtIrrigationMapLayersRoom.vue'
import AtIrrigationMapLayersLineEvents from './at-irrigation-map-line/AtIrrigationMapLayersLineEvents.vue'
import AtIrrigationMapLayersLineQuarterHourly from './at-irrigation-map-line/AtIrrigationMapLayersLineQuarterHourly.vue'

export default {
  components: {
    AtIrrigationMapHeader,
    AtIrrigationMapSideMenu,
    AtIrrigationMapLayersRoom,
    AtIrrigationMapLayersLineEvents,
    AtIrrigationMapLayersLineQuarterHourly,
  },
  name: 'AtIrrigationMap',
  props: {
    mapName: {
      type: String,
    },
    irrigationMapLoading: {
      type: Boolean,
    },
    dataPoint: {
      type: String,
    },
    context: {
      type: String,
    },
    timelineStartDate: {
      type: String,
    },
    timelineEndDate: {
      type: String,
    },
    irrigationMap: {
      type: [Array, Object],
    },
    workOrderInfo: {
      type: Object,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    irrigationLineName: {
      type: String,
      default: 'Irrigation Line',
    },
    lineActionEventKey: {
      type: String,
    },
    lineActionProgress: {
      type: Object,
    },
    lineActionName: {
      type: String,
    },
    lineActionOrigin: {
      type: String,
    },
    lineActionDestination: {
      type: String,
    },
    lineActionControlAreaIds: {
      type: Array,
    },
    lineActionControlAreas: {
      type: Array,
    },
    controlGroups: {
      type: Array,
    },
    tanks: {
      type: Array,
    },
  },
  data () {
    return {
      scale: 50,
      eventsScale: 90,
      showOption: '',
      showSummaries: false,
      showAddNewModal: false,
      selectedData: {},
      deleteOption: false,
      duplicateOption: false,
      modalSubTitle: '',
      modalTitle: '',
      selectedDuplicateDate: {},
      termSearch: null,
      mapSctructure: [],
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.setMapSctructure()
      this.getContainerSizes()
      this.scrollToCurrentDay()
      window.addEventListener('resize', this.getContainerSizes)
    })
  },
  methods: {
    transformRoomMap (id) {
      const map = JSON.parse(JSON.stringify(this.irrigationMap.data[id].map))
      const controlGroupName = this.irrigationMap.data[id].name

      const groupedByFactoryDay = map.reduce((byDay, event) => ({
        ...byDay,
        [changeToFactorytime(event.start_time_stamp).format('YYYYMMDD')]: [...(byDay[changeToFactorytime(event.start_time_stamp).format('YYYYMMDD')]) || [], event],
      }), {})

      const groupedByFactoryDayArea = {}
      for (const day in groupedByFactoryDay) {
        const newGroupByArea = {}

        for (const event in groupedByFactoryDay[day]) {
          this.setIrrigationsStatus(groupedByFactoryDay[day][event])
          const destination = groupedByFactoryDay[day][event].destination.split(',') ? groupedByFactoryDay[day][event].destination.split(',') : [groupedByFactoryDay[day][event].destination]

          for (let i = 0; i < destination.length; i++) {
            const areaName = destination[i].split(`${controlGroupName} `)[1]

            const eventStartDate = moment(groupedByFactoryDay[day][event].start_time_stamp)
            eventStartDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

            const workOrderStartDate = moment(this.workOrderInfo[id].find(element => element.name.toLowerCase() === areaName.toLowerCase()).work_order_start_date)
            workOrderStartDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

            const workOrderEndDate = moment(this.workOrderInfo[id].find(element => element.name.toLowerCase() === areaName.toLowerCase()).work_order_end_date)
            workOrderEndDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

            if (eventStartDate.diff(workOrderStartDate) >= 0 && workOrderEndDate.diff(eventStartDate) >= 0) {
              if (newGroupByArea[areaName]) {
                newGroupByArea[areaName].push(groupedByFactoryDay[day][event])
              } else {
                newGroupByArea[areaName] = [groupedByFactoryDay[day][event]]
              }
            }
          }
        }
        groupedByFactoryDayArea[day] = newGroupByArea
      }

      return this.validateIrrigationFirstDay(id, groupedByFactoryDayArea)
    },
    validateIrrigationFirstDay (roomId, groupedByFactoryDayArea) {
      if (Object.keys(groupedByFactoryDayArea).length > 0) {
        for (const area in this.workOrderInfo[roomId]) {
          if (this.workOrderInfo[roomId][area].work_order_name !== 'Empty' || this.workOrderInfo[roomId][area].work_order_name !== 'Semina/taglio finto') {
            const controlAreaName = this.workOrderInfo[roomId][area].name
            const nextDay = moment(this.workOrderInfo[roomId][area].work_order_start_date).add(1, 'day').format('YYYYMMDD')
            const nextSeedingDayEvents = groupedByFactoryDayArea[nextDay]

            if (nextSeedingDayEvents !== undefined && nextSeedingDayEvents) {
              if (Object.keys(nextSeedingDayEvents).length === 0) {
                const missingIrrigation = {
                  action: 'missing_irrigation',
                  control_area_ids: [this.workOrderInfo[roomId][area].id],
                }

                groupedByFactoryDayArea[nextDay][controlAreaName] = [missingIrrigation]
              } else {
                if (!nextSeedingDayEvents[controlAreaName]) {
                  const missingIrrigation = {
                    action: 'missing_irrigation',
                    control_area_ids: [this.workOrderInfo[roomId][area].id],
                  }

                  groupedByFactoryDayArea[nextDay][controlAreaName] = [missingIrrigation]
                }
              }
            } else {
              const missingIrrigation = {
                action: 'missing_irrigation',
                control_area_ids: [this.workOrderInfo[roomId][area].id],
              }

              groupedByFactoryDayArea[nextDay] = {
                [controlAreaName]: [missingIrrigation],
              }
            }
          }
        }
      }

      return groupedByFactoryDayArea
    },
    transformAreaMap () {
      const controlGroupId = this.irrigationMap.control_group_id
      const controlAreaId = this.irrigationMap.control_area_id
      const controlAreaName = this.workOrderInfo[controlGroupId][0].name
      const map = JSON.parse(JSON.stringify(this.irrigationMap.data[controlAreaId].map))

      const groupedByFactoryDay = map.reduce((byDay, event) => ({
        ...byDay,
        [changeToFactorytime(event.start_time_stamp).format('YYYYMMDD')]: [...(byDay[changeToFactorytime(event.start_time_stamp).format('YYYYMMDD')]) || [], event],
      }), {})

      const groupedByFactoryDayArea = {}
      for (const day in groupedByFactoryDay) {
        const newGroupByArea = {}

        for (const event in groupedByFactoryDay[day]) {
          this.setIrrigationsStatus(groupedByFactoryDay[day][event])

          const eventStartDate = moment(groupedByFactoryDay[day][event].start_time_stamp)
          eventStartDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

          const workOrderStartDate = moment(this.workOrderInfo[controlGroupId][0].work_order_start_date)
          workOrderStartDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

          const workOrderEndDate = moment(this.workOrderInfo[controlGroupId][0].work_order_end_date)
          workOrderEndDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

          if (eventStartDate.diff(workOrderStartDate) >= 0 && workOrderEndDate.diff(eventStartDate) >= 0) {
            if (newGroupByArea[controlAreaName]) {
              newGroupByArea[controlAreaName].push(groupedByFactoryDay[day][event])
            } else {
              newGroupByArea[controlAreaName] = [groupedByFactoryDay[day][event]]
            }
          }
        }
        groupedByFactoryDayArea[day] = newGroupByArea
      }

      return groupedByFactoryDayArea
    },
    transformLineMapEvents () {
      const map = JSON.parse(JSON.stringify(this.irrigationMap))

      const groupedByFactoryDay = map.reduce((byDay, event, index) => {
        event.mapIndex = index
        return ({
          ...byDay,
          [changeToFactorytime(event.start_time_stamp).format('YYYYMMDD')]: [...(byDay[changeToFactorytime(event.start_time_stamp).format('YYYYMMDD')]) || [], event],
        })
      }, {})

      for (const day in groupedByFactoryDay) {
        groupedByFactoryDay[day].sort((a, b) => (a.start_time_stamp > b.start_time_stamp ? 1 : -1))
      }

      for (const day in groupedByFactoryDay) {
        for (const event in groupedByFactoryDay[day]) {
          this.setIrrigationsStatus(groupedByFactoryDay[day][event])
        }
      }

      return groupedByFactoryDay
    },
    transformLineMapTime () {
      const map = JSON.parse(JSON.stringify(this.irrigationMap))
      const groupedByFactoryDay = map.reduce((byDay, event) => ({
        ...byDay,
        [changeToFactorytime(event.start_time_stamp).format('YYYYMMDD')]: [...(byDay[changeToFactorytime(event.start_time_stamp).format('YYYYMMDD')]) || [], event],
      }), {})

      for (const day in groupedByFactoryDay) {
        const newGroupByAction = groupedByFactoryDay[day].reduce((byAction, event) => {
          this.setIrrigationsStatus(event)
          return ({
            ...byAction,
            [event.action.toLowerCase()]: [...(byAction[event.action.toLowerCase()]) || [], event],
          })
        }, {})

        groupedByFactoryDay[day] = newGroupByAction
      }

      return groupedByFactoryDay
    },
    setIrrigationsStatus (event) {
      const dataArray = ['volume', 'ph', 'electrical_conductivity', 'orp', 'volume_flow']
      // const currentDay = moment().format('YYYYMMDD')
      const currentDay = changeToFactorytime(moment()).format('YYYYMMDD')
      // const eventDay = moment(event.start_time_stamp).format('YYYYMMDD')
      const eventDay = changeToFactorytime(event.start_time_stamp).format('YYYYMMDD')
      let max = 0

      if (currentDay < eventDay) {
        event.status = 'scheduled'
      } else if (event.progress) {
        event.status = 'current'
      } else if (currentDay === eventDay && this.isFutureEvent(event)) {
        event.status = 'scheduled'
      } else if (Math.round(event.volume_flow.avg * 100) / 100 === 0.00) {
        event.status = 'critical'
      } else {
        max = 0
        for (let j = 0; j < dataArray.length; j++) {
          max = event[dataArray[j]].level > max ? event[dataArray[j]].level : max
        }
        event.status = getStatusbyLevel(max)
      }

      const statusDetails = {}
      for (let j = 0; j < (event.status === 'scheduled' ? (dataArray.length - 1) : (dataArray.length)); j++) {
        statusDetails[dataArray[j]] = event[dataArray[j]]
      }

      event.status_details = statusDetails
    },
    isFutureEvent (event) {
      if ('type' in event) {
        return event.type === 'future-event'
      } else {
        return event.key === 'undefined' || event.key === 'future-event'
      }
    },
    setMapSctructure () {
      this.mapSctructure = []

      if (this.irrigationMap) {
        if (this.context === 'room' && this.irrigationMap.data) {
          const numberRooms = Object.keys(this.irrigationMap.data).length
          for (const roomId in this.irrigationMap.data) {
            const room = {
              id: this.irrigationMap.data[roomId].id,
              name: this.irrigationMap.data[roomId].name,
              structure: controlGroupStructure,
              open: numberRooms === 1 || (numberRooms > 1 && this.irrigationMap.data[roomId].id === 1),
            }

            this.mapSctructure.push(room)
            this.transformRoomMap(roomId)
          }
        } else if (this.context === 'area' && this.irrigationMap.data) {
          const controlGroupId = Object.keys(this.workOrderInfo)[0]
          const controlAreaName = this.workOrderInfo[controlGroupId][0].name.split(' ')
          const side = controlAreaName[1]
          const layer = controlAreaName[0]
          const controlAreaSctructure = {
            fields: [
              {
                name: layer,
                subFields: [
                  {
                    id: this.irrigationMap.control_area_id,
                    name: side,
                  },
                ],
                events: [],
              },
            ],
          }

          this.mapSctructure = [{
            id: this.irrigationMap.control_group_id,
            name: this.irrigationMap.data[this.irrigationMap.control_area_id].name,
            structure: controlAreaSctructure,
            open: true,
          }]

          this.transformAreaMap()
        } else if (this.context === 'line') {
          if (this.dataPoint === '15min') {
            this.mapSctructure = quarterHourlyViewMapStructure
            this.transformLineMapTime()
          } else if (this.dataPoint === 'day') {
            this.mapSctructure = eventViewMapStructure
            this.transformLineMapEvents()
          }
        }
      }
    },
    getContainerSizes () {
      if (this.$refs.side_menu) {
        if (this.$refs.side_menu.$refs.irrigationGroupsContainer) {
          const irrigationContainerHeight = this.$refs.side_menu.$refs.irrigationGroupsContainer.clientHeight - 5 + 'px'
          const irrigationHarvestingHighlightPos = this.$refs.layers_room.containerWidth - 4
          const root = document.querySelector('.irrigation')
          root.style.setProperty('--irrigation-container-height', irrigationContainerHeight)
          root.style.setProperty('--irrigation-harvesting-highlight-pos', irrigationHarvestingHighlightPos + 'px')
        }
      }
    },
    scrollToCurrentDay () {
      let numberEvents = 0
      for (const day in this.irrigationMapFiltered) {
        if (moment().format('YYYYMMDD') > day) {
          numberEvents += this.irrigationMapFiltered[day].length
        }
      }
      const groupsDiv = document.getElementById('subgroups_' + this._uid)
      groupsDiv.scrollLeft = this.eventsScale * numberEvents
    },
    getOriginDestinationShortNames (value) {
      if (value.includes('Grow Room')) {
        const roomNumber = value.split('#')[1].split(' Side')[0]
        let controlAreasNames = ''
        const roomName = `Grow Room #${roomNumber}`
        const controlAreas = value.split(', ')

        for (let j = 0; j < controlAreas.length; j++) {
          const controlAreaNameSplit = controlAreas[j].split(`${roomName} `)[1].split(' ')
          const sideNumber = controlAreaNameSplit[1]
          const layerNumber = controlAreaNameSplit[3]

          controlAreasNames += `R${roomNumber}-S${sideNumber}-L${layerNumber}` + (j < (controlAreas.length - 1) ? ',' : '')
        }
        return controlAreasNames
      } else if (value.includes('Tank')) {
        return value.split(' Irrigation ')[1]
      }
      return value
    },
    toogleShowSummaries () {
      this.showSummaries = !this.showSummaries
    },
    onSearchTerm (value) {
      this.termSearch = value
    },
    onToggleGroup (value) {
      if (Object.keys(this.irrigationMapConverted).length > 1) {
        this.mapSctructure = this.mapSctructure.map(r => {
          if (r.id === value.id) {
            r.open = !r.open
          } else {
            r.open = false
          }
          return r
        })
      } else {
        this.mapSctructure = this.mapSctructure.map(r => {
          if (r.id === value.id) {
            r.open = !r.open
          }
          return r
        })
      }
      setTimeout(() => this.getContainerSizes(), 10) // toggle has no callback, so added a timeout to trigger 10ms after
    },
  },
  computed: {
    computedStyle () {
      const style = {
        borderRadius: styleHelpers.genRadius({ topLeft: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 }),
      }
      return style
    },
    nEvents () {
      const numberEvents = Object.keys(this.irrigationMapFiltered).reduce(
        (previous, key) => previous + this.irrigationMapFiltered[key].length,
        0)

      return this.irrigationMapFiltered ? numberEvents : 0
    },
    irrigationMapConverted () {
      if (!this.irrigationMapLoading) {
        if (this.context === 'room') {
          const roomMap = {}
          for (const roomId in this.irrigationMap.data) {
            roomMap[roomId] = this.transformRoomMap(roomId)
          }

          return roomMap
        } else if (this.context === 'area') {
          const controlGroupId = this.irrigationMap.control_group_id
          const areaMap = this.transformAreaMap()

          return { [controlGroupId]: areaMap }
        } else if (this.context === 'line') {
          if (this.dataPoint === '15min') {
            return this.transformLineMapTime()
          } else if (this.dataPoint === 'day') {
            return this.transformLineMapEvents()
          }
        }
      }

      return {}
    },
    irrigationMapFiltered () {
      let numberEvents = 0

      if (this.termSearch) {
        if (this.context === 'line') {
          if (this.dataPoint === 'day') {
            const map = JSON.parse(JSON.stringify(this.irrigationMapConverted))
            const filtered = {}

            for (const day in map) {
              if (moment().format('YYYYMMDD') > day) {
                numberEvents += map[day].length
              }
              filtered[day] = map[day].filter(event =>
                (this.getOriginDestinationShortNames(event.origin).toLowerCase()).includes(this.termSearch.toLowerCase()) || (this.getOriginDestinationShortNames(event.destination).toLowerCase()).includes(this.termSearch.toLowerCase()),
              )
            }

            const groupsDiv = document.getElementById('subgroups_' + this._uid)
            groupsDiv.scrollLeft = this.eventsScale * numberEvents

            return filtered
          } else if (this.dataPoint === '15min') {
            const map = JSON.parse(JSON.stringify(this.irrigationMapConverted))
            const filtered = {}

            for (const day in map) {
              for (const eventType in map[day]) {
                filtered[day][eventType] = map[day][eventType].filter(event =>
                  (this.getOriginDestinationShortNames(event.origin).toLowerCase()).includes(this.termSearch.toLowerCase()) || (this.getOriginDestinationShortNames(event.destination).toLowerCase()).includes(this.termSearch.toLowerCase()),
                )
              }
            }

            return filtered
          }
        }
      }

      return JSON.parse(JSON.stringify(this.irrigationMapConverted))
    },
  },
  watch: {
    'dataPoint' () {
      this.setMapSctructure()
    },
    'context' () {
      this.setMapSctructure()
    },
    'irrigationMap' () {
      this.setMapSctructure()
      this.scrollToCurrentDay()
    },
  },
}
</script>

<style lang="scss">
$timelineHeight: 70px;

$timeline-levels-width: 265px;
$groupHeight: 50px;
$doneColor: hsl(170, 65%, 45%);
$warningColor: hsl(30, 90%, 65%);
$dangerColor: hsl(0, 100%, 50%);
$scheduledColor: hsl(200, 85%, 60%);

$containerMaxHeight: 100%;

.irrigation {
  --irrigation-container-height: 100vh;
  --irrigation-harvesting-highlight-pos: 100vw;
  --before-top: '0';

  position: relative;
  height: 100%;
  background: hsl(var(--primary-color-hue), 50%, 95%);
  z-index: 0;

  &__groups,
  &__subgroups {
    display: inline-block;
    vertical-align: top;
    max-height: $containerMaxHeight;
  }

  &__subgroups {
    @include card-gradient();

    color: hsl(var(--primary-color-hue), 55%, 35%);
    width: calc(100% - #{$timeline-levels-width});
    overflow: auto;
    min-height: 12rem;

    &__group {
      height: $groupHeight;
    }

    /* Add border for all groups but not the first occurance */
    // & > .irrigation__subgroups__group ~ .irrigation__subgroups__group {
    //   border-top: 2px solid hsl(var(--primary-color-hue), 20%, 83%);
    // }

    &__subgroup {
      position: relative;
      height: $groupHeight;
      background: #ffffff;
      padding-left: 150px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid hsl(var(--primary-color-hue), 40%, 88%);

      &.first {
        background: hsla(var(--primary-color-hue), 25%, 70%, 10%);
      }

      .cycle-info {
        // width: 50px;
        height: $groupHeight;
        position: absolute;
        z-index: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-left: 1px solid hsl(var(--primary-color-hue), 40%, 88%);

        &:last-of-type {
          border-right: 1px solid hsl(var(--primary-color-hue), 40%, 88%);
        }

        &__summary {
          @include radius(10px);

          display: flex;
          align-items: center;
          width: max-content;
          z-index: -1;
          background: rgba(0, 0, 0, 0.75);
          position: absolute;
          left: 50px;
          opacity: 0;
          transition: opacity 0.5s;

          &.multiple-events__summary {
            left: -100px;
          }

          &::before {
            content: '';
            position: absolute;
            top: var(--before-top);
            left: -12px;
            width: 0;
            height: 0;
            border-width: 6px;
            border-color: transparent rgba(0, 0, 0, 0.75) transparent transparent;
            border-style: solid;
          }

          &__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px;
            height: max-content;
            min-height: 3.8rem;
            overflow: hidden;
            background-color: transparent;

            .single-events,
            .multiple-events {
              display: inline-block;
              padding-right: 1.5rem;
              padding-left: 0.5rem;
              padding-top: 0.2rem;
              align-self: start;
              min-width: 9rem;
            }

            .single-event {
              &__icon {
                margin-left: -0.2rem;
              }

              span {
                white-space: pre;
                color: #ffffff;
                font-size: pxtorem(12);
                justify-content: left;
              }

              &:last-of-type {
                padding-right: 0.2rem;
              }
            }

            .multiple-events {
              span {
                white-space: pre;
                color: #ffffff;
                font-size: pxtorem(12);
                justify-content: left;
                background: transparent;
              }

              &:last-of-type {
                padding-right: 0.2rem;
              }

              &__icon {
                margin-left: -0.2rem;

                &__status-details {
                  margin-left: 0;
                  font-size: 0.75rem;
                  color: #ffffff;
                  padding-top: 0.1rem;

                  i {
                    padding-right: 0.1rem;
                  }

                  &.done {
                    color: hsl(0, 0%, 100%);
                  }

                  &.warning {
                    color: hsl(39, 98%, 65%);
                  }

                  &.danger,
                  &.critical {
                    color: hsl(0, 100%, 56%);
                  }

                  &.scheduled {
                    color: hsl(0, 0%, 100%);
                  }
                }
              }
            }

            span {
              white-space: pre;
              color: #ffffff;
              font-size: pxtorem(12);
            }
          }
        }

        &:hover,
        &.show {
          .cycle-info__summary {
            opacity: 1;
            z-index: 2;
          }
        }

        button,
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }

        button {
          opacity: 0;
          font-size: pxtorem(24);
          color: hsl(200, 39%, 88%);
          border: none;
          background: none;
          outline: none;
          border-radius: 0;
          transition: opacity 0.3s;

          i {
            font-size: pxtorem(28);
          }

          &:hover {
            opacity: 1;
          }

          &.done,
          &.warning,
          &.danger,
          &.critical,
          &.scheduled {
            opacity: 0.9;
            color: #ffffff;
            transition: opacity 0.3s;

            i {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              width: 24px;
              height: 24px;
              font-size: pxtorem(14);
            }

            &:hover {
              opacity: 1;
            }
          }

          &.done {
            i {
              background: $doneColor;
            }
          }

          &.warning {
            i {
              background: $warningColor;
            }
          }

          &.danger,
          &.critical {
            i {
              background: $dangerColor;
            }
          }

          &.scheduled {
            i {
              background: $scheduledColor;
            }
          }
        }

        div {
          &.seeding_irrigation,
          &.seeding,
          &.harvesting,
          &.harvesting_irrigation {
            i {
              font-size: 2rem;
            }

            .number-events {
              position: absolute;
              font-size: pxtorem(13);
              width: 90%;
              padding: 0 0.2rem;
              top: 1px;
              text-align: right;
              font-weight: 600;
            }
          }

          &.seeding,
          &.seeding_irrigation {
            border-left: 3px solid hsl(170, 63%, 30%);
            padding: 0 1px;
            color: white;
            background: hsl(170, 65%, 45%);
            text-align: center;

            &.rucola {
              color: white;
              background: rgb(82, 128, 168);
            }

            &.lattughino {
              color: white;
              background: rgb(161, 181, 156);
            }

            &.mix-delicato {
              color: white;
              background: rgb(230, 195, 138);
            }

            &.basilicooh {
              color: white;
              background: rgb(37, 164, 180);
            }

            &.mix-oriental {
              color: white;
              background: rgb(246, 155, 106);
            }

            &.mix-piccante {
              color: white;
              background: rgb(236, 150, 157);
            }
          }

          &.harvesting,
          &.harvesting_irrigation {
            color: white;
            background: hsl(200, 85%, 60%);
            border-right: 3px solid hsl(200, 80%, 30%);
            padding: 0 1px;
            text-align: center;
          }

          &.missing_irrigation {
            font-size: 2rem;
            color: hsl(270, 100%, 50%);
          }
        }
      }
    }
  }

  .va-count-badge {
    padding-top: 6px;
  }

  .va-count-badge__round {
    width: 1.2rem !important;
    height: 1.2rem !important;
    top: 4px !important;
    right: -3px !important;
    border-color: white;
    outline-style: solid;
    border-width: 8px;
    font-size: 0.6rem;
  }
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Chrome/Safari/Webkit */
  }
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 15px !important;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 0;
}

.va-modal {
  min-width: 550px;
}

.map-loading {
  display: inline-block;
  vertical-align: top;
  max-height: $containerMaxHeight;
  width: calc(100% - #{$timeline-levels-width});
  overflow: auto;
  min-height: 12rem;
}

</style>
