export const getStatusbyLevel = (level) => {
  let status = 'done'

  switch (level) {
    case -1:
      status = 'scheduled'
      break
    case 0:
      status = 'done'
      break
    case 1:
      status = 'warning'
      break
    case 2:
    case 3:
      status = 'danger'
      break
    default:
      break
  }

  return status
}

export const getColor = (status) => {
  let color = 'hsl(170, 65%, 45%)'

  switch (status) {
    case 'done':
      color = 'hsl(170, 65%, 45%)'
      break

    case 'warning':
      color = 'hsl(30, 90%, 65%)'
      break

    case 'danger':
    case 'critical':
      color = 'hsl(0, 100%, 50%)'
      break

    case 'scheduled':
      color = 'hsl(200, 85%, 60%)'
      break

    default:
      break
  }

  return color
}

export const getIconbyLevel = (level) => {
  let icon = ''

  switch (level) {
    case 0:
      icon = ''
      break
    case 1:
      icon = 'at-icon b_alert4'
      break
    case 2:
    case 3:
      icon = 'at-icon b_alert3'
      break
    default:
      break
  }

  return icon
}

export const getEventIcon = (action) => {
  let icon = 'at-icon'

  switch (action.toLowerCase()) {
    case 'drop_empty_bench':
    case 'seed':
    case 'seeding':
      icon += ' l_layer_state_sowing'
      break
    case 'release_empty_bench':
    case 'harvest_seed':
      icon += ' l_cuttig_seeding'
      break
    case 'empty_benches':
      icon += ' l_empty_bench'
      break

    case 'seeding_irrigation':
      icon += ' l_layer_state_sowing'
      // icon += ' l_sowing_water1'
      break

    case 'harvest':
    case 'harvesting':
      icon += ' l_cut'
      break

    case 'harvesting_irrigation':
      icon += ' l_cut1_water'
      break

    case 'missing_irrigation':
      icon += ' b_alert'
      break

    case 'irrigation':
    case 'irrigations':
      icon += ' b_water1drop'
      break

    case 'recalibration':
    case 'recalibrations':
      icon += ' b_settingbuttons'
      break

    case 'circulation':
    case 'circulations':
      icon += ' b_reload'
      break

    case 'fill':
      icon += ' b_water_volume2'
      break

    default:
      break
  }

  return icon
}

export const getGroupIcon = (groupName) => {
  let icon = ''

  switch (groupName) {
    case 'irrigations':
    case 'irrigation':
    case 'events':
    case 'event':
      icon = 'at-icon b_water1drop'
      break

    case 'recalibrations':
    case 'recalibration':
      icon = 'at-icon b_settingbuttons'
      break

    case 'circulations':
    case 'circulation':
      icon = 'at-icon b_reload'
      break

    case 'fill':
      icon = 'at-icon b_water_volume2'
      break

    default:
      break
  }

  return icon
}

export const getUnits = (data) => {
  let unit = ''

  switch (data) {
    case 'volume':
      unit = 'm³'
      break
    case 'electrical_conductivity':
      unit = 'mS/cm'
      break
    case 'orp':
      unit = 'mV'
      break
    case 'volume_flow':
      unit = 'm³/h'
      break
    default:
      break
  }

  return unit
}

export const getCurrentCellBackground = () => {
  return 'hsla(170, 64%, 45%, 15%)'
}

export const getCurrentCellShadow = () => {
  return ''
}

export const getCurrentCellStyle = () => {
  return 'hsla(var(--primary-color-hue), 25%, 70%, 30%)'
}

export const getWeekendCellStyle = () => {
  return 'hsla(var(--primary-color-hue), 44%, 95%, 30%)' // adjust
}

export const getSeparationCellStyle = () => {
  return '1px solid hsl(var(--primary-color-hue), 55%, 30%)'
}

export const getGridCellStyle = () => {
  return '1px solid hsl(var(--primary-color-hue), 40%, 88%)'
}
