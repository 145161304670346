// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import { ColorThemePlugin } from '../services/vuestic-ui'
import store from '../store/index'
import router from '../router/index'
import { VuesticPlugin } from '@/services/atmosphere-ui/components/components' // import only selected vuestic-ui components
import '../i18n/index'
import VueClipboard from 'vue-clipboard2'
import {
  AtmosphereColorThemes,
  styleHelpers,
} from '@/services/atmosphere-ui'
import { StatusThemePlugin } from '@/services/atmosphere-ui/plugins/StatusThemePlugin'
import { ViewLevelPlugin } from '@/services/atmosphere-ui/plugins/ViewLevelPlugin'
import { ColorModePlugin } from '@/services/atmosphere-ui/plugins/ColorModePlugin'
import VueFlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import '../metrics'
import '../registerServiceWorker'
import { consoleBuildInfo } from 'vue-cli-plugin-build-info/plugin'
import { firstValidRole, getDefaultRoleHomePage, validRoles } from '../store/modules/auth/utility'
import Keycloak from 'keycloak-js'
import axios from 'axios'
import { structuredApiUrl } from '../utilities/socketio'
// import { isEmpty } from 'lodash'

if (process.env.VUE_APP_BUILD_VERSION) {
  // eslint-disable-next-line
  const message = `%c${'Build_information:'}\n %c${'Version'}: %c${VERSION},\n %c${'Timestamp'}: %c${TIMESTAMP},\n %c${'Commit'}: %c${COMMIT}`
  // eslint-disable-next-line
  console.info(
    message,
    'color: blue;', 'color: red;', 'color: blue;', 'color: red;', 'color: blue;', 'color: red;', 'color: blue;',
  )
}

consoleBuildInfo()

Vue.use(VuesticPlugin)
Vue.use(VueClipboard)

Vue.use(ColorThemePlugin, AtmosphereColorThemes)
Vue.use(StatusThemePlugin)
Vue.use(ViewLevelPlugin)
Vue.use(ColorModePlugin)
Vue.use(VueFlatPickr)

// var u = new URL(window.location.href)

const initOptions = {
  url: process.env.VUE_APP_AUTH_URL || 'https://auth.ctrl.gaiavf.tech/',
  realm: process.env.VUE_APP_AUTH_REALM || 'identita-golose',
  clientId: process.env.VUE_APP_AUTH_CLIENT || 'dashboard',
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: window.location.href,
  checkLoginIframe: false,
}

const keycloak = new Keycloak(initOptions)
Vue.prototype.$keycloak = keycloak

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta

  if (to.meta.requiresAuth && !keycloak.authenticated) {
    keycloak.login()
  } else if (authorize && !authorize.some(r => store.getters.get_user_roles.includes(r))) {
    // at least one assigned role inside all roles required
    // role not authorised so redirect to home page
    // console.log('role not authorised so redirect to home page')
    next(getDefaultRoleHomePage(store.getters.get_user_role))
  } else if (Vue.prototype.$justlogged) {
    // console.log('just logged')
    Vue.prototype.$justlogged = false
    if (to.name === 'growing') {
      // console.log('to home page')
      next(getDefaultRoleHomePage(localStorage.getItem('role')))
    } else {
      next()
    }
  } else {
    next()
  }
})

Vue.prototype.$farmId = parseInt(process.env.VUE_APP_FARM_ID)

keycloak.init({ onLoad: initOptions.onLoad }).then(() => {
  axios.get(`${structuredApiUrl}/farms/${parseInt(process.env.VUE_APP_FARM_ID)}`)
    .then(response => {
      Vue.prototype.$farmTimezone = response.data.data.timezone
      Vue.prototype.$farmId = response.data.data.id
    })
    .catch(function (error) {
      console.log(error)
      Vue.prototype.$farmTimezone = 0
      Vue.prototype.$farmId = parseInt(process.env.VUE_APP_FARM_ID)
    })

  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    watch: {
      /**
       * Update CSS Custom Properties on Primary color change
       * @param val String with selected color value
       */
      '$themes.primary' (val) {
        const root = document.querySelector(':root')
        root.style.setProperty('--primary-color-hue', styleHelpers.hex2hsl(val).h)
        root.style.setProperty('--primary-color-sat', styleHelpers.hex2hsl(val).s + '%')
        root.style.setProperty('--primary-color-lig', styleHelpers.hex2hsl(val).l + '%')
        root.style.setProperty('--primary-color', 'hsl(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig)')
      },
      /**
       * Update CSS Custom Properties on View Level change
       * @param val String with selected color value
       * @param old String with old color value
       */
      '$level.value' (val, old) {
        const hue = styleHelpers.hex2hsl(this.$themes.primary).h
        const sat = styleHelpers.hex2hsl(this.$themes.primary).s
        const lig = styleHelpers.hex2hsl(this.$themes.primary).l
        const viewLevelHue = hue + styleHelpers.getViewLevelHueRange(val, old)
        this.$themes.primary = styleHelpers.hslToHex(viewLevelHue, sat, lig) // this will trigger the watch event and update the CSS Variables

        const root = document.querySelector(':root')
        root.style.setProperty('--shadow-radius', styleHelpers.getViewLevelShadowRadius(val))
        root.style.setProperty('--shape-radius', styleHelpers.getViewLevelShapeRadius(val))
      },
      /**
       * Update CSS Custom Properties on Color Mode change
       * @param val String with selected color mode (day/night)
       */
      '$mode.value' (val) {
        if (val === 'day') {
          document.body.classList.remove('night')
        } else {
          document.body.classList.add('night')
        }
      },
    },
  })

  // Token Refresh
}).catch(() => {
  console.log('Authentication init failed')
  keycloak.logout()
  // window.location.reload()
})

keycloak.onAuthSuccess = function () {
  // console.log(keycloak)
  // console.log('logged in')
  Vue.prototype.$justlogged = true
  localStorage.setItem('token', keycloak.token)
  localStorage.setItem('user', keycloak.tokenParsed.email)
  localStorage.setItem('preferred_username', keycloak.tokenParsed.preferred_username)
  if ('realm_access' in keycloak.tokenParsed) {
    const validrole = firstValidRole(keycloak.tokenParsed.realm_access.roles)
    const validroles = validRoles(keycloak.tokenParsed.realm_access.roles)
    // console.log('validrole ' + validrole)
    localStorage.setItem('role', validrole)
    localStorage.setItem('roles', validroles)
    localStorage.setItem('needs_sidebar', validrole)
  }
  setInterval(() => {
    // console.log('refreshing token...')
    keycloak.updateToken(70).then((refreshed) => {
      // console.log('token refreshed')
    }).catch(() => {
      // console.log('Failed to refresh token')
      keycloak.logout()
    })
  }, 60000)
}
