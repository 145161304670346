function getSidebarVisible (role) {
  if (role === 'SOWING OPERATOR') {
    return false
  }
  if (role === 'CUTTING OPERATOR') {
    return false
  }
  if (role === 'INBOUND OPERATOR') {
    return false
  }
  if (role === 'AGRONOMIST') {
    return true
  }
  return true
}

export default {
  setToken (state, payload) {
    state.token = payload.token
    state.role = payload.token === null ? null : JSON.parse(atob(payload.token.split('.')[1])).role
    state.user = payload.token === null ? null : JSON.parse(atob(payload.token.split('.')[1])).sub
    state.preferred_username = payload.token === null ? null : JSON.parse(atob(payload.token.split('.')[1])).preferred_username
    state.needs_sidebar = payload.token === null ? null : getSidebarVisible(state.role)
    // console.log('token: ' + state.token)
    // console.log('role: ' + state.role)
    // console.log('user: ' + state.user)
    // console.log('needs_sidebar: ' + state.needs_sidebar)
    // if (state.role === 'AGRONOMIST') {
    //   state.needs_sidebar = false
    // }
  },
}
