const Role = {
  ADMIN: 'ADMIN',
  AGRONOMIST: 'AGRONOMIST',
  CARTON_ATTENDANT: 'CARTON ATTENDANT',
  CARTON_OPERATOR: 'CARTON OPERATOR',
  CUTTING_OPERATOR: 'CUTTING OPERATOR',
  FACTORY_MANAGER: 'FACTORY MANAGER',
  INBOUND_OPERATOR: 'INBOUND OPERATOR',
  MAINTAINER: 'MAINTAINER',
  PACKING_OPERATOR: 'PACKING OPERATOR',
  PICKING_OPERATOR: 'PICKING OPERATOR',
  PRODUCTION_MANAGER: 'PRODUCTION MANAGER',
  PRODUCTION_OPERATOR: 'PRODUCTIONOPERATOR',
  QC_EMPLOYEE: 'QC EMPLOYEE',
  SHIPPING_OPERATOR: 'SHIPPING OPERATOR',
  SOWING_OPERATOR: 'SOWING OPERATOR',
  WAREHOUSE_OPERATOR: 'WAREHOUSE OPERATOR',
}

export { Role }
