<template>
  <div :id="'groups_' + uid" class="irrigation__groups disable-scrollbars" ref="irrigationGroupsContainer">
    <div class="irrigation__groups__placeholder-top"></div>
    <div
      class="irrigation__groups__group"
      v-for="room in mapStructure"
      :key="room.id"
    >
      <div :class="{ close: !room.open }">
        <div
          :ref="'irrigation_' + room.id"
          class="irrigation__groups__group__name"
          @click="onToggleGroup(room.id, room.open)"
          :style="{cursor: dataPoint !== 'events' ? 'pointer' : null}"
        >
          <div class="name-content">
            <va-icon :name="getGroupIcon(room.name.toLowerCase())"/>
            <div style="width: 100%;">
              <template v-if="context === 'planner-overview' && (room.name === 'total_benches' || room.name === 'total_empty_benches' || room.name === 'total_benches_with_plants' || room.name === 'occupation')">
                <h5> {{ $t(`productionSimulation.${room.name}`)}} </h5>
              </template>
              <h5 v-else>{{ room.name }}</h5>
            </div>
          </div>

          <va-icon v-if="dataPoint !== 'events'"  id="arrow-icon" name="at-icon b_arrow1-up"></va-icon>
        </div>

        <div v-if="room.open">
          <template v-for="field in room.structure.fields">
            <template v-if="field.name !== 'icon'">
              <div
                v-if="field.subFields.length === 0"
                class="irrigation__groups__group__subgroup"
                :key="field.name"
                :style="groupStyle(field.subFields.length)"
              >
                <!-- <h6>{{ field.name.length > 15 ? field.displayName : field.name }}</h6> -->
                <template v-if="context === 'line'">
                  {{ $t(`irrigationMapSideMenu.${field.name}`) }}
                </template>

                <template v-else-if="context === 'planner-overview' && field.link">
                  <router-link :to="getSimulationDetailsLink(field.link)">
                    {{ field.name }}
                  </router-link>
                </template>

                <template v-else>
                  <h6>{{ field.name }}</h6>
                </template>
              </div>

              <div v-else
                class="irrigation__groups__group__subgroup"
                :key="field.name"
                :style="groupStyle(field.subFields.length)"
              >
                <div class="subgroup_info">
                  <div class="subgroup_info__subgroup">
                    <template v-if="context === 'planner-overview' && field.link">
                      <router-link :to="getSimulationDetailsLink(field.link)">
                        {{ field.name }}
                      </router-link>
                    </template>

                    <template v-else>
                      <h6>{{ field.name }}</h6>
                    </template>
                  </div>

                  <div class="subgroup_info__sides" :class="field.subFields.length <= 1 ? 'single' : ''">
                    <div
                      v-for="subField in field.subFields"
                      :key="subField.id"
                      :style="sideStyle(field.subFields.length)"
                    >
                      <span v-if="subField.name.includes('<br>')" v-html="subField.name"></span>
                      <span v-else>{{ subField.name}}</span>
                    </div>
                  </div>
                </div>

              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGroupIcon } from './styleProperties'

export default {
  components: {},
  name: 'AtIrrigationMapSideMenu',
  props: {
    uid: {
      type: Number,
    },
    dataPoint: {
      type: String,
      default: 'events',
    },
    mapStructure: {
      type: Array,
    },
    context: {
      type: String,
    },
    id: {
      type: [String, Number],
    },
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    getGroupIcon,
    onToggleGroup (id, closed) {
      if (this.dataPoint !== 'events') {
        const groupId = 'irrigation_' + id
        const element = this.$refs[groupId][0]
        element.classList.toggle('close')

        this.$emit('toggleGroup', { id: id, closed: closed })
      }
    },
    groupStyle (nSubFields) {
      const style = {
        height: `calc(50px * ${nSubFields === 0 ? 1 : nSubFields})`,
      }
      return style
    },
    sideStyle (nSubFields) {
      nSubFields = nSubFields === 0 ? 1 : nSubFields
      const style = {
        height: `${1 / nSubFields * 100}%`,
      }
      return style
    },
    getSimulationDetailsLink (link) {
      link.params.simulationId = this.id
      return link
    },
  },
}
</script>

<style lang="scss">
$timelineHeight: 70px;
$timeline-levels-width: 265px;
$groupHeight: 50px;

.irrigation__groups {
  position: relative;
  width: $timeline-levels-width;
  background: hsl(var(--primary-color-hue), 50%, 95%);
  border-right: 1px solid hsla(var(--primary-color-hue), 20%, 83%, 0.5);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 50;
  padding-bottom: 15px;

  &__placeholder-top {
    z-index: 5;
    height: $timelineHeight;
    width: $timeline-levels-width;
    background: hsl(var(--primary-color-hue), 50%, 95%);
    position: sticky;
    top: 0;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: -2px;
      left: 0;
      right: 0;
    }
  }

  &__group {
    position: relative;

    &__name {
      padding: 15px 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: $groupHeight;
      background: hsla(var(--primary-color-hue), 25%, 70%, 0.1); // adjust
      border-top: 1px solid hsl(var(--primary-color-hue), 35%, 80%); // adjust
      border-bottom: 1px solid hsl(var(--primary-color-hue), 35%, 80%); // adjust

      .name-content {
        display: contents;

        h5 {
          display: inline-block;
          font-size: pxtorem(16);
          text-transform: uppercase;
          font-weight: 700;
          color: hsl(var(--primary-color-hue), 55%, 35%); // adjust
        }

        i {
          padding-right: 0.3rem;
        }
      }

      i {
        font-size: pxtorem(24);
      }

      &.close {
        #arrow-icon {
          transform: rotate(180deg);
        }
      }
    }

    &__subgroup {
      width: 100%;
      margin-left: 25px;
      // padding-left: 25px;
      background: hsl(var(--primary-color-hue), 50%, 95%);
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-of-type) {
        border-bottom: 1px solid hsl(var(--primary-color-hue), 35%, 80%);
      }

      &:last-of-type {
        padding-bottom: 1px;
      }

      h6 {
        font-size: pxtorem(16);
        color: hsl(var(--primary-color-hue), 55%, 35%);
      }

      .subgroup_info {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        &__subgroup {
          font-size: pxtorem(13);
          font-weight: 600;
          width: 50%;
        }

        &__sides {
          width: 100%;
          height: 100%;

          div {
            display: flex;
            align-items: center;
            padding-left: 20px;
            font-size: pxtorem(12);

            &:last-of-type {
              border-top: 1px solid hsl(var(--primary-color-hue), 35%, 80%);
            }
          }
        }
      }
    }

    /* hack to add a bottom border without screwing with the child elements */
    &:last-of-type::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid hsl(var(--primary-color-hue), 35%, 80%);
    }
  }
}
</style>
