<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 497 300"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;"
  >
    <g>
      <path
        d="M372.679,0l-300,300l123.626,0l300,-300l-123.626,0Z"
        style="fill: url(#_Linear1);"
      />
      <path
        d="M300,0l-300,300l123.627,0l300,-300l-123.627,0Z"
        style="fill: #fad546;"
      />
      <clipPath id="_clip2">
        <path d="M300,0l-300,300l123.627,0l300,-300l-123.627,0Z" />
      </clipPath>
      <g clip-path="url(#_clip2)">
        <path
          d="M434.493,-16.136l-376.477,376.477l2.635,2.635l376.477,-376.477l-2.635,-2.635Z"
          style="fill: #ffbf14;"
        />
        <path
          d="M375.315,-75.315l-376.477,376.477l2.634,2.635l376.478,-376.477l-2.635,-2.635Z"
          style="fill: #fae288;"
        />
        <g>
          <path
            d="M331.58,-31.58l-12.864,12.864l25.666,97.961l12.864,-12.864l-25.666,-97.961Z"
            style="fill-opacity: 0.8;"
          />
          <path
            d="M359.796,-59.796l-12.864,12.864l25.666,97.961l12.864,-12.864l-25.666,-97.961Z"
            style="fill-opacity: 0.8;"
          />
          <path
            d="M386.968,-86.968l-12.864,12.864l25.666,97.96l12.864,-12.864l-25.666,-97.96Z"
            style="fill-opacity: 0.8;"
          />
          <path
            d="M303.363,-3.363l-12.864,12.864l25.666,97.961l12.864,-12.864l-25.666,-97.961Z"
            style="fill-opacity: 0.8;"
          />
        </g>
        <g>
          <path
            d="M41.081,258.919l-12.865,12.865l25.666,97.96l12.865,-12.864l-25.666,-97.961Z"
            style="fill-opacity: 0.8;"
          />
          <path
            d="M69.297,230.703l-12.864,12.864l25.666,97.961l12.864,-12.864l-25.666,-97.961Z"
            style="fill-opacity: 0.8;"
          />
          <path
            d="M96.469,203.531l-12.864,12.864l25.666,97.961l12.864,-12.865l-25.666,-97.96Z"
            style="fill-opacity: 0.8;"
          />
          <path
            d="M12.864,287.136l-12.864,12.864l25.666,97.961l12.864,-12.864l-25.666,-97.961Z"
            style="fill-opacity: 0.8;"
          />
        </g>
      </g>
      <g
        transform="matrix(0.545717,-0.545717,0.707107,0.707107,-202.643,80.7395)"
      >
        <text
          x="147.511px"
          y="359.379px"
          style="
            font-family: 'Montserrat-Black', 'Montserrat';
            font-weight: 900;
            font-size: 46.17px;
            fill: #332b0e;"
        >
          T
          <tspan
            x="184.632px 222.63px 259.427px 296.547px 317.324px 354.583px 377.668px 414.788px "
            y="359.379px 359.379px 359.379px 359.379px 359.379px 359.379px 359.379px 359.379px "
          >
            EST SITE
          </tspan>
        </text>
      </g>
    </g>
    <defs>
      <linearGradient
        id="_Linear1"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(29.7515,29.7515,-29.7515,29.7515,261.02,168.465)"
      >
        <stop offset="0" style="stop-color: #000000; stop-opacity: 0.11;" />
        <stop offset="1" style="stop-color: #000000; stop-opacity: 0;" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'TestSiteBanner',
}
</script>

<style lang="scss" scoped>
</style>
