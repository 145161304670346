export const controlGroupStructure = {
  fields: [
    {
      name: 'Bench 1',
      subFields: [],
      events: [],
    },
    {
      name: 'Bench 2',
      subFields: [],
      events: [],
    },
    {
      name: 'Bench 3',
      subFields: [],
      events: [],
    },
  ],
}

const eventStructure = [
  {
    id: 1,
    name: 'events',
    structure: {
      fields: [
        {
          id: 1,
          name: 'destination',
          displayName: 'Destination',
          subFields: [],
        },
        {
          id: 2,
          name: 'origin',
          displayName: 'Origin',
          subFields: [],
        },
        {
          id: 3,
          name: 'start',
          displayName: 'Start',
          subFields: [],
        },
        {
          id: 4,
          name: 'duration',
          displayName: 'Duration (HH:mm:ss)',
          subFields: [],
        },
        {
          id: 5,
          name: 'flow',
          displayName: 'Flow (m³/h)',
          subFields: [],
        },
        {
          id: 6,
          name: 'volume',
          displayName: 'Volume (m³)',
          subFields: [],
        },
        {
          id: 7,
          name: 'ph',
          displayName: 'pH',
          subFields: [],
        },
        {
          id: 8,
          name: 'ec',
          displayName: 'EC (mS/cm)',
          subFields: [],
        },
        {
          id: 9,
          name: 'orp',
          displayName: 'ORP (mV)',
          subFields: [],
        },
      ],
    },
    open: true,
  },
]

const eventsTypesQuarterHourly = [
  {
    id: 1,
    name: 'irrigations',
    structure: {
      fields: [
        {
          id: 0,
          name: 'icon',
          subFields: [],
        },
        ...eventStructure[0].structure.fields,
      ],
    },
    open: true,
  },
  {
    id: 2,
    name: 'recalibrations',
    structure: {
      fields: [
        {
          id: 0,
          name: 'icon',
          subFields: [],
        },
        ...eventStructure[0].structure.fields,
      ],
    },
    open: true,
  },
  {
    id: 3,
    name: 'fill',
    structure: {
      fields: [
        {
          id: 0,
          name: 'icon',
          subFields: [],
        },
        ...eventStructure[0].structure.fields,
      ],
    },
    open: true,
  },
  {
    id: 4,
    name: 'circulations',
    structure: {
      fields: [
        {
          id: 0,
          name: 'icon',
          subFields: [],
        },
        ...eventStructure[0].structure.fields,
      ],
    },
    open: true,
  },
]

export const eventViewMapStructure = [
  ...eventStructure,
]

export const quarterHourlyViewMapStructure = [
  ...eventsTypesQuarterHourly,
]
