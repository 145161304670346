<template>
  <li
    class="app-sidebar-link-group"
    :class="computedClass"
  >
    <div v-if="!minimized">
      <div @click.stop.prevent="toggleMenuItem()">
        <app-sidebar-link
          :icon="icon"
          :iconRight="`at-icon l_arrow1-${expanded ? 'up' : 'down'}`"
          :title="title"
        />
      </div>

      <transition-expand>
        <ul
          class="app-sidebar-link-group__submenu"
          v-show="expanded"
          ref="linkGroupWrapper"
        >
          <slot/>
        </ul>
      </transition-expand>
    </div>

    <va-dropdown
      v-if="minimized"
      position="right"
      fixed
      :preventOverflow="false"
      ref="dropdown"
    >
      <div slot="anchor">
        <app-sidebar-link
          :icon="icon"
          iconRight="at-icon l_horizontalpoints"
          iconRightContent=""
          :activeByDefault="isActive"
          minimized
        />
      </div>
      <ul
        class="app-sidebar-link-group__submenu"
        :style="computedSubmenuColor"
      >
        <slot/>
      </ul>
    </va-dropdown>
  </li>
</template>

<script>
import TransitionExpand from './TransitionExpand'
import { colorShiftHsl, ColorThemeMixin } from '../../../../services/vuestic-ui'
import AppSidebarLink from './AppSidebarLink'

export default {
  name: 'app-sidebar-link-group',
  mixins: [ColorThemeMixin],
  inject: ['contextConfig'],
  props: {
    icon: [String, Array],
    title: String,
    minimized: Boolean,
    activeByDefault: Boolean,
    children: Array,
    color: {
      type: String,
      default: 'secondary',
    },
  },
  components: {
    TransitionExpand,
    AppSidebarLink,
  },
  data () {
    return {
      isActive: this.activeByDefault,
      isHovered: false,
      expanded: this.activeByDefault,
    }
  },
  watch: {
    $route () {
      this.$refs.dropdown && this.$refs.dropdown.hide()
      this.updateActiveState()
    },
    minimized (value) {
      if (!value) {
        this.isActive = false
      } else {
        this.updateActiveState()
      }
    },
  },
  methods: {
    toggleMenuItem () {
      this.expanded = !this.expanded
    },
    updateHoverState () {
      this.isHovered = !this.isHovered
    },
    updateActiveState () {
      const active = this.children.some(item => (item.name === this.$route.name || item.name === this.$route.meta.parentName))
      this.isActive = this.minimized ? active : false
      this.expanded = active
    },
  },
  computed: {
    computedClass () {
      return {
        'app-sidebar-link-group--minimized': this.minimized,
        'app-sidebar-link-group--isActive': this.isActive,
      }
    },
    computedLinkStyles () {
      if (this.isHovered || this.isActive) {
        return {
          color: this.$themes.primary,
          backgroundColor: colorShiftHsl(this.$themes.secondary, { s: -13, l: 15 }).css,
          borderColor: this.isActive ? this.$themes.primary : 'transparent',
        }
      }

      return {}
    },
    computedIconStyles () {
      if (this.isHovered || this.isActive) {
        return {
          color: this.$themes.primary,
        }
      }

      return 'white'
    },

    computedSubmenuColor () {
      return {
        backgroundColor: this.$themes.primary, // this.contextConfig.invertedColor ? 'white' : this.$themes[this.color],
      }
    },
  },
}

</script>

<style lang="scss">

.app-sidebar-link-group {
  flex-direction: column;
  position: relative;
  display: flex;

  &__submenu {
    list-style: circle;
    list-style-position: outside;
    padding: 0 !important;
    width: 100%;
    overflow: hidden;

    .app-sidebar-link {
      &__item {
        &-title {
          margin-left: 0;

          &::before {
            // position: relative;
            top: -2px;
            content: '●';
            font-size: 10px;
            color: rgba(#ffffff, 0.5);
            transition: ease 0.5s;
          }
        }

        &--active {
          border-left: 4px solid rgba(#ffffff, 0.5);

          .app-sidebar-link__item-title::before {
            color: #ffffff;
          }
        }
      }
    }

    a {
      display: block;
      padding-left: 2.5rem;
      font-weight: normal;
    }

    .app-sidebar-link-group--minimized & {
      max-width: 12rem;

      @include nav-gradient();
      @include shadow($radius: var(--shadow-radius));
      // @include radius(0 var(--shape-radius) var(--shape-radius) 0);
      @include sidebar-radius();

      margin-left: 2px;
      max-height: 80vh;
      padding: 0.375rem 0;
      overflow-y: auto;
      overflow-x: hidden;

      a {
        padding: 0.75rem 1rem;
        border: 0;
        font-size: pxtorem(14);
      }
    }
  }

  &--minimized {
    .va-dropdown {
      &__anchor {
        width: 100%;
      }
    }
  }
}
</style>
