import {
  getHoverColor,
  getBoxShadowColor,
  getGradientBackground,
  getFocusColor,
} from 'vuestic-ui/src/services/color-functions'
import * as styleHelpers from './helpers/StyleHelpers'

const AtmosphereColorThemes = {
  primary: '#009999',
  secondary: '#5ac1a7',
  success: '#50caa7',
  info: '#009999',
  danger: '#c33131',
  warning: '#ffd500',
  gray: '#9f9f9f',
  dark: '#444444',
}

export {
  AtmosphereColorThemes,
  styleHelpers,
  getHoverColor,
  getGradientBackground,
  getFocusColor,
  getBoxShadowColor,
}
