<template>
  <div :id="'subgroups_' + uid" class="irrigation__subgroups">
    <at-irrigation-map-timeline
      :uid="uid"
      :scale="scale"
      :padding="padding"
      :containerWidth="containerWidth"
      :timeline-start-date="timelineStartDate"
      :timeline-end-date="timelineEndDate"
      :data-point="'15min'"
      :zoom-level="24*4"
      :duration-days="durationDays"
      :duration-hours="durationHours"
      :irrigation-map="irrigationMap"
    />

    <template>
      <!-- Start spacing -->
      <div class="irrigation__timeslot__day">
        <template v-for="action in mapStructure">
          <template>
            <div v-for="(fields, name) in action.structure"
              class="irrigation__timeslot__day__group"
              :key="'action-start_' + action.name + 'fields_' + name"
            >
              <template v-for="subField in fields">
                <template v-if="subField.id === 0 || (subField.id !== 0 && action.open)">
                  <div
                    class="event-info first"
                    :key="'field_' + name + 'subfield_' + subField.id"
                    :style="getStyle(subField.id, true, null)"
                  >
                  </div>
                </template>
              </template>
            </div>
          </template>
        </template>
      </div>

      <!-- MATRIX - draw always the matrix accordingly to the timeslot -->
      <template v-for="(day) in (durationDays + 1)">
        <template v-for="quarterHour in getNumberHours(day) * 4">
          <div class="irrigation__timeslot__day" :key="'timeSlot_' + day + '_quarter_' + quarterHour + '_empty'">

            <template v-for="action in mapStructure">
              <!-- Irrigation, Recalibration, Fill, Circulation -->
              <template>
                <div v-for="(fields, name) in action.structure"
                  class="irrigation__timeslot__day__group"
                  :key="'timeSlot_' + day + '_action_' + action.name + '_field_' + name"
                >
                  <template v-for="subField in fields">
                    <!-- icon, destination, start, duration, volume, ph, ec, orp, flow -->
                    <div v-if="subField.id === 0 || (subField.id !== 0 && action.open)"
                      class="event-info"
                      :class="{ show: showSummaries}"
                      :key="'timeSlot_' + day + '_action_' + action.name + '_field_' + name + '_subfield_' + subField.id"
                      :style="getStyle(subField.id, false, day)"
                    >
                      <div :style="{display: 'flex', position: 'relative', height: '50px'}">
                        <template v-for="(event, index) in checkEvent(action.name, day, quarterHour)">
                          <div
                            :key="'timeSlot_' + day + '_action_' + action.name + '_field_' + name + '_subfield_' + subField.id + '_event_' + index"
                            class="event-info__container"
                          >
                            <!-- Event icon -->
                            <div v-if="subField.id === 0"
                              class="event-info__container icon"
                              :class="event.status"
                              :style="{left: `${event.eventLeft}px`, marginTop: '5px', width: `${event.eventSize}px`}"
                            >
                              <!-- This will not work for scheduled events because the eventKey is null-->
                              <button
                                :class="event.status"
                                :style="getButtonStyle(event.eventSize)"
                                @mouseover="showAllSummaries(event.key)"
                                @mouseleave="hidelAllSummaries(event.key)"
                              >
                                <va-icon v-if="event.action.toLowerCase() === 'rinse'">R</va-icon>
                                <va-icon v-else-if="event.action.toLowerCase() === 'disinfect'">D</va-icon>
                                <va-icon v-else :name="getEventIcon(event.action.toLowerCase())"/>
                              </button>
                            </div>

                            <!-- Event details -->
                            <div v-else-if="subField.id !== 0"
                              class="event-info__container details"
                              :class="event.status"
                              :style="{
                                left: `${event.eventLeft}px`,
                                width: `${event.eventSize}px`,
                                height: subField.id === fields.length - 1 ? '45px' : '',
                                borderRadius: subField.id === fields.length - 1 ? '0 0 15px 15px' : '',
                                marginBottom: subField.id === fields.length - 1 ? '5px' : '',
                              }"
                            >
                              <template v-for="(info, index) in getInfo(event, subField.name)">
                                <template v-if="info.size <= event.eventSize">
                                  <span :class="`event-info__container details__value ${info.status}`"
                                    :style="{display: 'flex', fontWeight: subField.name == 'flow' ? 'bold' : ''}"
                                    :key="'info__' + index"
                                    v-html="info.value"
                                  />
                                </template>
                                <template v-else>
                                  <span :class="`event-info__container details__value`"
                                    :key="'info__' + index"
                                    :style="{display: 'flex'}"
                                  >
                                    <button :class="event.status">
                                      <va-icon :name="'at-icon l_horizontalpoints'" :style="{fontSize: '30px'}"/>
                                    </button>
                                  </span>
                                  <!-- Event summary -->
                                  <div class="event-info__summary" :ref="`group_summary_${event.key}`"
                                    :key="'info__' + index + '_summary'"
                                    :style="{bottom: '7px', left: '50px'}"
                                  >
                                    <div class="event-info__summary__container">
                                      <div class="single-event">
                                        <span>{{ replace(info.value) }}</span>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </template>
                            </div>

                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </template>
          </div>
        </template>
      </template>

      <!-- End spacing -->
      <div class="irrigation__timeslot__day">
        <template v-for="action in mapStructure">
          <template>
            <div v-for="(fields, name) in action.structure"
              class="irrigation__timeslot__day__group"
              :key="'action-end_' + action.name + 'fields_' + name"
            >
              <template v-for="subField in fields">
                <div v-if="subField.id === 0 || (subField.id !== 0 && action.open)"
                  class="event-info last"
                  :key="'field_' + name + 'subfield_' + subField.id"
                  :style="{ width: `${padding + 'px'}` }"
                >
                  <!-- {{ subField.name.charAt(0)}} -->
                </div>
              </template>
            </div>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import AtIrrigationMapTimeline from '../AtIrrigationMapTimeline.vue'
import { getTimeFormat, getInFactoryTime, getTimeDateFormat } from '../../../helpers/TimeHelpers'
import { getIconbyLevel, getEventIcon, getColor, getUnits, getCurrentCellStyle, getWeekendCellStyle, getSeparationCellStyle, getStatusbyLevel } from './../styleProperties'

export default {
  name: 'AtIrrigationMapLayersLineQuarterHourly',
  props: {
    uid: {
      type: Number,
    },
    timelineStartDate: {
      type: String,
    },
    timelineEndDate: {
      type: String,
    },
    irrigationMap: {
      type: Object,
    },
    dataPoint: {
      type: String,
      default: '15min',
    },
    showSummaries: {
      type: Boolean,
      default: false,
    },
    mapStructure: {
      type: Array,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      scale: 210, // multiple of 3
      minToPixel: 210 / 15, // scale / 15
      padding: 35,
      zoomLevel: 4,
    }
  },
  mounted () {
  },
  computed: {
    containerWidth () {
      // const startDate = getInFactoryTime(this.timelineStartDate)
      const duration = this.durationHours * this.zoomLevel * this.scale
      return duration + this.padding * 2
    },
    durationDays () {
      return moment(this.timelineEndDate).diff(this.timelineStartDate, 'days')
    },
    durationHours () {
      return moment(this.timelineEndDate).diff(this.timelineStartDate, 'hours')
    },
  },
  methods: {
    getIconbyLevel,
    getEventIcon,
    getColor,
    getUnits,
    getCurrentCellStyle,
    getWeekendCellStyle,
    getSeparationCellStyle,
    separationCellStyle (index) {
      return index === 0 ? getSeparationCellStyle() : ''
    },
    getStyle (id, first, day) {
      let style = {}

      const currentDay = getInFactoryTime(this.timelineStartDate).add(day - 1, 'days')
      let background = ''

      if (id === 0) {
        style = {
          width: first ? this.padding + 'px' : `${this.scale + 'px'}`,
          background: 'hsla(var(--primary-color-hue), 25%, 70%, 10%)',
        }
        if (moment().format('YYYYMMDD') === currentDay.format('YYYYMMDD')) { // current
          background = getCurrentCellStyle()
          style = {
            width: first ? this.padding + 'px' : `${this.scale + 'px'}`,
            background: background,
          }
        } else if (currentDay.day() === 0 || currentDay.day() === 6) { // weeekend
          background = getWeekendCellStyle()
          style = {
            width: first ? this.padding + 'px' : `${this.scale + 'px'}`,
            background: background,
          }
        }
      } else {
        if (moment().format('YYYYMMDD') === currentDay.format('YYYYMMDD')) { // current
          background = getCurrentCellStyle()
        } else if (currentDay.day() === 0 || currentDay.day() === 6) { // weeekend
          background = getWeekendCellStyle()
        }

        style = {
          width: first ? this.padding + 'px' : `${this.scale + 'px'}`,
          background: background,
        }
      }

      return style
    },
    getButtonStyle (eventSize) {
      let style = {}
      if (eventSize < 36) {
        const middle = Math.floor(eventSize / 2)
        const left = middle - 12 // 24px icon size / 2
        style = {
          width: '24px',
          padding: '0',
          marginLeft: eventSize < 24 ? left + 'px' : '',
        }
      }

      return style
    },
    replace (value) {
      if (typeof value === 'string') {
        return value.replace('<br>', ', ')
      }
      return value
    },
    // check if there is an event in a quarter hourly period
    checkEvent (action, day, quarterHour) {
      const currentDay = moment(this.timelineStartDate).add(day - 1, 'days')
      const dayEvents = this.irrigationMap[currentDay.format('YYYYMMDD')]
      const events = []

      if (day !== 1) {
        currentDay.set('hour', 0)
        currentDay.set('minute', 0)
      }
      currentDay.add(15 * (quarterHour - 1), 'minute')

      let actionEvents = null
      if (dayEvents) {
        if (action.toLowerCase() === 'irrigation' || action.toLowerCase() === 'irrigations') {
          actionEvents = dayEvents.irrigation
        } else if (action.toLowerCase() === 'recalibration' || action.toLowerCase() === 'irrigations') {
          actionEvents = dayEvents.recalibration
        } else if (action.toLowerCase() === 'fill') {
          actionEvents = dayEvents.fill
        } else if (action.toLowerCase() === 'circulation' || action.toLowerCase() === 'circulations') {
          actionEvents = dayEvents.circulation
        }

        if (actionEvents) {
          for (let i = 0; i < actionEvents.length; i++) {
            const eventDate = getInFactoryTime(actionEvents[i].start_time_stamp)
            if (eventDate.format('YYYYMMDD') === currentDay.format('YYYYMMDD')) {
              if (eventDate.hour() === currentDay.hour()) {
                if (eventDate.minute() - currentDay.minute() >= 0 && eventDate.minute() - currentDay.minute() < 15) {
                  const duration = actionEvents[i].duration / 60 // in min
                  const size = Math.floor(duration) * this.minToPixel
                  const left = (eventDate.minute() - currentDay.minute()) * this.minToPixel
                  actionEvents[i].eventSize = size
                  actionEvents[i].eventLeft = left
                  actionEvents[i].eventMinimized = size < 14 // size of one digit

                  events.push(actionEvents[i])
                }
              }
            }
          }
        }
      }

      return events
    },
    getInfo (event, subfield) {
      if (subfield.toLowerCase() === 'destination') {
        if (event.action.toLowerCase() === 'irrigation' || event.action.toLowerCase() === 'disinfect' || event.action.toLowerCase() === 'rinse') {
          const roomNumber = event.destination.split('#')[1].split(' Side')[0]
          let controlAreasNames = ''

          const roomName = `Grow Room #${roomNumber}`

          const controlAreas = event.destination.split(', ')
          for (let j = 0; j < controlAreas.length; j++) {
            const controlAreaNameSplit = controlAreas[j].split(`${roomName} `)[1].split(' ')
            const sideNumber = controlAreaNameSplit[1]
            const layerNumber = controlAreaNameSplit[3]

            controlAreasNames += `R${roomNumber}-S${sideNumber}-L${layerNumber}` + (j < (controlAreas.length - 1) ? '<br>' : '')
          }
          return [
            { value: controlAreasNames, status: '', size: 90 },
          ]
        } else if (event.action.toLowerCase() === 'recalibration' || event.action.toLowerCase() === 'fill' || event.action.toLowerCase() === 'circulation') {
          return [
            { value: event.destination.split(' Irrigation ')[1], status: '', size: 90 },
          ]
        }
      } else if (subfield.toLowerCase() === 'origin') {
        return [
          { value: event.origin.split(' Irrigation ')[1] ? event.origin.split(' Irrigation ')[1] : event.origin, status: '', size: 90 },
        ]
      } else if (subfield.toLowerCase() === 'start') {
        return [
          { value: this.printTimeStamp(event.start_time_stamp), status: '', size: 90 },
        ]
      } else if (subfield.toLowerCase() === 'duration') {
        return [
          { value: this.durationToString(event.duration), status: '', size: 68 },
        ]
      } else if (subfield.toLowerCase() === 'volume') {
        const value = event.status === 'scheduled' ? Math.round(event.volume.ref * 100) / 100 : Math.round(event.volume.val * 100) / 100
        const nDigits = value.toString().replace('.', '').length
        return [
          {
            value: value,
            status: '',
            size: 10 * nDigits,
          },
        ]
      } else if (subfield.toLowerCase() === 'ph') {
        const value = event.status === 'scheduled' ? Math.round(event.ph.ref * 100) / 100 : Math.round(event.ph.avg * 100) / 100
        const nDigits = value.toString().replace('.', '').length
        return [
          {
            value: value,
            status: (event.ph.level === 1 || event.ph.level === 2 || event.ph.level === 3) ? getStatusbyLevel(event.ph.level) : '',
            size: 10 * nDigits,
          },
        ]
      } else if (subfield.toLowerCase() === 'ec') {
        const value = event.status === 'scheduled' ? Math.round(event.electrical_conductivity.ref * 100) / 100 : Math.round(event.electrical_conductivity.avg * 100) / 100
        const nDigits = value.toString().replace('.', '').length
        return [
          {
            value: value,
            status: (event.electrical_conductivity.level === 1 || event.electrical_conductivity.level === 2 || event.electrical_conductivity.level === 3) ? getStatusbyLevel(event.electrical_conductivity.level) : '',
            size: 10 * nDigits,
          },
        ]
      } else if (subfield.toLowerCase() === 'orp') {
        const value = event.status === 'scheduled' ? Math.round(event.orp.ref * 100) / 100 : Math.round(event.orp.avg * 100) / 100
        const nDigits = value.toString().replace('.', '').length
        return [
          {
            value: value,
            status: (event.orp.level === 1 || event.orp.level === 2 || event.orp.level === 3) ? getStatusbyLevel(event.orp.level) : '',
            size: 10 * nDigits,
          },
        ]
      } else if (subfield.toLowerCase() === 'flow') {
        const value = event.status !== 'scheduled' ? Math.round(event.volume_flow.avg * 100) / 100 : '-'
        const nDigits = event.status !== 'scheduled' ? value.toString().replace('.', '').length : 1
        return [
          {
            value: value,
            status: '',
            size: 10 * nDigits,
          },
        ]
      }
      return [{ value: '', status: '' }]
    },
    getNumberHours (day) {
      const currentDay = getInFactoryTime(this.timelineStartDate).add(day - 1, 'days')
      const lastDay = getInFactoryTime(this.timelineEndDate).add(day - 1, 'days')
      const diffHours = lastDay.diff(currentDay, 'hours')

      return diffHours > 24 ? 24 : diffHours
    },
    printTimeStamp (timestampUTC) {
      return getTimeDateFormat(getInFactoryTime(timestampUTC + 'Z'), 'short', true).replace(', ', '<br>')
    },
    durationToString (timeSeconds) {
      return getTimeFormat(moment.utc(timeSeconds * 1000), 'medium', false)
    },
    showAllSummaries (eventKey) {
      let summaries = []
      if (this.$refs[`group_summary_${eventKey}`]) {
        summaries = this.$refs[`group_summary_${eventKey}`]
        if (summaries.length !== 0) {
          for (let i = 0; i < summaries.length; i++) {
            summaries[i].style.opacity = 1
            summaries[i].style.zIndex = 1
          }
        }
      }
    },
    hidelAllSummaries (eventKey) {
      let summaries = []
      if (this.$refs[`group_summary_${eventKey}`]) {
        summaries = this.$refs[`group_summary_${eventKey}`]
        if (summaries.length !== 0) {
          for (let i = 0; i < summaries.length; i++) {
            summaries[i].style.opacity = null
            summaries[i].style.zIndex = null
          }
        }
      }
    },
  },
  watch: {
  },
  components: {
    AtIrrigationMapTimeline,
  },
}
</script>

<style lang="scss">
$timeline-levels-width: 265px;
$groupHeight: 50px;
$doneColor: hsl(170, 65%, 45%);
$warningColor: hsl(30, 90%, 65%);
$dangerColor: hsl(0, 100%, 50%);
$scheduledColor: hsl(200, 85%, 60%);

.irrigation {
  &__subgroups {
    white-space: nowrap;
  }

  &__timeslot {
    @include card-gradient();

    color: hsl(var(--primary-color-hue), 55%, 35%);
    width: calc(100% - #{$timeline-levels-width});
    overflow: auto;

    &__day {
      display: inline-flex;
      flex-direction: column;
      vertical-align: top;
      justify-content: center;
      align-items: center;

      &__group {
        background: #ffffff;

        .event-info {
          text-align: center;
          height: $groupHeight;
          border: none;
          border-left: 1px solid hsl(var(--primary-color-hue), 40%, 88%);
          border-bottom: 1px solid hsl(var(--primary-color-hue), 40%, 88%);
          font-size: pxtorem(13);

          &__summary {
            @include radius(10px);

            display: flex;
            align-items: center;
            width: max-content;
            z-index: -1;
            background: rgba(0, 0, 0, 0.75);
            position: absolute;
            left: 50px;
            opacity: 0;
            transition: opacity 0.5s;

            &::before {
              content: '';
              position: absolute;
              top: var(--before-top);
              left: -12px;
              width: 0;
              height: 0;
              border-width: 6px;
              border-color: transparent rgba(0, 0, 0, 0.75) transparent transparent;
              border-style: solid;
            }

            &__container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 8px;
              height: max-content;
              // min-height: 3.8rem;
              overflow: hidden;

              .single-events {
                display: inline-block;
                padding-right: 1.5rem;
                padding-left: 0.5rem;
                padding-top: 0.2rem;
                align-self: start;
                min-width: 9rem;
              }

              .single-event {
                span {
                  white-space: pre;
                  color: #ffffff;
                  font-size: pxtorem(12);
                  justify-content: left;
                  display: block;
                }

                &:last-of-type {
                  padding-right: 0.2rem;
                }
              }

              span {
                white-space: pre;
                color: #ffffff;
                font-size: pxtorem(12);
              }
            }
          }

          &__container {
            position: absolute;
            align-items: center;
            justify-content: center;

            &.icon,
            &.details {
              &.done {
                background: #{hsla(170, 65%, 45%, 0.25)};
              }

              &.warning {
                background: hsla(30, 90%, 65%, 0.25);
              }

              &.danger,
              &.critical {
                background: hsla(0, 100%, 50%, 0.25);
              }

              &.scheduled {
                background: hsla(200, 85%, 60%, 0.25);
              }
            }

            &.icon {
              border-radius: 15px 15px 0 0;
              height: 45px;
            }

            &.details {
              height: 50px;

              &__value {
                // display: flex;
                &.done {
                  font-weight: bold;
                  color: hsl(170, 65%, 45%);
                }

                &.warning {
                  font-weight: bold;
                  color: hsl(30, 90%, 65%);
                }

                &.danger,
                &.critical {
                  font-weight: bold;
                  color: hsl(0, 100%, 50%);
                }

                &.scheduled {
                  font-weight: bold;
                  color: hsl(200, 85%, 60%);
                }
              }
            }

            &:hover,
            &.show {
              .event-info__summary {
                opacity: 1;
                z-index: 1;
              }
            }
          }

          button,
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            white-space: pre-wrap;
          }

          button {
            opacity: 0;
            font-size: pxtorem(24);
            color: #d4e4ec;
            border: none;
            background: none;
            outline: none;
            border-radius: 0;
            transition: opacity 0.3s;

            i {
              font-size: pxtorem(28);
            }

            &:hover {
              opacity: 1;
            }

            &.done,
            &.warning,
            &.danger,
            &.critical,
            &.scheduled {
              opacity: 0.9;
              color: #ffffff;
              transition: opacity 0.3s;

              i {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                font-size: pxtorem(14);
              }

              &:hover {
                opacity: 1;
              }
            }

            &.done {
              i {
                background: $doneColor;
              }
            }

            &.warning {
              i {
                background: $warningColor;
              }
            }

            &.danger,
            &.critical {
              i {
                background: $dangerColor;
              }
            }

            &.scheduled {
              i {
                background: $scheduledColor;
              }
            }
          }
        }

        .last {
          border-left: 1px solid hsl(var(--primary-color-hue), 20%, 83%);
        }
      }
    }
  }
}
</style>
