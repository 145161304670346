<template>
  <div class="irrigation__subgroups__timeline">
    <canvas :id="'timeCanvas_' + uid" class="time-canvas"></canvas>
  </div>
</template>

<script>
import moment from 'moment'
import { hex2hsl, hslToHex, setSatLumRGBaColor } from '../../helpers/StyleHelpers'
import { getInFactoryTime } from '../../helpers/TimeHelpers'

export default {
  components: {},
  name: 'AtIrrigationMapTimeline',
  props: {
    uid: {
      type: Number,
    },
    scale: {
      type: Number,
      default: 150,
    },
    padding: {
      type: Number,
      default: 0,
    },
    containerWidth: {
      type: Number,
    },
    timelineStartDate: {
      type: String,
    },
    timelineEndDate: {
      type: String,
    },
    dataPoint: {
      type: String,
      default: 'day',
    },
    zoomLevel: {
      type: Number,
      default: 1,
    },
    durationDays: {
      type: Number,
    },
    durationHours: {
      type: Number,
    },
    nEvents: {
      type: Number,
      default: null,
    },
    irrigationMap: {
      type: Object,
    },
  },
  data () {
    return {
      canvas: null,
    }
  },
  mounted () {
    this.createTimeline()
  },
  methods: {
    createTimeline () {
      let duration = 0
      if (this.dataPoint === 'day') {
        duration = (this.durationDays + 1) * this.zoomLevel * this.scale
      } else if (this.dataPoint === '15min') {
        duration = this.durationHours * this.zoomLevel * this.scale
      } else if (this.dataPoint === 'events') {
        duration = this.nEvents * this.zoomLevel * this.scale
      }

      const c = document.getElementById('timeCanvas_' + this.uid)
      c.width = this.containerWidth
      c.height = 70

      this.canvas = c.getContext('2d')
      this.canvas.fillStyle = '#fff'
      this.canvas.fillRect(0, 0, 900, 50)

      const primaryColorHue = hex2hsl(this.$themes.primary).h

      const timelineTextColor = hslToHex(primaryColorHue, 55, 35)

      const timelineTextColorLight = hslToHex(primaryColorHue, 28, 90)

      const timelineSeparationPinColor = hslToHex(primaryColorHue, 55, 35)

      const timelineFillRectColor = 'rgba(42, 190, 165, 1)'

      if (this.dataPoint === 'day') {
        this.timelineDayZoom(duration, timelineTextColor, timelineTextColorLight, timelineSeparationPinColor, timelineFillRectColor)
      } else if (this.dataPoint === '15min') {
        this.timelineQuarterHourlyZoom(duration, timelineTextColor, timelineTextColorLight, timelineSeparationPinColor)
      } else if (this.dataPoint === 'events') {
        this.timelineEventsZoom(timelineTextColor, timelineTextColorLight, timelineSeparationPinColor, timelineFillRectColor)
      }
    },
    timelineDayZoom (duration, timelineTextColor, timelineTextColorLight, timelineSeparationPinColor, timelineFillRectColor) {
      // ------------------
      const startDate = moment(this.timelineStartDate)
      const currentDate = moment()
      // ------------------

      for (let i = 0, nDay = 1; i < duration; i += this.scale) {
        const newDate = moment(startDate)
        newDate.add(nDay - 1, 'days')
        nDay++

        if (newDate.format('YYYY MM DD') === currentDate.format('YYYY MM DD')) {
          this.canvas.fillStyle = timelineFillRectColor
          this.canvas.fillRect(i + this.padding, 26, this.scale, 71)

          // bottom current day marker
          this.canvas.strokeStyle = '#1c7d6d'
          this.canvas.lineWidth = 7
          this.canvas.beginPath()
          this.canvas.moveTo(i + (this.padding + 0.5), 70 + 0.85)
          this.canvas.lineTo(i + (this.padding) + this.scale, 70 + 0.85)
          this.canvas.stroke()

          this.canvas.lineWidth = 1
          this.canvas.strokeStyle = timelineSeparationPinColor
        }

        // ------------  Starting weekend line
        if (newDate.format('d') === '1') {
          this.canvas.beginPath()
          this.canvas.strokeStyle = timelineSeparationPinColor
          this.canvas.moveTo(i + (this.padding + 0.5), 35)
          this.canvas.lineTo(i + (this.padding + 0.5), 100)
          this.canvas.stroke()
          // ------------  /Starting weekend line
        } else if (newDate.format('YYYY MM DD') !== moment().format('YYYY MM DD') && newDate.format('YYYY MM DD') !== moment().add(1, 'day').format('YYYY MM DD')) {
          this.canvas.strokeStyle = timelineTextColorLight
          this.canvas.beginPath()
          this.canvas.moveTo(i + (this.padding + 0.5), 45)
          this.canvas.lineTo(i + (this.padding + 0.5), 100)
          this.canvas.stroke()
        }
      }

      this.canvas.textAlign = 'center'

      for (let i = 0, nDay = 1; i < duration; i += this.scale) {
        let dayOfTheWeek = ''
        let day = ''
        let monthYear = ''

        const newDate = moment(startDate).add(nDay - 1, 'days')

        dayOfTheWeek = newDate.format('ddd')
        day = newDate.format('DD')
        monthYear = newDate.format('MMMM YYYY').toUpperCase()
        nDay++

        this.canvas.font = 'bold 10px Montserrat, Arial'
        this.canvas.fillStyle = newDate.format('YYYY MM DD') === moment().format('YYYY MM DD') ? '#ffffff' : timelineTextColor
        this.canvas.fillText(dayOfTheWeek, i + (this.scale / 2) + this.padding, 40)

        this.canvas.font = 'normal 14px Montserrat, Arial'
        this.canvas.fillStyle = newDate.format('YYYY MM DD') === moment().format('YYYY MM DD') ? '#ffffff' : timelineTextColor
        this.canvas.fillText(day, i + (this.scale / 2) + this.padding, 63)

        if (i === 0 || day === '01') {
          this.canvas.font = 'normal 14px Montserrat, Arial'
          this.canvas.fillStyle = newDate.format('YYYY MM DD') === moment().format('YYYY MM DD') ? '#ffffff' : timelineTextColor
          this.canvas.fillText(monthYear, i + (this.scale / 2) + this.padding, 23)
        }
      }
    },
    timelineEventsZoom (timelineTextColor, timelineTextColorLight, timelineSeparationPinColor, timelineFillRectColor) {
      // ------------------
      const startDate = getInFactoryTime(this.timelineStartDate)
      startDate.set('hour', 0)
      startDate.set('minute', 0)
      const currentDate = getInFactoryTime()
      // ------------------

      for (let j = 0, incrementScale = 0; j < this.durationDays; j++) {
        const newDate = moment(startDate).add(j, 'days')
        const day = newDate.format('DD')
        const date = newDate.format('YYYYMMDD')

        // Year, month stamp
        if (j === 0 || newDate.format('DD') === '01') {
          const monthYear = newDate.format('MMMM YYYY').toUpperCase()
          this.canvas.textAlign = 'center'
          this.canvas.fillStyle = timelineTextColor
          this.canvas.font = 'normal 14px Montserrat, Arial'
          this.canvas.fillText(monthYear, incrementScale + this.padding, 23)
        }

        // Day bar
        if (newDate.format('YYYY MM DD') !== moment().add(1, 'day').format('YYYY MM DD')) {
          this.canvas.beginPath()
          this.canvas.lineWidth = 1
          this.canvas.strokeStyle = timelineSeparationPinColor
          this.canvas.moveTo((incrementScale + this.padding) + 0.5, 35)
          this.canvas.lineTo((incrementScale + this.padding) + 0.5, 100)
          this.canvas.stroke()
        }

        if (!this.irrigationMap[date]) {
          this.irrigationMap[date] = []
        }

        const nDayEvents = this.irrigationMap[date].length !== 0 ? this.irrigationMap[date].length : 1
        const dayOfTheWeek = nDayEvents > 4 ? newDate.format('dddd') : newDate.format('ddd')

        // current day
        if (newDate.format('YYYYMMDD') === currentDate.format('YYYYMMDD')) {
          this.canvas.fillStyle = timelineFillRectColor
          this.canvas.fillRect(incrementScale + this.padding, 26, this.scale * nDayEvents, 71)

          this.canvas.strokeStyle = timelineTextColor
          this.canvas.lineWidth = 5

          // bottom current day marker
          this.canvas.beginPath()
          this.canvas.strokeStyle = '#1c7d6d'
          this.canvas.lineWidth = 7
          this.canvas.moveTo(incrementScale + this.padding + 0.5, 71 + 0.5)
          // this.canvas.lineTo(incrementScale + this.padding + this.scale * nDayEvents + 0.5, 71 + 0.5)
          this.canvas.stroke()

          this.canvas.lineWidth = 1
          this.canvas.strokeStyle = timelineSeparationPinColor
        }

        // Day stamp
        this.canvas.font = 'bold 14px Montserrat, Arial'
        this.canvas.fillStyle = (newDate.format('YYYYMMDD') === currentDate.format('YYYYMMDD')) ? '#ffffff' : timelineTextColor
        const dayTextPosition = 45
        this.canvas.fillText(dayOfTheWeek + ', ' + day, incrementScale + (this.scale * (nDayEvents / 2)) + this.padding, dayTextPosition)
        incrementScale += this.scale

        // Events bars
        for (let i = 0; i < nDayEvents - 1; i++) {
          this.canvas.beginPath()
          this.canvas.strokeStyle = timelineTextColorLight
          this.canvas.moveTo(incrementScale + (this.padding + 0.5), 52)
          if (newDate.format('YYYY MM DD') !== currentDate.format('YYYY MM DD')) {
            this.canvas.lineTo(incrementScale + (this.padding + 0.5), 100)
          }
          this.canvas.stroke()

          incrementScale += this.scale
        }
      }
    },
    timelineQuarterHourlyZoom (duration, timelineTextColor, timelineTextColorLight, timelineSeparationPinColor) {
      const timelineFillRectColor = setSatLumRGBaColor(
        this.$themes.primary,
        0.05,
        { sat: 60, lum: 30 },
      )
      const startDate = moment(this.timelineStartDate)
      const currentDate = moment()

      // ------------------

      for (let i = 0, nMinutes = 5; i <= duration; i += this.scale / 3) {
        this.canvas.beginPath()

        const newDate = moment(startDate)

        newDate.add(nMinutes - 5, 'minutes')
        nMinutes += 5

        if (newDate.format('YYYY MM DD') === currentDate.format('YYYY MM DD')) {
          this.canvas.fillStyle = timelineFillRectColor
          this.canvas.fillRect(i + this.padding, 0, this.scale / 3, 70)
        }

        if (newDate.minute() === 0) { // hour line
          this.canvas.strokeStyle = timelineSeparationPinColor
          this.canvas.moveTo(i + (this.padding + 0.5), 55)
          this.canvas.lineTo(i + (this.padding + 0.5), 100)
        } else if (newDate.minute() === 15 || newDate.minute() === 30 || newDate.minute() === 45) { // 15min line
          this.canvas.strokeStyle = timelineTextColorLight
          this.canvas.moveTo(i + (this.padding + 0.5), 60)
          this.canvas.lineTo(i + (this.padding + 0.5), 100)
        } else { // 5min line
          this.canvas.strokeStyle = timelineTextColorLight
          this.canvas.moveTo(i + (this.padding + 0.5), 65)
          this.canvas.lineTo(i + (this.padding + 0.5), 100)
        }

        this.canvas.stroke()
      }

      this.canvas.textAlign = 'center'
      this.canvas.fillStyle = timelineTextColor

      // write day, day of week, month and year in the timeline
      for (let i = 0, nMinutes = 15; i <= duration; i += this.scale) {
        let minutes = ''
        let hours = ''
        let hoursMinutes = ''
        let monthDay = ''

        const newDate = moment(startDate)
        newDate.add(nMinutes - 15, 'minutes')

        minutes = newDate.format('mm')
        hours = newDate.format('HH') + 'h'
        hoursMinutes = newDate.format('HH:mm')
        monthDay = newDate.format('MMMM, DD').toUpperCase()
        nMinutes += 15

        if (minutes === '00') {
          this.canvas.font = 'bold 12px Montserrat, Arial'
          this.canvas.fillText(hoursMinutes, i + this.padding, 50)
        } else {
          this.canvas.font = 'normal 12px Montserrat, Arial'
          this.canvas.fillText(hoursMinutes, i + this.padding, 55)
        }

        if (i === 0 || (minutes === '00' && hours === '00h' && i !== duration)) {
          this.canvas.font = 'normal 14px Montserrat, Arial'
          this.canvas.fillText(monthDay, i + 60, 23)
        }
      }
    },
  },
  watch: {
    'containerWidth' () {
      this.createTimeline()
    },
    'dataPoint' () {
      this.createTimeline()
    },
    'timelineStartDate' () {
      this.createTimeline()
    },
    'timelineEndDate' () {
      this.createTimeline()
    },
  },
}
</script>

<style lang="scss">
$timelineHeight: 70px;

.irrigation__subgroups__timeline {
  position: sticky;
  top: 0;
  z-index: 20;
  background: #ffffff;
  height: $timelineHeight;

  .time-canvas {
    background: #ffffff;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid hsl(var(--primary-color-hue), 35%, 80%);
  }
}
</style>
