import Vue from 'vue'
import store from '../store/index'

// add translations directly to the application
Vue.i18n.add('en', require('./en.json'))
Vue.i18n.add('it', require('./it.json'))

// set the start locale to use
Vue.i18n.set(store.getters.getAppLanguage)

// set fallback for non-translated strings
Vue.i18n.fallback('it')
