<template>
  <div class="current-action">
    <va-card
      :title="$t('irrigation.irrigationLineStatus')"
      :subtitle="actionSubtitle"
    >
      <template slot="actions">
        <va-button flat small @click="setIrrigationEvent('add')">{{ $t('growing.irrigationEventsEdition.addNew') }}</va-button>
      </template>

      <div class="row">
        <!-- Action Details -->
        <div class="flex xs12 sm12">
          <template>
            <div class="content__details">
              <template v-if="action.progress.state !== 'Not running'">
                <template>
                  <va-icon v-if="action.action.toLowerCase() === 'irrigation'" name="at-icon l_levels"/>
                  <va-icon v-else-if="action.action.toLowerCase() === 'recalibration' || action.action.toLowerCase() === 'circulation'" name="at-icon l_reload"/>
                  <span>{{ action.origin }} {{ action.destination !== '' ? (' - ' + action.destination): ''}} </span>
                </template>
              </template>
            </div>
          </template>
        </div>

        <!-- Action Progress -->
        <div class="flex xs12 sm12">
          <va-progress-bar v-model="action.progress.percent">
            <span :style="{visibility: action.progress.state !== 'Not running' ? 'visible' : 'hidden'}">
              {{ action.progress.percent ? `${Math.round(action.progress.percent)}%` : ''}}
            </span>
          </va-progress-bar>
          <div class="row">
            <div class="flex xs4 sm4">
              <span :style="{visibility: action.progress.state ? 'visible' : 'hidden'}">{{ $t('irrigation.status') }}:
                <span><b>{{ action.progress.state ? $t(`irrigation.${action.progress.state}`) : '' }}</b></span>
              </span>
            </div>
            <!-- Controls -->
            <div class="flex xs4 sm4">
              <div class="buttons">
                <va-button v-if="action.progress.state === 'Paused'" flat icon="at-icon l_player_play" @click="setIrrigationEvent('play', action)"/>
                <va-button v-if="action.progress.state === 'Running'" flat icon="at-icon l_player_pause" @click="setIrrigationEvent('pause', action)"/>
                <va-button v-if="action.progress.state === 'Running' || action.progress.state === 'Paused'" flat icon="at-icon l_player_stop" @click="setIrrigationEvent('stop', action)"/>
              </div>
            </div>
            <div class="flex xs4 sm4 time__elapsed">

              <template v-if="action.progress.units === 'm3' && action.progress.state !== 'Not running'">
                <div>
                  <span>{{  $t('data.volume') }}: </span>
                  <span><b>{{ (action.progress.percent / action.progress.total) ? (Math.round(action.progress.percent * action.progress.total) / 100) : 0 }}/{{ Math.round(action.progress.total * 100) / 100 }} {{ getUnitFormated(action.progress.units) }}
                  </b></span>
                </div>
                <div class="mt-2">
                  <span>{{ $t('timeData.elapsedTime') }}: </span>
                  <span><b>{{ getElapsedTime(action.progress.elapsed_time) }}
                  </b></span>
                </div>
              </template>

              <template v-else-if="(action.progress.units === 's' || action.progress.units === 'sec') && action.progress.state !== 'Not running'">
                <div class="time__only">
                  <span>{{ $t('timeData.elapsedTime') }}: </span>
                  <span><b>{{ getTimeFormated(action.progress.total * action.progress.percent / 100) }}/{{ getTimeFormated(action.progress.total) }}{{action.progress.total > 3600 ? 'H' : ' min' }}</b>
                  </span>
                </div>
              </template>
              <template v-else>
                <div class="time__only"></div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </va-card>

  </div>
</template>

<script>
import { styleHelpers } from '@/services/atmosphere-ui'
import moment from 'moment-timezone'

export default {
  name: 'AtIrrigationMapCurrentAction',
  props: {
    irrigationLineName: {
      type: String,
      default: '',
    },
    action: {
      type: Object,
      default: () => {
        return {
          key: '3694919d-cc4c-4909-87ac-d5c5298319db',
          origin: '',
          destination: '',
          action: 'None',
          progress: {
            state: 'Not running',
            units: '',
            val: 0,
            total: 0,
            percent: 0,
            elapsed_time: '',
          },
          temperature: {},
          ph: {},
          electrical_conductivity: {},
          dissolved_oxygen: { },
          orp: {},
          volume_flow: {},
          volume: {},
        }
      },
    },
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    getIrrigationLineId () {
      return parseInt(this.$route.params.irrigationLineId)
    },
    getUnitFormated (units) {
      return styleHelpers.getOnlyUnitFormat(units)
    },
    getTimeFormated (seconds) {
      return seconds > 3600 ? moment.utc(seconds * 1000).format('HH:mm:ss') : moment.utc(seconds * 1000).format('mm:ss')
    },
    getElapsedTime (elapsedTime) {
      const time = elapsedTime.split(' (')[0]
      const timeLength = time.split(':').length

      if (timeLength <= 2) {
        return time + ' min'
      } else if (timeLength > 2) {
        return time + 'H'
      } else {
        return time
      }
    },
    setIrrigationEvent (selectedAction, action) {
      if (selectedAction === 'add') {
        this.$emit('addIrrigationEvent', {
          action: 'add',
        })
      } else {
        this.$emit('changeIrrigationEventStatus', {
          action: selectedAction,
          actionName: action.action.toLowerCase(),
          actionKey: action.key,
        })
      }
    },
  },
  computed: {
    actionSubtitle () {
      return this.action.progress ? (this.action.progress.state !== 'Not running' ? this.action.action : '') : ''
    },
  },
  watch: {
  },
  components: {},
}

</script>

<style lang="scss">

.current-action {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  left: 0;
  background: white;

  .va-card {
    box-shadow: none !important;
    border-radius: 0 !important;
    background: rgb(225, 238, 244);

    &__body {
      padding: 0 1.25rem 1.25rem !important;
    }
  }
}

.va-modal {
  .va-modal__message {
    width: 700px;
  }
}

.time__elapsed {
  text-align: end;
  padding: 0.5rem 1.25rem;

  .time__only {
    min-height: 2.5rem;
  }
}

.content__details {
  font-size: pxtorem(16);
  display: flex;

  i {
    font-size: pxtorem(20);
    margin-right: 10px;
    opacity: 0.8;
  }

  span {
    display: flex;
    align-items: center;
    color: hsl(var(--primary-color-hue), 40%, 30%) !important;
  }
}

.buttons {
  display: flex;
  justify-content: center;

  .va-button {
    padding: 0.2rem;
    margin: 0.2rem;
    font-size: 1.5rem;
  }
}
</style>
