<template>
  <div class="at-date-picker">
    <div :data-toggle="!disabled">
      <va-input
        v-model="valueProxy"
        readonly
        :placeholder="placeholder"
        :label="label"
        :disabled="disabled"
        :error="error"
        :success="success"
        :messages="messages"
        :error-messages="errorMessages"
      >
        <template slot="append">
          <va-icon v-if="time" name="at-icon b_time"/>
          <va-icon v-else name="at-icon b_calendar"/>
        </template>
      </va-input>
    </div>
    <flat-pickr
      class="at-date-picker__flatpickr"
      v-model="valueProxy"
      :config="fullConfig"
      :disabled="disabled"
      @on-open="onOpen"
      data-input
    />
  </div>
</template>

<script>
export default {
  name: 'at-date-picker',
  components: {
  },
  props: {
    value: {
      required: true,
    },
    weekDays: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    time: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
    success: {
      type: Boolean,
    },
    messages: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => {
      },
    },
  },
  data () {
    return {
      isOpen: false,
    }
  },
  computed: {
    valueProxy: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
    fullConfig () {
      return Object.assign({}, this.defaultConfig, this.config)
    },
    defaultConfig () {
      return {
        wrap: true,
        nextArrow: '<span aria-hidden="true" class="at-icon b_arrow1-right"/>',
        prevArrow: '<span aria-hidden="true" class="at-icon b_arrow1-left"/>',
        disableMobile: true, // doesn't work without this one at all
      }
    },
  },
  methods: {
    onOpen (selectedDates, dateStr, pcrObject) {
      const calendar = pcrObject.calendarContainer
      if (this.weekDays) {
        calendar.classList.add('flatpickr-calendar--show-days')
      }
    },
  },
}
</script>

<style lang="scss">
$datepickerActiveBackground: var(--primary-color);
$datepickerActiveColor: #ffffff;
$datepickerBackground: #ffffff;
$datepickerText: var(--primary-color);

$datepickerToday: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.5);
$datepickerOtherMonth: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.5);
$datepickerWeekday: $brand-secondary;
$datepickerDayHover: hsl(var(--primary-color-hue), 50%, 70%);
$datepickerDayRange: var(--primary-color);
$datepickerSeparatorColor: $separator-color;

$borderPadding: 0.5rem;
$dayPadding: 0.375rem;
$daySize: 2rem;
$dayMargin: 0.6rem;

.at-date-picker {
  position: relative;

  &__container {
    display: flex;
    position: relative;
    flex-wrap: wrap;
  }

  &__flatpickr {
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
    height: 100%;
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 0.5rem 0 0;
    border-bottom: 1px solid $brand-secondary;
    color: $brand-secondary;
    padding: 0.5rem;
    height: 2.375rem;
    cursor: pointer;
    background-color: $datepickerBackground;
    width: 2.2rem;
  }

  i + i {
    margin-left: 0.5rem;
  }
}
</style>
