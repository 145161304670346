<template>
  <transition v-if="value" name="fade">
    <div class="va-notification"
      :style="notificationStyle"
    >
      <div class="va-notification__simple" v-if="type === 'simple'">
        <div class="va-notification__simple__content">
          <slot/>
        </div>

        <div
          v-if="closeable"
          class="va-notification__simple__close-icon"
          @click="hideNotification()"
        >
          <va-icon :color="setColor()" :name="setIcon()" />
        </div>
      </div>

      <div class="va-notification__detailed" v-if="type === 'detailed'">

        <!-- card-expand arrow -->
        <button class="va-notification__detailed__expand-arrow" :class="{'expanded': expanded}" v-on:click="expand">
          <va-icon :name="'at-icon l_arrow1-right'"></va-icon>
        </button>
        <!-- card-expand arrow -->

        <div class="va-notification__detailed__content">
          <va-icon :color="setColor()" :name="setIcon()"></va-icon>
          <div>
            <h6>{{info.origin}}<span :class="setColor()">{{info.updated_at}}</span></h6>
            <h4>{{info.message}}</h4>

            <div v-show="expanded">
              <p class="va-notification__detailed__content__info">{{info.description}}</p>

              <div class="va-notification__detailed__content__actions">
                <div class="mt-3">
                  <va-button color="info">
                    <va-icon :name="'material-icons'">check</va-icon>
                    Done
                  </va-button>
                  <va-button color="danger">
                    <va-icon :name="'at-icon l_close1'"></va-icon>
                    Dismiss
                  </va-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="va-notification__detailed__details" v-show="expanded">
          <div class="va-notification__detailed__details__top">
            <h6>Actual</h6>
            <h5>{{info.currentValue}}<sub>{{info.currentValueUnit}}</sub></h5>
          </div>
          <div class="va-notification__detailed__details__bottom">
            <h6>Normal</h6>
            <h5>{{info.normalValue}}<sub>{{info.normalValueUnit}}</sub></h5>
          </div>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
// import { getHoverColor, getBoxShadowColor } from '@/services/atmosphere-ui'

export default {
  name: 'va-notification',
  computed: {
    notificationStyle () {
      return {
        borderColor: `var(--${this.setColor()}-color)`,
      }
    },
  },
  props: {
    type: {
      type: String,
      default: 'simple',
    },
    value: {
      type: Boolean,
      default: true,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => ({
        context_key: '',
        created_at: '',
        event_key: '',
        icon: '',
        id: '',
        level: 1,
        message: 'Temperature too High',
        tags: '',
        type: '',
        unread: false,
        origin: 'Room#2',
        updated_at: '06-01-2021 10:56',
        user_id: '',
        description: '',
        currentValue: '',
        currentValueUnit: '',
        normalValue: '',
        normalValueUnit: '',
      }),
    },
  },
  data () {
    return {
      expanded: false,
    }
  },
  methods: {
    hideNotification () {
      this.$emit('input', false)
      this.$store.commit('setSaveRecipe', { showState: false, success: false, recipeNumber: '' })
      this.$store.commit('setSaveInDatabase', { showState: false, success: false, identification: '' })
    },
    expand () {
      this.expanded = !this.expanded
    },
    setColor () {
      let color = 'info'

      switch (this.info.level) {
        case 0:
          color = 'success'
          break

        case 1:
          color = 'warning'
          break

        case 2:
          color = 'danger' // error
          break

        case 3:
          color = 'danger' // old
          break
      }

      return color
    },
    setIcon () {
      let icon = ''

      switch (this.info.level) {
        case 0:
          icon = 'at-icon l_notification_text'
          break

        case 1:
          icon = 'at-icon l_alert'
          break

        case 2:
          icon = 'at-icon l_alert'
          break

        case 3:
          icon = 'at-icon l_alert'
          break
      }
      return icon
    },
  },
}
</script>

<style lang='scss'>
// Notifications
$va-notification-margin-y: 0.25rem;
$va-notification-padding-x: 0.5rem;
$va-notification-padding-y: 0.75rem;
$va-notification-border: 7px;
$va-notification-border-radius: 0;
$va-notification-box-shadow: 0.125rem;

// Badge
$va-badge-margin-right: 0.5rem;
$va-badge-padding-x: 0.5rem;
$va-badge-padding-y: 0.125rem;
$va-badge-border-radius: 0.5rem;
$va-badge-font-size: 0.625rem;
$va-badge-letter-spacing: 0.0625rem;

// Close Icon
$va-close-icon-padding-x: 0.5rem;
$va-close-icon-padding-y: 0.0625rem;
$va-close-icon-font-size: 1.5rem;

.va-notification {
  @include shadow($radius: var(--shadow-radius));

  position: relative;
  background: #ffffff;
  padding: $va-notification-padding-y $va-notification-padding-x;
  margin: $va-notification-margin-y auto;
  border-left: $va-notification-border solid;
  border-radius: $va-notification-border-radius;

  &__simple {
    display: flex;
    align-items: center;

    &__content {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    &__close-icon {
      padding: $va-close-icon-padding-y $va-close-icon-padding-x;
      font-size: $va-close-icon-font-size;
      cursor: pointer;
    }
  }

  &__detailed {
    padding: 10px 10px 10px 10px;
    display: flex;

    &__content {
      margin-right: 20px;
      display: flex;
      justify-content: flex-start;

      h6,
      h4 {
        color: #545454;
        font-family: var(--font-family-body);
        font-weight: 500;
        letter-spacing: pxtorem(1.5);
      }

      h4 {
        font-size: pxtorem(24);
        margin-top: 5px;
      }

      h6 {
        font-size: pxtorem(16);

        span {
          color: hsl(var(--primary-color-hue), 30%, 60%);
          font-weight: 400;

          &::before {
            content: '●';
            margin: 0 10px;
          }

          &.info {
            &::before {
              color: var(--info-color);
            }
          }

          &.warning {
            &::before {
              color: var(--warning-color);
            }
          }

          &.danger {
            &::before {
              color: var(--danger-color);
            }
          }
        }
      }

      i {
        font-size: pxtorem(32);
        color: var(--info-color);
        margin-right: 15px;
      }

      &__info {
        font-size: pxtorem(18);
        color: #545454;
        margin-top: 0;
      }

      &__actions {
        .va-button {
          &:first-of-type {
            margin-left: 0;
          }

          i {
            font-size: pxtorem(18);
            color: #ffffff;
          }
        }
      }
    }

    &__details {
      display: flex;
      flex-direction: column;
      padding: 0 0 15px 0;
      margin-right: 20px;
      margin-left: 40px;

      &__top,
      &__bottom {
        h5,
        h6 {
          color: #545454;
          font-family: 'Montserrat', sans-serif;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        h5 {
          font-size: pxtorem(28);

          sub {
            font-size: pxtorem(18);
            margin-top: auto;
            margin-left: 3px;
            bottom: 0;
          }
        }

        h6 {
          font-size: pxtorem(12);
          letter-spacing: pxtorem(1.5);
          white-space: normal;
        }
      }

      &__top {
        margin-top: 20px;
      }

      &__bottom {
        margin-top: auto;

        h5,
        h6 {
          color: hsl(var(--primary-color-hue), 30%, 60%);
          font-weight: 400;
        }
      }
    }

    &__expand-arrow {
      right: 5px;
      background: none;
      border: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      color: var(--primary-color);
      opacity: 0.5;
      cursor: pointer;
      transition: opacity ease 0.5s;

      i {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: pxtorem(30);
      }

      &:hover {
        opacity: 1;
      }

      &.expanded {
        transform: rotate(180deg) translateY(50%);
      }
    }
  }

  @include media-breakpoint-down(xs) {
    @at-root {
      .va-notification {
        &__simple {
          &__content {
            flex-direction: column;
            align-items: flex-start;
          }

          &__close-icon {
            align-self: flex-start;
            display: flex;
            align-items: flex-start;
            padding: 0;
            padding-right: $va-close-icon-padding-x;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
