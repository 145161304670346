<template>
  <div class="settings-form" :style="{'--min-width': formMinWidth}">
    <div class="settings-form__header">
      <div class="settings-form__header-inner">
        <div class="settings-form__header-title">
          <va-icon name="at-icon l_settings1"/>
          <span>{{ title }}</span>
        </div>
        <div class="settings-form__header-subtitle"><span>{{ subtitle }}</span></div>
      </div>
    </div>

    <div class="settings-form__body">
      <template v-for="(settingFamily, settingFamilyName) in settingsArray">
        <div class="settings-form__setting-container" :key="'settingFamily_' + settingFamilyName">
          <div class="row">
            <div class="flex xs2" :key="'settingFamily_' + settingFamilyName">
              <div class="settings-form__family" :key="'settingFamily_' + settingFamilyName + '_title'">
                <div class="settings-form__family__title-icon" :key="'settingFamily_' + settingFamilyName + '_title-icon'">
                  <va-icon :name="getSettingIcon(settingFamilyName)"/>
                  <span>{{ $t(`growing.settingsFamily.${settingFamilyName}`) }}</span>
                </div>
              </div>
            </div>

            <div class="flex xs10" :key="'settingFamily_' + settingFamilyName + '_settings'">
              <div class="row">
                <template v-for="(setting, settingName) in settingFamily">
                  <div
                    :class="getSettingClass(settingsArray, settingName)"
                    :key="settingFamilyName + '_' + settingName"
                    v-if="settingName !== 'fill_delay' &&
                      settingName !== 'fill_delay_sensor' &&
                      settingName !== 'h2o2_injecion_rate' &&
                      settingName !== 'recover_tank' &&
                      settingName !== 'recovery_delay' &&
                      settingName !== 'recovery_delay_sensor' &&
                      settingName !== 'tanks_to_fill' &&
                      settingName !== 'auxiliary_events'"
                  >
                    <div class="settings-form__setting">
                      <!-- <template v-if="settingName === 'auxiliary_events'">
                        <div class="row settings-form__setting-object">
                          <div class="flex xs6 settings-form__setting-object-title" :key="settingFamilyName + '_' + settingName + '_title'">
                            <span>{{ $t(`growing.settingsEdition.${settingFamilyName}.${settingName}`) }}</span>
                          </div>
                          <div class="flex xs6 add-slot-button" :key="settingFamilyName + '_' + settingName+ '_add-button'">
                            <va-button flat small icon="at-icon card_plus"
                              @click="addAuxiliaryEventToList(settingName)"
                            >
                              {{ $t(`growing.settingsEdition.${settingFamilyName}.${settingName}`) }}
                            </va-button>
                          </div>
                        </div>

                        <div class="row slot mb-2" v-for="(event, index) in setting.value" :key="index">
                          <div class="flex xs12 offset--xs3 delete-slot-button" :key="index + '_delete'">
                            <va-button
                              @click="deleteAuxiliaryEvent(settingName, index)"
                              flat
                              small
                              color="danger"
                            >
                              <va-icon :name="'at-icon l_close1'"></va-icon>
                            </va-button>
                          </div>
                          <template v-for="(eventField, eventFieldName) in event">
                            <div v-if="eventFieldName.includes('fertilizer_1')" class="flex xs12" :key="index + '_' + eventFieldName + '_separator'">
                            </div>

                            <div class="flex xs3 settings-form__setting" :key="index + '_' + eventFieldName">
                              <va-input v-if="typeof(eventField) === 'string'"
                                :label="`${$t(`growing.settingsEdition.${settingFamilyName}.${eventFieldName}`)}`"
                                :disabled="!setting.editable"
                                v-model.number="setting.value[index][eventFieldName]"
                                :placeholder="eventField"
                                :error="!!errorMessages.length"
                                :error-messages="errorMessages"
                              />
                              <va-input v-else-if="typeof(eventField) === 'number'"
                                :label="`${$t(`growing.settingsEdition.${settingFamilyName}.${eventFieldName}`)}`"
                                :disabled="!setting.editable"
                                v-model.number="setting.value[index][eventFieldName]"
                                :placeholder="eventField.toString()"
                                step="1"
                                min="0"
                                :error="!!errorMessages.length"
                                :error-messages="errorMessages"
                              />
                            </div>
                          </template>
                        </div>
                      </template> -->

                      <!--<template v-else-if="settingName === 'tanks_to_fill'">
                         <va-select
                          :label="`${$t(`growing.settingsEdition.${settingFamilyName}.${settingName}`)}`"
                          v-model="setting.value"
                          textBy="description"
                          :options="freshWaterTanksOptions"
                        />
                      </template> -->

                      <template>
                        <va-input v-if="setting.type === 'string' || setting.type === 'irrigation-tank'"
                          :label="`${$t(`growing.settingsEdition.${settingFamilyName}.${settingName}`)}`"
                          :disabled="!setting.editable"
                          class=""
                          v-model="setting.value"
                          :placeholder="setting.value"
                          :error="!!errorMessages.length"
                          :error-messages="errorMessages"
                        />
                        <va-input v-else-if="setting.type === 'number'"
                          :label="`${$t(`growing.settingsEdition.${settingFamilyName}.${settingName}`)}`"
                          :disabled="!setting.editable"
                          class=""
                          v-model.number="setting.value"
                          :placeholder="setting.value.toString()"
                          step="1"
                          min="0"
                          :error="!!errorMessages.length"
                          :error-messages="errorMessages"
                        />
                        <template v-else-if="setting.type === 'timestamp'">
                          <form>
                            <at-date-picker v-if="settingName === 'default_light_duration'"
                              ref="datepicker"
                              :label="`${$t(`growing.settingsEdition.${settingFamilyName}.${settingName}`)}`"
                              :time="true"
                              :config="{
                                noCalendar: true,
                                enableTime: true,
                                enableSeconds: false,
                                dateFormat: 'H\\hr',
                                minuteIncrement: 60,
                                defaultHour: 0,
                                time_24hr: true,
                                inline: false,
                                locale: getCalendarLanguage(),
                              }"
                              v-model="setting.value"
                            />
                            <at-date-picker v-else
                              ref="datepicker"
                              :label="`${$t(`growing.settingsEdition.${settingFamilyName}.${settingName}`)}`"
                              :time="true"
                              :config="{
                                noCalendar: true,
                                enableTime: true,
                                enableSeconds: true,
                                dateFormat: 'H:i:S',
                                minuteIncrement: 1,
                                defaultHour: 0,
                                time_24hr: true,
                                inline: false,
                                locale: getCalendarLanguage(),
                              }"
                              v-model="setting.value"
                            />
                          </form>
                        </template>
                        <template v-else-if="setting.type === 'time-seconds'">
                          <form>
                            <at-date-picker
                              ref="datepicker"
                              :label="`${$t(`growing.settingsEdition.${settingFamilyName}.${settingName}`)}`"
                              :time="true"
                              :config="{
                                noCalendar: true,
                                enableTime: true,
                                enableSeconds: true,
                                dateFormat: 'H:i:S',
                                minuteIncrement: 1,
                                defaultHour: 0,
                                time_24hr: true,
                                inline: false,
                                locale: getCalendarLanguage(),
                              }"
                              v-model="setting.timeSecondsString"
                            />
                          </form>
                        </template>
                        <va-select v-else-if="setting.type === 'bool'"
                          :label="`${$t(`growing.settingsEdition.${settingFamilyName}.${settingName}`)}`"
                          v-model="setting.valueSelected"
                          textBy="description"
                          :options="boolOptions"
                        />
                        <va-select v-else-if="setting.type === 'sensor'"
                          :label="`${$t(`growing.settingsEdition.${settingFamilyName}.${settingName}`)}`"
                          v-model="setting.valueSelected"
                          textBy="description"
                          :options="sensorOptions"
                        />
                      </template>

                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment-timezone'
import { Italian } from 'flatpickr/dist/l10n/it.js'
import { english } from 'flatpickr/dist/l10n/default.js'
import { styleHelpers } from '@/services/atmosphere-ui'
import { fillDelaySensorOptions, emptyAuxiliaryEvent } from '@/data/settingsSettings'

export default {
  name: 'at-settings-form',
  props: {
    title: {
      type: String,
      default: 'Settings',
    },
    subtitle: {
      type: String,
      default: '',
    },
    settingsModal: {
      type: Boolean,
      default: false,
    },
    settingsArray: {
      type: Object,
    },
    freshWaterTanksOptions: {
      type: Array,
    },
  },
  data () {
    return {
      errorMessages: [],
      boolOptions: [
        {
          id: 1,
          description: 'true',
          value: true,
        },
        {
          id: 2,
          description: 'false',
          value: false,
        },
      ],
      sensorOptions: fillDelaySensorOptions,
      formMinWidth: '900px',
    }
  },
  computed: {
    computedClass () {
      return {
        'va-card--danger': this.$status.value === 'danger',
      }
    },
    computedStyle () {
      const style = {
        /* Update styles via JS Helpers (currently they are being changed in a global watcher) */
        // '--primary-color-hue': hex2hsl(this.$themes.primary).h,
        // '--primary-color': this.$themes.primary,

        /* Alternative: Update styles via JS Helpers */
        // background: cardGradient(this.$themes.primary, this.$status.value, this.$mode.value),
        // boxShadow: genShadow(this.$themes.primary, this.$status.value, this.$mode.value),
        // borderRadius: genRadius({ topLeft: '24px', bottomRight: '24px' }),
      }
      return style
    },
  },
  created () {
    for (const settingFamily in this.settingsArray) {
      for (const setting in this.settingsArray[settingFamily]) {
        if (this.settingsArray[settingFamily][setting].type === 'bool') {
          const option = this.boolOptions.find(option => option.value === this.settingsArray[settingFamily][setting].value)
          this.$set(this.settingsArray[settingFamily][setting], 'valueSelected', option)
        } else if (this.settingsArray[settingFamily][setting].type === 'sensor') {
          const option = this.sensorOptions.find(option => option.value === this.settingsArray[settingFamily][setting].value) ? this.sensorOptions.find(option => option.value === this.settingsArray[settingFamily][setting].value) : this.sensorOptions[0]
          this.$set(this.settingsArray[settingFamily][setting], 'valueSelected', option)
        } else if (this.settingsArray[settingFamily][setting].type === 'time-seconds') {
          this.$set(this.settingsArray[settingFamily][setting], 'timeSecondsString', moment.utc(this.settingsArray[settingFamily][setting].value * 1000).format('HH:mm:ss'))
        }
      }
    }
  },
  methods: {
    getSettingIcon (settingName) {
      let icon = 'at-icon l_'
      switch (settingName.toLowerCase()) {
        case 'irrigation':
          icon += 'water1drop'
          break
        case 'climate':
          icon += 'weather_cloud'
          break
        case 'light':
          icon += 'ledson'
          break
        case 'ros':
          icon += 'robot2'
          break
        default:
          break
      }

      return icon
    },
    getSettingClass (settingsArray, settingName) {
      let settingClass = 'flex '

      const maxLength = Object.keys(settingsArray).reduce((max, key) => {
        return max > Object.keys(settingsArray[key]).length ? max : Object.keys(settingsArray[key]).length
      }, 0)

      if (maxLength >= 3) {
        settingClass += 'xs4'
        this.formMinWidth = '900px'
      } else {
        settingClass += 'xs6'
        this.formMinWidth = '750px'
      }

      if (settingName === 'auxiliary_events') {
        settingClass = 'flex xs12'
      }

      return settingClass
    },
    getUnitFormated (units) {
      return styleHelpers.getOnlyUnitFormat(units)
    },
    addAuxiliaryEventToList (settingName) {
      if (settingName === 'auxiliary_events') {
        this.settingsArray.irrigation.auxiliary_events.value.push(JSON.parse(JSON.stringify(emptyAuxiliaryEvent)))
      }
    },
    deleteAuxiliaryEvent (settingName, index) {
      if (settingName === 'auxiliary_events') {
        this.settingsArray.irrigation.auxiliary_events.value.splice(index, 1)
      }
    },
    getCalendarLanguage () {
      if (Vue.i18n.locale() === 'it') {
        return Italian
      } else {
        return english
      }
    },
  },
}
</script>

<style lang="scss">
.settings-form {
  --min-width: '900px';

  min-width: var(--min-width);

  &__header {
    &-inner {
      display: flex;
      padding-bottom: 1.25rem;
      align-items: center;
    }

    &-title,
    &-subtitle {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: none;
      height: 3rem;

      span {
        display: flex;
        height: 30px;
        align-items: center;
      }
    }

    &-title {
      color: hsl(var(--primary-color-hue), 40%, 30%) !important;
      font-family: var(--font-family-title);
      font-weight: 900;
      font-size: pxtorem(18);
      letter-spacing: 0.09375rem;
      line-height: 1.75rem;
      white-space: normal;

      i {
        font-size: pxtorem(30);
        margin-right: 0;
      }
    }

    &-subtitle {
      color: hsl(var(--primary-color-hue), 40%, 30%) !important;

      &::before {
        content: '';
        height: 7px;
        width: 7px;
        background-color: var(--primary-color);
        margin: 9px;
        border-radius: 50%;
        display: inline-block;
      }
    }
  }

  &__setting-container {
    &:not(:first-child) {
      border-top: 1px solid hsla(var(--primary-color-hue), 20%, 83%, 1);
      padding-top: 0.8rem;
    }
  }

  &__family {
    display: flex;
    align-items: center;

    &__title-icon {
      display: flex;
      align-items: center;
      padding-left: 0.375rem;
      padding-right: 0.375rem;
      height: 26px;
    }

    span {
      display: flex;
      align-items: center;
    }

    i {
      font-size: pxtorem(24);
      margin-right: 0;
    }
  }

  &__setting {
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    &-object {
      margin-bottom: 1rem;
      background: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.1);

      &-title {
        display: flex;
        align-items: center;

        span {
          font-size: 0.55rem;
          text-transform: uppercase;
          color: hsl(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig));
          font-family: var(--font-family-body);
          font-weight: 700;
        }

        .va-button__content {
          font-size: 10px;
        }
      }
    }

    .slot {
      padding: 0.75rem 0.375rem 0 0.375rem;
      background: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.1);
    }

    .add-slot-button {
      text-align: right;
    }

    .delete-slot-button {
      position: relative;

      .va-button {
        position: absolute;
        top: -10px;
        right: 0;

        &.va-button--small {
          padding: 0.125rem 0.125rem;
        }

        i {
          font-size: 1.2rem;
        }
      }
    }

    .va-select__label,
    .va-input__container__label {
      text-transform: uppercase !important;
    }
  }
}

.va-modal {
  .va-modal__header {
    margin-bottom: 0;

    // h6 {
    //   text-transform: uppercase;
    //   font-weight: 600;
    //   font-size: pxtorem(16);
    // }
  }

  .va-modal__message {
    width: auto !important;
  }

  // .va-modal__message {
  //   width: 700px;

  //   .va-input__container__label,
  //   .va-select__label {
  //     font-family: var(--font-family-body);
  //     text-transform: none;
  //     top: 0.25rem;
  //     margin-bottom: 0;
  //     font-size: 0.55rem;
  //   }

  //   .va-input__container__input {
  //     font-family: var(--font-family-body);
  //   }

  //   .min_ref_value,
  //   .max_ref_value {
  //     .va-input__container {
  //       border-color: #f5a455 !important;
  //     }
  //   }

  //   .ref_value {
  //     .va-input__container {
  //       border-color: var(--success-color) !important;
  //     }
  //   }

  //   .min_value,
  //   .max_value {
  //     .va-input__container {
  //       border-color: #ff3333 !important;
  //     }
  //   }

  //   .transition_value {
  //     .va-input__container__input {
  //       text-align: center;
  //     }

  //     .va-input__container::before {
  //       content: '\e84d';
  //       font-family: "atmosphere-icons";
  //       position: absolute;
  //       left: 3.8em;
  //       top: 0.96em;
  //       font-size: 1.5em;
  //       color: var(--primary-color);
  //     }
  //   }

  //   .va-select__displayed-text {
  //     font-size: 0.95rem;
  //   }

  //   .title {
  //     border-bottom: 2px solid;
  //     margin-bottom: 20px;

  //     h6 {
  //       @include radius(0 15px 0 0);

  //       width: 120px;
  //       // width: 100px;
  //       display: inline-block;
  //       padding: 3px 20px 1px 10px;
  //       // padding: 3px 20px 1px 20px;
  //       color: #ffffff;
  //       font-size: pxtorem(14);
  //       text-transform: uppercase;
  //     }

  //     &.day {
  //       border-color: #94bdd1;

  //       h6 {
  //         background: #94bdd1;
  //       }
  //     }

  //     &.night {
  //       border-color: #4d80b2;

  //       h6 {
  //         background: #4d80b2;
  //       }
  //     }
  //   }

  //   // .settings-form {
  //   //   .slot {
  //   //     padding: 0.75rem 0.375rem 0 0.375rem;
  //   //     background: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.1);
  //   //   }

  //   //   .slot-total-info {
  //   //     display: flex;
  //   //     align-items: center;

  //   //     span {
  //   //       font-size: 0.7rem;
  //   //       text-transform: uppercase;

  //   //       b {
  //   //         font-size: 0.9rem;
  //   //         text-transform: none;
  //   //         color: var(--primary-color);
  //   //       }
  //   //     }
  //   //   }

  //   //   .add-slot-button {
  //   //     text-align: right;
  //   //   }

  //   //   .delete-slot-button {
  //   //     position: relative;

  //   //     .va-button {
  //   //       position: absolute;
  //   //       top: -10px;
  //   //       right: 0;

  //   //       &.va-button--small {
  //   //         padding: 0.125rem 0.125rem;
  //   //       }

  //   //       i {
  //   //         font-size: 1.2rem;
  //   //       }
  //   //     }
  //   //   }

  //   //   .flex {
  //   //     .va-input {
  //   //       // padding-right: 0.75rem;
  //   //       padding-left: 0.375rem;
  //   //       padding-right: 0.375rem;

  //   //       &.va-input-wrapper {
  //   //         margin-bottom: 0.75rem;
  //   //       }
  //   //     }
  //   //   }
  //   // }
  // }
}
</style>
