<template>
  <div class="app-navbar-actions">
    <chart-slider-dropdown class="app-navbar-actions__item" v-if="getTimelineButtonVisibility()"></chart-slider-dropdown>
    <!-- <log-dropdown class="app-navbar-actions__item"/> -->
    <!-- <color-dropdown class="app-navbar-actions__item" v-if="checkIsMobile()"/> -->
    <!-- <settings-dropdown class="app-navbar-actions__item"/> -->
    <notification-dropdown class="app-navbar-actions__item" v-if="checkIsMobile()"/>
    <language-dropdown class="app-navbar-actions__item" v-if="checkIsMobile()"/>
    <profile-dropdown class="app-navbar-actions__item app-navbar-actions__item--profile">
      <span>{{ setNameInitials() }}</span>
    </profile-dropdown>
  </div>
</template>

<script>
import ChartSliderDropdown from './dropdowns/ChartSliderDropdown'
import LanguageDropdown from './dropdowns/LanguageDropdown'
import ProfileDropdown from './dropdowns/ProfileDropdown'
import NotificationDropdown from './dropdowns/NotificationDropdown'
// import LogDropdown from './dropdowns/LogDropdown'
// import ColorDropdown from './dropdowns/ColorDropdown'
// import SettingsDropdown from './dropdowns/SettingsDropdown'
import { ColorThemeMixin } from '../../../../services/vuestic-ui'

export default {
  name: 'app-navbar-actions',
  mixins: [ColorThemeMixin],
  inject: ['contextConfig'],
  components: {
    ChartSliderDropdown,
    LanguageDropdown,
    // ColorDropdown,
    // SettingsDropdown,
    // LogDropdown,
    NotificationDropdown,
    ProfileDropdown,
  },
  data () {
    return {
      mobileWidth: 767,
    }
  },
  props: {
    userName: {
      type: String,
      default: '',
    },
    isTopBar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isTopBarProxy: {
      get () {
        return this.isTopBar
      },
      set (isTopBar) {
        this.$emit('update:isTopBar', isTopBar)
      },
    },
  },
  methods: {
    setNameInitials () {
      const user = this.userName.split('@')[0]
      const userNames = user.split('.')
      if (userNames.length < 2) {
        return userNames[0]
      }
      return userNames[0].charAt(0) + userNames[1].charAt(0)
    },
    getTimelineButtonVisibility () {
      return this.$store.state.showGrowingTimelineButton
    },
    checkIsMobile () {
      return window.matchMedia(`(min-width: ${this.mobileWidth}px)`).matches
    },
  },
}
</script>

<style lang="scss">
.app-navbar-actions {
  display: flex;
  align-items: center;

  &__item {
    // margin-top: 0.3rem;
    // padding: 0;
    margin-left: 1.25rem;
    margin-right: 1.25rem;

    &:last-of-type {
      margin-right: 0;
    }

    &--profile {
      display: flex;
      justify-content: center;
      margin: auto 0 auto 1.25rem;
    }

    @include media-breakpoint-down(lg) {
      margin-right: 0.25rem;
    }

    @include media-breakpoint-down(sm) {
      margin-right: 0;

      &:first-of-type {
        margin-left: 0;
      }

      &--profile {
        position: absolute;
        right: 0.75rem;
        top: 1.25rem;
        height: fit-content;
        margin: auto;
      }
    }
  }
}
</style>
