import Vue from 'vue'
import Vuex from 'vuex'
import VuexI18n from 'vuex-i18n' // load vuex i18n module
import app from './modules/app'
import auth from './modules/auth/index.js'

import * as getters from './getters'
// import * as authgetters from './modules/auth/getters.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true, // process.env.NODE_ENV !== 'production',
  getters,
  // authgetters,
  modules: {
    app,
    auth,
  },
  state: {
    timelineMinDate: null,
    saveRecipe: { showState: false, success: false, recipeNumber: '', message: '' },
    saveInDatabase: { showState: false, success: false, identification: '', message: '' },
    showGrowingTimelineButton: false,
    appLanguage: localStorage.getItem('appLanguage') || 'it', // || process.env.VUE_APP_I18N_LOCALE
  },
  mutations: {
    setTimelineButtonVisibility (state, val) {
      state.showGrowingTimelineButton = val
    },
    setTimelineMinDate (state, val) {
      state.timelineMinDate = val
    },
    setSaveRecipe (state, val) {
      state.saveRecipe = val
    },
    setSaveInDatabase (state, val) {
      state.saveInDatabase = val
    },
    setAppLanguage (state, language) {
      state.appLanguage = language
      localStorage.setItem('appLanguage', language)
    },
  },
})

Vue.use(VuexI18n.plugin, store)

export default store
