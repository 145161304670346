import { Role } from '../../../router/roles.js'

export const navigationRoutes = {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'growing',
      displayName: 'menu.growing',
      meta: {
        iconClass: 'at-icon l_menudashboard',
        authorize: [
          Role.AGRONOMIST,
          Role.PRODUCTION_MANAGER,
          Role.FACTORY_MANAGER,
          Role.PRODUCTION_MANAGER,
        ],
      },
      disabled: true,
      children: [
        {
          name: 'farm-overview',
          displayName: 'menu.growing-rooms',
          meta: {
          },
          children: [
            {
              displayName: 'concrete',
              name: 'room-details',
            },
          ],
        },
        {
          name: 'irrigation-overview',
          displayName: 'menu.irrigation',
          meta: {
          },
          children: [
            {
              displayName: 'concrete',
              name: 'irrigation-details',
            },
          ],
        },
        {
          name: 'recipes-growing',
          displayName: 'menu.recipes',
          children: [
            {
              name: 'recipes-growing',
              displayName: 'menu.growing-recipes',
            },
          ],
        },
      ],
    },
  ],
}
