var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-form",style:({'--min-width': _vm.formMinWidth})},[_c('div',{staticClass:"settings-form__header"},[_c('div',{staticClass:"settings-form__header-inner"},[_c('div',{staticClass:"settings-form__header-title"},[_c('va-icon',{attrs:{"name":"at-icon l_settings1"}}),_c('span',[_vm._v(_vm._s(_vm.title))])],1),_c('div',{staticClass:"settings-form__header-subtitle"},[_c('span',[_vm._v(_vm._s(_vm.subtitle))])])])]),_c('div',{staticClass:"settings-form__body"},[_vm._l((_vm.settingsArray),function(settingFamily,settingFamilyName){return [_c('div',{key:'settingFamily_' + settingFamilyName,staticClass:"settings-form__setting-container"},[_c('div',{staticClass:"row"},[_c('div',{key:'settingFamily_' + settingFamilyName,staticClass:"flex xs2"},[_c('div',{key:'settingFamily_' + settingFamilyName + '_title',staticClass:"settings-form__family"},[_c('div',{key:'settingFamily_' + settingFamilyName + '_title-icon',staticClass:"settings-form__family__title-icon"},[_c('va-icon',{attrs:{"name":_vm.getSettingIcon(settingFamilyName)}}),_c('span',[_vm._v(_vm._s(_vm.$t(("growing.settingsFamily." + settingFamilyName))))])],1)])]),_c('div',{key:'settingFamily_' + settingFamilyName + '_settings',staticClass:"flex xs10"},[_c('div',{staticClass:"row"},[_vm._l((settingFamily),function(setting,settingName){return [(settingName !== 'fill_delay' &&
                    settingName !== 'fill_delay_sensor' &&
                    settingName !== 'h2o2_injecion_rate' &&
                    settingName !== 'recover_tank' &&
                    settingName !== 'recovery_delay' &&
                    settingName !== 'recovery_delay_sensor' &&
                    settingName !== 'tanks_to_fill' &&
                    settingName !== 'auxiliary_events')?_c('div',{key:settingFamilyName + '_' + settingName,class:_vm.getSettingClass(_vm.settingsArray, settingName)},[_c('div',{staticClass:"settings-form__setting"},[[(setting.type === 'string' || setting.type === 'irrigation-tank')?_c('va-input',{attrs:{"label":("" + (_vm.$t(("growing.settingsEdition." + settingFamilyName + "." + settingName)))),"disabled":!setting.editable,"placeholder":setting.value,"error":!!_vm.errorMessages.length,"error-messages":_vm.errorMessages},model:{value:(setting.value),callback:function ($$v) {_vm.$set(setting, "value", $$v)},expression:"setting.value"}}):(setting.type === 'number')?_c('va-input',{attrs:{"label":("" + (_vm.$t(("growing.settingsEdition." + settingFamilyName + "." + settingName)))),"disabled":!setting.editable,"placeholder":setting.value.toString(),"step":"1","min":"0","error":!!_vm.errorMessages.length,"error-messages":_vm.errorMessages},model:{value:(setting.value),callback:function ($$v) {_vm.$set(setting, "value", _vm._n($$v))},expression:"setting.value"}}):(setting.type === 'timestamp')?[_c('form',[(settingName === 'default_light_duration')?_c('at-date-picker',{ref:"datepicker",refInFor:true,attrs:{"label":("" + (_vm.$t(("growing.settingsEdition." + settingFamilyName + "." + settingName)))),"time":true,"config":{
                              noCalendar: true,
                              enableTime: true,
                              enableSeconds: false,
                              dateFormat: 'H\\hr',
                              minuteIncrement: 60,
                              defaultHour: 0,
                              time_24hr: true,
                              inline: false,
                              locale: _vm.getCalendarLanguage(),
                            }},model:{value:(setting.value),callback:function ($$v) {_vm.$set(setting, "value", $$v)},expression:"setting.value"}}):_c('at-date-picker',{ref:"datepicker",refInFor:true,attrs:{"label":("" + (_vm.$t(("growing.settingsEdition." + settingFamilyName + "." + settingName)))),"time":true,"config":{
                              noCalendar: true,
                              enableTime: true,
                              enableSeconds: true,
                              dateFormat: 'H:i:S',
                              minuteIncrement: 1,
                              defaultHour: 0,
                              time_24hr: true,
                              inline: false,
                              locale: _vm.getCalendarLanguage(),
                            }},model:{value:(setting.value),callback:function ($$v) {_vm.$set(setting, "value", $$v)},expression:"setting.value"}})],1)]:(setting.type === 'time-seconds')?[_c('form',[_c('at-date-picker',{ref:"datepicker",refInFor:true,attrs:{"label":("" + (_vm.$t(("growing.settingsEdition." + settingFamilyName + "." + settingName)))),"time":true,"config":{
                              noCalendar: true,
                              enableTime: true,
                              enableSeconds: true,
                              dateFormat: 'H:i:S',
                              minuteIncrement: 1,
                              defaultHour: 0,
                              time_24hr: true,
                              inline: false,
                              locale: _vm.getCalendarLanguage(),
                            }},model:{value:(setting.timeSecondsString),callback:function ($$v) {_vm.$set(setting, "timeSecondsString", $$v)},expression:"setting.timeSecondsString"}})],1)]:(setting.type === 'bool')?_c('va-select',{attrs:{"label":("" + (_vm.$t(("growing.settingsEdition." + settingFamilyName + "." + settingName)))),"textBy":"description","options":_vm.boolOptions},model:{value:(setting.valueSelected),callback:function ($$v) {_vm.$set(setting, "valueSelected", $$v)},expression:"setting.valueSelected"}}):(setting.type === 'sensor')?_c('va-select',{attrs:{"label":("" + (_vm.$t(("growing.settingsEdition." + settingFamilyName + "." + settingName)))),"textBy":"description","options":_vm.sensorOptions},model:{value:(setting.valueSelected),callback:function ($$v) {_vm.$set(setting, "valueSelected", $$v)},expression:"setting.valueSelected"}}):_vm._e()]],2)]):_vm._e()]})],2)])])])]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }