<template>
  <div class="irrigation-events__form">
    <template v-if="addEvent">
      <div class="flex xs12">
        <div class="flex md12 xs6 pb-3">
          <va-select
            :label="$t('tanks.actions.select_action').toUpperCase()"
            v-model="selectedIrrigationEvent"
            textBy="selectionActionName"
            :options="irrigationPossibleEvents"
          />
        </div>

      </div>
      <div class="flex xs12">
        <div class="row">
          <template v-for="(setting, name) in selectedIrrigationEvent.actionSettings">
            <template v-if="name === 'start_time_stamp'">
              <div class="flex xs6 mb-3" :class="'irrigation-event__setting'" :key="name">
                <form>
                  <at-date-picker
                    ref="datepicker"
                    :label="`${$t(`growing.irrigationEventsEdition.${'start_time_stamp'}`).toUpperCase()}`"
                    :time="true"
                    :config="{
                      noCalendar: true,
                      enableTime: true,
                      enableSeconds: true,
                      dateFormat: 'H:i:S',
                      minuteIncrement: 1,
                      defaultHour: 0,
                      time_24hr: true,
                      inline: false,
                      locale: getCalendarLanguage(),
                    }"
                    v-model="selectedData.startTime"
                  />
                </form>
              </div>
              <div class="flex xs6 mb-3" :key="name + '__dummy'"></div>
            </template>

            <div v-else-if="name === 'duration'" class="flex xs6 mb-3" :class="'irrigation-event__setting'" :key="name">
              <form>
                <at-date-picker
                  ref="datepicker"
                  :label="`${$t(`growing.irrigationEventsEdition.${'duration'}`).toUpperCase()}`"
                  :time="true"
                  :config="{
                    noCalendar: true,
                    enableTime: true,
                    enableSeconds: true,
                    dateFormat: 'H:i:S',
                    minuteIncrement: 1,
                    defaultHour: 0,
                    time_24hr: true,
                    inline: false,
                    locale: getCalendarLanguage(),
                  }"
                  v-model="selectedData.durationString"
                />
              </form>
            </div>

            <div v-else-if="name === 'originTank'" class="flex xs6 mb-3" :class="'irrigation-event__setting'" :key="name">
              <va-select v-if="name === 'originTank'"
                :label="$t('tanks.actions.originTank').toUpperCase()"
                v-model="selectedData.originTank"
                textBy="description"
                :options="irrigationEventOriginOptions"
                :error="!!originTankErrors"
                :placeholder="originTankErrors"
              />
            </div>

            <div v-else-if="name === 'destinationTank'" class="flex xs6 mb-3" :class="'irrigation-event__setting'" :key="name">
              <va-select
                :label="$t('tanks.actions.destinationTank').toUpperCase()"
                v-model="selectedData.destinationTank"
                textBy="description"
                :options="irrigationEventDestinationOptions"
                :error="!!destinationTankErrors"
                :placeholder="destinationTankErrors"
              />
            </div>

            <div v-else-if="name === 'volume'" class="flex xs6 mb-3" :class="'irrigation-event__setting'" :key="name">
              <va-input
                v-model="selectedData.volume"
                :label="$t(`tanks.actions.${name}`).toUpperCase() + ' ' + getOnlyUnitFormat('m3') "
                type="number"
                step="1"
                placeholder="0"
                min="0"
                :error="!!volumeErrors.length"
                :error-messages="volumeErrors"
              />
            </div>

            <div v-else-if="name === 'overfill'" class="flex xs6 mb-3" :class="'irrigation-event__setting'" :key="name">
              <va-checkbox
                :label="$t('tanks.actions.overfill')"
                v-model="selectedData.overfill"
              />
            </div>

            <div v-else-if="name === 'room'" class="flex xs6 mb-3" :class="'irrigation-event__setting'" :key="name">
              <va-select
                :label="$t('irrigation.room')"
                v-model="selectedData.room"
                textBy="description"
                :options="controlGroupsOptions"
                :error="!!roomErrors"
                :placeholder="roomErrors"
              />
            </div>

            <div v-else-if="name === 'side1'" class="flex xs6 mb-3" :class="'irrigation-event__setting'" :key="name">
              <va-select
                :label="$t('irrigation.side1')"
                v-model="selectedData.side1"
                textBy="description"
                :options="side1Options"
                :error="!!sideErrors"
                :placeholder="sideErrors"
                :noOptionsText="noSideOptionsText"
              />
            </div>

            <div v-else-if="name === 'side2'" class="flex xs6 mb-3" :class="'irrigation-event__setting'" :key="name">
              <va-select
                :label="$t('irrigation.side2')"
                v-model="selectedData.side2"
                textBy="description"
                :options="side2Options"
                :error="!!sideErrors"
                :placeholder="sideErrors"
                :noOptionsText="noSideOptionsText"
              />
            </div>

            <div v-else-if="name === 'water_duration'" class="flex xs6 mb-3" :class="'irrigation-event__setting'" :key="name">
              <form>
                <at-date-picker
                  ref="datepicker"
                  :label="`${$t(`growing.irrigationEventsEdition.${name}`).toUpperCase()}`"
                  :time="true"
                  :config="{
                    noCalendar: true,
                    enableTime: true,
                    enableSeconds: true,
                    dateFormat: 'H:i:S',
                    minuteIncrement: 1,
                    defaultHour: 0,
                    time_24hr: true,
                    inline: false,
                    locale: getCalendarLanguage(),
                  }"
                  v-model="selectedData.waterDurationString"
                />
              </form>
            </div>

            <div v-else-if="name === 'water_volume'" class="flex xs6 mb-3" :class="'irrigation-event__setting'" :key="name">
              <va-input
                v-model="selectedData.waterVolume"
                :label="$t(`tanks.actions.${name}`).toUpperCase() + ' ' + getOnlyUnitFormat('m3') "
                type="number"
                step="1"
                placeholder="0"
                min="0"
                :error="!!waterVolumeErrors.length"
                :error-messages="waterVolumeErrors"
              />
            </div>

            <div v-else-if="name === 'sanitizer_duration'" class="flex xs6 mb-3" :class="'irrigation-event__setting'" :key="name">
              <form>
                <at-date-picker
                  ref="datepicker"
                  :label="`${$t(`growing.irrigationEventsEdition.${name}`).toUpperCase()}`"
                  :time="true"
                  :config="{
                    noCalendar: true,
                    enableTime: true,
                    enableSeconds: true,
                    dateFormat: 'H:i:S',
                    minuteIncrement: 1,
                    defaultHour: 0,
                    time_24hr: true,
                    inline: false,
                    locale: getCalendarLanguage(),
                  }"
                  v-model="selectedData.sanitizerDurationString"
                />
              </form>
            </div>

            <div v-else-if="name === 'sanitizer_volume'" class="flex xs6 mb-3" :class="'irrigation-event__setting'" :key="name">
              <va-input
                v-model="selectedData.sanitizerVolume"
                :label="$t(`tanks.actions.${name}`).toUpperCase() + ' ' + getOnlyUnitFormat('m3') "
                type="number"
                step="1"
                placeholder="0"
                min="0"
                :error="!!sanitizerVolumeErrors.length"
                :error-messages="sanitizerVolumeErrors"
              />
            </div>

            <div v-else-if="name === 'transition_value'" class="flex xs6 mb-3" :class="'irrigation-event__setting'" :key="name">
              <form>
                <at-date-picker
                  ref="datepicker"
                  :label="`${$t(`growing.irrigationEventsEdition.${name}`).toUpperCase()}`"
                  :time="true"
                  :config="{
                    noCalendar: true,
                    enableTime: true,
                    enableSeconds: true,
                    dateFormat: 'H:i:S',
                    minuteIncrement: 1,
                    defaultHour: 0,
                    time_24hr: true,
                    inline: false,
                    locale: getCalendarLanguage(),
                  }"
                  v-model="selectedData.sanitizerTransitionValueString"
                />
              </form>
            </div>

            <div v-else-if="name === 'ph'" class="flex xs12" :class="'irrigation-event__setting'" :key="name">
              <div class="title">
                <h6>{{ $t('data.ph')}}</h6>
              </div>
              <div class="row">
                <div class="flex xs2 mr-3">
                  <va-input
                    class="min_value"
                    v-model.number="selectedData.phMin"
                    label="MIN"
                    type="number"
                    step="1"
                    min="0"
                    :error="!!phMinErrors.length"
                    :error-messages="phMinErrors"
                  />
                </div>
                <div class="flex xs2 mr-3">
                  <va-input
                    class="min_ref_value"
                    v-model.number="selectedData.phRefMin"
                    label="REF MIN"
                    type="number"
                    step="1"
                    :min="selectedData.phMin"
                    :error="!!phRefMinErrors.length"
                    :error-messages="phRefMinErrors"
                  />
                </div>
                <div class="flex xs2 mr-3">
                  <va-input
                    class="ref_value"
                    v-model.number="selectedData.phRef"
                    label="REF"
                    type="number"
                    step="1"
                    :min="selectedData.phRefMin"
                    :error="!!phRefErrors.length"
                    :error-messages="phRefErrors"
                  />
                </div>
                <div class="flex xs2 mr-3">
                  <va-input
                    class="max_ref_value"
                    v-model.number="selectedData.phRefMax"
                    label="REF MAX"
                    type="number"
                    step="1"
                    :min="selectedData.phRef"
                    :error="!!phRefMaxErrors.length"
                    :error-messages="phRefMaxErrors"
                  />
                </div>
                <div class="flex xs2 mr-3">
                  <va-input
                    class="max_value"
                    v-model.number="selectedData.phMax"
                    label="MAX"
                    type="number"
                    step="1"
                    :min="selectedData.phRefMax"
                    :error="!!phMaxErrors.length"
                    :error-messages="phMaxErrors"
                  />
                </div>
              </div>
            </div>

            <div v-else-if="name === 'ec'" class="flex xs12" :class="'irrigation-event__setting'" :key="name">
              <div class="title">
                <h6>{{ $t('data.electrical_conductivity') }}</h6>
              </div>
              <div class="row">
                <div class="flex xs2 mr-3">
                  <va-input
                    class="min_value"
                    v-model.number="selectedData.ecMin"
                    :label="`MIN ${getOnlyUnitFormat('mS/cm')}`"
                    type="number"
                    step="1"
                    min="0"
                    :error="!!ecMinErrors.length"
                    :error-messages="ecMinErrors"
                  />
                </div>
                <div class="flex xs2 mr-3">
                  <va-input
                    class="min_ref_value"
                    v-model.number="selectedData.ecRefMin"
                    :label="`REF MIN ${getOnlyUnitFormat('mS/cm')}`"
                    type="number"
                    step="1"
                    :min="selectedData.ecMin"
                    :error="!!ecRefMinErrors.length"
                    :error-messages="ecRefMinErrors"
                  />
                </div>
                <div class="flex xs2 mr-3">
                  <va-input
                    class="ref_value"
                    v-model.number="selectedData.ecRef"
                    :label="`REF ${getOnlyUnitFormat('mS/cm')}`"
                    type="number"
                    step="1"
                    :min="selectedData.ecRefMin"
                    :error="!!ecRefErrors.length"
                    :error-messages="ecRefErrors"
                  />
                </div>
                <div class="flex xs2 mr-3">
                  <va-input
                    class="max_ref_value"
                    v-model.number="selectedData.ecRefMax"
                    :label="`REF MAX ${getOnlyUnitFormat('mS/cm')}`"
                    type="number"
                    step="1"
                    :min="selectedData.ecRef"
                    :error="!!ecRefMaxErrors.length"
                    :error-messages="ecRefMaxErrors"
                  />
                </div>
                <div class="flex xs2 mr-3">
                  <va-input
                    class="max_value"
                    v-model.number="selectedData.ecMax"
                    :label="`MAX ${getOnlyUnitFormat('mS/cm')}`"
                    type="number"
                    step="1"
                    :min="selectedData.ecRefMax"
                    :error="!!ecMaxErrors.length"
                    :error-messages="ecMaxErrors"
                  />
                </div>
              </div>
            </div>

            <div v-else-if="name === 'fertilizers' || name === 'fertilisers'" class="flex xs12" :class="'irrigation-event__setting'" :key="name">
              <div class="title">
                <h6>{{ $t('data.fertilizers') }}</h6>
              </div>
              <div class="row">
                <template v-for="(tank, index) in fertilisersOptions">
                  <div class="fertilizers flex xs3" :key="tank.id">
                    <va-input
                      v-model.number="selectedData.fertilizers[tank.tank_id].val"
                      :label="tank.description.toUpperCase() + ' ' + '%'"
                      type="number"
                      step="1"
                      placeholder="0"
                      min="0"
                      max="100"
                    />
                    <div class="fertilizers_errors" :key="'error_' + tank.id" v-if="fertilizersErrors.length > 0">
                      {{ fertilizersErrors[index][0] }}
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <div v-else-if="name === 'controlAreas'" class="flex xs12" :class="'irrigation-event__setting'" :key="name">
              <div class="title day">
                <h6>{{ 'Control areas' }}</h6>
              </div>
              <div class="row">
                <div class="flex xs4 mb-5" v-for="room in controlAreaOptions" :key="room.id">
                  <at-selection-tree isOpen :label="room.name" :ref="`${room.name}_tree`" @toggleSelection="toggleSelection">
                    <va-tree-node v-for="area in room.control_areas" :key="room.id + '_' + area.id">
                      <va-checkbox
                        :label="area.name"
                        v-model="area.selected"
                        :disabled="area.readonly"
                      />
                    </va-tree-node>
                  </at-selection-tree>
                </div>
              </div>
            </div>

          </template>
        </div>
      </div>
    </template>

    <!-- modify event -->
    <template v-else>
      <div class="row mb-3">
        <div class="row mt-3">
          <div class="flex xs6 mb-3" v-for="option in editableOptions" :key="option">
            <template v-if="event[option].ref !== undefined">
              <template v-if="option === 'duration'">
                <form>
                  <at-date-picker class="irrigation-event__datepicker"
                    ref="datepicker"
                    :label="`${$t(`growing.irrigationEventsEdition.${option}`).toUpperCase()}`"
                    :time="true"
                    :config="{
                      noCalendar: true,
                      enableTime: true,
                      enableSeconds: true,
                      dateFormat: 'H:i:S',
                      minuteIncrement: 1,
                      defaultHour: 0,
                      time_24hr: true,
                      inline: false,
                      locale: getCalendarLanguage(),
                    }"
                    v-model="event.durationString"
                  />
                </form>
              </template>
              <va-input v-else-if="typeof(event[option].ref) === 'string'"
                v-model.number="event[option].ref"
                :label="`${$t(`growing.irrigationEventsEdition.${option}`).toUpperCase()}`"
                :placeholder="event[option].ref"
                :error="!!errorMessages.length"
                :error-messages="errorMessages"
              />
              <va-input v-else-if="typeof(event[option].ref) === 'number'"
                v-model.number="event[option].ref"
                :placeholder="(Math.round(event[option].ref * 100) / 100).toString()"
                :label="`${$t(`growing.irrigationEventsEdition.${option}`).toUpperCase()}`"
                min="0"
                :error="!!errorMessages.length"
                :error-messages="errorMessages"
              />
            </template>

            <template v-else>
              <template v-if="option === 'duration'">
                <form>
                  <at-date-picker class="irrigation-event__datepicker"
                    ref="datepicker"
                    :label="`${$t(`growing.irrigationEventsEdition.${option}`).toUpperCase()}`"
                    :time="true"
                    :config="{
                      noCalendar: true,
                      enableTime: true,
                      enableSeconds: true,
                      dateFormat: 'H:i:S',
                      minuteIncrement: 1,
                      defaultHour: 0,
                      time_24hr: true,
                      inline: false,
                      locale: getCalendarLanguage(),
                    }"
                    v-model="event.durationString"
                  />
                </form>
              </template>
              <template v-else-if="option === 'start_time_stamp'">
                <form>
                  <at-date-picker class="irrigation-event__datepicker"
                    ref="datepicker"
                    :label="`${$t(`growing.irrigationEventsEdition.${option}`).toUpperCase()}`"
                    :time="true"
                    :config="{
                      noCalendar: true,
                      enableTime: true,
                      enableSeconds: true,
                      dateFormat: 'H:i:S',
                      minuteIncrement: 1,
                      defaultHour: 0,
                      time_24hr: true,
                      inline: false,
                      locale: getCalendarLanguage(),
                    }"
                    v-model="event.startTime"
                  />
                </form>
              </template>

              <template v-else-if="(event.action.toLowerCase() === 'irrigation' || event.action.toLowerCase() === 'fill') && option === 'origin'">
                <va-select
                  :label="$t('tanks.actions.originTank').toUpperCase()"
                  v-model="event.originTank"
                  textBy="description"
                  :options="event.originTankOptions"
                  :error="!!originTankErrors"
                  :placeholder="originTankErrors"
                />
              </template>

              <template v-else>
                <va-input v-if="typeof(event[option]) === 'string'"
                  :disabled="option === 'destination' ||
                    (option === 'origin' && event.action.toLowerCase() === 'recalibration') ||
                    (option === 'origin' && event.action.toLowerCase() === 'circulation') ||
                    (option === 'origin' && event.action.toLowerCase() === 'circulate')"
                  v-model.number="event[option]"
                  :label="`${$t(`growing.irrigationEventsEdition.${option}`).toUpperCase()}`"
                  :placeholder="event[option]"
                  :error="!!errorMessages.length"
                  :error-messages="errorMessages"
                />
                <va-input v-else-if="typeof(event[option]) === 'number'"
                  v-model.number="event[option]"
                  :placeholder="event[option].toString()"
                  :label="`${$t(`growing.irrigationEventsEdition.${option}`).toUpperCase()}`"
                  min="0"
                  :error="!!errorMessages.length"
                  :error-messages="errorMessages"
                />
              </template>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import { Italian } from 'flatpickr/dist/l10n/it.js'
import { english } from 'flatpickr/dist/l10n/default.js'
import { getOnlyUnitFormat } from '@/services/atmosphere-ui/helpers/StyleHelpers'
import { getUTCtimeString, getLocalNowTimeDate } from '@/services/atmosphere-ui/helpers/TimeHelpers'
import moment from 'moment-timezone'

export default {
  name: 'at-irrigation-event-form',
  props: {
    irrigationLineId: {
      type: Number,
    },
    event: {
      type: Object,
    },
    editableOptions: {
      type: Array,
    },
    addEvent: {
      type: Boolean,
      default: false,
    },
    irrigationPossibleEvents: {
      type: Array,
    },
    controlGroups: {
      type: Array,
    },
    tanks: {
      type: Object,
    },
  },
  data () {
    return {
      errorMessages: [],
      selectedIrrigationEvent: {},
      selectedData: {
        originTank: '',
        destinationTank: '',
        room: '',
        side1: '',
        side2: '',
        duration: '',
        volume: '',
        overfill: false,
        fertilizers: [],
        durationString: '',
        controlAreas: [],
        waterDuration: '',
        waterVolume: '',
        sanitizerDuration: '',
        sanitizerVolume: '',
        sanitizerTransitionValue: '',
        startTime: this.getNowFactoryTimeString(),
      },
      originTankErrors: '',
      destinationTankErrors: '',
      durationErrors: [],
      volumeErrors: [],
      roomErrors: '',
      sideErrors: '',
      waterDurationErrors: [],
      waterVolumeErrors: [],
      sanitizerDurationErrors: [],
      sanitizerVolumeErrors: [],
      sanitizerTransitionValueErrors: [],
      phMinErrors: [],
      phRefMinErrors: [],
      phRefErrors: [],
      phRefMaxErrors: [],
      phMaxErrors: [],
      ecMinErrors: [],
      ecRefMinErrors: [],
      ecRefErrors: [],
      ecRefMaxErrors: [],
      ecMaxErrors: [],
      fertilizersErrors: [],
    }
  },
  mounted () {
    this.selectedData.fertilizers = this.fertilisersOptions.reduce((fertilizerArray, fertilizer) => ({
      ...fertilizerArray,
      [fertilizer.tank_id]: {
        ...fertilizer,
        val: null,
      },
    }), {})
  },
  computed: {
    irrigationEventOriginOptions () {
      const options = []
      let j = 0

      if (this.selectedIrrigationEvent.actionName === 'irrigate') {
        if (this.tanks['fresh-water-tank']) {
          this.$set(options, 0,
            {
              id: 1,
              description: this.tanks['fresh-water-tank'][0].name,
              ros_irrigation_id: this.tanks['fresh-water-tank'][0].settings.ros.ros_irrigation_id.value,
            },
          )
          j = 1
        }

        for (let i = 0; i < this.tanks['drainage-tank'].length; i++) {
          this.$set(options, i + j,
            {
              id: i + j + 1,
              description: this.tanks['drainage-tank'][i].name,
              ros_irrigation_id: this.tanks['drainage-tank'][i].settings.ros.ros_irrigation_id.value,
            },
          )
        }
      } else if (this.selectedIrrigationEvent.actionName === 'fill_water_tank') {
        return this.selectedIrrigationEvent.originTankOptions
      } else if (this.selectedIrrigationEvent.actionName === 'fill_tank') {
        this.$set(options, 0,
          {
            id: 1,
            description: this.tanks['fresh-water-tank'][0].name,
            ros_irrigation_id: this.tanks['fresh-water-tank'][0].settings.ros.ros_irrigation_id.value,
          },
        )

        const destinationId = this.selectedData.destinationTank ? this.selectedData.destinationTank.ros_irrigation_id : ''
        let optionsNumber = 2

        for (let i = 0; i < this.tanks['drainage-tank'].length; i++) {
          if (destinationId !== this.tanks['drainage-tank'][i].settings.ros.ros_irrigation_id.value) {
            this.$set(options, optionsNumber - 1,
              {
                id: optionsNumber,
                description: this.tanks['drainage-tank'][i].name,
                ros_irrigation_id: this.tanks['drainage-tank'][i].settings.ros.ros_irrigation_id.value,
              },
            )
            optionsNumber++
          }
        }
      } else if (this.selectedIrrigationEvent.actionName === 'empty_tank_to_waste') {
        let j = 1
        for (const tank in this.tanks) {
          for (let i = 0; i < this.tanks[tank].length; i++) {
            options.push(
              {
                id: j,
                description: this.tanks[tank][i].name,
                ros_irrigation_id: this.tanks[tank][i].settings.ros.ros_irrigation_id.value,
              },
            )
            j++
          }
        }
      } else if (this.selectedIrrigationEvent.actionName === 'circulate_tank' || this.selectedIrrigationEvent.actionName === 'recalibrate_tank') {
        for (let i = 0; i < this.tanks['drainage-tank'].length; i++) {
          this.$set(options, i,
            {
              id: i + 2,
              description: this.tanks['drainage-tank'][i].name,
              ros_irrigation_id: this.tanks['drainage-tank'][i].settings.ros.ros_irrigation_id.value,
            },
          )
        }
      } else if (this.selectedIrrigationEvent.actionName === 'recover_tank') {
        for (let i = 0; i < this.tanks['recovery-tank'].length; i++) {
          this.$set(options, i,
            {
              id: i + 1,
              description: this.tanks['recovery-tank'][i].name,
              ros_irrigation_id: this.tanks['recovery-tank'][i].settings.ros.ros_irrigation_id.value,
            },
          )
        }
      } else if (this.selectedIrrigationEvent.actionName === 'disinfection_tank') {
        this.$set(options, 0,
          {
            id: 1,
            description: this.tanks['fresh-water-tank'][0].name,
            ros_irrigation_id: this.tanks['fresh-water-tank'][0].settings.ros.ros_irrigation_id.value,
          },
        )
      }

      return options
    },
    irrigationEventDestinationOptions () {
      const options = []

      if (this.selectedIrrigationEvent.actionName === 'fill_water_tank') {
        for (let i = 0; i < this.tanks['drainage-tank'].length; i++) {
          this.$set(options, i,
            {
              id: i + 1,
              description: this.tanks['drainage-tank'][i].name,
              ros_irrigation_id: this.tanks['drainage-tank'][i].settings.ros.ros_irrigation_id.value,
            },
          )
        }
      } else if (this.selectedIrrigationEvent.actionName === 'fill_tank') {
        const originId = this.selectedData.originTank ? this.selectedData.originTank.ros_irrigation_id : ''
        let optionsNumber = 1

        for (let i = 0; i < this.tanks['drainage-tank'].length; i++) {
          if (originId !== this.tanks['drainage-tank'][i].settings.ros.ros_irrigation_id.value) {
            this.$set(options, optionsNumber - 1,
              {
                id: optionsNumber,
                description: this.tanks['drainage-tank'][i].name,
                ros_irrigation_id: this.tanks['drainage-tank'][i].settings.ros.ros_irrigation_id.value,
              },
            )
            optionsNumber++
          }
        }
      } else if (this.selectedIrrigationEvent.actionName === 'recover_tank') {
        for (let i = 0; i < this.tanks['drainage-tank'].length; i++) {
          this.$set(options, i,
            {
              id: i + 1,
              description: this.tanks['drainage-tank'][i].name,
              ros_irrigation_id: this.tanks['drainage-tank'][i].settings.ros.ros_irrigation_id.value,
            },
          )
        }
      }

      return options
    },
    controlGroupsOptions () {
      let roomOptions = []

      roomOptions = this.controlGroups.map((controlGroup, index) => (
        {
          id: index + 1,
          description: controlGroup.name,
          ros_namespace: controlGroup.settings.ros.ros_namespace.value,
          areasSide1: controlGroup.control_areas.sort((a, b) => (a.name > b.name ? 1 : -1))
            .filter(area => (
              area.name.includes('Bench') && area.occupation !== 0
            ))
            .map((area, index) => (
              {
                id: index + 1,
                description: area.name + `${area.work_order_name ? ' - ' + area.work_order_name : ''}`,
                ros_irrigation_id: area.settings.ros.ros_irrigation_id.value,
              }
            )),
          areasSide2: controlGroup.control_areas.sort((a, b) => (a.name > b.name ? 1 : -1))
            .filter(area => (
              area.name.includes('Side 2') && area.occupation !== 0
            ))
            .map((area, index) => (
              {
                id: index + 1,
                description: area.name + `${area.work_order_name ? ' - ' + area.work_order_name : ''}`,
                ros_irrigation_id: area.settings.ros.ros_irrigation_id.value,
              }
            )),
        }
      ))

      return roomOptions
    },
    controlAreaOptions () {
      let options = []

      options = this.controlGroups.map((controlGroup) => (
        {
          id: controlGroup.id,
          name: controlGroup.name,
          ros_namespace: controlGroup.settings.ros.ros_namespace.value,
          control_areas: controlGroup.control_areas.sort((a, b) => (a.id > b.id ? 1 : -1))
            .map((area) => (
              {
                id: area.id,
                name: area.name,
                ros_irrigation_id: area.settings.ros.ros_irrigation_id.value,
                selected: area.occupation === 100,
                readonly: area.occupation < 100,
              }
            )),
        }
      ))

      return options
    },
    side1Options () {
      let sideOptions = []

      if (this.selectedData.room) {
        sideOptions = this.controlGroupsOptions.filter(room => (
          room.description === this.selectedData.room.description
        )).map(option => option.areasSide1)[0]
      }

      return sideOptions
    },
    side2Options () {
      let sideOptions = []

      if (this.selectedData.room) {
        sideOptions = this.controlGroupsOptions.filter(room => (
          room.description === this.selectedData.room.description
        )).map(option => option.areasSide2)[0]
      }

      return sideOptions
    },
    fertilisersOptions () {
      const options = []

      if (this.tanks) {
        if (this.tanks['fertiliser-tank']) {
          for (let i = 0; i < this.tanks['fertiliser-tank'].length; i++) {
            this.$set(options, i,
              {
                id: i + 1,
                tank_id: this.tanks['fertiliser-tank'][i].id,
                description: this.tanks['fertiliser-tank'][i].name,
                ros_irrigation_id: this.tanks['fertiliser-tank'][i].settings.ros.ros_irrigation_id.value,
              },
            )
          }
        }
      }
      return options
    },
    noSideOptionsText () {
      if (this.selectedData.room === '') {
        return this.$t('irrigation.selectRoom')
      }
      return this.$t('irrigation.sideEmpty')
    },
    formReady () {
      let fertErrors = false
      for (const tank in this.fertilizersErrors) {
        if (this.fertilizersErrors[tank].length > 0) {
          fertErrors = true
        }
      }

      return this.originTankErrors === '' &&
        this.destinationTankErrors === '' &&
        this.roomErrors === '' &&
        this.sideErrors === '' &&
        !this.durationErrors.length &&
        !this.volumeErrors.length &&
        !this.waterDurationErrors.length &&
        !this.waterVolumeErrors.length &&
        !this.sanitizerDurationErrors.length &&
        !this.sanitizerVolumeErrors.length &&
        !this.sanitizerTransitionValueErrors.length &&
        !this.phMinErrors.length &&
        !this.phRefMinErrors.length &&
        !this.phRefErrors.length &&
        !this.phRefMaxErrors.length &&
        !this.phMaxErrors.length &&
        !this.ecMinErrors.length &&
        !this.ecRefMinErrors.length &&
        !this.ecRefErrors.length &&
        !this.ecRefMaxErrors.length &&
        !this.ecMaxErrors.length &&
        !fertErrors
    },
  },
  methods: {
    getOnlyUnitFormat,
    getCalendarLanguage () {
      if (Vue.i18n.locale() === 'it') {
        return Italian
      } else {
        return english
      }
    },
    getNowFactoryTimeString () {
      const now = getLocalNowTimeDate()
      const nowHours = ('0' + now.getHours()).slice(-2)
      const nowMinutes = ('0' + now.getMinutes()).slice(-2)
      const nowSeconds = ('0' + now.getSeconds()).slice(-2)
      return `${nowHours}:${nowMinutes}:${nowSeconds}`
    },
    setDurationVolumeFields () {
      if (this.selectedData.side1 !== '' && this.selectedData.side2 !== '') {
        this.selectedData.volume = '2.1'
        this.selectedData.duration = '600'
        this.selectedData.durationString = moment.utc(600 * 1000).format('HH:mm:ss')
      } else if (this.selectedData.side1 !== '' || this.selectedData.side2 !== '') {
        this.selectedData.volume = '1.05'
        this.selectedData.duration = '600'
        this.selectedData.durationString = moment.utc(600 * 1000).format('HH:mm:ss')
      } else {
        this.selectedData.volume = ''
        this.selectedData.duration = ''
      }
    },
    toggleSelection (roomName, selectAll) {
      const selectedGroup = this.controlAreaOptions.find(element => element.name === roomName)
      selectedGroup.control_areas.forEach((area) => { area.selected = area.readonly ? area.selected : selectAll })
    },
    confirmAddAction () {
      if (this.selectedIrrigationEvent.actionName !== 'disinfection_tank') {
        this.durationErrors = this.selectedData.durationString ? [] : [this.$t('irrigation.durationError')]
      }

      if (this.selectedIrrigationEvent.actionName !== 'empty_tank_to_waste' && this.selectedIrrigationEvent.actionName !== 'disinfection_tank') {
        this.volumeErrors = this.selectedData.volume ? [] : [this.$t('irrigation.volumeError')]
      }

      if (this.selectedIrrigationEvent.actionName === 'disinfection_tank') {
        this.waterVolumeErrors = this.selectedData.waterVolume ? [] : [this.$t('irrigation.volumeError')]
        this.waterDurationErrors = this.selectedData.waterDurationString ? [] : [this.$t('irrigation.durationError')]

        this.sanitizerVolumeErrors = this.selectedData.sanitizerVolume ? [] : [this.$t('irrigation.volumeError')]
        this.sanitizerDurationErrors = this.selectedData.sanitizerDurationString ? [] : [this.$t('irrigation.durationError')]
        this.sanitizerTransitionValueErrors = this.selectedData.sanitizerTransitionValueString ? [] : [this.$t('irrigation.transitionValueError')]
      }

      if (this.selectedIrrigationEvent.actionName === 'fill_water_tank') {
        this.originTankErrors = this.selectedData.originTank ? '' : this.$t('irrigation.originTankError')
      }

      if (this.selectedIrrigationEvent.actionName === 'recover_tank' || this.selectedIrrigationEvent.actionName === 'fill_tank' || this.selectedIrrigationEvent.actionName === 'fill_water_tank') {
        this.destinationTankErrors = this.selectedData.destinationTank ? '' : this.$t('irrigation.destinationTankError')
      }

      if (this.selectedIrrigationEvent.actionName === 'irrigate') {
        this.roomErrors = this.selectedData.room ? '' : this.$t('irrigation.roomError')
        this.sideErrors = (this.selectedData.side1 || this.selectedData.side2) ? '' : this.$t('irrigation.sideError')
      }

      if (this.selectedIrrigationEvent.actionName === 'recalibrate_tank') {
        this.phMinErrors = []
        this.phRefMinErrors = []
        this.phRefErrors = []
        this.phRefMaxErrors = []
        this.phMaxErrors = []

        this.validateValues('ph', this.selectedData.phMin, this.selectedData.phRefMin, this.selectedData.phRef, this.selectedData.phRefMax, this.selectedData.phMax)

        this.ecMinErrors = []
        this.ecRefMinErrors = []
        this.ecRefErrors = []
        this.ecRefMaxErrors = []
        this.ecMaxErrors = []

        this.validateValues('ec', this.selectedData.ecMin, this.selectedData.ecRefMin, this.selectedData.ecRef, this.selectedData.ecRefMax, this.selectedData.ecMax)

        this.fertilizersErrors = []
        this.validateTotalPercentage()
      }
    },
    validateValues (tag, min, minRef, ref, maxRef, max) {
      min = parseFloat(min)
      minRef = parseFloat(minRef)
      ref = parseFloat(ref)
      maxRef = parseFloat(maxRef)
      max = parseFloat(max)
      const maxValue = Math.max(min, minRef, ref, maxRef)
      const minValue = Math.min(minRef, ref, maxRef, max)

      if (min >= minValue) {
        tag === 'ph' ? this.phMinErrors = ['Should be the lowest'] : this.ecMinErrors = ['Should be the lowest']
      }
      if (minRef <= min) {
        tag === 'ph' ? this.phRefMinErrors = ['Should be higher than MIN'] : this.ecRefMinErrors = ['Should be higher than MIN']
      }
      if (minRef >= ref) {
        tag === 'ph' ? this.phRefMinErrors = ['Should be lowest then REF'] : this.ecRefMinErrors = ['Should be lowest then REF']
      }
      if (ref <= minRef) {
        tag === 'ph' ? this.phRefErrors = ['Should be higher than REF MIN'] : this.ecRefErrors = ['Should be higher than REF MIN']
      }
      if (ref >= maxRef) {
        tag === 'ph' ? this.phRefErrors = ['Should be lowest then REF MAX'] : this.ecRefErrors = ['Should be lowest then REF MAX']
      }
      if (maxRef <= ref) {
        tag === 'ph' ? this.phRefMaxErrors = ['Should be higher than REF'] : this.ecRefMaxErrors = ['Should be higher than REF']
      }
      if (maxRef >= max) {
        tag === 'ph' ? this.phRefMaxErrors = ['Should be lowest then MAX'] : this.ecRefMaxErrors = ['Should be lowest then MAX']
      }
      if (max <= maxValue) {
        tag === 'ph' ? this.phMaxErrors = ['Should be the highest'] : this.ecMaxErrors = ['Should be the highest']
      }
    },
    validateTotalPercentage () {
      this.fertilizersErrors = []
      for (const fertilizer in this.selectedData.fertilizers) {
        if (this.selectedData.fertilizers[fertilizer].val > 100 || this.selectedData.fertilizers[fertilizer].val < 0) {
          this.fertilizersErrors.push(['0 - 100%'])
        } else {
          this.fertilizersErrors.push([])
        }
      }
    },
    setAddNewActionData () {
      const day = moment().tz(this.$farmTimezone).format().split('T')[0]
      const startTimeStamp = getUTCtimeString(new Date(day + 'T' + this.selectedData.startTime)).split('.')[0]

      const duration = moment.duration(this.selectedData.durationString).asSeconds()

      if (this.selectedIrrigationEvent.actionName === 'fill_water_tank') {
        return {
          originTank: this.selectedData.originTank.ros_irrigation_id,
          destinationTank: this.selectedData.destinationTank.ros_irrigation_id,
          duration: duration,
          volume: this.selectedData.volume,
        }
      } else if (this.selectedIrrigationEvent.actionName === 'irrigate') {
        return {
          startTimeStamp: startTimeStamp,
          originTank: this.selectedData.originTank.ros_irrigation_id,
          side1: this.selectedData.side1 !== '' ? this.selectedData.side1.ros_irrigation_id : '',
          side2: this.selectedData.side2 !== '' ? this.selectedData.side2.ros_irrigation_id : '',
          duration: duration,
          volume: this.selectedData.volume,
        }
      } else if (this.selectedIrrigationEvent.actionName === 'fill_tank') {
        return {
          startTimeStamp: startTimeStamp,
          originTank: this.selectedData.originTank.ros_irrigation_id,
          destinationTank: this.selectedData.destinationTank.ros_irrigation_id,
          duration: duration,
          volume: this.selectedData.volume,
          overfill: this.selectedData.overfill,
        }
      } else if (this.selectedIrrigationEvent.actionName === 'recover_tank') {
        return {
          originTank: this.selectedData.originTank.ros_irrigation_id,
          destinationTank: this.selectedData.destinationTank.ros_irrigation_id,
          duration: duration,
          volume: this.selectedData.volume,
        }
      } else if (this.selectedIrrigationEvent.actionName === 'empty_tank_to_waste') {
        return {
          originTank: this.selectedData.originTank.ros_irrigation_id,
          duration: duration,
          volume: this.selectedData.volume,
        }
      } else if (this.selectedIrrigationEvent.actionName === 'circulate_tank') {
        return {
          startTimeStamp: startTimeStamp,
          originTank: this.selectedData.originTank.ros_irrigation_id,
          duration: duration,
          volume: this.selectedData.volume,
        }
      } else if (this.selectedIrrigationEvent.actionName === 'recalibrate_tank') {
        const fertilizers = []
        for (const fertilizer in this.selectedData.fertilizers) {
          fertilizers.push(
            {
              id: this.selectedData.fertilizers[fertilizer].tank_id,
              val: this.selectedData.fertilizers[fertilizer].val,
            },
          )
        }

        return {
          startTimeStamp: startTimeStamp,
          originTank: this.selectedData.originTank.ros_irrigation_id,
          duration: moment.duration(this.selectedData.durationString).asSeconds(),
          volume: this.selectedData.volume,

          phMin: this.selectedData.phMin,
          phRefMin: this.selectedData.phRefMin,
          phRef: this.selectedData.phRef,
          phRefMax: this.selectedData.phRefMax,
          phMax: this.selectedData.phMax,

          ecMin: this.selectedData.ecMin,
          ecRefMin: this.selectedData.ecRefMin,
          ecRef: this.selectedData.ecRef,
          ecRefMax: this.selectedData.ecRefMax,
          ecMax: this.selectedData.ecMax,

          fertilizers: fertilizers,
        }
      } else if (this.selectedIrrigationEvent.actionName === 'disinfection_tank') {
        const controlAreasSelected = []
        for (const controlGroup in this.controlAreaOptions) {
          for (const area in this.controlAreaOptions[controlGroup].control_areas) {
            if (this.controlAreaOptions[controlGroup].control_areas[area].selected) {
              controlAreasSelected.push(this.controlAreaOptions[controlGroup].control_areas[area].ros_irrigation_id)
            }
          }
        }

        return {
          originTank: this.selectedData.originTank.ros_irrigation_id,
          controlAreas: controlAreasSelected,
          waterDuration: moment.duration(this.selectedData.waterDurationString).asSeconds(),
          waterVolume: this.selectedData.waterVolume,
          sanitizerDuration: moment.duration(this.selectedData.sanitizerDurationString).asSeconds(),
          sanitizerVolume: this.selectedData.sanitizerVolume,
          sanitizerTransitionValue: moment.duration(this.selectedData.sanitizerTransitionValueString).asSeconds(),
        }
      }
    },
    clearFields () {
      this.selectedIrrigationEvent = {}

      this.originTankErrors = ''
      this.destinationTankErrors = ''
      this.roomErrors = ''
      this.sideErrors = ''
      this.durationErrors = []
      this.volumeErrors = []

      this.phMinErrors = []
      this.phRefMinErrors = []
      this.phRefErrors = []
      this.phRefMaxErrors = []
      this.phMaxErrors = []

      this.ecMinErrors = []
      this.ecRefMinErrors = []
      this.ecRefErrors = []
      this.ecRefMaxErrors = []
      this.ecMaxErrors = []
      this.fertilizersErrors = []

      for (const data in this.selectedData) {
        if (data === 'overfill') {
          this.selectedData[data] = false
        } else if (data === 'fertilizers') {
          this.selectedData[data] = this.fertilizerTankOptions.reduce((fertilizerArray, fertilizer) => ({
            ...fertilizerArray,
            [fertilizer.tank_id]: {
              ...fertilizer,
              val: null,
            },
          }), {})
        } else {
          this.selectedData[data] = ''
        }
      }
    },
  },
  watch: {
    'selectedData.side1' () {
      this.setDurationVolumeFields()
    },
    'selectedData.side2' () {
      this.setDurationVolumeFields()
    },
  },
}
</script>

<style lang="scss">
.va-input {
  &.disabled {
    opacity: 0.4;
  }
}

.va-modal__message {
  width: 700px;

  .va-input__container__label,
  .va-select__label {
    font-family: var(--font-family-body);
    text-transform: none;
    top: 0.25rem;
    margin-bottom: 0;
    font-size: 0.55rem;
  }

  .va-input__container__input {
    font-family: var(--font-family-body);
  }

  .min_ref_value,
  .max_ref_value {
    .va-input__container {
      border-color: #f5a455 !important;
    }
  }

  .ref_value {
    .va-input__container {
      border-color: var(--success-color) !important;
    }
  }

  .min_value,
  .max_value {
    .va-input__container {
      border-color: #ff3333 !important;
    }
  }

  .transition_value {
    .va-input__container__input {
      text-align: center;
    }

    .va-input__container::before {
      content: '\e84d';
      font-family: "atmosphere-icons";
      position: absolute;
      left: 3.8em;
      top: 0.96em;
      font-size: 1.5em;
      color: var(--primary-color);
    }
  }

  .va-select__displayed-text {
    font-size: 0.95rem;
  }

  .title {
    border-bottom: 2px solid;
    margin-bottom: 20px;

    h6 {
      @include radius(0 15px 0 0);

      width: 150px;
      display: inline-block;
      padding: 3px 20px 1px 10px;
      color: #ffffff;
      font-size: pxtorem(14);
      text-transform: uppercase;
      border-color: #94bdd1;
      background: #94bdd1;
    }

    &.day {
      border-color: #94bdd1;

      h6 {
        background: #94bdd1;
        width: 150px;
      }
    }

    &.night {
      border-color: #4d80b2;

      h6 {
        background: #4d80b2;
      }
    }
  }

  .irrigation-events__form {
    min-height: 10rem;

    .slot {
      padding: 0.75rem 0.375rem 0 0.375rem;
      background: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.1);
    }

    .slot-total-info {
      display: flex;
      align-items: center;

      span {
        font-size: 0.7rem;
        text-transform: uppercase;

        b {
          font-size: 0.9rem;
          text-transform: none;
          color: var(--primary-color);
        }
      }
    }

    .add-slot-button {
      text-align: right;
    }

    .delete-slot-button {
      position: relative;

      .va-button {
        position: absolute;
        top: -10px;
        right: 0;

        &.va-button--small {
          padding: 0.125rem 0.125rem;
        }

        i {
          font-size: 1.2rem;
        }
      }
    }

    .flex {
      .va-input,
      .va-select__dropdown {
        padding-left: 0.375rem;
        padding-right: 0.375rem;

        &.va-input-wrapper {
          margin-bottom: 0.75rem;
        }
      }
    }
  }
}

</style>
