<template>
  <div class="chart-slider">
    <button class="date-picker" id="datePicker" ref="datePicker">
      <va-icon class="at-icon l_calendar" />
    </button>

    <div class="slider-container" v-if="enableSlider">
      <div class="pins months">
        <label
          v-for="pin in sliderMax"
          :key="pin"
          :style="{
            left: calculateMonthSpacing(pin) + 'px',
            width: calculateMonthWidth(pin) + 'px'
          }"
        >
          {{ getPinMonthLabel(pin) }}
        </label>
      </div>

      <div class="pins days">
        <label
          v-for="pin in sliderMax"
          :key="pin"
          :style="{ left: calculatePinSpacing(pin) + 'px' }"
        >
          {{ getPinDayLabel(pin) }}
        </label>
      </div>

      <div
        @click.self="onPosClick"
        class="slider-container__timeline"
        :style="{ width: this.timelineWidth + 40 + 'px' }"
      >
        <div class="pins">
          <span
            v-for="pin in sliderMax"
            :key="pin"
            :style="{ left: calculatePinSpacing(pin) + 'px' }"
            :class="highlightDays(pin) ? 'highlight' : ''"
          ></span>
        </div>

        <vue-draggable-resizable
          id="draggable"
          :x="x"
          :grid="[40, 30]"
          :w="this.handleWidth"
          :h="23"
          axis="x"
          :onDrag="onDrag"
          @dragstop="onDragstop"
        >
        </vue-draggable-resizable>
      </div>

      <div class="pins hours" v-if="this.zoomLevel !== 'day'">
        <label
          v-for="pin in sliderMax"
          :key="pin"
          :style="{ left: calculatePinSpacing(pin) + 'px' }"
        >
          {{ getPinLabel(pin) }}
        </label>
      </div>
    </div>

    <div class="zoom" v-if="enableSlider">
      <h6>{{ $t('timeData.zoomLevel') }}</h6>

      <div class="zoom__options">
        <button @click="onZoomChange('day')" class="zoom__options__btn">
          <div class="zoom__options__btn__day"></div>
          <label>24h</label>
        </button>

        <button @click="onZoomChange('hour')" class="zoom__options__btn" :disabled="zoomLevel15minDisabled">
          <div
            :class="activeZoom('hour') ? 'active' : ''"
            class="zoom__options__btn__hour"
          ></div>
          <label>1h</label>
        </button>

        <button @click="onZoomChange('15min')" class="zoom__options__btn">
          <div
            :class="activeZoom('15min') ? 'active' : ''"
            class="zoom__options__btn__min"
          ></div>
          <label>15m</label>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueDraggableResizable from 'vue-draggable-resizable'
import flatpickr from 'flatpickr'
import { getLocalNowTimeDate } from '@/services/atmosphere-ui/helpers/TimeHelpers'

export default {
  name: 'AtChartsSlider',
  components: {
    VueDraggableResizable,
  },
  data () {
    return {
      enableSlider: false,
      sliderMax: null,
      sliderValue: 1,
      pinSpace: 40,
      addedDay: false,
      selectedDateArray: null,
      zoomLevel: 'hour',
      handleWidth: 40,
      selectedInvertals: null,
      timelineWidth: 0,
      x: 0,
      timeline: null,
      draggableHover: false,
      zoomLevel15minDisabled: false,
    }
  },
  mounted () {
    let maxDate = null
    let defaultDate = []

    if (this.$route.name === 'irrigation-details') {
      this.zoomLevel = 'day'
      this.zoomLevel15minDisabled = true
      const now = getLocalNowTimeDate()
      const yesterday = new Date(new Date(now.setHours(now.getHours() - 24)).setHours(0, 0))
      const tomorrow = new Date(getLocalNowTimeDate().setHours(23, 59))
      defaultDate = [yesterday, tomorrow]
    } else {
      const now = getLocalNowTimeDate()
      const twoDaysAgo = new Date(now.setHours(now.getHours() - 48))
      maxDate = getLocalNowTimeDate()
      defaultDate = [twoDaysAgo, getLocalNowTimeDate()]
    }

    this.minDate = this.$store.getters.getTimelineMinDate

    flatpickr('#datePicker', {
      mode: 'range',
      inline: false,
      dateFormat: 'd-m-Y',
      onChange: this.onDateChange,
      onReady: this.onDateChange,
      maxDate: maxDate,
      minDate: new Date(this.minDate),
      defaultDate: defaultDate,
    })
  },
  methods: {
    onZoomChange (zoomOption) {
      this.zoomLevel = zoomOption
      this.sliderValue = 1

      this.updateSliderData()
    },
    getData (pos) {
      if (this.selectedDateArray) {
        const selectedStartDate = new Date(this.selectedDateArray[0])
        const startPin = Math.floor(pos / 40)
        const endPin = Math.floor(startPin + (this.handleWidth / 40))

        let startDate = null
        let endDate = null

        switch (this.zoomLevel) {
          case 'hour':
            startDate = new Date(new Date(selectedStartDate).setHours(selectedStartDate.getHours() + startPin))
            endDate = new Date(new Date(selectedStartDate).setHours(selectedStartDate.getHours() + endPin))
            break

          case '15min':
            startDate = new Date(new Date(selectedStartDate).setHours(selectedStartDate.getHours() + startPin / 4))
            startDate.setMinutes(startDate.getMinutes() + (15 * (startPin % 4)))
            endDate = new Date(new Date(selectedStartDate).setHours(selectedStartDate.getHours() + endPin / 4))
            endDate.setMinutes(endDate.getMinutes() + (15 * (endPin % 4)))
            break

          default:
            startDate = new Date(new Date(selectedStartDate).setDate(selectedStartDate.getDate() + startPin))
            endDate = new Date(new Date(selectedStartDate).setDate(selectedStartDate.getDate() + endPin))
            break
        }

        this.selectedInvertals = { start: startDate, end: endDate, dataPoint: this.zoomLevel }
        this.$emit('selectedInvertal', this.selectedInvertals)
        this.$root.$emit('selectedInvertal', this.selectedInvertals)
      }
    },
    onDateChange (selectedDates, dateStr, instance) {
      if (selectedDates.length > 1) {
        const range = Math.ceil(Math.abs(new Date(selectedDates[1]) - new Date(selectedDates[0])) / (1000 * 60 * 60 * 24))

        if (range < 1) {
          alert('The range has to be more than 2 days!')
          instance.setDate(this.selectedDateArray)
        } else if (range > 25) {
          alert('The range has to be less than 25 days!')
          instance.setDate(this.selectedDateArray)
        } else {
          const today = new Date().getDate() === selectedDates[1].getDate() && new Date().getMonth() === selectedDates[1].getMonth() && new Date().getFullYear() === selectedDates[1].getFullYear()

          if (today) {
            const localFarmTime = getLocalNowTimeDate()
            selectedDates[1].setHours(localFarmTime.getHours())
            selectedDates[1].setMinutes(localFarmTime.getMinutes())
            this.selectedDateArray = selectedDates
          } else {
            if (this.zoomLevel !== 'day') {
              selectedDates[1].setHours(selectedDates[1].getHours() + 24)
            }
            this.selectedDateArray = selectedDates
          }

          this.updateSliderData()
        }
      }
    },
    calculatePinSpacing (pin) {
      if (pin === 1) {
        return 0
      }

      return (pin - 1) * this.pinSpace
    },
    calculateMonthSpacing (pin) {
      let leftPos = 0

      if (pin === 0 || pin === 1) {
        leftPos = 0
      } else {
        leftPos = ((pin - 1) * this.pinSpace)
      }

      return leftPos
    },
    calculateMonthWidth (pin) {
      const startDate = new Date(this.selectedDateArray[0])
      const endDate = new Date(this.selectedDateArray[1])
      let newDate = new Date(new Date(startDate).setDate(startDate.getDate() + (--pin)))
      let monthSize = new Date(newDate.getYear(), newDate.getMonth(), 0).getDate()
      let numOfSelectedSize = (monthSize - newDate.getDate())
      let width = 0

      if (this.zoomLevel !== 'day') {
        newDate = new Date(new Date(startDate).setHours(startDate.getHours() + pin))
        monthSize = monthSize * 24
        numOfSelectedSize = (monthSize - (newDate.getDate() * 24))

        if (pin === 0) {
          if (newDate.getMonth() === endDate.getMonth()) {
            numOfSelectedSize = (endDate.getDate() - startDate.getDate()) * 24
            width = numOfSelectedSize * this.pinSpace
          } else {
            width = (numOfSelectedSize) * this.pinSpace
          }
        } else if (newDate.getMonth() === startDate.getMonth() && newDate.getDate() === 1 && newDate.getHours() === 0) {
          width = numOfSelectedSize * this.pinSpace
        } else if (newDate.getMonth() === endDate.getMonth() && newDate.getDate() === 1 && newDate.getHours() === 0) {
          numOfSelectedSize = (endDate.getDate() * 24) - 24
          width = numOfSelectedSize * this.pinSpace
        } else if (newDate.getDate() === 1 && newDate.getHours() === 0) {
          width = monthSize * this.pinSpace
        }
      } else {
        if (pin === 0) {
          if (newDate.getMonth() === endDate.getMonth()) {
            numOfSelectedSize = (endDate.getDate() - startDate.getDate())
            width = (numOfSelectedSize + 1) * this.pinSpace
          } else {
            width = (numOfSelectedSize + 1) * this.pinSpace
          }
        } else if (newDate.getMonth() === startDate.getMonth() && newDate.getDate() === 1) {
          width = (numOfSelectedSize + 1) * this.pinSpace
        } else if (newDate.getMonth() === endDate.getMonth() && newDate.getDate() === 1) {
          numOfSelectedSize = endDate.getDate()
          width = (numOfSelectedSize + 1) * this.pinSpace
        } else if (newDate.getDate() === 1) {
          width = monthSize * this.pinSpace
        }
      }

      return width
    },
    getPinMonthLabel (pin) {
      const startDate = new Date(this.selectedDateArray[0])
      let newDate = null
      let label = ''

      if (this.zoomLevel === 'day') {
        newDate = new Date(new Date(startDate).setDate(startDate.getDate() + (--pin)))
      } else if (this.zoomLevel === 'hour') {
        newDate = new Date(new Date(startDate).setHours(startDate.getHours() + (--pin)))
      } else if (this.zoomLevel === '15min') {
        newDate = new Date(new Date(startDate).setHours(startDate.getHours() + (--pin / 4)))
        newDate.setMinutes(startDate.getMinutes() + (15 * (pin % 4)))
      }

      const month = newDate.toLocaleString(Vue.i18n.locale(), { month: 'long' })

      if (pin === 0) {
        const endDate = new Date(this.selectedDateArray[1])
        const numOfDaysMonth = new Date(0, newDate.getMonth(), 0).getDate()

        if (startDate.getMonth() !== endDate.getMonth() && numOfDaysMonth - newDate.getDate() < 3) {
          label = ''
        } else {
          label = `${month} ${newDate.getFullYear()}`
        }
      } else if (newDate.getDate() === 1) {
        if (this.zoomLevel === 'day') {
          label = `${month} ${newDate.getFullYear()}`
        } else if (this.zoomLevel === 'hour') {
          if (newDate.getHours() === 0) {
            label = `${month} ${newDate.getFullYear()}`
          }
        } else if (this.zoomLevel === '15min') {
          if (newDate.getHours() === 0 && newDate.getMinutes() === 0) {
            label = `${month} ${newDate.getFullYear()}`
          }
        }
      }

      return label
    },
    getPinDayLabel (pin) {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      const startDate = new Date(this.selectedDateArray[0])
      const endDate = new Date(this.selectedDateArray[1])
      let newDate = new Date(new Date(startDate).setDate(startDate.getDate() + (--pin)))
      let month = ''
      let label = ''

      if (this.zoomLevel === 'day') {
        if (newDate <= endDate) {
          label = `${newDate.getDate()}`
        }
      } else if (this.zoomLevel === 'hour') {
        newDate = new Date(new Date(startDate).setHours(startDate.getHours() + pin))

        if (newDate < endDate) {
          if (newDate.getHours() === 0) {
            month = newDate.toLocaleString(Vue.i18n.locale(), { day: 'numeric', month: 'long' })
            const weekday = newDate.toLocaleString(Vue.i18n.locale(), { weekday: 'long' })
            label = ` ${weekday}, ${month} ${newDate.getFullYear()}`
            // label = ` ${days[newDate.getDay()]}, ${month} ${newDate.getFullYear()}`
          }
        }
      } else if (this.zoomLevel === '15min') {
        newDate = new Date(new Date(startDate).setHours(startDate.getHours() + pin / 4))
        newDate.setMinutes(startDate.getMinutes() + (15 * (pin % 4)))
        if (newDate < endDate) {
          if (newDate.getHours() === 0 && newDate.getMinutes() === 0) {
            month = newDate.toLocaleString(Vue.i18n.locale(), { day: 'numeric', month: 'long' })
            label = ` ${days[newDate.getDay()]}, ${month} ${newDate.getFullYear()}`
          }
        }
      }

      return label
    },
    getPinLabel (pin) {
      const startDate = new Date(this.selectedDateArray[0])
      const endDate = new Date(this.selectedDateArray[1])

      let newHour = null
      let label = ''

      if (this.zoomLevel === 'hour') {
        newHour = new Date(startDate.setHours(startDate.getHours() + (--pin)))
        label = `${String(newHour.getHours()).padStart(2, '0')}:00`
      } else if (this.zoomLevel === '15min') {
        newHour = new Date(startDate.setHours(startDate.getHours() + (--pin / 4)))
        newHour.setMinutes(startDate.getMinutes() + (15 * (pin % 4)))
        label = `${String(newHour.getHours()).padStart(2, '0')}:${String(newHour.getMinutes()).padStart(2, '0')}`
      }

      if (newHour > endDate) {
        label = ''
      }

      return label
    },
    updateSliderData () {
      let zoomlevelCalc = 0

      switch (this.zoomLevel) {
        case 'hour':
          zoomlevelCalc = 60 * 60 * 1000
          break
        case '15min':
          zoomlevelCalc = 15 * 60 * 1000
          break
        case 'day':
          zoomlevelCalc = 86400000
          break

        default:
          zoomlevelCalc = 86400000
          break
      }

      let diff = Math.floor((Date.parse(this.selectedDateArray[1]) - Date.parse(this.selectedDateArray[0])) / zoomlevelCalc)
      if (diff) {
        if (this.zoomLevel === 'day') {
          diff++
        }

        this.enableSlider = true
        this.sliderMax = diff + 1
        this.timelineWidth = diff * this.pinSpace

        switch (this.zoomLevel) {
          case 'hour':
            this.handleWidth = 24 * this.pinSpace
            break

          case '15min':
            this.handleWidth = 24 * this.pinSpace
            break

          default:
            this.handleWidth = diff > 24 ? (24 * this.pinSpace) : (diff * this.pinSpace)
            break
        }

        // move handle to last position (startDate)
        this.x = (diff - (this.handleWidth / this.pinSpace)) * this.pinSpace

        // move scrollBar to the right
        setTimeout(() => {
          this.timeline = document.getElementsByClassName('slider-container')[0]
          this.timeline.scrollLeft = this.timeline.scrollWidth
        }, 5)

        this.getData(this.x)
      }
    },
    onDrag: function (x) {
      const limit = x + this.handleWidth

      if (limit > this.timelineWidth || x < 0) {
        return false
      } else {
        this.x = x
      }
    },
    onPosClick: function ($event) {
      const pos = Math.floor($event.offsetX / this.pinSpace) * this.pinSpace
      const limit = pos + this.handleWidth

      if (limit > this.timelineWidth) {
        return false
      } else {
        this.x = pos
        this.getData(this.x)
      }
    },
    onDragstop: function ($event) {
      this.getData(this.x)
    },
    highlightDays (pin) {
      const startDate = new Date(this.selectedDateArray[0])
      const endDate = new Date(this.selectedDateArray[1])
      let newDate = null

      if (newDate <= endDate) {
        if (this.zoomLevel === 'day') {
          newDate = new Date(new Date(startDate).setDate(startDate.getDate() + (--pin)))

          if (newDate.getDate() === 1) {
            return true
          }
        } else if (this.zoomLevel === 'hour') {
          newDate = new Date(new Date(startDate).setHours(startDate.getHours() + (--pin)))

          if (newDate.getHours() === 0) {
            return true
          }
        } else if (this.zoomLevel === '15min') {
          newDate = new Date(new Date(startDate).setHours(startDate.getHours() + (--pin / 4)))
          newDate.setMinutes(startDate.getMinutes() + (15 * (pin % 4)))

          if (newDate.getHours() === 0 && newDate.getMinutes() === 0) {
            return true
          }
        }
      }

      return false
    },
    activeZoom (zoomOption) {
      if (zoomOption === 'hour') {
        return this.zoomLevel === 'hour' || this.zoomLevel === '15min'
      } else if (zoomOption === '15min') {
        return this.zoomLevel === '15min'
      }

      return false
    },
  },
  computed: {
    computedStyle () {
      return null
    },
  },
  watch: {
    '$store.state.timelineMinDate' () {
      this.$refs.datePicker._flatpickr.config.minDate = new Date(this.$store.getters.getTimelineMinDate)
    },
  },
}
</script>

<style lang="scss">

.chart-slider {
  @include card-radius();
  @include shadow($radius: var(--shadow-radius));
  @include card-gradient();

  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  z-index: 2;

  .date-picker {
    border: none;
    color: var(--primary-color);
    outline: none;
    font-size: 2.5rem;
    cursor: pointer;
    background: none;
    margin-right: 50px;

    .va-icon {
      @include card-radius();

      background: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.1);
      width: 50px;
      height: 52px;

      &::before {
        margin-top: 6px;
        margin-left: 5px;
      }
    }
  }

  .zoom {
    margin-left: 50px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    user-select: none;

    h6 {
      font-size: pxtorem(12);
      display: block;
      width: 100%;
      text-align: center;
      user-select: none;
    }

    &__options {
      display: flex;
      justify-items: start;

      &__btn {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0;

        &:not(:last-of-type) {
          margin-right: 5px;
        }

        &__day,
        &__hour,
        &__min {
          width: 23px;
          height: 23px;
          background: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.3);

          &.active {
            background: var(--primary-color);
          }
        }

        &__day {
          background: var(--primary-color);
          clip-path: polygon(0 100%, 100% 65%, 100% 100%, 0% 100%);
        }

        &__hour {
          clip-path: polygon(0 65%, 100% 35%, 100% 100%, 0 100%);
        }

        &__min {
          clip-path: polygon(0 35%, 100% 5%, 100% 100%, 0 100%);
        }

        label {
          font-size: pxtorem(12);
          font-weight: 600;
        }
      }
    }
  }

  .slider-container {
    height: 105px;
    padding: 25px 0 0 20px !important;
    overflow-x: auto;
    width: 100%;

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      height: 10px;
    }

    .pins {
      position: relative;
      z-index: 0;

      &.months {
        label {
          text-align: center;
          position: absolute;
          top: -20px;
          left: 0;
          font-size: 1rem;
          white-space: nowrap;
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -khtml-user-select: none;
          -ms-user-select: none;
        }
      }

      &.days {
        label {
          width: 40px;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 0.75rem;
          font-weight: 600;
          white-space: pre;
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -khtml-user-select: none;
          -ms-user-select: none;
        }
      }

      &.hours {
        label {
          width: 40px;
          text-align: center;
          position: absolute;
          top: 5px;
          transform: translate(-20px);
          font-size: 0.65rem;
          font-weight: 500;
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -khtml-user-select: none;
          -ms-user-select: none;
        }
      }
    }

    &__timeline {
      margin-top: 20px;
      height: 23px;
      position: relative;
      display: block;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        border-top: 3px solid hsla(var(--primary-color-hue), var(--primary-color-sat), 20%, 0.3);
        width: calc(100% - 40px);
        transform: translateY(-50%);
      }

      .pins {
        position: relative;
        z-index: 0;

        span {
          position: absolute;
          top: 0;
          width: 1px;
          height: 20px;
          background: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.7);
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -khtml-user-select: none;
          -ms-user-select: none;

          &::before {
            content: '●';
            color: var(--primary-color);
            position: absolute;
            top: -0.1em;
            left: -0.28em;
            transform: scale(0.7);
          }

          &.highlight {
            height: 35px;
            top: -15px;

            &::before {
              top: 0.85em;
            }
          }
        }
      }

      .draggable {
        height: 23px;
        background: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.25);
        color: #ffffff;
        text-align: center;
        cursor: move;
        border-radius: 20px;

        &__inner {
          position: relative;
          width: 100%;
          height: 100%;
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          background: hsla(var(--primary-color-hue), var(--primary-color-sat), var(--primary-color-lig), 0.75);
          width: 10px;
          height: 100%;
        }

        &::before {
          left: 0;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        &::after {
          right: 0;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
}
</style>
