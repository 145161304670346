import { hex2hsl } from 'vuestic-ui/src/services/color-functions'

/**
 * Converts a HSL color value to HEX. Conversion formula
 * adapted from https://stackoverflow.com/a/44134328/1880662
 * @param number h
 * @param number s
 * @param number l
 */
const hslToHex = (h, s, l) => {
  l /= 100
  const a = s * Math.min(l, 1 - l) / 100
  const f = n => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color).toString(16).padStart(2, '0') // convert to Hex and prefix "0" if needed
  }

  return `#${f(0)}${f(8)}${f(4)}`
}

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   Number  h       The hue
 * @param   Number  s       The saturation
 * @param   Number  l       The lightness
 * @return  Object          The RGB representation
 */
const hslToRgb = (h, s, l) => {
  let r, g, b

  if (s === 0) {
    r = g = b = l // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q

    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) }
}

/**
 * Generate a Gradient background
 * @param String color
 * @param Object args
 * @return Array with CSS background vendors
 */
const genGradient = (color, { ...args }) => {
  const hslColor = hex2hsl(color)

  args = {
    thirdColor: args.thirdColor === undefined ? false : args.thirdColor,
    deg: args.deg === undefined ? 135 : args.deg,
    startHue: args.startHue === undefined ? hslColor.h : args.startHue,
    startSat: args.startSat === undefined ? hslColor.s : args.startSat,
    startLum: args.startLum === undefined ? hslColor.l : args.startLum,
    startAlpha: args.startAlpha === undefined ? 1 : args.startAlpha,
    startPoint: args.startPoint === undefined ? 0 : args.startPoint,
    middleHue: args.middleHue === undefined ? hslColor.h : args.middleHue,
    middleSat: args.middleSat === undefined ? hslColor.s : args.middleSat,
    middleLum: args.middleLum === undefined ? hslColor.l : args.middleLum,
    middleAlpha: args.middleAlpha === undefined ? 1 : args.middleAlpha,
    middlePoint: args.middlePoint === undefined ? 50 : args.middlePoint,
    endHue: args.endHue === undefined ? hslColor.h : args.endHue,
    endSat: args.endSat === undefined ? hslColor.s : args.endSat,
    endLum: args.endLum === undefined ? hslColor.l : args.endLum,
    endAlpha: args.endAlpha === undefined ? 1 : args.endAlpha,
    endPoint: args.endPoint === undefined ? 100 : args.endPoint,
  }

  const rgbStart = hslToRgb((args.startHue / 360), (args.startSat / 100), (args.startLum / 100))
  const rgbaStart = `rgba(${rgbStart.r}, ${rgbStart.g}, ${rgbStart.b}, ${args.startAlpha})`
  const rgbMiddle = hslToRgb((args.middleHue / 360), (args.middleSat / 100), (args.middleLum / 100))
  const rgbaMiddle = `rgba(${rgbMiddle.r}, ${rgbMiddle.g}, ${rgbMiddle.b}, ${args.middleAlpha})`
  const rgbEnd = hslToRgb((args.endHue / 360), (args.endSat / 100), (args.endLum / 100))
  const rgbaEnd = `rgba(${rgbEnd.r}, ${rgbEnd.g}, ${rgbEnd.b}, ${args.endAlpha})`
  const gradient = !args.thirdColor ? `linear-gradient(${args.deg}deg, ${rgbaStart}, ${rgbaEnd})` : `linear-gradient(${args.deg}deg, ${rgbaStart}, ${rgbaMiddle}, ${rgbaEnd})`

  const background = [
    `${color}`,
    `${'-webkit-' + gradient}`,
    `${'-moz-' + gradient}`,
    `${gradient}`,
    `filter: progid:dximagetransform.microsoft.gradient(startcolorstr=${rgbaStart}, endcolorstr=${rgbaEnd}, gradienttype=1)`,
  ]

  return background
}

/**
 * Generate a Gradient background for the Nav and Sidebar components
 * @param String color
 * @param String selected day/night mode
 * @return Array with CSS background vendors
 */
const navGradient = (color, type, mode) => {
  const dayMode = mode === 'day' ?? false

  let background = genGradient(color, {
    deg: 135,
    startSat: dayMode ? 60 : 50,
    startLum: dayMode ? 48 : 20,
    startAlpha: 1,
    endSat: 50,
    endLum: dayMode ? 25 : 12,
    endAlpha: 1,
  })

  switch (type) {
    case 'danger':
      background = genGradient(color, {
        deg: 135,
        startHue: 0,
        startSat: 60,
        startLum: dayMode ? 48 : 30,
        startAlpha: 1,
        endSat: 50,
        endLum: dayMode ? 35 : 12,
        endAlpha: 1,
        endPoint: 30,
      })
      break

    case 'warning':
      background = genGradient(color, {
        deg: 135,
        startSat: dayMode ? 60 : 50,
        startLum: dayMode ? 48 : 20,
        startAlpha: 1,
        startPoint: 60,
        endHue: 275,
        endSat: dayMode ? 60 : 50,
        endLum: dayMode ? 35 : 20,
        endAlpha: 1,
      })
      break
  }

  return background
}

/**
 * Generate a Gradient background for the Card components
 * @param String color
 * @param String selected day/night mode
 * @return Array with CSS background vendors
 */
const cardGradient = (color, type, mode) => {
  const dayMode = mode === 'day' ?? false

  let background = genGradient(color, {
    deg: 135,
    startSat: dayMode ? 50 : 40,
    startLum: dayMode ? 100 : 14,
    startAlpha: 0.9,
    endSat: dayMode ? 50 : 40,
    endLum: dayMode ? 95 : 12,
    endAlpha: 0.9,
  })

  switch (type) {
    case 'danger':
      background = genGradient(color, {
        deg: 135,
        startHue: 0,
        startSat: dayMode ? 100 : 60,
        startLum: dayMode ? 95 : 30,
        startAlpha: dayMode ? 0.9 : 1,
        endSat: dayMode ? 50 : 40,
        endLum: dayMode ? 95 : 12,
        endAlpha: 0.9,
      })
      break
  }

  return background
}

/**
 * Generate a Gradient background for the Button components
 * @param String color
 * @return Array with CSS background vendors
 */
const buttonGradient = (color) => {
  const colorHsl = hex2hsl(color)
  let args = {}

  if (colorHsl.h > 90 && colorHsl.h < 270 && colorHsl.s >= 30 && colorHsl.l >= 30) {
    // interface colours
    args = {
      deg: 90,
      startSat: 40,
      startLum: 50,
      endHue: hex2hsl(color).h + 20,
      endSat: 40,
      endLum: 50,
    }
  } else {
    if (colorHsl.s >= 30 && colorHsl.l >= 30) {
      // communicaion colours
      args = {
        deg: 135,
        startSat: 100,
        startLum: hex2hsl(color).l + 20,
      }
    } else {
      // dark & grey colours
      args = {
        endLum: hex2hsl(color).l - 20,
      }
    }
  }

  const background = genGradient(color, args)

  return background
}

/**
 * Generate a element Shadow
 * @param String color
 * @param String day/night mode
 * @param Object args
 * @return String with CSS shadow
 */
const genShadow = (color, type, mode, { ...args }) => {
  const dayMode = mode === 'day' ?? false

  args = {
    offsetY: args.offsetY === undefined ? 3 : args.offsetY,
    offsetX: args.offsetX === undefined ? 3 : args.offsetX,
    radius: args.radius === undefined ? 6 : args.radius,
    spread: args.spread === undefined ? 0 : args.spread,
    alpha: args.alpha === undefined ? (dayMode ? 0.5 : 0.9) : args.alpha,
  }

  const hslColor = hex2hsl(color)
  let sat = 40
  let lum = dayMode ? 0.25 : 0.12
  let hsla = `hsla(${hslColor.h}, ${sat}, ${lum}, ${args.alpha})`

  switch (type) {
    case 'danger':
      sat = dayMode ? 1 : 0.6
      lum = dayMode ? 0.5 : 0.3
      args = {
        offsetY: 0,
        offsetX: 0,
        radius: 30,
        spread: dayMode ? -5 : 0,
        alpha: 0.8,
      }
      hsla = `hsla(0, ${sat}, ${lum}, ${args.alpha})`
      break
  }

  const shadow = `${args.offsetY}px ${args.offsetX}px ${args.radius}px ${args.spread}px ${hsla}`

  return shadow
}

/**
 * Generate an element Radius
 * @param Object args
 * @return String with CSS border radius
 */
const genRadius = (args = { radius: 0, topLeft: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 }) => {
  args = {
    radius: args.radius === undefined ? -1 : args.radius,
    topLeft: args.topLeft === undefined ? 0 : args.topLeft,
    topRight: args.topRight === undefined ? 0 : args.topRight,
    bottomRight: args.bottomRight === undefined ? 0 : args.bottomRight,
    bottomLeft: args.bottomLeft === undefined ? 0 : args.bottomLeft,
  }

  let radius = `${args.radius}`

  if (args.radius === -1) {
    radius = `${args.topLeft} ${args.topRight} ${args.bottomRight} ${args.bottomLeft}`
  }

  return radius
}

/**
 * Convert RGB to HEX color format
 * @param Integer red
 * @param Integer greed
 * @param Integer blue
 */
const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
  const hex = x.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}).join('')

/**
 * Set custom Saturation and Luminess in HEX color
 * @param String color
 * @param Object args
 * @return String with CSS border radius
 */
const setSatLumHexColor = (color, args = { sat: 0, lum: 0 }) => {
  const hslColor = hex2hsl(color)

  args = {
    sat: args.sat === undefined ? hslColor.s : args.sat,
    lum: args.lum === undefined ? hslColor.l : args.lum,
  }

  const rgbColor = hslToRgb((hslColor.h / 360), (args.sat / 100), (args.lum / 100))

  return rgbToHex(rgbColor.r, rgbColor.g, rgbColor.b)
}

/**
 * Set custom Saturation and Luminess in RGBa color
 * @param String color
 * @param Number alpha
 * @param Object args
 * @return String with CSS border radius
 */
const setSatLumRGBaColor = (color, alpha, args = { sat: 0, lum: 0 }) => {
  const hslColor = hex2hsl(color)

  args = {
    sat: args.sat === undefined ? hslColor.s : args.sat,
    lum: args.lum === undefined ? hslColor.l : args.lum,
  }

  const rgbColor = hslToRgb((hslColor.h / 360), (args.sat / 100), (args.lum / 100))

  return `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${alpha})`
}

/**
 * Get HUE range based on View Level
 * @param String currentView
 * @param String previousView
 * @return Value to add to the current HUE
 */
const getViewLevelHueRange = (currentView, previousView) => {
  const levels = ['ground', 'bird', 'airplane', 'satelite']
  const currentLevelIndex = levels.indexOf(currentView)
  const previousLevelIndex = levels.indexOf(previousView)

  return (currentLevelIndex - previousLevelIndex) * 10
}

/**
 * Get shadow radius based on View Level
 * @param String currentView
 * @return Value to add to the current HUE
 */
const getViewLevelShadowRadius = (currentView) => {
  const levels = [
    { level: 'ground', value: '6px' },
    { level: 'bird', value: '12px' },
    { level: 'airplane', value: '24px' },
    { level: 'satelite', value: '36px' },
  ]

  return levels.find(i => i.level === currentView).value
}

/**
 * Get shape radius based on View Level
 * @param String currentView
 * @return Value to add to the current HUE
 */
const getViewLevelShapeRadius = (currentView) => {
  const levels = [
    { level: 'ground', value: '24px' },
    { level: 'bird', value: '12px' },
    { level: 'airplane', value: '6px' },
    { level: 'satelite', value: '3px' },
  ]

  return levels.find(i => i.level === currentView).value
}

const setOpacityForHexColor = (color, opacity) => {
  const hsl = hex2hsl(color)
  const rgb = hslToRgb(hsl.h, hsl.s, hsl.l)

  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`
}

const unitFormat = (value, units) => {
  const unitFormatList = [
    {
      unit: '',
      value: '',
      precison: 2,
    },
    {
      unit: 'oC',
      value: 'ºC',
      precison: 1,
    },
    {
      unit: '%',
      value: '%',
      precison: 1,
    },
    {
      unit: 'ppm',
      value: 'ppm',
      precison: 1,
    },
    {
      unit: 'kPa',
      value: 'kPa',
      precison: 2,
    },
    {
      unit: 'mV',
      value: 'mV',
      precison: 1,
    },
    {
      unit: 'umol/m2/s',
      value: 'μmol/m²/s',
      precison: 1,
    },
    {
      unit: 'μmol/m2/s',
      value: 'μmol/m²/s',
      precison: 2,
    },
    {
      unit: 'mS/cm',
      value: 'mS/cm',
      precison: 2,
    },
    {
      unit: 'm3',
      value: 'm³',
      precison: 2,
    },
    {
      unit: 'm3/h',
      value: 'm³/h',
      precison: 0,
    },
  ]

  value = (typeof value !== 'undefined' && value !== null) ? (Math.round(value * 100) / 100) : '-'
  units = units ?? '-'

  if (value === '-' || units === '-') {
    return '-'
  }

  let precision = 0
  const unitFormat = unitFormatList.find(i => i.unit === units)

  if (unitFormat) {
    units = unitFormat.value
    precision = (units === 'm³/h' && value < 100) ? 2 : unitFormat.precison
  }

  const splitedValue = value.toFixed(precision).toString().split('.')
  // const splitedValue = parseFloat(parseFloat(value)).toString().split('.')
  let formatedUnit = `${splitedValue[0]}<sub>.${splitedValue[1]}${units}</sub>`

  if (splitedValue[1] === undefined) {
    formatedUnit = `${splitedValue[0]}<sub>${units}</sub>`
  }

  return formatedUnit
}

const growingIcon = (context, dataName) => {
  let icon = ''
  switch (dataName) {
    case 'temperature':
      icon = 'at-icon l_mesure_intemperature'
      break
    case 'relative_humidity':
      icon = 'at-icon l_mesure_inhumidity'
      break
    case 'co2':
      icon = 'at-icon l_co2'
      break
    case 'dew_point':
      icon = 'at-icon l_dewpoint'
      break
    case 'vpd':
      icon = 'at-icon l_water1drop'
      break
    case 'ph':
      icon = 'at-icon l_mesure_ph'
      break
    case 'electrical_conductivity':
      icon = 'at-icon l_plusminus'
      break
    case 'orp':
      icon = 'at-icon l_plusminus'
      break
    case 'pressure':
      icon = 'at-icon l_menuplants'
      break
    case 'volume_flow':
      icon = context === 'irrigation' ? 'at-icon l_weather_fog' : 'at-icon l_airflow'
      break
    case 'volume':
      icon = context === 'irrigation' ? 'at-icon l_water_volume' : 'at-icon l_air_volume'
      break
    case 'ppfd':
      icon = 'at-icon l_ppfd'
      break
    default:
      break
  }
  return icon
}

const getGradient = (ctx, chartArea) => {
  let width, height, gradient
  const chartWidth = chartArea.right - chartArea.left
  const chartHeight = chartArea.bottom - chartArea.top
  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth
    height = chartHeight
    gradient = ctx.createLinearGradient(chartArea.right, 0, chartArea.left, 0)
    gradient.addColorStop(1, 'rgb(60, 28, 123)') // 350nm
    gradient.addColorStop(0.9, 'rgb(0, 114, 187)') // 400nm
    gradient.addColorStop(0.8, 'rgb(0, 151, 134)') // 450nm
    gradient.addColorStop(0.7, 'rgb(60, 156, 44)') // 500nm
    gradient.addColorStop(0.6, 'rgb(215, 221, 17)') // 550nm
    gradient.addColorStop(0.5, 'rgb(255, 201, 17)') // 600nm
    gradient.addColorStop(0.4, 'rgb(238, 59, 15)') // 650nm
    gradient.addColorStop(0.3, 'rgb(225, 0, 24)') // 700nm
    gradient.addColorStop(0.2, 'rgb(188, 0, 26)') // 750nm
    gradient.addColorStop(0.1, 'rgb(138, 0, 21)') // far red 800nm
  }
  return gradient
}

const getOnlyUnitFormat = (units) => {
  const unitFormatList = [
    {
      unit: '',
      value: '',
    },
    {
      unit: 'ºC',
      value: 'ºC',
    },
    {
      unit: 'oC',
      value: 'ºC',
    },
    {
      unit: '%',
      value: '%',
    },
    {
      unit: 'ppm',
      value: 'ppm',
    },
    {
      unit: 'kPa',
      value: 'kPa',
    },
    {
      unit: 'mV',
      value: 'mV',
    },
    {
      unit: 'umol/m2/s',
      value: 'μmol/m²/s',
    },
    {
      unit: 'μmol/m2/s',
      value: 'μmol/m²/s',
    },
    {
      unit: 'μmol/m2/s',
      value: 'μmol/m²/s',
    },
    {
      unit: 'mS/cm',
      value: 'mS/cm',
    },
    {
      unit: 'm3',
      value: 'm³',
    },
    {
      unit: 'm3/h',
      value: 'm³/h',
    },
  ]
  const unitFormat = unitFormatList.find(i => i.unit === units)
  return unitFormat ? unitFormat.value : units
}

export {
  genGradient,
  navGradient,
  cardGradient,
  genShadow,
  genRadius,
  setSatLumHexColor,
  hex2hsl,
  getViewLevelHueRange,
  hslToHex,
  getViewLevelShadowRadius,
  getViewLevelShapeRadius,
  setOpacityForHexColor,
  buttonGradient,
  unitFormat,
  growingIcon,
  getGradient,
  getOnlyUnitFormat,
  setSatLumRGBaColor,
}
