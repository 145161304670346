export const breadcrumbs = {
  root: {
    name: '/',
    displayName: 'menu.home',
  },
  routes: [
    {
      name: 'growing',
      displayName: 'menu.growing',
      children: [
        {
          name: 'farm-overview',
          displayName: 'menu.farm-overview',
          children: [
            {
              name: 'room-details',
              displayName: 'menu.room-details',
              children: [
                {
                  name: 'area-details',
                  displayName: 'menu.area-details',
                  children: [
                    {
                      name: 'area-recipe-growing-edit',
                      displayName: 'menu.recipe-growing-edit',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'irrigation-overview',
          displayName: 'menu.irrigation-overview',
          children: [
            {
              name: 'irrigation-details',
              displayName: 'menu.irrigation-details',
              children: [
                {
                  name: 'tank-details',
                  displayName: 'menu.irrigation-tank',
                },
                {
                  name: 'fresh-water-tank-details',
                  displayName: 'menu.fresh-water-tank',
                },
                {
                  name: 'drainage-tank-details',
                  displayName: 'menu.drainage-tank',
                },
                {
                  name: 'recovery-tank-details',
                  displayName: 'menu.recovery-tank',
                },
                {
                  name: 'mixing-tank-details',
                  displayName: 'menu.recovery-tank',
                },
              ],
            },
          ],
        },
        {
          name: 'recipes-growing',
          displayName: 'menu.growing-recipes',
          children: [
            {
              name: 'recipe-growing-new',
              displayName: 'menu.recipe-growing-create',
            },
            {
              name: 'recipe-growing-edit',
              displayName: 'menu.recipe-growing-edit',
            },
            {
              name: 'recipe-growing-duplicate',
              displayName: 'menu.recipe-growing-duplicate',
            },
          ],
        },
      ],
    },
    {
      name: 'notifications',
      displayName: 'menu.notifications',
    },
  ],
}
