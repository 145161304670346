export const fillDelaySensorOptions = [
  {
    id: 1,
    description: 'option 1',
    value: 'option1',
  },
  {
    id: 2,
    description: 'option 2',
    value: 'option2',
  },
]

export const emptyAuxiliaryEvent = {
  duration_sec: 0,
  ec_setpoint: 0,
  fertilizer_1: 0,
  fertilizer_2: 0,
  fertilizer_3: 0,
  fertilizer_4: 0,
  major_event: '',
  minor_events_list: '[]',
  ph_setpoint: 0,
  starting_time: '',
  water_volume: 0,
}
