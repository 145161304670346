<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="197px" height="197px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="30" stroke-width="3" stroke="#23A3D2" stroke-dasharray="45.553093477052 45.553093477052" fill="none" stroke-linecap="round">
        <animateTransform attributeName="transform" type="rotate" dur="1.3513513513513513s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
      </circle>
      <circle cx="50" cy="50" r="23" stroke-width="3" stroke="#66BCA1" stroke-dasharray="39.269908169872416 39.269908169872416" stroke-dashoffset="39.269908169872416" fill="none" stroke-linecap="round">
        <animateTransform attributeName="transform" type="rotate" dur="1.3513513513513513s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>
      </circle>
    </svg>
  </div>
</template>

<script>

export default {
  name: 'spinner',
}
</script>
